import { Route, Redirect, Switch, useLocation, useHistory, BrowserRouter } from 'react-router-dom';
import { Registration } from "./pages/Registration/Registration";
import React, { useEffect, useState, createContext } from "react";
import {addPaymentTokens, ConfirmEmailAPI } from "./Helper/API";
import 'react-toastify/dist/ReactToastify.css';
import Profile from "./pages/Profile/Profile/Profile";
import { ProfileSearch } from "./pages/Profile/ProfileSearch";
import { ProfileInbox } from "./pages/Profile/ProfileInbox";
import { BookingOverview } from "./pages/Booking/BookingOverview";
import { Header } from "./pages/Header/Header";
import NotFound from "./pages/Information/NotFound";
import { ProfileFavorites } from "./pages/Profile/ProfileFavorites";
import { ProfileSupportTickets } from "./pages/Profile/ProfileSupportTickets";
import { ProfileAdmin } from "./pages/Profile/ProfileAdmin";
import { ProfileAdditionalInformation } from "./pages/Profile/ProfileAdditionalInformation/ProfileAdditionalInformation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./pages/Profile/CheckoutForm";
import { CheckoutFormSuccess } from "./pages/Profile/CheckoutFormSuccess";
import { HomePage } from "./pages/Website/HomePage";
import { Impressum } from "./pages/Website/Impressum/Impressum";
import { Privacy } from "./pages/Website/Privacy/Privacy";
import { ProfileTransactionHistory } from "./pages/Profile/ProfileTransactionHistory";
import { BookingPaymentResponse } from "./pages/Booking/BookingPaymentResponse";
import { Footer } from "./pages/Footer/Footer";
import {FooterNew} from "./pages/Footer/FooterNew";


const stripePromise = loadStripe('pk_test_51KCNJeEjKmnELJngWmNGoPhnWypuNHPCejtgAOMxLVOBSoHZP5QJU5xHIa1DW1eQlixkQBxoD38FL76OH8fiiWRR003ew3tgM5')
export let options = {
    clientSecret: ''
}

// @ts-ignore
export const UserContext = createContext()

function App() {
    const [loggedInToken, setLoggedInToken] = useState<undefined | string>()
    const [loggedIn, setLoggedIn] = useState(false)
    const [clientSecret, setClientSecret] = useState({
        clientSecret: ''
    });
    const token = localStorage.getItem('token') as string
    const currentType = localStorage.getItem('type')

    useEffect(() => {
        if (token?.length < 1) {
            setLoggedIn(false)
        } else {
            setLoggedInToken(token)
            setLoggedIn(true)
        }
    }, [])

    options = {
        clientSecret: clientSecret.clientSecret
    };

    const selectedLanguage = localStorage.getItem('language');

    useEffect(()=>{
        if(selectedLanguage === null) {
            localStorage.setItem('language', 'de')
        }
    },[])


    useEffect(() => {
        if (loggedInToken && currentType !== "ADMIN") {
            addPaymentTokens({ amount: 1000 }).then(res => {
                setClientSecret({
                    clientSecret: res.clientSecret
                })
            }).catch(error => error);
        }
    }, [loggedInToken])


    return (
        <UserContext.Provider value={{ loggedInToken, setLoggedInToken }}>
            <BrowserRouter>
                <Switch>
                    <Route path="/website">
                        <Header />
                        <HomePage />
                    </Route>
                    <Route path="/login">
                        <Redirect to={'/website/login'}/>
                    </Route>
                    <Route path="/" exact>
                        {loggedIn ? <Redirect to="/profile/0" /> : <Redirect to="/website" />}
                    </Route>
                    <Route path="/impressum">
                        <Header />
                        <Impressum />
                        <FooterNew/>
                    </Route>
                    <Route path="/privacy">
                        <Header />
                        <Privacy />
                        <FooterNew/>
                    </Route>
                    <Route path="/registration">
                        <Header/>
                        <Registration />
                        <FooterNew/>
                    </Route>
                    <Route exact path="/profile/:id">
                        <Header />
                        <Profile />
                        <FooterNew />
                    </Route>
                    <Route path="/inbox">
                        <Header />
                        <ProfileInbox />
                        <FooterNew />
                    </Route>
                    <Route path="/admin">
                        <Header />
                        <ProfileAdmin />
                    </Route>
                    <Route path="/payment-response">
                        <CheckoutFormSuccess />
                    </Route>
                    <Route path="/booking-payment">
                        <BookingPaymentResponse />
                    </Route>
                    <Route path="/search">
                        <Header />
                        <ProfileSearch />
                        <FooterNew />
                    </Route>
                    <Route path="/overview">
                        <Header />
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <BookingOverview />
                            </Elements>
                        )}
                        <FooterNew/>
                    </Route>
                    <Route path="/additional-information/:id">
                        <Header />
                        <ProfileAdditionalInformation />
                        <FooterNew />
                    </Route>
                    <Route path="/favorites/:id">
                        <Header />
                        <ProfileFavorites />
                        <FooterNew />
                    </Route>
                    <Route path="/support/:id">
                        <Header />
                        <ProfileSupportTickets />
                        <FooterNew />
                    </Route>
                    <Route path="/transaction-history/:id">
                        <Header />
                        <ProfileTransactionHistory />
                        <FooterNew />
                    </Route>
                    <Route path="/currency/:id">
                        <Header />
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        )}
                        <FooterNew />
                    </Route>
                    <Route path="/auth/social/redirect">
                        <Header />
                        <CONFIRM_SOCIAL />
                    </Route>
                    <Route path="/auth/confirm-password">
                        <CONFIRMEMAIL />
                    </Route>
                    <Route path="/auth/change-password"
                    render={(props) =>
                        <Redirect to={{
                            pathname: `/website${props.location.search}`,
                        }}/>}
                    >
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </BrowserRouter>
        </UserContext.Provider>
    );
}

function CONFIRMEMAIL() {
    const token = useQuery("token");
    const history = useHistory();
    useEffect(() => {
        ConfirmEmailAPI(token!)
        history.push('/website')
    }, []);
    return null;
}

export function CONFIRM_SOCIAL() {
    const token = useQuery("token");
    const type = useQuery("type");
    const history = useHistory();
    useEffect(() => {
        localStorage.setItem('token', token!)
        localStorage.setItem('type', type!);
        history.push('/profile/0')
    }, []);
    return null;
}

export function useQuery(key: string) {
    return new URLSearchParams(useLocation().search).get(key);
}

export default App
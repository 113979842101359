import classes from './FooterNew.module.scss'
import {Link} from "react-router-dom";
export function FooterNew() {
    return (
        <div className={classes.footer}>
            <div className={classes.link}><Link to={'/impressum'}>Impressum</Link></div>
            <div className={classes.link}><Link to={'/'}>Kontakt</Link></div>
            <div className={classes.link}><Link to={'/privacy'}>Datenschutz</Link></div>
        </div>
    )
}
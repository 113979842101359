import {Col} from "react-bootstrap";
import classes from "../Styles/Login.module.scss";
import {ILogin, IRegister} from "../../pages/Registration/Registration";
import {EyeClose, EyeOpen} from "../Icons/Icons";
import {useState} from "react";
import {I18N} from "../../i18n/i18n";


//
interface IState {
    state: ILogin;
    keyUp?: any;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}


interface IRegistration {
    state: IRegister;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}


const LoginInput = ({changeHandler, state, keyUp}: IState) => {

    return (
        <>
            <Col md={12}
                 className="pt-5 d-flex flex-column align-items-center justify-content-center">
                <input className={classes.input} name="email" placeholder={`${I18N('enterAddress')}*`}
                       type="email" onChange={changeHandler} value={state.email}/>
                <input className={`${classes.input} mt-4`} name="password"
                       onKeyUp={keyUp}
                       placeholder={`${I18N('enterPassword')}*`}
                       type="password" onChange={changeHandler}
                       value={state.password}/>
            </Col>
        </>
    );
}

export default LoginInput;

export const RegistrationInputPassword = ({changeHandler, state}: IRegistration) => {

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    return (
        <>
            <input className="input-primary"
                   type={passwordShown ? "text" : "password"}
                   placeholder={`${I18N('enterPassword')}*`}
                   aria-label="Password" name="password"
                   onChange={changeHandler} value={state?.password}/>
            <span onClick={togglePassword}>
                {passwordShown ? <EyeOpen/> : <EyeClose/>}
            </span>
            <input className="input-primary"
                   type="password"
                   placeholder={`${I18N('confirmPassword')}*`} name="rePassword" aria-label="rePassword"
                   onChange={changeHandler}
                   value={state?.rePassword}/>
            <p className="text-center my-1 pt-1">
                {I18N('pass_characters')}
            </p>
        </>
    );
}

export const RegistrationInput = ({changeHandler, state}: IRegistration) => {
    return (
        <>
            <input className="input-primary"
                   type="text"
                   name="firstName"
                   onChange={changeHandler}
                   value={state.firstName}
                   placeholder={`${I18N('enter_first_name')}*`}/>
            <input className="input-primary"
                   type="text"
                   name="lastName"
                   onChange={changeHandler}
                   value={state.lastName} placeholder={`${I18N('lastName')}*`} />
            <input className="input-primary"
                   type="email"
                   placeholder={`${I18N('enterAddress')}*`} name="email"
                   onChange={changeHandler}
                   value={state.email}/>

        </>
    );
}

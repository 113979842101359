import React, {useContext, useEffect, useState} from 'react';
import {ILogin, IRegister} from "../Registration/Registration";
import classes from './Login.module.scss'
import {addNewPassword, forgotPasswordAPI, LoginAPI, RegistrationAPI as register} from "../../Helper/API";
import {apiSuccessHandler, apiWarningHandler} from "../../Helper/api.services";
import {I18N} from "../../i18n/i18n";
import {BASE_PATH} from "../../Helper/api.route";
import {GmailIcon, ArrowLeft} from "../../components/Icons/Icons";
import { useHistory, useLocation} from "react-router-dom";
import {UserContext} from "../../App";

interface ILoginModalProps {
    open: boolean;
    setOpen: Function;
}

export function LoginModal(props: ILoginModalProps) {

    const [loginData, setLoginData] = useState<ILogin>({email: '', password: ''});
    const [forgotPassword, setForgotPassword] = useState<boolean>(false);
    const { setLoggedInToken }: any = useContext(UserContext);
    const [pWNotValid, setPWNotValid] = useState<boolean>(false);
    const [pWNotValidMsg, setpWNotValidMsg] = useState<string>('');
    const [registration, setRegistration] = useState<boolean>(false);
    const [rB1Active, setRB1Active] = useState(true);
    const [rB2Active, setRB2Active] = useState(false)
    const [warningMsg, setWarningMessage] = useState<string>("")

    const [registrationData, setRegistrationData] = useState<IRegister>({
        email: '',
        password: '',
        rePassword: '',
        firstName: '',
        lastName: '',
        type: 'CLUB',
        roles: 'CLUB'
    });

    const [newPasswordInfo, setNewPasswordInfo] = useState({
        password: '',
        rePassword: '',
        token: ''
    })

    const EMAIL_VALID: RegExp = /^[\w-/.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const history = useHistory();
    const pwToken = useQuery('pwToken')
    const token  = useQuery("token");
    const refreshToken = useQuery("refreshToken");
    const location = useLocation()

    useEffect(()=>{
        if(pwToken){
            setNewPasswordInfo((prevState)=>({...prevState, token: pwToken}))
        }
    },[])

    useEffect(()=>{
        if(location.hash === '#registration') {
            setRegistration(true)
        }
    },[location])

    const newPasswordHandler = () => {
        if ((newPasswordInfo?.password !== newPasswordInfo?.rePassword)) {
            setPWNotValid(true);
            setpWNotValidMsg('Achtung: Passwörter stimmen nicht überein!');
        } else if (newPasswordInfo?.password?.length < 7) {
            setPWNotValid(true);
            setpWNotValidMsg('Dein Passwort muss mindestens 7 Zeichen lang sein');
        } else {
            if(pwToken){
                setNewPasswordInfo((prevState) => ({...prevState, token: pwToken}));
                sendNewPassword()
            }
        }
    }

    const sendNewPassword = () => {
        addNewPassword(newPasswordInfo!).then(res => {
            if (res !== undefined) {
                history.push('/website');
                apiSuccessHandler(
                    <>
                        {I18N('success_refresh_password', 'de', [{
                            key: 'success_refresh_password',
                            value: I18N('success_refresh_password', 'de')
                        }])}
                    </>
                )
            }
        }).catch(error => error)
    }

    async function _login(): Promise<void> {
        LoginAPI(loginData, setLoggedInToken).then(res => {
            const userType = localStorage.getItem('type')
            if (userType === 'ADMIN') {
                history.push('/admin')
            } else {
                props.setOpen(false)
                history.push("/profile/0", { update: true })
            }
        }).catch(error => error);
    }

    useEffect(() => {
        setLoggedInToken(token)
    }, [token])

    function useQuery(key: string) {
        return new URLSearchParams(useLocation().search).get(key);
    }

    async function forgotPasswordHandler() {
        forgotPasswordAPI({email: loginData.email}).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('check_email', 'de', [{
                        key: 'check_email',
                        value: I18N('check_email', 'de')
                    }])}
                </>
            )
        }).catch(error => error);
    }

    function dataIsValid(): boolean {
        if (registrationData.password.length < 8 || !EMAIL_VALID.test(registrationData.email) || registrationData.password !== registrationData.rePassword) {
            let warningMessage = `${(registrationData.password.length < 8) ? 'Das Passwort ist zu kurz!  ' : ''}
            ${!EMAIL_VALID.test(registrationData.email) ? 'Die Email ist ungültig! ' : ''}
            ${(registrationData.password !== registrationData.rePassword) ? 'Die Passwörter stimmen nicht überein!' : ''}`
            setWarningMessage(warningMessage)
            return false;
        } else {
            return true;
        }
    };

    const registrationHandler = async () => {
        if(dataIsValid()){
            const registrationDetails = {
                email: registrationData.email,
                password: registrationData.password,
                firstName: registrationData.firstName,
                lastName: registrationData.lastName,
                type: registrationData.type,
                roles: registrationData.roles,
            }
            await register(registrationDetails).then(res => {
                history.push('/website');
            }).catch(error => {
                apiWarningHandler(
                    <>
                        {I18N('email_not_valid', 'de', [{
                            key: 'email_not_valid',
                            value: I18N('email_not_valid', 'de')
                        }])}
                    </>
                )
            })
        }
    }

    return (
        <div className={classes.modalContainer + (props.open ? " " + classes.open : "")}>
            <div className={classes.modalInnerContainer}>
                <div className={classes.closeButton} onClick={()=>{props.setOpen(false); setRegistration(false); setWarningMessage("")}}>X</div>
                {
                    pwToken && (
                        <>
                            <div className={classes.headline}>PLEASE TYPE IN YOUR NEW PASSWORD</div>
                            <div className={classes.textfields + " align-items-center"}>
                                <input className={classes.input}
                                       placeholder="NEUES PASSWORT"
                                       type="password"
                                       onChange={(e) => setNewPasswordInfo({... newPasswordInfo, password: e.target.value,})}
                                />
                                <input className={classes.input}
                                       placeholder="NEUES PASSWORT WIEDERHOLEN"
                                       type="password"
                                       onChange={(e) => setNewPasswordInfo({... newPasswordInfo, rePassword: e.target.value,})}
                                />
                                {
                                    pWNotValid && <p className="password-not-valid mt-3 mb-0">{pWNotValidMsg}</p>
                                }
                                <button className={classes.button + " mt-3"}
                                        onClick={() => {newPasswordHandler()}}
                                >PASSWORT ZURÜCKSETZEN
                                </button>
                            </div>
                        </>
                    )
                }
                {
                    forgotPassword && (
                        <>
                            <div className={classes.headline}>TYPE IN YOUR EMAIL</div>
                            <div className={classes.textfields}>
                                <input className={classes.input}
                                       placeholder="E-MAIL"
                                       type="email"
                                       onChange={(e) => setLoginData({...loginData, email: e.target.value,})}
                                />
                            </div>
                            <button className={classes.button}
                                    onClick={() => {
                                        forgotPasswordHandler()
                                    }}
                            >PASSWORT ÄNDERN
                            </button>
                            <p className={classes.forgotPassword}
                               onClick={() => {
                                   setForgotPassword(false)
                               }}
                            >
                                <ArrowLeft height={12} /> Zurück
                            </p>

                        </>
                    )
                }
                {
                    (!forgotPassword && !registration) && (
                        <>
                            <div className={classes.headline}>LETS WORK TOGETHER!</div>
                            <div className={classes.textfields} style={{clear: "both"}}>
                                <input className={classes.input}
                                       placeholder="MEMBERNAME"
                                       type="text"
                                       onChange={(e) => setLoginData({...loginData, email: e.target.value,})}
                                />
                                <input className={classes.input}
                                       placeholder="PASSWORT"
                                       type="password"
                                       onKeyPress={(e)=>{
                                           if (e.key === 'Enter') {
                                               _login()
                                           }
                                       }}
                                       onChange={(e) => setLoginData({...loginData, password: e.target.value,})}
                                />
                            </div>
                            <p className={classes.forgotPassword}
                               onClick={() => {
                                   setForgotPassword(true)
                               }}
                            >
                                {I18N('forgotPassword', 'de', [{
                                    key: 'forgotPassword',
                                    value: I18N('forgotPassword', 'de')
                                }])}
                            </p>
                            <button className={classes.button}
                                    onClick={() => {
                                        _login()
                                    }}
                            >LOGIN!
                            </button>
                            <hr className="bg-white w-75 mx-auto"/>
                            <p className={classes.typography}>{I18N('noAccount')}</p>
                            <div className={classes.link}
                                 onClick={()=>{
                                      setRegistration(true)
                                 }}>
                                <p className={classes.forgotPassword}>
                                    {I18N('register')}
                                </p>
                            </div>
                            <hr className="bg-white w-75 mx-auto"/>
                            <a href={BASE_PATH + `/auth/${'google'}/redirect/?token=${token}&refreshToken=${refreshToken}`}// onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'google')}
                               className={classes.googleLoginBtn + " d-flex justify-content-center d-md-block hover-opacity cursor_pointer text-decoration-none"}>
                                <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <GmailIcon/>
                                                </span>
                                    <div className="form-control social-text"
                                         aria-label="Username"
                                         aria-describedby="basic-addon1"
                                    >Mit Google anmelden
                                    </div>
                                </div>
                            </a>
                        </>
                    )
                }
                {
                    (!forgotPassword && registration) && (
                        <>
                            <div className={(classes.headline + ' mb-4')}>NEU BEI BLOGHEAD?</div>
                            <div className={classes.buttonWrapper}>
                                <button className={(rB1Active ? classes.redButton : classes.blueButton) + " " + classes.registrationButton}
                                        onClick={() => {
                                            setRB1Active(true)
                                            setRB2Active(false)
                                            setRegistrationData((prevState:any) => ({...prevState, type: "CLUB", roles: "CLUB"}))
                                        }}
                                >Veranstalter
                                </button>
                                <button className={(rB2Active ? classes.redButton : classes.blueButton) + " " + classes.registrationButton}
                                        onClick={() => {
                                            setRB1Active(false)
                                            setRB2Active(true)
                                            setRegistrationData((prevState:any) => ({...prevState, type: "ARTIST", roles: "ARTIST"}))
                                        }}
                                >Artist
                                </button>
                            </div>
                            <input className={(classes.input + ' ' + classes.registrationInput)}
                                   placeholder="Name"
                                   type="text"
                                   onChange={(e) => setRegistrationData({...registrationData, lastName: e.target.value,})}
                            />
                            <input className={(classes.input + ' ' + classes.registrationInput)}
                                   placeholder="Vorname"
                                   type="text"
                                   autoComplete="new-password"
                                   onChange={(e) => setRegistrationData({...registrationData, firstName: e.target.value,})}
                            />
                            <input className={(classes.input + ' ' + classes.registrationInput)}
                                   placeholder="E-Mail"
                                   type="email"
                                   onChange={(e) => setRegistrationData({...registrationData, email: e.target.value,})}
                            />
                            <input className={(classes.input + ' ' + classes.registrationInput)}
                                   placeholder="Passwort"
                                   type="new-password"
                                   onChange={(e) => setRegistrationData({...registrationData, password: e.target.value,})}
                            />
                            <input className={(classes.input + ' ' + classes.registrationInput)}
                                   placeholder="Passwort wiederholen"
                                   type="new-password"
                                   onChange={(e) => setRegistrationData({...registrationData, rePassword: e.target.value,})}
                            />
                            <div className={classes.buttonWrapper}>
                                <button className={classes.redButton}
                                        onClick={() => {
                                            registrationHandler()
                                        }}
                                >Registrieren
                                </button>
                            </div>
                            {
                                warningMsg !== "" &&
                                    <div className={classes.warningMsg}>{warningMsg}</div>
                            }
                            <hr className={"bg-white w-100 my-0"}/>
                            <a href={BASE_PATH + `/auth/${'google'}/redirect/?token=${token}&refreshToken=${refreshToken}`}// onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'google')}
                               className={classes.googleLoginBtn + " d-flex justify-content-center d-md-block hover-opacity cursor_pointer text-decoration-none"}>
                                <div className="input-group mb-3 mt-2">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <GmailIcon/>
                                                </span>
                                    <div className="form-control social-text"
                                         aria-label="Username"
                                         aria-describedby="basic-addon1"
                                    >Mit Google registrieren
                                    </div>
                                </div>
                            </a>
                            <hr className={"bg-white w-100 my-0"}/>
                            <p className={classes.forgotPassword}
                               onClick={() => {
                                   setRegistration(false)
                                   setWarningMessage("")
                               }}
                            >
                                <ArrowLeft height={12}/> Zurück zum Login
                            </p>
                        </>
                    )
                }
            </div>
        </div>
    )
}
import {getUserCoordinates, getUserCoordinatesByID} from "../../Helper/API";
import {Spinner} from "react-bootstrap";
import {useEffect, useState} from "react";
import AsyncSelect from "react-select/async";

export function LocationSelectField({
                                        handleLocation,
                                        presettedValue,
                                        selectStyles,
                                        className,
                                        isDisabled
                                    }: any) {

    const [defaultValue, setDefaultValue] = useState<{ value: string, zipCode: string, label: string }>()

    useEffect(() => {
        getLocationByID()
    }, [])

    const changeHandlerLocation = (inputValue: string) => {
        if (inputValue?.length > 2) {
            return new Promise((resolve, reject) => {
                getUserCoordinates(inputValue).then(data => {

                    const tempArray = data?.map?.((item: any) => {
                        return {
                            value: item.id,
                            zipCode: item.zipCode,
                            label: item.name
                        }
                    })
                    resolve(tempArray)
                }).catch(error => reject(error))
            })
        }
    }

    const getLocationByID = () => {

        if (presettedValue === undefined || presettedValue === null) {

            setDefaultValue({
                value: '',
                zipCode: '',
                label: ''
            })
        } else {
            getUserCoordinatesByID(presettedValue).then(data => {
                setDefaultValue({
                    value: data.id,
                    zipCode: data.zipCode,
                    label: data.name
                })
            }).catch(error => error)
        }
    }

    return (
        <div className={className}>
            {presettedValue !== undefined && defaultValue === undefined &&

                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            }
            {((presettedValue !== undefined && defaultValue !== undefined) || presettedValue === undefined) &&
                <AsyncSelect id="location"
                             placeholder={"PLZ oder Ort eingeben.."}
                             defaultValue={defaultValue ? defaultValue : ''}
                             loadOptions={changeHandlerLocation}
                             name="location"
                             formatOptionLabel={(userCoordinates: any) => `${userCoordinates?.zipCode} - ${userCoordinates?.label}`}
                             onChange={(event) => {
                                 handleLocation(event);
                                 setDefaultValue({
                                     value: '',
                                     zipCode: '',
                                     label: ''
                                 })
                             }}
                             isDisabled={isDisabled}
                             onInputChange={changeHandlerLocation}
                             styles={selectStyles}
                />
            }
        </div>
    );
}
import {Col, Container, Modal, Row, Button} from "react-bootstrap";
import {XDeleteIcon} from "../../components/Icons/Icons";
import React, {useRef, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {iAddPhotoToGallery, ProfileGallery} from "./ProfileModals/ProfileModals";
import {addPictureForGallery, deleteUserPhotoInGallery} from "../../Helper/API";
import {SaveChangeButton} from "../../components/Buttons/Buttons";
import {apiSuccessHandler} from "../../Helper/api.services";
import {SRLWrapper} from "simple-react-lightbox";
import {I18N} from "../../i18n/i18n";
import Carousel from 'react-grid-carousel'
import {IProfileInfo} from "../../Helper/Interfaces/IProfile";

interface IProps {
    galleryModalOpen: boolean,
    profileInformation?: IProfileInfo | null,
    refreshProfileGallery: (newProfileGalleryItems: Array<any>) => void
    setGalleryModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export function ProfilePictures(props: IProps) {
    const [photoInGallery, setPhotoInGallery] = useState<iAddPhotoToGallery>({pictures: []});
    const inputRef = useRef<HTMLInputElement>()


    function handlerChange(event: any) {
        setPhotoInGallery({pictures: [...props.profileInformation?.gallery!, event.target.files]});
    }

    async function onAddPhotoToGallery(e: any): Promise<void> {
        if (!inputRef?.current?.files?.length) return
        const data = new FormData();
        for (let file in inputRef.current.files) {
            data.append('pictures', inputRef.current.files[file])
        }
        addPictureForGallery(data!).then(res => {
            if (res) {
                props.refreshProfileGallery(res)
                apiSuccessHandler(
                    <>
                        {I18N('success_add_picture', 'de', [{
                            key: 'success_add_picture',
                            value: I18N('success_add_picture', 'de')
                        }])}
                    </>
                )
            };
        }).catch(error => error);
    }

    function onDeleteUserPhotoInGallery(id: any) {
        deleteUserPhotoInGallery(id).then(res => {
            props.refreshProfileGallery(res)
            apiSuccessHandler(
                <>
                    {I18N('success_delete_picture', 'de', [{
                        key: 'success_delete_picture',
                        value: I18N('success_delete_picture', 'de')
                    }])}
                </>
            )
        }).catch(error => error);
    }

    const wrapperFunction = (e: any) => {
        onAddPhotoToGallery(e);
        props.setGalleryModalOpen(false);
    }

    return (
        <>
            <Container className={props?.profileInformation?.user?.type === 'CLUB' ? 'px-0' : props?.profileInformation?.user?.type === 'ARTIST' ? '' : 'px-0'}>
                <Row className=" p-0">
                        <Modal show={props.galleryModalOpen} onHide={() => props.setGalleryModalOpen(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {I18N('pictures', 'de', [{
                                        key: 'pictures',
                                        value: I18N('pictures', 'de')
                                    }])}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="align-items-center">
                                    <Col xl={12}>
                                        <ProfileGallery changeHandler={handlerChange} refHandler={inputRef}/>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => props.setGalleryModalOpen(false)}>
                                    {I18N('close', 'de', [{
                                        key: 'close',
                                        value: I18N('close', 'de')
                                    }])}
                                </Button>
                                <SaveChangeButton clickHandler={wrapperFunction}/>
                            </Modal.Footer>
                        </Modal>
                    <Col xl={12} className="my-4 d-flex align-items-center justify-content-center">
                        {props?.profileInformation?.gallery?.length !== 0 ? <SRLWrapper>
                            <Carousel showDots autoplay={10000}
                                      cols={props?.profileInformation?.gallery && props?.profileInformation?.gallery?.length > 1 ? 3 : 1}
                                      rows={1} gap={10} wrap='nowrap'
                                      dotColorActive="#5B7ADA" dotColorInactive="#A8BEFE"
                                      responsiveLayout={[
                                          {
                                              breakpoint: 1200,
                                              cols: 3
                                          },
                                          {
                                              breakpoint: 990,
                                              cols: 2
                                          },
                                          {
                                              breakpoint: 768,
                                              cols: 1
                                          }
                                      ]}
                            >
                                {props?.profileInformation?.gallery?.map?.(pic => {
                                    return (
                                        <Carousel.Item key={pic?.id}>
                                            <div className="position-relative">
                                                <img height="300px"
                                                     width="100%"
                                                     alt="Gallery pic"
                                                     onError={(e: any) => (e.target.onerror = null, e.target.src = '/defaultGalleryImage.jpg')}
                                                     style={{objectFit: 'contain', cursor: 'pointer'}}
                                                     src={pic?.picture}/>
                                                {props?.profileInformation?.isMyProfile &&
                                                <div onClick={() => onDeleteUserPhotoInGallery(pic.id)}
                                                     className="position-absolute cursor_pointer"
                                                     style={{right: '5%', top: '3%'}}>
                                                    <XDeleteIcon/>
                                                </div>}
                                            </div>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </SRLWrapper> : <span className="text-center my-3 fw-bold">
                                {I18N('no_gallery_image', 'de', [{
                                    key: 'no_gallery_image',
                                    value: I18N('no_gallery_image', 'de')
                                }])}</span>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};


import {io} from "socket.io-client";
import { DOMAIN_PATH } from "./api.route";

export const token = localStorage.getItem("token") as string;

export const socket = (token: string) => {
    if (!token?.length) return null
    // return io("ws://localhost:3005", {
    return io("wss://" + DOMAIN_PATH, {
        extraHeaders: {
            bearer:
            token
        }
    });
}



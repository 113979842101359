import LoadingPage from "../Information/LoadingPage";
import classes from "../../components/Styles/ProfileSearch.module.scss";
import {Container, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {I18N} from "../../i18n/i18n";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"
import {getPaymentTokens} from "../../Helper/API";
import moment from "moment";

export function ProfileTransactionHistory() {
    const [isLoading, setIsLoading] = useState(false);
    const [tokenHistory, setTokenHistory] = useState([{
        id: 0,
        createdAt: '',
        userId: 0,
        amount: 0,
        balanceBefore: 0,
        balanceAfter: 0,
        type: '',
        paymentId: null,
        description: ''
    }])

    useEffect(() => {
        setIsLoading(true)
        getPaymentTokens().then(res => {
            setTokenHistory(res)
            setIsLoading(true)
        }).catch(error => error).finally(() => {
            setIsLoading(false);
        });
    }, [])

    const sortTransactions = (prev: { id: number; }, current: { id: number; }) => current.id - prev.id


    return (
        <div className={classes.TransactionHistory}>
            {isLoading && <LoadingPage/>}
            <div className={`${classes.heading}`}>
                <Container className={`${isLoading && 'filter-blur'} mx-4`}>
                    <h3 className="headline-title">
                        {I18N('transaction_history', 'de', [{
                            key: 'transaction_history',
                            value: I18N('transaction_history', 'de')
                        }])}
                    </h3>
                </Container>
            </div>
            <Container className={`${isLoading && 'filter-blur'} mt-5 mb-3 responsive-table`}>
                <Table striped bordered hover variant="dark">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>
                            {I18N('created_at', 'de', [{
                                key: 'created_at',
                                value: I18N('created_at', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('type', 'de')}
                        </th>
                        <th>
                            {I18N('balance_before', 'de', [{
                                key: 'balance_before',
                                value: I18N('balance_before', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('balance_after', 'de', [{
                                key: 'balance_after',
                                value: I18N('balance_after', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('your_purchases', 'de', [{
                                key: 'your_purchases',
                                value: I18N('your_purchases', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('description', 'de')}
                        </th>
                    </tr>
                    </thead>

                    <tbody>

                    {tokenHistory?.length > 0 ?
                        tokenHistory
                            ?.filter(key => (key.description === 'SUCCESS_PURCHASE') || (key?.description !== 'SUCCESS_PURCHASE' && key?.type === 'MINUS'))
                            ?.sort(sortTransactions)
                            ?.map?.((key: any) => {
                            let date = new Date(key.createdAt);
                            let dateMDY = moment(date).format('DD.MM.YYYY');
                            return (
                                <tr key={key?.id}>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            #{key?.id}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {dateMDY}
                                        </Rosa>
                                    </td>
                                    <td className={`${key?.type === 'PLUS' ? 'text-success' : 'text-danger'} fw-bold`}>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.type}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.balanceBefore}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.balanceAfter}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.amount}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.description === 'SUCCESS_PURCHASE' ?
                                                <>{I18N('token_purchase', 'en')}</> :
                                                <>{I18N('booking_payment', 'en')}</>}
                                        </Rosa>
                                    </td>
                                </tr>
                            )
                        }) :
                        <tr>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Container>

        </div>
    )
}
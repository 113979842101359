import React, {useEffect, useState} from "react";
import classes from "../../components/Styles/ProfileSearch.module.scss";
import {Col, Container, Image, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {I18N} from "../../i18n/i18n";
import {
    GeoLocation,
    StarFillCardIcon
} from "../../components/Icons/Icons";
import {Link, useParams} from "react-router-dom";
import {RatingCalculation} from "./ProfileSearch";
import {deleteUserFromFavorites, getUserFavorites} from "../../Helper/API";
import LoadingPage from "../Information/LoadingPage";
import {apiSuccessHandler} from "../../Helper/api.services";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"
import moment from "moment";
import ProfileCard from "../../components/ProfileCard/ProfileCard";


export function ProfileFavorites() {

    const {id} = useParams<{id: string}>() ?? '0';
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userFavorite, setUserFavorite] = useState<any>({
        id: 0,
        createdAt: '',
        userid: 0,
        categories: [],
        reviewStatistic: {
            _avg: {
                rating: 0,
            },
            _count: {
                _all: 0
            }
        },
        savedBy: 0,
        user: {
            id: 0,
            firstName: '',
            lastName: '',
            portfolio: {
                picture: ''
            }
        },
        userId: 0
    })
    useEffect(() => {
        setIsLoading(true)
        getUserFavorites().then(data => {
            setUserFavorite(data)
        }).catch(error => error).finally(() => {
            setIsLoading(false)
        });
    }, [JSON.stringify(userFavorite)])

    const removeFavorite = (userId: number) => {
        setIsLoading(true)
        deleteUserFromFavorites(userId).then(res => {
            setIsLoading(true)
            if (res) {
                setUserFavorite(res);
                apiSuccessHandler(
                    <>
                        {I18N('success_remove_favorite', 'de', [{
                            key: 'success_remove_favorite',
                            value: I18N('success_remove_favorite', 'de')
                        }])}
                    </>
                )
            }
        }).catch(error => error).finally(() => {
            setIsLoading(false);
        });
    }

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {I18N('click_to_remove_favorite', 'de', [{
                key: 'click_to_remove_favorite',
                value: I18N('click_to_remove_favorite', 'de')
            }])}

        </Tooltip>
    );

    return (
        <div>
            {isLoading && <LoadingPage/>}
            <div className={classes.header + (isLoading ? ' filter-blur ' : ' ') + 'position-relative'}>
                <h2 className={classes.headline}>
                    FAVORITEN
                </h2>
            </div>
            <div className={classes.searchContainer + ` ${isLoading && 'filter-blur'} `}>
                <Row>
                        {userFavorite.length > 0 ? userFavorite?.map?.((user: any) => {
                                let date = new Date(user?.createdAt);
                                let dateMDY = moment(date).format('DD.MM.YYYY')
                                return (
                                    <Col xl={4}
                                         sm={12}
                                         md={6}
                                         lg={6}
                                         xxl={3}
                                         className="mt-3"
                                         key={user?.id}>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            <ProfileCard firstName={user.user.firstName}
                                                         lastName={user.user.lastName}
                                                         rating={user?.reviewStatistic?._avg?.rating}
                                                         location={user.name}
                                                         picturePath={user.user.portfolio.picture}
                                                         categories={user?.categories}
                                                         userId={user?.userId}
                                                         isFavorite={true}
                                            />
                                        </Rosa>
                                    </Col>
                                )
                            }) :
                            <Col xl={12} className="d-flex align-items-center justify-content-center">
                            <span className="text-center fw-bold">
                                {I18N('no_favorites', 'de', [{
                                    key: 'no_favorites',
                                    value: I18N('no_favorites', 'de')
                                }])}
                            </span>
                            </Col>
                        }
                </Row>
            </div>
        </div>
    )
}
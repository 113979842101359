import React, { useEffect, useState } from "react";
import { useQuery } from "../../App";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { CheckedFailed, CheckedSuccess } from "../../components/Icons/Icons";
import { I18N } from "../../i18n/i18n";
import { addBookingReview, createBookingInvoice, generateStripeTokens, manipulatePaymentTokens } from "../../Helper/API";
import { PaymentTypeEnum } from "../Profile/ProfileAdditionalInformation/ProfileAdditionalInformation";
import { apiSuccessHandler } from "../../Helper/api.services";
import { BOOKING_FINISHED, BookingFinishedRating, iBookingFinish } from "../Profile/ProfileModals/ProfileModals";
import { FinishBookingButton } from "../../components/Buttons/Buttons";


export function BookingPaymentResponse() {
    const [isPaymentFailed, setIsPaymentFailed] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const redirectStatus = useQuery("redirect_status");
    const paymentIntent = useQuery("payment_intent");
    const history = useHistory();
    const [finishedBooking, setFinishedBooking] = useState<iBookingFinish>({
        message: '',
        rating: 0,
        status: BOOKING_FINISHED.FINISHED
    })

    function handleRating(rate: number) {
        rate = rate / 20;
        setFinishedBooking(prev => ({ ...prev, rating: rate }))
    }

    const handleChangeText = (event: any) => {
        setFinishedBooking(prev => ({ ...prev, [event.target.name]: event.target.value }));
    }
    let bookingId = localStorage.getItem('bookingId');

    async function finishBookingByAccepting(): Promise<void> {
        addBookingReview(finishedBooking!, +bookingId!).then(res => {
            if (res) {
                createBookingInvoice(+bookingId!).then(res => {}).catch(error => error);
                apiSuccessHandler(
                    <>
                        {I18N('set_booking_finished', 'de')}
                    </>
                );
                history.push('/profile/0')
            }
        }).catch(error => error);
    }


    useEffect(() => {
        if (redirectStatus === 'failed') {
            setIsPaymentFailed(true)
        } else if (redirectStatus === 'succeeded' && paymentIntent) {
            generateStripeTokens({ paymentIntent: paymentIntent, description: 'BOOKING_PURCHASE' }).then(res => {
                if (res) {
                    manipulatePaymentTokens({
                        amount: res.transactionAmount,
                        type: PaymentTypeEnum.MINUS
                    }).then(res => res).catch(error => error);
                }
            }).catch(error => error);
        }
    }, [])

    useEffect(() => {
        if (finishedBooking?.rating! > 0 && finishedBooking?.message?.length! > 0) {
            setValidForm(true);
        }
    }, [finishedBooking])

    return (
        <div className="blur_image">
            <div className="success_modal p-5 flex-column">
                <Container>
                    {isPaymentFailed ? <CheckedFailed /> : <CheckedSuccess />}
                    <h4 className="fw-600 fs-3">
                        {isPaymentFailed ? <>
                            Failed
                        </> : <span>
                            {I18N('set_booking_finished', 'de')}
                        </span>}
                    </h4>
                    <p className="text-muted">
                        {I18N('leave_review', 'de')}
                    </p>

                    <BookingFinishedRating state={finishedBooking} handleRating={handleRating}
                        changeHandler={handleChangeText} />

                    <FinishBookingButton clickHandler={finishBookingByAccepting} formIsValid={validForm}
                        buttonName={I18N('finish_booking', 'de')} />


                </Container>
            </div>
        </div>
    )
}
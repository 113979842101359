import classes from "../../components/Styles/HomePage.module.scss"
import { FooterNew } from "../Footer/FooterNew";
import { useHistory } from "react-router-dom";
import "react-on-scroll-animation/build/index.css";
import React, { useContext, useEffect, useState } from "react";
import { getWebsiteData } from "../../Helper/API";
import LoadingPage from "../Information/LoadingPage";
import { UserContext } from "../../App";
import { token } from "../../Helper/socket.client";
import { FirstSection } from "./FirstSection/FirstSection";
import { AboutSection } from "./AboutSection/AboutSection";
import { UspSection } from "./USPSection/UspSection";
import { ArtistsSection } from "./ArtistsSection/ArtistsSection";
import { Advantages } from "./Advantages/Advantages";
import { BigSlider } from "./BigSlider/BigSlider";
import { ImageSliderElement } from "./ArtistsSection/ImageSlider/imageSlide";
import { Events } from "./BigSlider/Events";
import {VrSection} from "./VRSection/VRSection";

export function HomePage() {
    const { loggedInToken: getToken }: any = useContext(UserContext)
    const history = useHistory();
    const IMAGESLIDEELEMENTS: ImageSliderElement[] = [
        { imgSrc: '/BigSliderImages/firstImage.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/BigSliderImages/secondImage.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/BigSliderImages/bigImage.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/BigSliderImages/lastImage.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
    ]
    const [data, setData] = useState({
        artists: 0,
        clubs: 0,
        events: 0,
        bookings: 0
    })

    useEffect(() => {
        if (token) {
            history.push('/profile/0')
        }
    }, [getToken])

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getWebsiteData().then(res => {
            setIsLoading(true);
            setData(res);
        }).catch(error => error).finally(() => {
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            {isLoading && <LoadingPage />}
            <FirstSection />
            <AboutSection />
            <UspSection />
            <ArtistsSection />
            <Advantages />
            <BigSlider imagess={IMAGESLIDEELEMENTS} />
            <Events />
            <VrSection/>
            <FooterNew />
        </>
    )
}
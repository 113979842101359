import classes from "../../components/Styles/LoadingPage.module.scss"
import {I18N} from "../../i18n/i18n";


const LoadingPage = () => {
    return (
        <>
            <div className={classes.loader}>
                <div className={classes.loaderDiv}>
                    {I18N('loading', 'de', [{
                        key: 'loading',
                        value: I18N('loading', 'de')
                    }])}...
                </div>
            </div>
        </>
    )
}
export default LoadingPage;
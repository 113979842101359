import classes from './UspSection.module.scss'
import EStickyNote from "../../../components/EStickyNote/EStickyNote";
import {Link} from "react-router-dom";
import {I18N} from "../../../i18n/i18n";
import React from "react";
import buttonStyles from "../../../components/Buttons/ButtonsStyle.module.scss";

export function UspSection() {
    return (
        <section className={classes.uspSection}>
            <div className={classes.uspContainer}>
                <div className={classes.leftSideContainer}>
                    <div className={classes.textfield}>
                        <div className={classes.headline}>
                            EVERY BUSINESS IS ESSENTIAL <br></br> TO HAVE A PROFESSIONAL USP
                        </div>
                        <div className={classes.bodyText}>
                            Our platform is able<br></br>
                            to manage your USP, <br></br>Kalender,bookings and <br></br> most important help<br></br>
                            you in your bookkeeping <br></br> administration.<br></br>
                            Find out what benefits <br></br> we have for you.
                        </div>
                    </div>
                </div>
                <div className={classes.rightSideContainer}>
                    <EStickyNote
                        top={10}
                        right={10}
                        open={true}
                    >
                        Hier steht ein Text darüber, was du
                        mit einem toll angelegten Profil alles
                        erreichen kannst. Wir bieten dir hier
                        die perfekte Selbstdarstellung. Über
                        ein Bewertungssystem und unsere
                        BlogHeadCoins schaffst du es bis ganz
                        nach oben
                    </EStickyNote>
                    <div className={classes.row1}>
                        <div className={classes.ele}>
                            <img className={classes.eleIMG} src="/SVG/icon_sound_48px.svg" alt="Logo of the platform" />
                            <div>
                                ARTIST PRESENTATION
                            </div>
                        </div>

                        <div className={classes.ele}>
                            <img className={classes.eleIMG} src="/SVG/icon_community_48px.svg" alt="Logo of the platform" />
                            <div>
                                WORLDWIDE <br></br> COMMUNITY
                            </div>
                        </div>
                    </div>
                    <div className={classes.row2}>

                        <div className={classes.ele}>
                            <img className={classes.eleIMG} src="/SVG/icon_calendar_48px.svg" alt="Logo of the platform" />
                            <div>
                                ESSENTIAL <br></br> CALENDAR MANAGEMENT
                            </div>
                        </div>

                        <div className={classes.ele}>
                            <img className={classes.eleIMG} src="/SVG/icon_bookkeeping_48px.svg" alt="Logo of the platform" />
                            <div>
                                BOOKKEEPING <br></br>  AND PAYMENTPLAN
                            </div>
                        </div>
                        <div className={classes.ele}>
                            <img className={classes.eleIMG} src="/SVG/icon_profil_48px.svg" alt="Logo of the platform" />
                            <div>
                                GETTING <br></br> YOUR USP
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>
              <Link to={'/registration'} style={{textDecoration: "none"}}>
                  <button className={buttonStyles.registerButtonLila}>
                      {I18N('register_now').toUpperCase()}
                  </button>
              </Link>
            </div>
        </section >)
}
import {CheckedFailed, CheckedSuccess} from "../../components/Icons/Icons";
import {Container} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {I18N} from "../../i18n/i18n";
import {useQuery} from "../../App";
import {useEffect, useState} from "react";
import {apiSuccessHandler} from "../../Helper/api.services";
import {generateStripeTokens} from "../../Helper/API";


export function CheckoutFormSuccess() {
    const history = useHistory();
    const [isPaymentFailed, setIsPaymentFailed] = useState(false);
    const goToProfile = () => {
        history.push('/profile/0');
    }

    const redirectStatus = useQuery("redirect_status");

    const paymentIntent = useQuery("payment_intent");
    useEffect(() => {
        if (redirectStatus === 'failed') {
            setIsPaymentFailed(true)
        } else if (redirectStatus === 'succeeded' && paymentIntent) {
            generateStripeTokens({paymentIntent: paymentIntent, description: 'SUCCESS_PURCHASE'}).then(res => {
                if (res) {
                    apiSuccessHandler('success')
                }
            }).catch(error => error);
        }
    }, [])


    return (
        <div className="blur_image">
            <div className="success_modal p-5 flex-column">
                <Container>
                    {isPaymentFailed ? <CheckedFailed/> : <CheckedSuccess/>}
                    <h4 className="fw-600 fs-3">
                        {isPaymentFailed ? <>
                            Failed
                        </> : <span>
                             {I18N('payment_succeeded', 'de', [{
                                 key: 'payment_succeeded',
                                 value: I18N('payment_succeeded', 'de')
                             }])}
                        </span>}
                    </h4>
                    <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut blanditiis
                        debitis delectus.</p>
                    <button className="forgot-button" onClick={goToProfile}>
                        {I18N('go_to_profile', 'de', [{
                            key: 'go_to_profile',
                            value: I18N('go_to_profile', 'de')
                        }])}
                    </button>


                </Container>
            </div>
        </div>
    )
}
import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ToastContainer} from "react-toastify";
import SimpleReactLightbox from 'simple-react-lightbox';
import "./components/Styles/Toasts.scss"
import './fonts/Helvetica/Helvetica.ttf';

ReactDOM.render(
    <>

        <React.StrictMode>
            <SimpleReactLightbox>
                <App/>
            </SimpleReactLightbox>
        </React.StrictMode>
        <ToastContainer
            position="top-right"
            className="Toastify__toast-theme--light"
           // autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
        />
    </>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import {Container} from "react-bootstrap";
import "./ProfileSection.scss"

interface IProfileSection {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
    setEdit?: React.Dispatch<React.SetStateAction<boolean>>,
    iconSize?: number,
    isMyProfile: boolean
    iconSrc: string,
    title: string,
}

const ProfileSection = (props: IProfileSection) => {

    const iconStyles : React.CSSProperties = {
        height: props.iconSize ? props.iconSize + "rem" : "3rem",
        width: props.iconSize ? props.iconSize + "rem" : "3rem"
    }

    const toggleEdit = () => {
        if(props.setEdit){
            props.setEdit(true)
        }
    }

    return (
        <div className="ProfileSection">
            <Container>
                <div className="ProfileSection__head">
                    <div className="ProfileSection__head__heading">
                        <img className="ProfileSection__head__image" style={iconStyles} src={props.iconSrc}/>
                        <p className="ProfileSection__head__headline">{props.title}</p>
                    </div>
                    {
                        (props.setEdit && props.isMyProfile) &&
                            <div className="ProfileSection__head__edit-button">
                                <button className="edit-icon-button" onClick={()=>{toggleEdit()}}>
                                    <img src={"/SVG/icon_edit_48px.svg"}/>
                                </button>
                            </div>

                    }

                </div>
                <div className="ProfileSection__body">
                    {props.children}
                </div>
            </Container>
        </div>
    );
};

export default ProfileSection;
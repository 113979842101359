import React, {useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import classes from '../../components/Styles/BookingOverview.module.scss'
import {I18N} from "../../i18n/i18n";
import {apiSuccessHandler, apiWarningHandler} from "../../Helper/api.services";
import {CreditCardIcon} from "../../components/Icons/Icons";
import {FRONTED_PATH} from "../../pages/Header/Header";
import {PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {options} from "../../App";
import {changePaymentAmount, manipulatePaymentTokens} from "../../Helper/API";
import {iPaymentManipulation, PaymentTypeEnum} from "../../pages/Profile/ProfileAdditionalInformation/ProfileAdditionalInformation";
import LoadingPage from "../../pages/Information/LoadingPage"


// This values are the props in the UI
let amount = "10";
const style = {"layout": "vertical"};
const currency = "EUR";


export const ButtonWrapper = ({currency, showSpinner}) => {

    const [{options, isPending}, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    const [addTokens, setAddTokens] = useState<iPaymentManipulation>({
        amount: 0,
        type: PaymentTypeEnum.PLUS
    })

    return (<>
            {isPending && showSpinner && <div className="d-flex align-items-center justify-content-center mb-3">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}


            {/*{(showSpinner && isPending) && <div className="spinner"/>}*/}
            {!isPending &&
            <PayPalButtons
                style={{
                    shape: 'rect',
                    color: 'gold',
                    layout: 'horizontal',
                    label: 'paypal',
                    tagline: false
                }}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        manipulatePaymentTokens({amount: Number(amount), type: PaymentTypeEnum.PLUS}).then(res => {
                            if (res) {
                                window.location.href = `${FRONTED_PATH}/booking-payment`;
                            }
                            manipulatePaymentTokens({amount: Number(amount), type: PaymentTypeEnum.MINUS}).then(res => {
                                if (res) {
                                    window.location.href = `${FRONTED_PATH}/booking-payment`;
                                }
                            }).catch(error => error);
                        }).catch(error => error);
                    });
                }}
            />
            }
        </>
    );
}


export default function Payment(props: { parentAmount: string, bookingId: string }) {

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, [])

    const stripe = useStripe();
    const elements = useElements();
    const [showInputFields, setShowInputFields] = useState<boolean>(false);
    const [showCreditCards, setShowCreditCards] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        addActive(props?.parentAmount)
    }, [])

    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe?.retrievePaymentIntent(clientSecret)?.then(({paymentIntent}) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    apiSuccessHandler('Payment succeeded!')
                    break;
                case "processing":
                    apiWarningHandler('Your payment is processing.')
                    break;
                case "requires_payment_method":
                    apiWarningHandler('Your payment was not successful, please try again.')
                    break;
                default:
                    apiWarningHandler('Something went wrong.')
                    break;
            }
        });

    }, [stripe]);

    useEffect(() => {
        setShowSpinner(true)
        if (showCreditCards) {
            setTimeout(() => {
                setShowSpinner(false)
            }, 2000)
        }
    }, [showCreditCards])

    localStorage.setItem('bookingId', props?.bookingId)


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }


        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {

                return_url: `${FRONTED_PATH}/booking-payment`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            apiWarningHandler(error?.message)
        } else {
            apiWarningHandler('An unexpected error occured.')
        }
    };

    const addActive = (price) => {
        if (showCreditCards) {
            setShowCreditCards(false);
        }
        setShowInputFields(true);
        amount = price;
        changePaymentAmount({id: options.clientSecret, amount: `${amount}00`}).then(res => {

        }).catch(error => error);
    }

    return (
        <>
            {isLoading && <LoadingPage/>}

            <Container className={`${isLoading ? 'filter-blur' : ''} ${classes.Payments}`}>
                <Row style={{height: '100%'}} className=" align-items-center responsive-payments-card">
                    {showInputFields &&
                    <Col md={12}>

                        <PayPalScriptProvider
                            options={{
                                "client-id": "AVflXa3Ua3qRLFDkDbtDKzjf-FiKHBLpGUBSYhrN2QWxFTqP3n6-ly42yejAhTNjmT4WZ5vNtoQVTsGJ",
                                components: "buttons",
                                currency: "EUR"
                            }}>
                            <ButtonWrapper
                                currency={currency}
                                showSpinner={true}
                            />
                        </PayPalScriptProvider>
                        <button className="debit-card-button" onClick={() => {
                            setShowCreditCards(true);
                            setShowInputFields(false);
                        }
                        }>
                            <CreditCardIcon/>
                            {I18N('debit_or_credit_card', 'de', [{
                                key: 'debit_or_credit_card',
                                value: I18N('debit_or_credit_card', 'de')
                            }])}
                        </button>
                    </Col>}
                    {showCreditCards && <Col md={12}>
                        <form id="payment-form" onSubmit={handleSubmit}>
                            {showSpinner &&
                            <div className="d-flex align-items-center justify-content-center mb-3">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            }

                            {(stripe || elements) && <PaymentElement id="payment-element"/>}
                            <button disabled={isLoading || !stripe || !elements} id="submit"
                                    className={`${classes.btn_primary_modal} w-100 mt-3`}
                                    style={{float: 'right'}}>
                                <span id="button-text">
                                    {isLoading ? <div className="spinner" id="spinner"></div> :
                                        <>
                                            {I18N('pay', 'de', [{
                                                key: 'pay',
                                                value: I18N('pay', 'de')
                                            }])}
                                        </>
                                    }
                                </span>
                            </button>
                        </form>
                    </Col>
                    }
                </Row>
            </Container>
        </>

    );
}

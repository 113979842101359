import React, {useEffect, useState} from 'react';
import ReactSoundCloud from 'react-soundcloud-embedded'
import "./ProfileSoundCloud.scss"
import {SxProps, TextField} from "@mui/material";
import {Clear, Done} from "@mui/icons-material";
import {updateUserPortfolio} from "../../../Helper/API";
import {IProfileInfo} from "../../../Helper/Interfaces/IProfile";

interface IProfileSoundcloudProps {
    edit: boolean,
    setEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setProfileInformation: React.Dispatch<React.SetStateAction<IProfileInfo | null>>,
    soundcloudUrl: string | null;
    isMyProfile: boolean | null;
}


const ProfileSoundcloud = (props: IProfileSoundcloudProps) => {

    const [soundcloudUrl, setSoundcloudUrl] = useState<string | null>("")
    const [oldUrl, setOldUrl] = useState<string>("")

    useEffect(()=>{
        if(props.soundcloudUrl) {
            setSoundcloudUrl(props.soundcloudUrl)
            setOldUrl(props.soundcloudUrl)
        }
    },[props.soundcloudUrl])


    const handleUrlChange = (e) => {
        setSoundcloudUrl(e.target.value)
    }

    const updateUrlInPorfolio = () => {
        updateUserPortfolio({soundcloudUrl: soundcloudUrl!}).then(res => {
                if (res) {
                    setSoundcloudUrl(res.soundcloudUrl)
                }
            }
        ).catch(error => error)
    }

    const textFieldStyles : React.CSSProperties = {
        color: "#fff !important",
        background: "#1f1f1f",
        borderRadius: "10px",
        marginBottom: ".5rem"
    }

    const textFieldSX : SxProps = {
        textArea: {
            "&::-webkit-input-placeholder": {
                color : '#cecece'
            },
            color: "#fff",
        }
    }

    return (
        <div className="ProfileSoundcloud">
            {
                props.edit ? (
                    <>
                        <TextField multiline
                                   fullWidth
                                   placeholder={"Füge deinen Link ein...(https://soundcloud.com/Pfad/zu/deinem/Song)"}
                                   sx={textFieldSX}
                                   style={textFieldStyles}
                                   value={soundcloudUrl || ""}
                                   onChange={(e)=> {
                                       handleUrlChange(e)
                                   }}
                        />
                        <button className="ProfileInformation__button-group__button"
                                onClick={() => {
                                    updateUrlInPorfolio()
                                    props.setEdit(false)
                                }}
                        >
                            <Done/>
                        </button>
                        <button className="ProfileInformation__button-group__button"
                                onClick={() => {
                                    props.setEdit(false)
                                }}
                        >
                            <Clear/>
                        </button>
                    </>
                ) : (
                    soundcloudUrl ? (
                        <ReactSoundCloud visual={false}
                                         height={"250px"}
                                         url={soundcloudUrl}
                        />
                    ) : (
                        <p>Füge einen Soundcloudlink hinzu (https://soundcloud.com/Pfad/zu/deinem/Song)</p>
                    )
                )
            }
        </div>
    );
};

export default ProfileSoundcloud;


import classes from "../Styles/Login.module.scss";
import buttonClass from "../Styles/ProfileCards.module.scss";
import buttonStyles from "./ButtonsStyle.module.scss";
import buttonClass2 from "../Styles/BookingOverview.module.scss";
import styles from "../Styles/Registration.module.scss";
import { Button } from "react-bootstrap";
import { I18N } from "../../i18n/i18n";
import { SendMessageIcon } from "../Icons/Icons";
import React from "react";
import {Link} from "react-router-dom";


interface ILogin {
    formIsValid?: boolean
    clickHandler: (e: any, id?: number) => void
    buttonName?: string,
    eventId?: number;
    text?: string
}


interface iSendMessage {
    clickHandler: any
}

export const LoginButton = ({ clickHandler }: ILogin) => {

    return (
        <>
            <button type="submit" className={classes.btn_primary}
                onClick={clickHandler}>
                {I18N('login', 'de', [{ key: 'login', value: I18N('login', 'de') }])}
            </button>
        </>
    );
};

export const FindOutMoreButton = ({ onClick, name, primary }: { onClick: () => void, name?: string, primary?: 'white' | 'lila' }) => {

    return (

        <div className={primary ? primary === 'lila' ? buttonStyles.registerButtonLila : buttonStyles.registerButtonWhite : buttonStyles.registerButtonLila}>
            {name}
        </div>

    );
};

export const RegisterButton = ({ formIsValid, clickHandler }: ILogin) => {
    return (
        <Link to={'/register'}>
            <button type="submit" disabled={!formIsValid} onClick={clickHandler}
                className={`${styles.btn_primary} mt-5`}>
                {I18N('createAccount', 'en', [{
                    key: 'createAccount',
                    value: I18N('createAccount', 'en')
                }])}
            </button>
        </Link>
    );
}

export const SaveChangeButton = ({ clickHandler, text }: ILogin) => {
    return (
        <>
            <Button className={classes.btn_primary_modals} onClick={clickHandler}>
                {text ? text : I18N('save', 'de')}
            </Button>
        </>
    );
}

export const CalendarConfirmButton = ({ clickHandler, buttonName }: ILogin) => {
    return (
        <>
            <Button className={classes.btn_primary_modals} onClick={clickHandler}>
                {buttonName}
            </Button>
        </>
    );
}


export const SendBookingRequestButton = ({ clickHandler }: ILogin) => {
    return (
        <>
            <button className={`${buttonClass.btn_primary} m-0 w-auto`} onClick={clickHandler}>
                {I18N('send_request', 'de', [{
                    key: 'send_request',
                    value: I18N('send_request', 'de')
                }])}
            </button>
        </>
    )
}

export const BookingRequestManipulatingButton = ({ clickHandler, buttonName, eventId, style } : any) => {

    const styleHandler = () => {
        switch (style) {
            case undefined: return  ""
            case "warning": return classes.bg_warning
            case "danger": return classes.bg_danger
        }
    }

    return (
        <>
            <Button className={classes.btn_primary_modals + " " + styleHandler()}
                onClick={e => clickHandler(e, eventId)}>
                {buttonName}
            </Button>
        </>
    )
}

export const FinishBookingButton = ({ clickHandler, buttonName, eventId, formIsValid }: ILogin) => {
    return (
        <Button className={classes.btn_primary_modals} disabled={!formIsValid}
            onClick={e => clickHandler(e, eventId)}>
            {buttonName}
        </Button>
    )
}

export const SendMessageButton = ({ clickHandler }: iSendMessage) => {
    const buttonStyle = {
        border: 'none',
        background: 'transparent',
        margin: '0 0 0 -40px',
    }
    return (
        <>
            <button onClick={clickHandler} style={buttonStyle}>
                <SendMessageIcon />
            </button>
        </>
    )
}

export const CancelButton = ({onClick, children, className}) => {

    return (
        <button className={classes.btn_secondary + " " + className} onClick={()=>{onClick()}}>
            {children}
        </button>
    )
}


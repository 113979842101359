import classes from './Events.module.scss'
export function Events() {
    return (
        <section className={classes.section}>
            <div className={classes.textfield}>

                <div className={classes.headline}>
                    HIER STEHT ETWAS ZUM THEMA EVENTS.
                </div>
                <div className={classes.bodyText}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Do- nec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                </div>
                <div className={classes.bodyText}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Do- nec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                </div>
            </div>
        </section>
    )
}
import React, {useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import classes from '../../components/Styles/BookingOverview.module.scss'
import {I18N} from "../../i18n/i18n";
import {apiSuccessHandler, apiWarningHandler} from "../../Helper/api.services";
import {CreditCardIcon, CurrencyIcon} from "../../components/Icons/Icons";
import {FRONTED_PATH} from "../Header/Header";
import {PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {options} from "../../App";
import {changePaymentAmount, manipulatePaymentTokens} from "../../Helper/API";
import {iPaymentManipulation, PaymentTypeEnum} from "./ProfileAdditionalInformation/ProfileAdditionalInformation";
import LoadingPage from "../Information/LoadingPage";

export interface iPaymentMethod {
    paymentMethodId?: string,
    amount?: number
}

// This values are the props in the UI
let amount = "10";
const style = {"layout": "vertical"};
const currency = "EUR";


export const ButtonWrapper = ({currency, showSpinner}) => {

    const [{options, isPending}, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    const [addTokens, setAddTokens] = useState<iPaymentManipulation>({
        amount: 0,
        type: PaymentTypeEnum.PLUS
    })

    return (<>
            {isPending && showSpinner && <div className="d-flex align-items-center justify-content-center mb-3">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>}


            {/*{(showSpinner && isPending) && <div className="spinner"/>}*/}
            {!isPending &&
            <PayPalButtons
                style={{
                    shape: 'rect',
                    color: 'gold',
                    layout: 'horizontal',
                    label: 'paypal',
                    tagline: false
                }}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {


                        manipulatePaymentTokens({...addTokens!, amount: Number(amount)}).then(res => {
                            if (res) {
                                window.location.href = `${FRONTED_PATH}/payment-response`;
                            }
                        }).catch(error => error);
                    });
                }}
            />
            }
        </>
    );
}


export default function CheckoutForm() {

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, [])

    const stripe = useStripe();
    const elements = useElements();
    const [showInputFields, setShowInputFields] = useState<boolean>(false);
    const [showCreditCards, setShowCreditCards] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addTokens, setAddTokens] = useState<iPaymentManipulation>({
        amount: 0,
        type: PaymentTypeEnum.PLUS
    })
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );


        if (!clientSecret) {
            return;
        }

        stripe?.retrievePaymentIntent(clientSecret)?.then(({paymentIntent}) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    apiSuccessHandler('Payment succeeded!')
                    break;
                case "processing":
                    apiWarningHandler('Your payment is processing.')
                    break;
                case "requires_payment_method":
                    apiWarningHandler('Your payment was not successful, please try again.')
                    break;
                default:
                    apiWarningHandler('Something went wrong.')
                    break;
            }
        });
    }, [stripe]);

    useEffect(() => {
        setShowSpinner(true)
        if (showCreditCards) {
            setTimeout(() => {
                setShowSpinner(false)
            }, 2000)
        }
    }, [showCreditCards])


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }


        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${FRONTED_PATH}/payment-response`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            apiWarningHandler(error?.message)
        } else {
            apiWarningHandler('An unexpected error occured.')
        }
    };

    const addActive = (id: any, price) => {
        if (showCreditCards) {
            setShowCreditCards(false);
        }

        const currentClass = document.getElementById(id);
        const allItems = document.getElementsByClassName('payment-card-select');
        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].classList.contains('active-tab')) {
                allItems[i].classList.remove('active-tab')
            }
            allItems[i].classList.remove('active-tab');
        }
        currentClass?.classList.add('active-tab');
        setShowInputFields(true);
        amount = price;
        changePaymentAmount({id: options.clientSecret, amount: `${amount}00`}).then(res => {
        }).catch(error => error);
    }


    const [plans, setPlans] = useState([
        {id: 1, name: 'Starter', price: 10, description: 'Lorem ipsum dolor sit amet, consecteturlsad.'},
        {
            id: 2, name: 'Personal', price: 50, description: 'Lorem ipsum dolor sit amet, consecteturlsad.'
        },
        {id: 3, name: 'Team', price: 100, description: 'Lorem ipsum dolor sit amet, consecteturlsad.'}
    ])


    return (
        <>
            {isLoading && <LoadingPage/>}

            <div className={`${isLoading ? 'filter-blur' : ''} mt-6 ${classes.Payments}`}>
                <Container>
                    <Row className=" align-items-center responsive-payments-card">
                        <Col md={`${showCreditCards || showInputFields ? 6 : 12}`} className="pe-md-5">
                            <Row className="w-100 m-auto">
                                {plans?.map?.(payment => {
                                    return (
                                        <Col sm={12}
                                             className="payment-card-select my-3 d-flex justify-content-between align-items-center"
                                             onClick={() => addActive(payment?.id, payment?.price)} key={payment?.id}
                                             id={`${payment?.id}`}>
                                            <div className="d-flex flex-column">
                                                <h4>{payment?.name}</h4>
                                                <span className="payment-description">{payment?.description}</span>
                                            </div>
                                            <div className="payment-quantity">
                                                {payment?.price}
                                                <CurrencyIcon/>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                        {showInputFields && <Col md={6}>
                            <PayPalScriptProvider
                                options={{
                                    "client-id": "AVflXa3Ua3qRLFDkDbtDKzjf-FiKHBLpGUBSYhrN2QWxFTqP3n6-ly42yejAhTNjmT4WZ5vNtoQVTsGJ",
                                    components: "buttons",
                                    currency: "EUR"
                                }}>
                                <ButtonWrapper
                                    currency={currency}
                                    showSpinner={true}
                                />
                            </PayPalScriptProvider>
                            <button className="debit-card-button" onClick={() => {
                                setShowCreditCards(true);
                                setShowInputFields(false);
                            }
                            }>
                                <CreditCardIcon/>
                                {I18N('debit_or_credit_card', 'de', [{
                                    key: 'debit_or_credit_card',
                                    value: I18N('debit_or_credit_card', 'de')
                                }])}
                            </button>
                        </Col>}
                        {showCreditCards && <Col md={6}>
                            <form id="payment-form" onSubmit={handleSubmit}>
                                {showSpinner &&
                                    <div className="d-flex align-items-center justify-content-center mb-3">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                }

                                {(stripe || elements) && <PaymentElement id="payment-element"/>}
                                <button disabled={isLoading || !stripe || !elements} id="submit"
                                        className={`${classes.btn_primary_modal} w-100 mt-3`}
                                        style={{float: 'right'}}>
                                <span id="button-text">
                                    {isLoading ? <div className="spinner" id="spinner"></div> :
                                        <>
                                            {I18N('pay', 'de', [{
                                                key: 'pay',
                                                value: I18N('pay', 'de')
                                            }])}
                                        </>
                                    }
                                </span>
                                </button>
                            </form>
                        </Col>}
                    </Row>
                </Container>
            </div>
        </>

    );
}

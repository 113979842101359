import axios from "axios";
import {
    iBankInfo, iChangeLanguage,
    iForgotPassword, iNewPassword, iUserInfo, iUserMusicCategory, iWorkingDays
} from "../pages/Profile/Profile/Profile";
import {
    IAvailabilty,
    IProfileInfo, IProfileName
} from "./Interfaces/IProfile";
import {
    iBiography,
    iBookingFinish,
    iBookUser,
    iBookUserAnswer, iCreateCalendarEvent, iDialogArtist, iFavoriteUser,
    iSendMessage,
    iSocialNetwork, iSupportComment, iSupportStatus, iSupportTicket,

} from "../pages/Profile/ProfileModals/ProfileModals";
import {ILogin, IRegister} from "../pages/Registration/Registration";
import {
    ADD_EVENT_CALENDAR,
    ADD_IMAGE_FOR_USER_GALLERY,
    ADD_PORTFOLIO_USER_INFO,
    ADMIN_PATH,
    ALL_ACCEPTED_BOOKINGS,
    BASE_PATH,
    BOOK_AN_ARTIST, CLIENT_ID_FOR_INSTAGRAM, CLIENT_SECRET_FOR_INSTAGRAM,
    DEFAULT_BANK_INFO, FRONTEND_PATH,
    GET_INVOICE_BY_ID,
    GET_PORTFOLIO_CATEGORY_LIST,
    GET_USER_COORDINATES,
    INSTAGRAM_AUTH_PATH,
    LOGIN_PATH,
    NOTIFICATIONS_PATH,
    PAYMENT_PATH,
    REGISTRATION_PATH,
    SEND_PRIVATE_MESSAGE,
    SUPPORT_PATH,
    UPDATE_PROFILE_PATH,
    UPDATE_USER_DESCRIPTION,
    UPLOAD_HEADER_IMAGE,
    UPLOAD_PROFILE_PICTURE,
    USER_FAVORITES,
    WEBSITE_STATS_PATH

} from "./api.route";
import {apiErrorHandler, apiErrorHandlerBadRequest, apiSuccessHandler, requests} from "./api.services";
import {I18N} from "../i18n/i18n";
import React from "react";
import {iCompanyInfo, iPaymentManipulation, iStripePayment} from "../pages/Profile/ProfileAdditionalInformation/ProfileAdditionalInformation";
import {IPortfolioPutRequest, IUserPutRequest} from "./IAPI";
import {determineDay} from "./Interfaces/Functions";


// import {iPaymentMethod} from "../pages/Profile/CheckoutForm";


export async function LoginAPI(body: ILogin, cb: (token: string) => void) {

    return new Promise((resolve, reject) => {
        axios.post(LOGIN_PATH, body).then(res => {
            cb(res.data.token)
            localStorage.setItem('type', res.data.type);
            localStorage.setItem('token', res.data.token);
            apiSuccessHandler(
                <>
                    {I18N('success_login', 'de', [{
                        key: 'success_login',
                        value: I18N('success_login', 'de')
                    }])}
                </>
            );
            resolve('')
        }).catch(error => {
            if (error.message == 'Request failed with status code 400') {
                apiErrorHandlerBadRequest(
                    <>
                        {I18N('pass_characters', 'de', [{
                            key: 'pass_characters',
                            value: I18N('pass_characters', 'de')
                        }])}
                    </>
                );
                reject('');
            } else {
                apiErrorHandler(error)
                reject('');
            }
        });
    })
}

export async function RegistrationAPI(body: IRegister) {
    return await axios.post(REGISTRATION_PATH, {
        ...body
    }).then(res => {
        apiSuccessHandler(
            <>
                {I18N('success_register', 'de', [{
                    key: 'success_register',
                    value: I18N('success_register', 'de')
                }])}
            </>
        );
    }).catch(error => apiErrorHandler(error));
}

export async function ConfirmEmailAPI(token: string) {
    return await axios.get(BASE_PATH + `/auth/confirm-email/?token=${token}`)
        .then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_verified', 'de', [{
                        key: 'success_verified',
                        value: I18N('success_verified', 'de')
                    }])}
                </>
            );
        }).catch(error => apiErrorHandler(error));
}

export async function ConfirmSocialAPI(token: string, refreshToken: string, social: string) {
    try {

        const response = await axios.get(BASE_PATH + `/auth/${social}/redirect/?token=${token}&refreshToken=${refreshToken}`)

        window.location.href = response.config.url as string;


        apiSuccessHandler(
            <>
                {I18N('success_login', 'de', [{
                    key: 'success_login',
                    value: I18N('success_login', 'de')
                }])}
            </>
        );
    } catch (error) {
        apiErrorHandler(error)
    }
}

export function getCurrentUserData(id: string) {
    return requests.get(BASE_PATH + `/portfolio/${id}`)
        .then(data => data)
        .catch(error => error)
}

export function getUserData(callback: ((args: any) => void), route: string, id: string) {
    return requests.get(BASE_PATH + `/portfolio/${id}`)
        .then(data => {
                 data.categories = data.categories.map((category) => category.category)
                return data
            }
        )
        .catch(error => apiErrorHandler(error, callback, route));
}

export function getUserPortfolio(id: string) {
    return requests.get(BASE_PATH + `/portfolio/${id}`)
        .then((data) => {
                data.categories = data.categories.map((category) => category.category)
                data.jobs = data.jobs.map((job) => job.job)
                return data
            }
        )
        .catch(error => apiErrorHandler(error))
}

export async function updateProfile(body: IUserPutRequest) {
    return await requests.put(UPDATE_PROFILE_PATH, {...body})
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getInstaAccessToken(code: string){
    const body = {
        client_id: CLIENT_ID_FOR_INSTAGRAM,
        client_secret: CLIENT_SECRET_FOR_INSTAGRAM,
        redirect_uri: FRONTEND_PATH + 'profile/0',
        grant_type: 'authorization_code',
        code: code
    }
        return await requests.get(INSTAGRAM_AUTH_PATH, {body})
            .then(data => data)
            .catch(error => apiErrorHandler(error));
}

export async function updateUserDescription(body: IProfileInfo) {
    return await requests.put(UPDATE_USER_DESCRIPTION, {description: body.description})
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateUserPortfolio(body: IPortfolioPutRequest) {
    return await requests.put(UPDATE_USER_DESCRIPTION, body)
        .then(
            data => {
                data.categories = data.categories.map((category) => category.category)
                data.jobs = data.jobs.map((job) => job.job)
                return data
            }
        )
        .catch(error => apiErrorHandler(error));
}

export async function updateUserPrice(body: IProfileInfo) {
    const requestBody = {
        pricePerHour: body.pricePerHour,
        pricePerEvent: body.pricePerEvent,
        priceDescription: body.priceDescription
    }

    return await requests.put(`${UPDATE_USER_DESCRIPTION}`, requestBody )
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addUserSocialNetwork(body: iSocialNetwork) {
    return await requests.post(`${ADD_PORTFOLIO_USER_INFO}/platform`, {...body})
        .then(data => {
            if (!data) throw new Error('Etwas ist schief gelaufen')
            return data
        }).catch(error => apiErrorHandler(error));
}

export async function deleteUserSocialNetwork(id: number) {
    return await requests.delete(BASE_PATH + `/portfolio/platform/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addPictureForGallery(body: FormData) {
    return await requests.post(ADD_IMAGE_FOR_USER_GALLERY, body)
        .then(data => {
            if (!data) throw new Error('Selected file is not image!')
            return data
        }).catch(error => apiErrorHandler(error))
}

export async function deleteUserPhotoInGallery(id: number) {
    return await requests.delete(BASE_PATH + `/portfolio/gallery/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function uploadProfilePicture(body: any) {
    return await requests.put(UPLOAD_PROFILE_PICTURE, body)
        .then(data => {
            if (!data) throw new Error('Selected file is not image!')
            return data
        }).catch(error => apiErrorHandler(error))
}

export async function uploadHeaderImage(body: any) {
    return await requests.put(UPLOAD_HEADER_IMAGE, body)
        .then(data => {
            if (!data) throw new Error('Selected file is not image!')
            return data
        }).catch(error => apiErrorHandler(error))
}

export async function getAllMusicCategory() {
    return await requests.get(GET_PORTFOLIO_CATEGORY_LIST)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addMusicCategory(body: iUserMusicCategory) {
    return await requests.post(GET_PORTFOLIO_CATEGORY_LIST, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addPortfolioJob(body: iUserMusicCategory) {
    return await requests.post(`${ADD_PORTFOLIO_USER_INFO}/job`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deleteMusicCategory(id: number) {
    return await requests.delete(`${GET_PORTFOLIO_CATEGORY_LIST}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deletePortfolioJob(id: number) {
    return await requests.delete(`${ADD_PORTFOLIO_USER_INFO}/job/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAllPortfolioJobs() {
    return await requests.get(`${ADD_PORTFOLIO_USER_INFO}/job`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}


export async function updateUserInfo(body) {
    return await requests.put(UPDATE_USER_DESCRIPTION, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}


export async function addNewBooking(body: iBookUser) {
    return await requests.post(BOOK_AN_ARTIST, body)
        .then(data => {
            if (!data) throw new Error('Etwas ist schief gelaufen')
            return data
        }).catch(error => apiErrorHandler(error))
}

export async function getBookingList() {
    return await requests.get(BOOK_AN_ARTIST).then(data => data).catch(error => apiErrorHandler(error));
}

export async function addBookingAnswer(body: iBookUserAnswer, id?: number) {
    return await requests.put(BASE_PATH + `/booking/${id}`, {...body})
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAllUsers(body?: any) {
    return await requests.get(UPDATE_USER_DESCRIPTION, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAllAcceptedBookings() {
    return await requests.get(ALL_ACCEPTED_BOOKINGS)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addBookingReview(body: iBookingFinish, id?: number) {
    return await requests.put(BASE_PATH + `/booking/put/bookings/${id}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getUserInfo() {
    return await requests.get(UPDATE_PROFILE_PATH)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewPrivateMessage(body: iSendMessage) {
    return await requests.post(SEND_PRIVATE_MESSAGE, body)
        .then(data => {
            if (!data) throw new Error('Etwas ist schief gelaufen')
            return data
        }).catch(error => apiErrorHandler(error))
}

export async function getUserCoordinates(search: string = '') {
    return await requests.get(GET_USER_COORDINATES, {search})
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getUserCoordinatesByID(id: any) {
    return await requests.get(GET_USER_COORDINATES + '/' + id)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}


export async function getBookingOccasion() {
    return requests.get(`${BOOK_AN_ARTIST}/occasion`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewCalendarEvent(body: iCreateCalendarEvent) {
    return await requests.post(ADD_EVENT_CALENDAR, body)
        .then(data => {
            if (!data) throw new Error('Etwas ist schief gelaufen')
            return data
        }).catch(error => apiErrorHandler(error));
}

export async function getCalendarEvents(id: number) {
    return await requests.get(`${ADD_EVENT_CALENDAR}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateCalendarEvent(body: iCreateCalendarEvent, id: number) {
    return await requests.put(`${ADD_EVENT_CALENDAR}/${id}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deleteCalendarEvent(id: number) {
    return await requests.delete(`${ADD_EVENT_CALENDAR}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getCalendarEventsInfo(userId: number, eventId: number) {
    return await requests.get(`${ADD_EVENT_CALENDAR}/${userId}/${eventId}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewArtistCalendarInfo(id: number, body: iDialogArtist) {

    return requests.post(`${ADD_EVENT_CALENDAR}/request/${id}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addUserToFavorites( id: number, body?: iFavoriteUser) {
    return requests.post(`${USER_FAVORITES}/${id}`, body).then(data => {
        if (!data) throw new Error('Etwas ist schief gelaufen')
        return data
    }).catch(error => apiErrorHandler(error))
}

export async function deleteUserFromFavorites(id: number) {
    return requests.delete(`${USER_FAVORITES}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error))
};

export async function getUserFavorites() {
    return requests.get(`${USER_FAVORITES}/list`)
        .then(data => data)
        .catch(error => apiErrorHandler(error))
}

export async function addNewSupportTicket(body: iSupportTicket) {
    return requests.post(`${SUPPORT_PATH}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getSupportTicket() {
    return requests.get(`${SUPPORT_PATH}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getSupportTicketID(id: number) {
    return requests.get(`${SUPPORT_PATH}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewSupportComment(body: iSupportComment) {
    return requests.post(`${SUPPORT_PATH}/comment`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function changeSupportStatus(body: iSupportStatus, id: number) {
    return requests.put(`${SUPPORT_PATH}/${id}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addCompanyLogo(body: any) {
    return requests.put(`${UPDATE_USER_DESCRIPTION}/upload/logo`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getCompanyData(id: number) {
    return requests.get(`${UPDATE_USER_DESCRIPTION}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateCompanyData(body: iCompanyInfo) {
    return requests.put(`${UPDATE_USER_DESCRIPTION}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addPaymentTokens(body?: any) {
    return requests.post(`${PAYMENT_PATH}/charge`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function changePaymentAmount(body?: any) {
    return requests.post(`${PAYMENT_PATH}/charge/amount`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getPaymentTokens() {
    return requests.get(`${PAYMENT_PATH}/points`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function manipulatePaymentTokens(body: iPaymentManipulation) {
    return requests.post(`${PAYMENT_PATH}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function generateStripeTokens(body: iStripePayment) {
    return requests.post(`${PAYMENT_PATH}/stripe/points`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAdminStatistics() {
    return requests.get(`${SUPPORT_PATH}/admin/statistic`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAllJobs() {
    return requests.get(`${ADD_PORTFOLIO_USER_INFO}/job`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getInvoiceByID(id) {
    return requests.get(`${GET_INVOICE_BY_ID}/` + id)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function createBookingInvoice(id) {
    return requests.post(`${GET_INVOICE_BY_ID}/` + id)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addPortfolioBiography(body: iBiography) {
    return requests.post(`${ADD_PORTFOLIO_USER_INFO}/biography`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getPortfolioBiography() {
    return requests.get(`${ADD_PORTFOLIO_USER_INFO}/user/biography`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deletePortfolioBiography(id: number) {
    return requests.delete(`${ADD_PORTFOLIO_USER_INFO}/biography/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getAllAdmins() {
    return requests.get(`${ADMIN_PATH}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewAdmin(body: any) {
    return requests.post(`${ADMIN_PATH}`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deleteAdmin(id: number) {
    return requests.delete(`${ADMIN_PATH}/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getBookingEvents() {
    return requests.get(`${BOOK_AN_ARTIST}/occasion`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function deleteBookingOccasion(id: number) {
    return requests.delete(`${BOOK_AN_ARTIST}/occasion/${id}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addBookingOccasion(body: iUserMusicCategory) {
    return requests.post(`${BOOK_AN_ARTIST}/occasion`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function forgotPasswordAPI(body: iForgotPassword) {
    return requests.post(`${BASE_PATH}/auth/forgot-password`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function addNewPassword(body: iNewPassword) {
    return requests.put(`${BASE_PATH}/auth/change-password`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getWebsiteData() {
    return requests.get(WEBSITE_STATS_PATH)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getNewNotifications() {
    return requests.get(`${NOTIFICATIONS_PATH}/unread`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function clearUnreadNotifications(id: number) {
    return requests.put(`${NOTIFICATIONS_PATH}/${id}`)
        .then(data => data)
        .catch(error => error);
}

export async function getAllNotifications() {
    return requests.get(NOTIFICATIONS_PATH)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function clearAllHeaderNotifications() {
    return requests.put(NOTIFICATIONS_PATH)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}


export async function getNumberOfTokens() {
    return requests.get(`${PAYMENT_PATH}/points/balance`)
        .then(data => data)
        .catch(error => {
                if (error?.message === 'Request failed with status code 401') {
                    return localStorage.setItem('token', '')
                }
                apiErrorHandler(error)
            }
        );
}

export async function clearNotificationType(type: string) {
    return requests.put(`${NOTIFICATIONS_PATH}/type/${type}`)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function getBankInfo() {
    return requests.get(DEFAULT_BANK_INFO)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateBankInfo(body: iBankInfo) {
    return requests.put(DEFAULT_BANK_INFO, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateBankInfoFirstTime(body: iBankInfo) {
    return requests.post(DEFAULT_BANK_INFO, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function updateWorkingDays(body: iWorkingDays) {
    return requests.post(`${ADD_PORTFOLIO_USER_INFO}/working`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}

export async function changeLanguageAPI(body: iChangeLanguage) {
    return requests.put(`${UPDATE_PROFILE_PATH}/change/language`, body)
        .then(data => data)
        .catch(error => apiErrorHandler(error));
}
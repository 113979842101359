import { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, ReturnIconGray } from '../../../../components/Icons/Icons';
import classes from './imageSlide.module.scss';

export interface ImageSliderElement {
    imgSrc: string;
    headline: string;
    bodyText: string
}

export function ImageSlider(props: { elements: ImageSliderElement[] }) {
    const [activeImage, setActiveImage] = useState(0)

    function changeActiveImage(index) {
        let maybeeNewIndex = activeImage + index
        let newIndex = 0
        if (maybeeNewIndex < 0) {
            newIndex = props.elements.length - 1
        } else if (maybeeNewIndex >= props.elements.length) {
            newIndex = 0
        } else {
            newIndex = maybeeNewIndex
        }
        setActiveImage(newIndex)
    }

    useEffect(() => {
        let timer = setInterval(() => {
           // changeActiveImage(1)
        }, 4000)
        return () => {
            clearInterval(timer)
        };
    }, [activeImage])
    return (
        <div className={classes.ImageSliderContainer}>
            <div className={`${classes.switchButtons}  `} onClick={() => changeActiveImage(-1)}>
                <ArrowLeft height={30} />
            </div>
            {props.elements.map((element, index) => {
                return (<ImageSlideItem length={props.elements.length} currentIndex={index} onClick={() => setActiveImage(index)} element={element} activeIndex={activeImage} />)
            })}
            <div className={`${classes.switchButtonsMobile} hide`}>
                <div onClick={() => changeActiveImage(-1)}>
                    <ArrowLeft height={30} />
                </div>
                <div onClick={() => changeActiveImage(1)}>
                    <ArrowRight height={30} />
                </div>
            </div>
            <div className={classes.switchButtons} onClick={() => changeActiveImage(1)}>
                <ArrowRight height={30} />
            </div>
        </div>
    )
}

export function ImageSlideItem(props: { length: number, element: ImageSliderElement, activeIndex: number, currentIndex: number, onClick: () => void }) {
    let isActive = props.activeIndex === props.currentIndex

    function shouldBeVisibleOnTablet() {
        let thisIsTheEnd = props.activeIndex === props.length - 1
        let thisIsTheStart = props.activeIndex === 0
        if (isActive) {
            return true
        }
        if (thisIsTheEnd) {
            return props.currentIndex === props.length - 2
        } else if (thisIsTheStart) {
            return props.currentIndex === 1
        } else {
            return props.currentIndex === props.activeIndex + 1
        }
    }

    return (
        <div onClick={props.onClick} className={`${!isActive ? classes.ImageSliderItemConatinerActive : classes.ImageSliderItemConatiner} ${shouldBeVisibleOnTablet() ? '' : classes.hide} ${isActive ? '' : classes.hideMobile}`} >
            <div className={classes.leftImageContainer}>
                <img alt="artist" src={props.element.imgSrc} className={classes.image}>
                </img>
            </div>

            {isActive &&
                <div className={classes.rightImageContainer}>
                    <div className={classes.headline}>{props.element.headline}</div>
                    <div className={classes.body}>{props.element.bodyText}</div>
                </div>}
        </div>)
}
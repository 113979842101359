import buttonStyles from "../../../components/Buttons/ButtonsStyle.module.scss";
import {LoginModal} from "../../Login/LoginModal";
import {useState} from "react";
import {Link} from "react-router-dom";

export function FirstSection() {

    const [loginOpen, setLoginOpen] = useState<boolean>(false)


    return (
        <section className="bg_image_website">
            <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img alt="Bloghead" src="/Logo-Bloghead-Claim-white.png" style={{ width: '60%' }}>
                </img>
                <div style={{position: 'absolute', bottom: '25%', display: 'flex', justifyContent: 'center'}}>
                    <Link to={'/website#registration'} className={buttonStyles.registerButtonLila} onClick={()=>setLoginOpen(true)}>MEMBER WERDEN</Link>
                </div>
            </div>
            <LoginModal open={loginOpen} setOpen={setLoginOpen}/>
        </section>
    )
}
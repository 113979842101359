import {Container} from "react-bootstrap";

export function Privacy() {
    return (
        <div className="static-page py-5">
            <Container>
                <h3>
                    Datenschutz
                </h3>
                <p>Die MuVe GmbH (nachfolgend „wir“) betreibt die unter der Domain „www.XY.de“ abrufbare Webseite
                    (nachfolgend „Webseite“). Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei
                    Nutzung dieser Webseite. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar
                    sind, also z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>
                <p>
                    Ihre Daten werden nach den Bestimmungen des Telemediengesetzes (TMG) und des Datenschutzrechts,
                    insbesondere des Bundesdatenschutzgesetzes (BDSG) und der Datenschutz-Grundverordnung (DSGVO)
                    erhoben, verarbeitet und genutzt. Mittels dieser Datenschutzerklärung informieren wir über die
                    Erhebung von personenbezogenen Daten auf und über unsere Webseite bei der betroffenen Person nach
                    Art. 13 DSGVO.
                </p>
                <p>
                    Erhebung personenbezogener Daten bei informatorischer Nutzung
                </p>
                <p>
                    (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht zur Nutzung der
                    Website anmelden, registrieren oder uns sonst Informationen übermitteln, erheben wir keine
                    personenbezogenen Daten, mit Ausnahme der Daten, die Ihr Browser übermittelt. Zweck dieser
                    Datenerhebung ist es, Ihnen den Besuch der Website zu ermöglichen und die Funktionsfähigkeit der
                    Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur
                    Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Diese sind:
                </p>
                <div className="d-flex flex-column">
                    <span>
                    – IP-Adresse
                </span>
                    <span>
                    – Datum und Uhrzeit der Anfrage
                </span>
                    <span>– Zeitzonendifferenz zur Greenwich Mean Time (GMT)</span>
                    <span>– Inhalt der Anforderung (konkrete Seite)</span>
                    <span>– Zugriffsstatus/HTTP-Statuscode</span>
                    <span>– jeweils übertragene Datenmenge</span>
                    <span>– Website, von der die Anforderung kommt</span>
                    <span>– Browser</span>
                    <span>– Betriebssystem und dessen Oberfläche</span>
                    <span>– Sprache und Version der Browsersoftware.</span>
                </div>
                <p className="mt-3">
                    (2) Weiterhin werden bei Nutzung der Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
                    handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser
                    zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (in diesem Fall also
                    uns), bestimmte Informationen zufließen. Ein Cookie beinhaltet typischerweise den Namen der Domain,
                    von dem das Cookie stammt, die „Lebenszeit“ des Cookies, und einen Wert, regelmäßig eine zufällig
                    generierte einmalige Nummer. Cookies können keine Programme ausführen oder Viren auf Ihren Computer
                    übertragen. Zweck der Nutzung ist es, unsere Webseite insgesamt nutzerfreundlicher und effektiver zu
                    machen. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach
                    einem Seitenwechsel identifiziert werden kann.
                </p>
                <p>Diese Website nutzt Cookies in folgendem Umfang:
                </p>
                <p>– Transiente Cookies (Session Cookies) werden automatisiert gelöscht, wenn Sie den Browser schließen.
                    Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit
                    welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch
                    kann Ihr Rechner wiedererkannt werden, wenn Sie auf die Website zurückkehren. Die Session-Cookies
                    werden gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.</p>
                <p>– Persistente Cookies (Dauerhafte Cookies) werden automatisiert nach einer vorgegebenen Dauer
                    gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den
                    Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p>
                <p>– Cookies in Zusammenhang mit Diensten Dritter, wie nachfolgend beschrieben.
                </p>
                <p>– Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch Ihr
                    Flash-Plug-in. Diese speichern die notwendigen Daten unabhängig von Ihrem verwendetem Browser und
                    haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen
                    Sie ein entsprechendes Add-On installieren
                </p>
                <p>– Web Beacons sind elektronische Zeichen (auch „Clear GIFs“ oder „Web Bugs“ genannt), die uns
                    gestatten, die Anzahl der Nutzer zu zählen, die die Webseite besucht haben.
                </p>
                <p>Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme
                    von Third-Party-Cookies oder allen Cookies ablehnen. Informationen über die Verwaltung und Löschung
                    von Cookies sowie eine entsprechende Anleitung hierzu für die gängigen Browser sind zudem unter
                    www.meine-cookies.org abrufbar. Wir weisen Sie jedoch darauf hin, dass Sie dann eventuell nicht alle
                    Funktionen dieser Website nutzen können.
                </p>
                <p>(3) Diese gespeicherten Informationen werden getrennt von eventuell weiter bei uns angegeben Daten
                    gespeichert. Insbesondere werden die Daten der Cookies nicht mit Ihren weiteren Daten, sofern solche
                    übermittelt werden, verknüpft</p>
                <p>
                    Löschfristen
                </p>
                <p>‍Soweit nicht in diesen Datenschutzhinweisen anders beschrieben, speichern wir Ihre Daten nur so
                    lange, wie es für die Zwecke, für die sie erhoben oder verarbeitet wurden, notwendig ist, es sei
                    denn, gesetzliche Aufbewahrungsfristen erfordern eine längere Speicherung. Ihre personenbezogenen
                    Daten werden also nach der Bearbeitung Ihres Anliegens gelöscht soweit nichts anderes vereinbart
                    wurde oder gesetzlich vorgeschrieben ist.
                </p>
                <p>Bestandsdaten werden zwei Jahre nach Beendigung des Vertragsverhältnisses zum Ablauf des
                    Kalenderjahres gelöscht, es sei denn eine längere Speicherung ist erforderlich und gesetzlich
                    zulässig.
                </p>
                <p>Statistische anonyme Auswertung der Nutzungsdaten
                </p>
                <p>Wir sind berechtigt, für Zwecke der Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung
                    der Webseite Nutzungsprofile unter Verwendung von Pseudonymen zu erstellen, sofern Sie dem nicht
                    widersprechen. Insbesondere werten wir die Nutzungsdaten zu statistischen Zwecken anonym aus, um die
                    Webseite bedarfsgerecht zu gestalten. Sie können dieser Verwendung Ihrer personenbezogenen Daten
                    durch Mitteilung an uns widersprechen.
                </p>
                <p>‍Einsatz von Google Analytics
                </p>
                <p>(1) Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google
                    Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die
                    eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                    Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in
                    den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser
                    Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
                    oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
                    Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und
                    dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um
                    Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und
                    um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                    Website-Betreiber zu erbringen.
                </p>
                <p>(2) Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                    anderen Daten von Google zusammengeführt.
                </p>
                <p>
                    (3) Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                    Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                </p>
                <p>(4) Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                    Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                    Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen
                    und installieren: <a
                        href="http://tools.google.com/dlpage/gaoptout?hl=de.">http://tools.google.com/dlpage/gaoptout?hl=de.</a>

                </p>
                <p>(5) Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden
                    IP-Adressen gekürzt weiterverarbeitet, eine direkte Personenbeziehbarkeit kann damit ausgeschlossen
                    werden.
                </p>
                <p>(6) Die Nutzung von Google Analytics erfolgt in Übereinstimmung mit den Voraussetzungen, auf die sich
                    die deutschen Datenschutzbehörden mit Google verständigten. Informationen des Drittanbieters: Google
                    Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
                    Nutzerbedingungen: <a
                        href="http://www.google.com/analytics/terms/de.html"
                        className="text-break">http://www.google.com/analytics/terms/de.html</a>,
                    Übersicht zum Datenschutz:
                    <a href="http://www.google.com/intl/de/analytics/learn/privacy.html"
                       className="text-break"> http://www.google.com/intl/de/analytics/learn/privacy.html</a>,
                    sowie die Datenschutzerklärung:
                    http://www.google.de/intl/de/p… und Empfänger personenbezogener Daten
                </p>
                <p>(1) Wir setzen im Rahmen der Verarbeitung der personenbezogenen Daten Unterauftragnehmer ein und
                    schließen mit diesen Auftragsverarbeitern einen Vertrag gemäß den Anforderungen des Art. 28 DSGVO.
                </p>
                <p>Als Unterauftragnehmer zum Hosting der Webseite wird die Host Europe GmbH eingesetzt.
                </p>
                <p>‍Schutz der personenbezogenen Daten
                </p>
                <p>‍Wir treffen technische und organisatorische Maßnahmen gemäß den Anforderungen von Art. 32 DSGVO zum
                    Schutz der personenbezogenen Daten des Nutzers. Alle unsere Mitarbeiter, die mit der Verarbeitung
                    personenbezogener Daten beschäftigt sind, sind auf das Datengeheimnis verpflichtet.
                    Personenbezogenen Daten des Nutzers werden bei der Übermittlung an die Webseite mittels HTTPS
                    verschlüsselt.
                    Vom Tracking ausschließen
                    Rechtsgrundlagen</p>

                <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit.
                    Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen
                    Person einholen, dient Art. 6 Abs. 1 lit. a DSGVO als Rechtsgrundlage.
                    Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
                    Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
                    Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
                    Maßnahmen erforderlich sind.
                    Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
                    erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
                    Rechtsgrundlage.
                    Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit.
                    f DSGVO.
                    Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung technisch
                    notweniger Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Die Rechtsgrundlage für die Verarbeitung
                    personenbezogener Daten unter Verwendung von Cookies zu Analysezwecken ist Art. 6 Abs. 1 lit. f
                    DSGVO DSGVO.
                    Nach Ihrer Registrierung für unseren Newsletter speichern wir Ihre E-Mail-Adresse zum Zweck der
                    Zusendung des Newsletters. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DSGVO
                    Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                    Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
                    das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die
                    Verarbeitung.
                    Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser
                    berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all
                    unserer Mitarbeiter und unserer Anteilseigner.</p>
                <p>Keine automatisierte Entscheidungsfindung / kein Profiling
                </p>
                <p>‍Wir nehmen keine automatisierte Entscheidungsfindung und kein Profiling vor.
                </p>
                <p>Rechte betroffener Personen
                </p>
                <p>Dem Nutzer und sonstigen betroffenen Personen stehen in Bezug auf deren personenbezogenen Daten
                    folgende Rechte uns gegenüber zu:
                    Recht auf Auskunft über die betreffenden personenbezogenen Daten (Art. 15 DSGVO)
                    Recht auf Berichtigung (Art. 16 DSGVO)
                    Recht auf Löschung (Art. 17 DSGVO)
                    Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
                    Widerspruchsrecht gegen die Verarbeitung, falls die Datenverarbeitung aufgrund von Art. 6 Abs. 1
                    lit. e oder lit. f DSGVO erfolgt (Art. 21 DSGVO); siehe hierzu auch der nachfolgende Hinweis auf das
                    Widerspruchsrecht nach Art. 21 DSGVO
                    Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
                    Recht, eine erteilte Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund
                    der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird, wenn die Datenverarbeitung
                    auf einer Einwilligung nach Art. 6 Abs. 1 lit. a oder Artikel 9 Abs. 2 lit. a DSGVO beruht
                    Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                    personenbezogenen Daten durch uns zu beschweren (Art. 77 DSGVO).</p>
                <p>Hinweis auf das Widerspruchsrecht nach Art. 21 DSGVO
                </p>
                <p>‍A. Widerspruchsrecht aufgrund der besonderen Situation
                </p>
                <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
                    die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e
                    (öffentliche Sicherheit) oder f (Datenverarbeitung auf der Grundlage einer Interessenabwägung) DSGVO
                    erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                    Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende
                    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
                    überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen.
                </p>
                <p>B. Widerspruchsrecht bei Direktwerbung
                </p>
                <p>Falls wir personenbezogene Daten von Ihnen verarbeiten, um Direktwerbung zu betreiben, so haben Sie
                    das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum
                    Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                    Direktwerbung in Verbindung steht. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
                    so werden die personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                </p>
                <p>C. Ausübung des Widerspruchsrechts
                </p>
                <p>Das Widerspruchsrecht kann formlos ausgeübt werden, etwa per Post an XY.
                </p>
                <p>Diensteanbieter / Verantwortliche Stelle / Kontaktdaten / Widerspruch / Widerruf einer Einwilligung
                </p>
                <p>Diensteanbieter gem. § 13 Telemediengesetz (TMG) und Verantwortlicher im Sinne der DSGVO, sonstiger
                    in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen
                    mit datenschutzrechtlichem Charakter ist die:
                    Adresse
                    XY</p>
                <p>Alle Auskunfts-, Berichtigungs- und Löschungsersuchen, Widersprüche oder Widerrufe einer
                    Einwilligung, die Geltendmachung des Rechts auf Einschränkung der Verarbeitung oder des Rechts auf
                    Datenübertragbarkeit sowie Kommentare oder Fragen des Nutzers in Bezug auf Datenschutz sind an diese
                    Adresse zu richten.
                </p>

            </Container>
        </div>
    )
}
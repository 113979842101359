// base path for api's

export const DOMAIN_PATH = 'backend.blog-head.com'
export const BASE_PATH = 'https://' + DOMAIN_PATH
export const FRONTEND_PATH = 'https://blog-head.com'

//export const BASE_PATH = 'http://localhost:3005'
//export const FRONTEND_PATH = 'http://localhost:3000'
//admin
export const ADMIN_PATH = BASE_PATH + `/user/admin`

export const INSTAGRAM_AUTH_PATH = 'https://api.instagram.com/oauth/authorize'
const APP_CLIENTID_FOR_INSTAGRAM = '238983941941439'
const APP_CLIENT_SECRET_FOR_INSTAGRAM = 'a4dee4042fba7a6484ca41305065ee4e'
const TEST_APP_CLIENTID_FOR_INSTAGRAM = '546556147647217'
const TEST_APP_CLIENT_SECRET_FOR_INSTAGRAM = 'e131abd9fae8ee33856a52e9590559e8'

export const TEST_INSTAGRAM = true

export const CLIENT_ID_FOR_INSTAGRAM = TEST_INSTAGRAM ? TEST_APP_CLIENTID_FOR_INSTAGRAM : APP_CLIENTID_FOR_INSTAGRAM
export const CLIENT_SECRET_FOR_INSTAGRAM = TEST_INSTAGRAM ? TEST_APP_CLIENT_SECRET_FOR_INSTAGRAM : APP_CLIENT_SECRET_FOR_INSTAGRAM

// registration path
export const REGISTRATION_PATH = BASE_PATH + `/auth/register`;

// login path
export const LOGIN_PATH = BASE_PATH + `/auth/login`

//get website stats
export const WEBSITE_STATS_PATH = BASE_PATH + `/auth/statistic`;

// user manipulation
export const UPDATE_PROFILE_PATH = BASE_PATH + `/user`;

// update user description - get all users
export const UPDATE_USER_DESCRIPTION = BASE_PATH + `/portfolio`;

export const UPDATE_PRICE_PATH = BASE_PATH + `/portfolio`

// add social network
export const ADD_SOCIAL_NETWORK_MUSICAN = BASE_PATH + `/portfolio/platform`;

// add user picture for gallery
export const ADD_IMAGE_FOR_USER_GALLERY = BASE_PATH + `/portfolio/gallery`;

//upload user profile picture
export const UPLOAD_PROFILE_PICTURE = BASE_PATH + `/portfolio/picture`;

//upload user profile picture
export const UPLOAD_HEADER_IMAGE = BASE_PATH + `/portfolio/headerImg`;

//add portfolio info
export const ADD_PORTFOLIO_USER_INFO = BASE_PATH + `/portfolio`;

export const GET_INVOICE_BY_ID = BASE_PATH + `/invoice`;

//portfolio category list
export const GET_PORTFOLIO_CATEGORY_LIST = BASE_PATH + `/portfolio/category`;

// booking
export const BOOK_AN_ARTIST = BASE_PATH + `/booking`;

//booking accepted list
export const ALL_ACCEPTED_BOOKINGS = BASE_PATH + `/booking/get/bookings`;

//send user private message
export const SEND_PRIVATE_MESSAGE = BASE_PATH + `/chat/message`;

//get user location
export const GET_USER_COORDINATES = BASE_PATH + `/coordinates`;

//add event on calendar
export const ADD_EVENT_CALENDAR = BASE_PATH + `/events`;

//user favorites
export const USER_FAVORITES = BASE_PATH + `/user/favorite`;

//support
export const SUPPORT_PATH = BASE_PATH + `/support`;

//payment
export const PAYMENT_PATH = BASE_PATH + `/payment`;

//notifications
export const NOTIFICATIONS_PATH = BASE_PATH + `/notifications`;

//BANK payment
export const DEFAULT_BANK_INFO = BASE_PATH + `/payment-info`;


import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {BookingCalendarIcon} from "../../components/Icons/Icons";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import styled from "@emotion/styled";
import classes from "../../components/Styles/ProfileCalendar.module.scss";
import {I18N} from "../../i18n/i18n";
import React, {useEffect, useState} from "react";
import {CalendarConfirmButton, SaveChangeButton} from "../../components/Buttons/Buttons";
import {
    iCreateCalendarEvent,
    ProfileCalendarDescription,
    ProfileCalendarEvent,
} from "./ProfileModals/ProfileModals";
import {
    addNewArtistCalendarInfo,
    addNewCalendarEvent,
    deleteCalendarEvent,
    getCalendarEvents,
    getCalendarEventsInfo,
    updateCalendarEvent
} from "../../Helper/API";
import {apiSuccessHandler, apiWarningHandler} from "../../Helper/api.services";
import LoadingPage from "../Information/LoadingPage";
import moment from "moment";

const StyleWrapper = styled.div`
  .fc td {
    background: transparent;
  }

  .fc-button, .fc-daygrid-event, .fc-today-button {
    color: var(--white);
    background: var(--black);
    border: 1px solid var(--white);
  }

  .fc-theme-standard td{
   border: none; 
  }
  
  .fc-theme-standard th {
    border: none;
  }
  
  .td {
    border: none;
  }
  
  .fc-bg-event {
    opacity: 1;
  }
  
  .fc-button:focus-visible, .fc-button:active {
    outline: none;
    background: var(--primary) !important;
  }

  .fc-toolbar-title {
    color: var(--white);
  }

  .fc-button-primary:disabled, .fc-button-primary:hover {
    background: var(--black);
    border: 1px solid var(--white);
  }

  .fc-button-primary:hover {
    opacity: .8;
  }

  .fc-button-primary:disabled {
    cursor: not-allowed;
  }

  .fc-col-header-cell-cushion, .fc-daygrid-day-number {
    color: var(--white);
    text-decoration: none;
    opacity: .7;
    text-transform: uppercase;
  }

  .fc-scrollgrid-sync-table {
    border-collapse: separate;
    border-spacing: 10px;
  }
  
  .fc-daygrid-day {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .fc-day-other .fc-daygrid-day-top {
    z-index: 3;
  }

  .fc-col-header-cell {
    background: var(--black);
    color: var(--white);
  }
  
  .fc-scrollgrid {
    border: none;
  }

  .fc-event-title-container {
    text-align: center;
  }

  .fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--white, #fff);
  }

  .fc-daygrid-more-link {
    color: var(--secondary);
    text-decoration: none;
  }

  .fc .fc-daygrid-event-harness {
    margin-bottom: 0.25rem;
  }

  .fc-daygrid-event {
    cursor: pointer;
  }

  .fc-daygrid-event:hover {
    background: rgba(91, 122, 218, 0.65);
  }

  .fc-day {
    height: 1rem;
  }

  .fc-daygrid-day-frame {
    transition: 0.5s;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--white);
  }

  .fc-daygrid-day-frame:hover {
    cursor: pointer;
    background: rgba(91, 122, 218, 0.25);
  }

  @media (max-width: 768px) {
    .fc .fc-toolbar {
      flex-direction: column;
    }

    .fc .fc-view-harness {
      height: 55vh !important;
    }

    .fc-daygrid-day-frame {
      display: flex;
      justify-content: center;
    }

    .fc-toolbar-chunk {
      margin-top: 0.5rem;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      width: 100%;
    }

    .fc .fc-daygrid-day-bottom, .fc-event-time {
      font-size: 11px;
    }

    .fc-popover-body .fc-event-title {
      display: inline;
    }

    .fc-popover-body .fc-daygrid-event-harness {
      width: fit-content;
      margin: auto;
      margin-top: 0.25rem;
    }
    
    .fc-daygrid-day-frame{
      border-top: 1px solid var(--white);
    }
    
    td[role=presentation] {
      border: none !important;
    }

    .fc-popover-body .fc-event-future {
      padding: 0 0.25rem;
    }

    .fc-event-title, .fc-daygrid-event-dot {
      display: none;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
      justify-content: center;
    }

    .fc-col-header-cell-cushion, .fc-daygrid-day-number {
      font-size: 0.7rem;
    }

    .fc-daygrid-day-number {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fc-daygrid-day-frame {
      align-items: center;
      flex-direction: column;
    }
  }
`

interface iEvent {
    id: any,
    title: string,
    date: any,
    display?: string,
    color?: string,
    start?: string,
    end?: string,
    backgroundColor?: string
}

export function ProfileCalendar({myProfile, myID, userType}: any) {

    const [show2, setShow2] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [show3, setShow3] = useState<boolean>(false);
    const [show4, setShow4] = useState<boolean>(false);
    const [show5, setShow5] = useState<boolean>(false);
    const [state, setState] = useState<iCreateCalendarEvent>({
        startDate: '',
        nameOfEvent: '',
        description: '',
        locationId: 0
    })

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [eventId, setEventId] = useState<number>(0);
    const [prevDate, setPrevDate] = useState({
        startDate: ''
    })

    const [infoState, setInfoState] = useState({
        description: '',
        id: 0,
        location: {
            name: '',
            zipCode: '',
            id: 0
        },
        nameOfEvent: '',
        requests: [],
        startDate: ''
    });

    const [message, setMessage] = useState({
        message: ''
    });
    const [events, setEvents] = useState<iEvent[]>([])
    const handleClose2 = () => setShow2(false);
    const handleClose = () => setShow(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleClose5 = () => setShow5(false);

    const handleDateClick = (arg: any) => {
        if(myProfile){
            const current = new Date();
            let date = new Date(arg.dateStr)
            if (arg?.date < current) {
                apiWarningHandler(
                    <>
                        {I18N('no_events_past', 'de', [{
                            key: 'no_events_past',
                            value: I18N('no_events_past', 'de')
                        }])}
                    </>
                )
                return;
            }
            let isoString = date.toISOString();
            setState(prev => ({...prev, startDate: isoString}))
            if (events.filter(e => e?.start === arg.dateStr).length > 0) {
                setShow2(false)
            } else {
                setState(prev => ({...prev,
                    nameOfEvent: '',
                    description: '',
                    locationId: 0
                }))
                setShow2(true)
            }
        }
    }

    const handlerChange = (event: any) => {
        setState(prev => ({...prev, [event.target.name]: event.target.value}));
        setMessage(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    function handleLocation(current: any) {
        setState(prev => ({...prev, locationId: current?.value}));
    }

    const handleState = (event: any) => {
        setState(prev => ({...prev, [event.target.name]: +event.target.value}));
    }

    const handleTime = (date: string) => {
        setState(prev => ({...prev, startDate: date}))
    }

    async function onCreateEvent(e: any): Promise<void> {
        let dateRequestState = userType !== 'ARTIST' ? state  : {
            startDate: state.startDate,
            nameOfEvent: 'ArtistEvent',
            description: 'ArtistEvent',
            locationId: 3023
        }
        addNewCalendarEvent(dateRequestState).then(res => {
            const now = new Date()
            const picked = new Date(res?.startDate)
            setEvents(prev => ([...prev, {
                title: res?.nameOfEvent,
                date: res?.startDate,
                id: res?.id,
                start: moment(res?.startDate).format('YYYY-MM-DD'),
                end: moment(res?.startDate).format('YYYY-MM-DD'),
                display: 'background',
                backgroundColor: picked > now ? 'rgb(97, 9, 209)' : 'rgb(159,159,159)'
            }]))
            setState(res)
        }).catch(error => error);
    }

    useEffect(() => {
        if (myID) {
            setIsLoading(true)
            getCalendarEvents(myID).then(data => {
                const eventsArray = data?.map?.((event: any) => {

                    const now = new Date()
                    const picked = new Date(event.startDate)

                    return {
                    id: event.id,
                    start: moment(event.startDate).format('YYYY-MM-DD'),
                    end: moment(event.startDate).format('YYYY-MM-DD'),
                    display: 'background',
                    backgroundColor: picked > now ? 'rgb(97, 9, 209)' : 'rgb(159,159,159)'
                    }
                })
                setEvents(eventsArray);
            }).catch(error => error).finally(() =>
                setIsLoading(false));
        }
    }, [eventId, prevDate, myID]);

    const eventClickHandler = (info: any) => {
            const eventId = +info.event?._def?.publicId;
            let date = new Date(info.event?.startStr)
            let isoString = date.toISOString();
            setState(prev => ({...prev, startDate: isoString}))
            setEventId(eventId);
            getCalendarEventsInfo(myID, eventId).then(res => {
                setInfoState(res)
            }).catch(error => error);
            setShow(true);
    }

    const wrapperFunctionTwo = (e: any) => {
        handleClose2();
        onCreateEvent(e);

    }

    const handleDeleteEvent = () => {
        deleteCalendarEvent(eventId).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_delete_event', 'de', [{
                        key: 'success_delete_event',
                        value: I18N('success_delete_event', 'de')
                    }])}
                </>
            )
            setShow3(false);
            setEventId(0);
        }).catch(error => error);
    }

    const handleEditEvent = () => {
        setState(prev => ({...prev, startDate: prevDate}))
        updateCalendarEvent(state, eventId).then(res => {
            setEvents(prev => ([...prev, {title: res?.nameOfEvent, date: res?.startDate, id: res?.id}]))
            setShow4(false);
            setPrevDate({
                startDate: ''
            });
            setState({
                startDate: '',
                nameOfEvent: '',
                description: '',
                locationId: 0
            })
            apiSuccessHandler(
                <>
                    {I18N('success_edit_event', 'de', [{
                        key: 'success_edit_event',
                        value: I18N('success_edit_event', 'de')
                    }])}
                </>
            )
        }).catch(error => error);
    }

    const handleArtistMessage = () => {
        addNewArtistCalendarInfo(eventId, message).then(res => {
            setShow5(false);
            if (res) {
                setShow5(false);
                apiSuccessHandler(
                    <>
                        {I18N('success_sent_offer', 'de', [{
                            key: 'success_sent_offer',
                            value: I18N('success_sent_offer', 'de')
                        }])}
                    </>
                )
            }
        }).catch(error => error);
    }

    const localeButtonText: any = {
        'en-US': {
            today: 'today'
        },
        'de': {
            today: 'heute'
        }
    }
    let date = new Date(infoState?.startDate);
    let dateMDY = moment(date).format('DD.MM.YYYY HH:mm');

    const renderEventContent = (eventInfo) => {

        return (
            <div className={classes.calendarIcon}>
                <img src={"/SVG/icon_onair_48px.svg"}/>
            </div>
        )
    }

    const handleInfo = () => {
        if(infoState) {
            const newState = {
                startDate: infoState.startDate,
                nameOfEvent: infoState.nameOfEvent,
                description: infoState.description,
                locationId: infoState.location.id
            }
            setState(newState)
        }
    }

    return (
        <div className={classes.ProfileCalendar + (isLoading ? " filter-blur" : "")}>
            <Container>
            {isLoading && <LoadingPage/>}
                <StyleWrapper>
                    <Col xl={12} className={`${classes.bg_secondary} d-flex align-items-center`}>
                        <img className={classes.calendar_icon} src={"/SVG/icon_calendar_48px.svg"}/>
                        <p className={`${classes.card_title} ms-1`}>
                            {I18N('calendar', 'de', [{
                                key: 'calendar',
                                value: I18N('calendar', 'de')
                            }])}
                        </p>
                    </Col>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        locale="de"
                        initialView="dayGridMonth"
                        events={events}
                        timeZone='local'
                        dayMaxEvents={2}
                        dateClick={handleDateClick}
                        buttonText={localeButtonText['de']}
                        eventClick={eventClickHandler}
                        eventContent={renderEventContent}
                    />
                </StyleWrapper>
            </Container>
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('event_for_date', 'de', [{
                            key: 'event_for_date',
                            value: I18N('event_for_date', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <ProfileCalendarEvent changeHandler={handlerChange}
                                              selectHandler={handleState}
                                              timeHandler={handleTime}
                                              selectLocation={handleLocation}
                                              state={state}
                                              userType={userType}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <SaveChangeButton clickHandler={wrapperFunctionTwo}/>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('events_action_for_calendar', 'de', [{
                            key: 'events_action_for_calendar',
                            value: I18N('events_action_for_calendar', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            {
                                userType === 'CLUB' && (
                                    <>
                                        <div>
                                            <p className="fw-bold mb-0">
                                                {I18N('event_name', 'de', [{
                                                    key: 'event_name',
                                                    value: I18N('event_name', 'de')
                                                }])}
                                            </p>
                                            {infoState?.nameOfEvent}
                                        </div>
                                        <div className="mt-2">
                                            <p className="fw-bold mb-0">
                                                {I18N('start_at', 'de', [{
                                                    key: 'start_at',
                                                    value: I18N('start_at', 'de')
                                                }])}
                                            </p>
                                            {dateMDY}
                                        </div>

                                        <div className="mt-2">
                                            <p className="fw-bold mb-0">
                                                {I18N('description', 'de', [{
                                                    key: 'description',
                                                    value: I18N('description', 'de')
                                                }])}
                                            </p> {infoState?.description}
                                        </div>

                                        <div className="mt-2">
                                            <p className="fw-bold mb-0">
                                                {I18N('location', 'de', [{
                                                    key: 'location',
                                                    value: I18N('location', 'de')
                                                }])}
                                            </p>
                                            {infoState?.location?.name}
                                        </div>

                                        <div className="mt-2">
                                            <p className="fw-bold mb-0">
                                                {I18N('zip_code', 'de', [{
                                                    key: 'zip_code',
                                                    value: I18N('zip_code', 'de')
                                                }])}:
                                            </p>
                                            {infoState?.location?.zipCode}
                                        </div>
                                    </>
                                )
                            }

                            {userType === 'CLUB' && myProfile === true && <div className="mt-2">
                                <p className="fw-bold mb-0">
                                    {I18N('requested_by', 'de', [{
                                        key: 'requested_by',
                                        value: I18N('requested_by', 'de')
                                    }])}
                                </p>
                                <div>
                                    {infoState?.requests?.map?.((requestInfo: any) => {
                                        return (
                                            <>
                                                <hr/>
                                                <div
                                                    key={requestInfo.id} className="d-flex mt-2">
                                                    <Row className="align-items-center">
                                                        <Col xl={4}>
                                                            <img
                                                                src={!(requestInfo?.user?.portfolio?.picture) ? '/user.jpg'
                                                                    : requestInfo?.user?.portfolio?.picture}
                                                                onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                                                className={classes.eventReviewCardImage}
                                                                alt="User profile"/>
                                                        </Col>
                                                        <Col xl={4}>
                                                            <span className="mb-0"> {requestInfo?.user?.firstName}
                                                                <span
                                                                    className="ms-1">{requestInfo?.user?.lastName}</span></span>
                                                        </Col>

                                                        <Col xl={4} className="d-flex justify-content-end">
                                                            <a href={requestInfo?.user?.id} target="_blank">
                                                                <button className={classes.btn_warning}>
                                                                    {I18N('visit', 'de', [{
                                                                        key: 'visit',
                                                                        value: I18N('visit', 'de')
                                                                    }])}
                                                                </button>
                                                            </a>
                                                        </Col>
                                                        <Col xl={12} className="mt-3 d-flex flex-column">
                                                            <span
                                                                className="fw-bold">{I18N('artist_description', 'de', [{
                                                                key: 'artist_description',
                                                                value: I18N('artist_description', 'de')
                                                            }])}
                                                            </span>
                                                            <span>  {requestInfo?.message}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <hr/>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>}
                            {userType === 'CLUB' && myProfile === false && <hr/>}
                        </Col>
                        <Col xl={12}>
                            <p>
                                {userType === 'CLUB' && myProfile === true && <span>
                                    {I18N('delete_or_edit_event', 'de', [{
                                        key: 'delete_or_edit_event',
                                        value: I18N('delete_or_edit_event', 'de')
                                    }])}
                                </span>
                                }

                                {userType === 'CLUB' && myProfile === false &&
                                <span>
                                        {I18N('apply_for_event', 'de', [{
                                            key: 'apply_for_event',
                                            value: I18N('apply_for_event', 'de')
                                        }])}
                                    </span>}

                            </p>
                            <div className="d-flex align-items-center justify-content-evenly">
                                {
                                    (myProfile && userType === 'ARTIST') && (
                                        <button className={classes.btn_decline}
                                                onClick={() => {
                                                    setShow3(true);
                                                    setShow(false)
                                                }}>
                                            {I18N('delete', 'de', [{
                                                key: 'delete',
                                                value: I18N('delete', 'de')
                                            }])}
                                        </button>
                                    )
                                }
                                { (myProfile && userType === 'CLUB') &&
                                <>
                                    <button className={classes.btn_decline}
                                            onClick={() => {
                                                setShow3(true);
                                                setShow(false)
                                            }}>
                                        {I18N('delete', 'de', [{
                                            key: 'delete',
                                            value: I18N('delete', 'de')
                                        }])}
                                    </button>
                                    <button className={classes.btn_primary} onClick={() => {
                                        handleInfo()
                                        setShow4(true);
                                        setShow(false);
                                    }}>
                                        {I18N('edit', 'de', [{
                                            key: 'edit',
                                            value: I18N('edit', 'de')
                                        }])}
                                    </button>
                                </>
                                }
                                {userType === 'CLUB' && myProfile === false &&
                                <button className={classes.btn_apply} onClick={() => {
                                    setShow5(true);
                                    setShow(false)
                                }
                                }>

                                    {I18N('apply', 'de', [{
                                        key: 'apply',
                                        value: I18N('apply', 'de')
                                    }])}
                                </button>}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {I18N('see_less')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('events_action_for_calendar', 'de', [{
                            key: 'events_action_for_calendar',
                            value: I18N('events_action_for_calendar', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>
                                {I18N('delete_event', 'de', [{
                                    key: 'delete_event',
                                    value: I18N('delete_event', 'de')
                                }])}
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <CalendarConfirmButton
                        buttonName={I18N('delete', 'de', [{
                            key: 'delete',
                            value: I18N('delete', 'de')
                        }])}
                        clickHandler={handleDeleteEvent}/>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('events_action_for_calendar', 'de', [{
                            key: 'events_action_for_calendar',
                            value: I18N('events_action_for_calendar', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <ProfileCalendarEvent changeHandler={handlerChange}
                                              selectHandler={handleState}
                                              timeHandler={handleTime}
                                              selectLocation={handleLocation}
                                              state={state}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <CalendarConfirmButton
                        buttonName={I18N('edit', 'de', [{
                            key: 'edit',
                            value: I18N('edit', 'de')
                        }])}
                        clickHandler={handleEditEvent}/>
                </Modal.Footer>
            </Modal>

            <Modal show={show5} onHide={handleClose5}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('events_action_for_calendar', 'de', [{
                            key: 'events_action_for_calendar',
                            value: I18N('events_action_for_calendar', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <ProfileCalendarDescription changeHandler={handlerChange} state={message}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <CalendarConfirmButton
                        buttonName={I18N('apply', 'de', [{
                            key: 'apply',
                            value: I18N('apply', 'de')
                        }])}
                        clickHandler={handleArtistMessage}/>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
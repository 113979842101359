import React, {useContext, useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import classes from "../../components/Styles/ProfileInbox.module.scss";
import {KeyboardArrowLeft} from "@mui/icons-material";
import {I18N} from "../../i18n/i18n";
import {SearchBoxInbox, SendMessageInputDialog} from "./ProfileModals/ProfileModals";
import {socket, token} from "../../Helper/socket.client";

import LoadingPage from "../Information/LoadingPage";
import {SendMessageButton} from "../../components/Buttons/Buttons";
import {useParams} from "react-router-dom";
import {getUserPortfolio} from "../../Helper/API";
import {UserContext} from "../../App";
import moment from "moment";
import ProfileSection from "./ProfileSection/ProfileSection";
import {IProfileInfo} from "../../Helper/Interfaces/IProfile";

let socketClient: any = null;

export function ProfileInbox() {

    const {loggedInToken: getToken}: any = useContext(UserContext)
    const [clickedMobile, setClickedMobile] = useState<boolean>(false);
    const [isMob, setIsMob] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isChatOpened, setIsChatOpened] = useState<boolean>(false)
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
    const [messageContent, setMessageContent] = useState<any>('');
    const [conversation, setConversation] = useState<any>();
    const [displayMessages, setDisplayMessages] = useState<any>();
    const [currentConversation, setCurrentConversation] = useState<number>();
    const [sendToID, setSendToID] = useState<number>();
    const [query, setQuery] = useState("")
    const {id} = useParams<{id: string}>() ?? '0';
    const [profilePicture, setProfilePicture] = useState<any>();
    const [receiverPicture, setReceiverPicture] = useState<any>();
    const [activeChat, setActiveChat] = useState<number>();
    const [userPortfolio, setUserPortfolio] = useState<IProfileInfo | null>(null)

    useEffect(() => {
        getUserPortfolio("0").then(data => {
            setProfilePicture(data.picture)
            setUserPortfolio(data)
        }).catch(error => error);
    }, [])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        if (getToken) {
            socketClient = socket(getToken)
            socketClient?.on('msgToClient', data => {
                setDisplayMessages((prev: any) => ({...prev, messages: [...prev.messages, data]}))
            })
            socketClient?.on('sendAllMessages', message => {
                setDisplayMessages(message[0])
                setIsChatOpened(true);
                setSendToID(message[0].toId);
                const senderData = message[0]?.createdBy != id ? message[0].createdBy : message[0].participantId
                getUserPortfolio(senderData).then(data => {
                    setReceiverPicture(data.picture)
                }).catch(error => error)
            });
            socketClient?.on('sendAllConversations', conversations => {
                setConversation(conversations)
                setIsLoading(false)
            })
            socketClient?.emit('getAllConversations')
        }
    }, [getToken])

    useEffect(() => {
        socketClient?.emit('getAllConversations')
    }, [JSON.stringify(displayMessages)])


    const openMessageDialog = (id: number) => {
        setCurrentConversation(id)
        socketClient?.emit('getAllMessages', {id})
    };

    useEffect(() => {

        if (isMobile && isChatOpened === true) {
            setIsChatOpened(false)
        }

    }, [])

    const onSendMessage = () => {
        if (!messageContent.trim().length) return
        if (currentConversation && sendToID) {
            socketClient?.emit('msgToServer', {
                'content': messageContent,
                'to': sendToID,
                'conversationId': currentConversation
            });
            setMessageContent('')
        }
    }

    const scrollTo = document.getElementById('displayedMessages')

    useEffect(() => {
        if (scrollTo) {
            scrollTo.scrollTop = scrollTo.scrollHeight;
        }
    }, [JSON.stringify(displayMessages)])


    useEffect(() => {
        if (width <= 1000) {
            setIsMob(true);
        } else {
            setIsMob(false);
        }
    }, [width])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile: boolean = (width <= 1000);

    const clickHandler = () => {
        if (isMobile) {
            setClickedMobile(true);
        } else {
            setClickedMobile(false);
        }
    }

    const toggleMobileChat = () => {
        setClickedMobile(!clickedMobile)
    }

    const setActiveInbox = (id: number) => {
        setActiveChat(id)
        const items = document.querySelectorAll('.inbox-card-select');
        items.forEach((a: any) => {
            a.classList.remove("active-inbox");
        });
        const item = document.getElementById(`${id}`);
        item?.classList.add("active-inbox")
    }


    function handlerChange(event: any) {
        setMessageContent(event.target.value);
    }

    function keyUp(event: any) {
        if (event.keyCode === 13) onSendMessage()
    }

    const sortMessages = (prev: { id: number; }, current: { id: number; }) => prev.id - current.id

    const filterHandler = (event: any) => {
        setQuery(event.target.value)
    }
    return (
        <div className={classes.ProfileInbox}>
        <ProfileSection isMyProfile={false} iconSrc={"/inbox-icon.svg"} title={"CHAT"}>
            <>
                {isLoading && <LoadingPage/>}
                <Container className={`${isLoading && 'filter-blur'}`}>
                    <Row className="pb-3 py-xl-0">

                        {!showSearchModal && !clickedMobile && <Col md={12} className="py-3 border-bottom">
                            <SearchBoxInbox changeHandler={filterHandler}/>
                        </Col>}
                        <Col md={12} className="py-2 position-relative">
                            <Row>
                                {!clickedMobile && (
                                    <div
                                        className={`${classes.userCard_scroll_inbox} border-right col-lg-4`}>
                                        <OpenedMessages conversation={conversation} query={query}
                                                        clickHandler={clickHandler}
                                                        setActiveInbox={setActiveInbox}
                                                        openMessageDialog={openMessageDialog}/>
                                    </div>
                                )}
                                {(!isMob || clickedMobile) && (
                                    <div className={`${classes.userCard_scroll_inbox} col-lg-8 huahau`} id="displayedMessages">
                                        <Row>
                                            <div className={classes.topHeader}>
                                                <div className={classes.backArrow} onClick={toggleMobileChat}>
                                                    <KeyboardArrowLeft/>
                                                </div>
                                                <a href={`/profile/` + conversation?.find(conv => conv.id === activeChat)?.user?.id}
                                                   target="_blank"
                                                   className={classes.profileLink}
                                                >
                                                    <img
                                                        src={!(conversation?.find(conv => conv.id === activeChat)?.user?.portfolio?.picture)
                                                            ? '/user.jpg' :
                                                            (conversation?.find(conv => conv.id === activeChat)?.user?.portfolio?.picture)}
                                                        onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                                        className={classes.userCard_profile}
                                                        alt="User profile"/>
                                                </a>
                                                <div className={classes.headerNameWrapper}>
                                                    <p className={classes.headerName}>{conversation?.find(conv => conv.id === activeChat)?.user?.firstName} {conversation?.find(conv => conv.id === activeChat)?.user?.lastName}</p>
                                                </div>
                                            </div>
                                            {
                                                userPortfolio?.userId &&  <div key={displayMessages?.id} className="p-6">


                                                    {displayMessages?.messages?.sort(sortMessages)?.map?.((whoSendMessage: any) =>
                                                        <MessageList
                                                            whoSendMessage={whoSendMessage}
                                                            receiverPicture={receiverPicture}
                                                            key={whoSendMessage?.id}
                                                            profilePicture={profilePicture}
                                                            userId={userPortfolio?.userId}
                                                            displayMessages={displayMessages}
                                                            sendToID={sendToID}/>
                                                    )}
                                                </div>
                                            }


                                            <Col md={12} className={classes.send_message_input}>
                                                {isChatOpened &&
                                                    <>
                                                        <SendMessageInputDialog textValue={messageContent}
                                                                                keyUp={keyUp}
                                                                                changeHandler={handlerChange}/>
                                                        <SendMessageButton clickHandler={onSendMessage}/>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </div>)}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        </ProfileSection>
        </div>
    );
}

const OpenedMessages = ({conversation, query, clickHandler, openMessageDialog, toggleMobileChat, setActiveInbox}: any) => {

    return (
        <>
            {conversation?.filter?.((userName: any) => {
                if (query === '') {
                    return userName?.user?.firstName
                } else if (userName?.user?.firstName.toLowerCase().includes(query.toLowerCase())) {
                    return userName?.user?.firstName
                }
            }).map?.((message: any) => {
                return (
                    <div className={`${classes.userCard} inbox-card-select`} id={message?.id}
                         onClick={() => {
                             clickHandler();
                             setActiveInbox(message?.id);
                             openMessageDialog(message?.id);
                         }}
                         key={message?.id}
                    >
                        <div className="d-flex align-items-center">
                            <div className="position-relative ">
                                <img
                                    src={!(message.user?.portfolio?.picture) ? '/user.jpg' :
                                        (message?.user?.portfolio?.picture)}
                                    onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                    className={classes.userCard_profile}
                                    alt="User profile"/>
                                {/*<span className={classes.userCard_active}></span>*/}
                            </div>
                            <div className="d-flex flex-column ms-3">
                                <span className={classes.userCard_name}>
                                    {(message.user?.firstName?.length === 0) ? 'N/A' :
                                        (message?.user?.firstName)}
                                    <span className="ms-1">
                                        {(message.user?.lastName.length === 0) ? 'N/A' :
                                            (message?.user?.lastName)}
                                    </span>
                                </span>
                                <span className={classes.userCard_message}>
                                    {!(message?.messages[0]?.content) ?
                                        I18N('noData', 'de') :
                                        <div
                                            className={classes.wrap_message}>
                                            <span
                                                className="ms-1">{message?.messages[0]?.content}
                                            </span>
                                        </div>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

const MessageList = ({whoSendMessage, userId, profilePicture, receiverPicture}: any) => {
    let date = new Date(whoSendMessage?.createdAt);
    let hoursMinutes = moment(date).format('HH:mm');
    return (
        <div>
            {(whoSendMessage?.from === userId) ?
                <div>
                    <Col md={12}
                         className={classes.userCard_sent_messages}>
                        <div

                            className="d-flex flex-column me-3 align-items-end">
                            <span
                                className={classes.userCard_messages}>
                                {whoSendMessage?.content.split("\n").map(word => {
                                        return <span style={{display: 'block'}}>{word}</span>
                                    }
                                )}
                            </span>
                            <span
                                className={classes.userCard_messages_time}>
                                {hoursMinutes}
                            </span>
                        </div>
                        <a href={`/profile/0`}
                           target="_blank">
                            <img
                                src={!(profilePicture)
                                    ? '/user.jpg' :
                                    (profilePicture)}
                                onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                className={classes.userCard_profile}
                                alt="User profile"/>

                        </a>
                    </Col>
                </div>
                :
                <>
                    <Col md={12}
                         className={classes.userCard_received_messages}>
                        <a href={`/profile/${whoSendMessage?.fromInfo?.id}`}
                           target="_blank">
                            <img
                                src={!(receiverPicture)
                                    ? '/user.jpg' :
                                    (receiverPicture)}
                                onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                className={classes.userCard_profile}
                                alt="User profile"/>
                        </a>
                        <div
                            className="d-flex flex-column ms-3 align-items-start">
                            <span
                                className={classes.userCard_messages}>
                                {whoSendMessage?.content.split("\n").map(word => {
                                        return <span style={{display: 'block'}}>{word}</span>
                                    }
                                )}
                            </span>
                            <span
                                className={classes.userCard_messages_time}>
                                {hoursMinutes}
                            </span>
                        </div>
                    </Col>
                </>
            }
        </div>
    )
}
import classes from "../../components/Styles/Header.module.scss"
import { XDeleteIcon } from "../../components/Icons/Icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { flushSync } from 'react-dom';
import {
    changeLanguageAPI,
    clearNotificationType,
    getNewNotifications,
    getUserPortfolio
} from "../../Helper/API";
import { apiSuccessHandler } from "../../Helper/api.services";
import { getLanguage, I18N } from "../../i18n/i18n";
import { Modal } from "react-bootstrap";
import { socket, token } from "../../Helper/socket.client";
import "react-on-scroll-animation/build/index.css";
import { UserContext } from "../../App";
import { FRONTEND_PATH } from "../../Helper/api.route";
import {LoginModal} from "../Login/LoginModal";

export const FRONTED_PATH = FRONTEND_PATH;

export enum NotificationsTypeEnum {
    BOOKING = 'BOOKING',
    SUPPORT = 'SUPPORT',
    CHAT = 'CHAT',
}

let socketClient: any = null;

export function Header() {
    const { loggedInToken: getToken }: any = useContext(UserContext)

    const [currentUser, setCurrentUser] = useState<any>();
    const isAdmin = localStorage.getItem('type') === 'ADMIN';
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [notificationForChat, setNotificationForChat] = useState<number>(0);
    const [notificationForBooking, setNotificationForBooking] = useState<number>(0);
    const [notificationForSupport, setNotificationForSupport] = useState<number>(0);
    const location = useLocation();
    const [selectedLanguage, setSelectedLanguage] = useState({
        language: ''
    })
    const [activeMenuItem, setActiveMenuItem] = useState<string>("");
    const [changedMake, setChangedMake] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false)

    useEffect(() => {
        if (history.location.pathname.includes('/login')) {
            setLoginModalOpen(true)
        }
        if(!isAdmin && history.location.pathname.includes("/admin")) {
            if(loggedIn) {
                history.push("/profile/0")
            } else {
                history.push("/website")
            }
        }
    }, [window.location.href])

    useEffect(() => {
        if (getToken) {
            socketClient = socket(getToken)
            socketClient?.on('notificationToClient', data => {
                if (data) {
                    if (data.type === 'CHAT') {
                        if (!(history?.location?.pathname?.includes('inbox'))) {
                            SuccessDispatch(data.type)
                            setNotificationForChat(notificationForChat => notificationForChat + 1);
                        }
                    } else if (data.type === 'SUPPORT') {
                        SuccessDispatch(data.type)
                        setNotificationForSupport(notificationForSupport => notificationForSupport + 1);
                    } else {
                        SuccessDispatch(data.type)
                        setNotificationForBooking(notificationForBooking => notificationForBooking + 1);
                    }

                }
            })
        }
        if (!loggedIn && getToken) {
            setLoggedIn(true);
            getNewNotifications().then(res => {
                const bookingNotification = res?.filter(key => key.type === 'BOOKING');
                const messageNotification = res?.filter(key => key.type === 'CHAT');
                const supportNotification = res?.filter(key => key.type === 'SUPPORT');
                setNotificationForBooking(bookingNotification?.length);
                setNotificationForChat(messageNotification?.length);
                setNotificationForSupport(supportNotification?.length)
            }).catch(error => error);
        } else {
            if (!getToken && loggedIn) {
                history.push('/website')
                setLoggedIn(false)
            }
        }
        if(loggedIn) {
            if (!isAdmin && !location?.pathname?.includes('/website')) {
                getUserPortfolio("0").then(data => {
                    flushSync(() => {
                        setCurrentUser(data?.user?.id)
                    });
                }).catch(error => error);
            }
        }
    }, [getToken, currentUser])

    const SuccessDispatch = (notificationType: string) => apiSuccessHandler(
        <div onClick={() => goToNotification(notificationType, currentUser)}>
            {I18N('new_notification', 'de', [{
                key: 'new_notification',
                value: I18N('new_notification', 'de')
            }])}
        </div>
    )

    const logoutHandler = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('type');
        apiSuccessHandler(
            <>
                {I18N('logout_success', 'de', [{
                    key: 'logout_success',
                    value: I18N('logout_success', 'de')
                }])}
            </>
        )
        setLoggedIn(false)
        history.push('/website');
    }

    const handleMouseClick = (e) => {        if(window.innerWidth < 1024) {
            setProfileModalOpen(true)
        } else {
            if (!e.currentTarget.dataset.expand) {
                return;
            } else {
                setActiveMenuItem(e.currentTarget.dataset.expand);
            }
        }

    };

    const handleMouseEnter = (navItem: string) => {
        setActiveMenuItem(navItem)
    }

    const handleMouseLeave = () => {
        setActiveMenuItem("");
    };

    const goToNotification = (data: string, currentUser: number) => {
        if (data === 'CHAT') {
            history.push(`/inbox/${currentUser}`);
            setNotificationForChat(0);
        } else if (data === 'SUPPORT') {
            history.push(`/support/${currentUser}`);
            setNotificationForSupport(0);
        } else if (data === 'BOOKING') {
            history.push(`/overview`);
            setNotificationForBooking(0);
        } else {
            history.push('/profile/0');
        }
    }

    const clearHeaderNotification = (type: string | undefined) => {
        if(type){
            clearNotificationType(type).then(res => {
                if (res) {
                    if (type === 'SUPPORT') {
                        setNotificationForSupport(0)
                    } else if (type === 'CHAT') {
                        setNotificationForChat(0)
                    } else {
                        setNotificationForBooking(0)
                    }
                }
            }).catch(error => error);
        }
    }

    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname
    })

    useEffect(() => {
        setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    }, [location])

    useEffect(() => {
        if (route?.from?.includes('/auth/social/redirect')) {
            window.location.reload()
        }

        if (location.hash) {
                const id = location.hash.replace('#', '');
                const scrollTarget = document.getElementById(id);

                if (scrollTarget) {
                    let t = scrollTarget.getBoundingClientRect().top - document.body.getBoundingClientRect().top
                    window.scrollTo({ behavior: 'smooth', top: id === 'events' ? t - 150: t - 50});
                }
            }
    }, [location])

    function changeLanguage() {
        if (getLanguage() === 'en') {
            localStorage.setItem('language', 'de')
            setSelectedLanguage({ language: 'DE' })
            setChangedMake(!changedMake);
            setTimeout(() => {
                window.location.reload()
            }, 500)
        } else {
            localStorage.setItem('language', 'en')
            setSelectedLanguage({ language: 'EN' })
            setChangedMake(!changedMake);
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }
    }

    useEffect(() => {
        if (selectedLanguage?.language?.length > 0) {
            changeLanguageAPI({ ...selectedLanguage }).then(res => {
            }).catch(error => error);
        }
    }, [changedMake])

    /**
     * Initialize menu-items considering user login status
     */

    interface IMenuItem {
        notification?: number;
        label: string;
        clearNotification?: string;
        link?: string;
        menu_list?: Array<IMenuItem>
    }

    const profileMenuList : Array<IMenuItem> =  [
                {
                    "label": !isAdmin ? 'my_profile' : 'dashboard',
                    "link": isAdmin ? '/admin' : `/profile/${0}`
                },
                ... !isAdmin ? [
                    {
                        "notification": notificationForChat,
                        "label": 'inbox',
                        "link": `/inbox/${currentUser}`,
                        "clearNotification": NotificationsTypeEnum.CHAT
                    }, {
                        "clearNotification": NotificationsTypeEnum.BOOKING,
                        "label": 'booking_overview',
                        "link": '/overview',
                        "notification": notificationForBooking
                    }, {

                        "label": 'favorites',
                        "link": `/favorites/0`,
                    },
                    {
                        "label" : 'cash_in',
                        "link": `/currency/${currentUser}`
                    }, {
                        "label": 'company_information',
                        "link": `/additional-information/${currentUser}`
                    }, {
                        "label": 'transaction_history',
                        "link": `/transaction-history/${currentUser}`
                    }, {
                        "label": 'support',
                        "link": `/support/${currentUser}`,
                        "clearNotification" : NotificationsTypeEnum.SUPPORT,
                        "notification" : notificationForSupport,
                    }] :  [
                    {
                        "label": 'profile_search',
                        "link": '/search'
                    }]
            ]


    const menuItems : Record<string, IMenuItem>= {
        ... loggedIn ?  {
            "products": {
                "notification": notificationForChat + notificationForBooking,
                "label": !isAdmin ? 'artists' : 'dashboard',
                "link": !isAdmin ? '/search' : '/admin',
                "menu_list": []
            },
        } : (window.innerWidth > 480) ? {
            "about" : {
                "label": 'about',
                "link": '/website#about'
            },
            "artists" : {
                "label": 'artists',
                "link": '/website#artists'
            },
            "events" : {
                "label": 'events',
                "link": '/website#events'
            }
        } : {}
    }

    return (
        <div>
            <header style={{ top: 0}} className={classes.headerContainer}>
                <Link to={`${token?.length > 1 && !isAdmin ? `/profile/0` : isAdmin ? `/admin` : '/website'}`}>
                    <img src="/Logo-Bloghead-white.svg" alt="Logo of the platform" />
                </Link>
                <nav className={`${!loggedIn && classes.header} nav-bar`}>
                    <ul className="nav__links mb-0">
                        {
                            /**
                             * Iterate trough first level of menuItems and visualize menu-items
                             */
                            Object.keys(menuItems).map((navItem, navItemKey) => {
                                const menuItem = menuItems[navItem];
                                return (
                                    <li data-expand={navItem}
                                        key={navItemKey}
                                        className="nav--link"
                                        onMouseEnter={(e) => {handleMouseClick(e)}}
                                        onMouseLeave={()=>{handleMouseLeave()}}
                                    >
                                        <div className="label-container">
                                            {
                                                menuItem.link ? (
                                                    <Link to={menuItem.link}
                                                          className="menu-label"
                                                    >
                                                        {
                                                            I18N(menuItem.label, 'de', [{
                                                                key: menuItem.label,
                                                                value: I18N(menuItem.label, 'de')
                                                            }])
                                                        }
                                                    </Link>
                                                ) : (
                                                    <p className="menu-label">
                                                        {
                                                            I18N(menuItem.label, 'de', [{
                                                                key: menuItem.label,
                                                                value: I18N(menuItem.label, 'de')
                                                            }])
                                                        }
                                                    </p>
                                                )
                                            }
                                            {   /** show number of notifications on Menu-item if available */
                                                (!!menuItem.notification && menuItem.notification > 0) &&
                                                <span className="notification-header-number">{menuItem.notification}</span>
                                            }
                                        </div>
                                        <span className="label-box"></span>

                                        {   /** show list when corresponding menu-item is clicked */
                                            menuItem?.menu_list &&
                                            <div id={navItem}
                                                 data-expand={navItem}
                                                 className={"menu-list" + (activeMenuItem === navItem ? " open" : "")}
                                                 onMouseEnter={(e)=>{handleMouseClick(e)}}
                                                 onMouseLeave={()=>{handleMouseLeave()}}
                                            >
                                                <ul className="subMenu__items">
                                                    {
                                                        /**
                                                         * Iterate trough second level of menuItems and show list-items of the corresponding menu-item
                                                         */
                                                        menuItem?.menu_list?.map((listElement, listElementKey) => {
                                                            return (
                                                                <li className="subMenu--item" key={listElementKey}>
                                                                    {
                                                                        listElement.clearNotification ? (
                                                                            <Link to={listElement.link}
                                                                                  className="text-decoration-none"
                                                                                  onClick={() => {clearHeaderNotification(listElement.clearNotification)}}
                                                                            >
                                                                                {
                                                                                    I18N(listElement.label, 'de', [{
                                                                                        key: listElement.label,
                                                                                        value: I18N(listElement.label, 'de')
                                                                                    }])
                                                                                }
                                                                            </Link>
                                                                        ) : (
                                                                            <Link to={listElement.link} className="text-decoration-none">
                                                                                {
                                                                                    I18N(listElement.label, 'de', [{
                                                                                        key: listElement.label,
                                                                                        value: I18N(listElement.label, 'de')
                                                                                    }])
                                                                                }
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    {   /** show number of notifications on Menu-item if available */
                                                                        (!!listElement.notification && listElement.notification > 0) &&
                                                                        <span className="notification-menu-item">{listElement.notification}</span>
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </nav>
                <div className={classes.options}>
                    {
                        /** language setting not needed in short-term, please don't delete
                        loggedIn &&
                        <select name="language" style={{ width: '5%' }} onChange={changeLanguage}
                        >
                            <option hidden>{localStorage.getItem('language') === 'de' ? 'DE' : 'EN'}</option>
                            <option value="EN" className="option-flag">
                                EN
                            </option>
                            <option value="DE" className="option-flag">
                                DE
                            </option>
                        </select>
                        */
                    }
                    {
                        loggedIn &&
                        <Link className="icon-link" to={`/`}>
                            <img className={classes.icons} src={"/SVG/icon_coin_48px.svg"}/>
                        </Link>
                    }
                    {
                        (!isAdmin && loggedIn) &&
                            <Link className="icon-link" to={`/favorites/0`}>
                                <img className={classes.icons} src={"/SVG/icon_heart_48px.svg"}/>
                            </Link>
                    }
                    {
                        loggedIn &&
                            <div className={classes.profileButton + " icon-link nav--link"}
                                 data-expand={"profile"}
                                 onMouseEnter={(e) => {handleMouseClick(e)}}
                                 onMouseLeave={()=>{handleMouseLeave()}}
                            >
                                <img className={classes.icons} src={"/SVG/icon_profil_48px.svg"}/>
                                <div id={"profile"}
                                     data-expand={"profile"}
                                     className={"menu-list" + (activeMenuItem === "profile" ? " open" : "")}
                                     onMouseEnter={(e)=>{handleMouseClick(e)}}
                                     onMouseLeave={()=>{handleMouseLeave()}}
                                >
                                    <ul className="subMenu__items">
                                        {
                                            /**
                                             * Iterate trough second level of profileMenuList and show list-items on hover
                                             */
                                            profileMenuList?.map((listElement, listElementKey) => {
                                                return (
                                                    <li className="subMenu--item" key={listElementKey}>
                                                        {
                                                            listElement.clearNotification ? (
                                                                <Link to={listElement.link}
                                                                      className="text-decoration-none"
                                                                      onClick={() => {clearHeaderNotification(listElement.clearNotification)}}
                                                                >
                                                                    {
                                                                        I18N(listElement.label, 'de', [{
                                                                            key: listElement.label,
                                                                            value: I18N(listElement.label, 'de')
                                                                        }])
                                                                    }
                                                                </Link>
                                                            ) : (
                                                                <Link to={listElement.link} className="text-decoration-none">
                                                                    {
                                                                        I18N(listElement.label, 'de', [{
                                                                            key: listElement.label,
                                                                            value: I18N(listElement.label, 'de')
                                                                        }])
                                                                    }
                                                                </Link>
                                                            )
                                                        }
                                                        {   /** show number of notifications on Menu-item if available */
                                                            (!!listElement.notification && listElement.notification > 0) &&
                                                            <span className="notification-menu-item">{listElement.notification}</span>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                    }
                    {loggedIn ? (
                        <div className={classes.buttonContainer} onClick={()=>{logoutHandler()}}>
                            <button className={classes.loginButton}>
                                {I18N('logout')}
                            </button>
                        </div>
                    ) : (
                        <div className={classes.buttonContainer}>
                            <button className={classes.loginButton}
                                  onClick={()=>{setLoginModalOpen(true)}}
                            >
                                Login
                            </button>
                        </div>
                    )}
                    {/* TODO: Insert social media links below */}
                    <Link className="icon-link" to={'/'}>
                        <img className={classes.icons} src={"/SVG/icon_insta_48px.svg"}/>
                    </Link>
                    <Link className="icon-link" to={'/'}>
                        <img className={classes.icons} src={"/SVG/icon_ifb_48px.svg"}/>
                    </Link>
                </div>
            </header>
            <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} />
            <div className={classes.header_mobile}>
                <Link
                    to={`${loggedIn && !isAdmin ? `/profile/0` : isAdmin ? `/admin` : !loggedIn && '/website'}`}>
                    <img src="/Logo-Bloghead-white.svg" alt="Logo of the platform" />
                </Link>
                {
                    !loggedIn ? (
                        <div className={classes.buttonContainer}>
                            <button className={classes.loginButton}
                                    onClick={()=>{
                                        setLoginModalOpen(true)
                                        setShow(false)
                                    }}
                            >
                                Login
                            </button>
                        </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <div className={classes.profileButton + " icon-link"} onClick={()=>{setProfileModalOpen(true)}}>
                                    <img className={classes.icons} src={"/SVG/icon_profil_48px.svg"}/>
                                </div>
                                <button onClick={() => setShow(true)} className={classes.closeBtn}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        )
                }
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header className="d-flex justify-content-end">
                        <div onClick={() => setShow(false)}>
                            <XDeleteIcon />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            Object.keys(menuItems).map((navItem, navItemKey)=>{
                                const menuItem = menuItems[navItem];
                                return (
                                    <div key={navItemKey} className={classes.menuItem}>
                                        {
                                            menuItem.link ? (
                                                <Link to={menuItem.link}
                                                      className={classes.mobileMenu_menuItemLabel}
                                                      onClick={()=>{setShow(false)}}
                                                >
                                                    {
                                                        I18N(menuItem.label, 'de', [{
                                                            key: menuItem.label,
                                                            value: I18N(menuItem.label, 'de')
                                                        }])
                                                    }
                                                </Link>
                                            ) : (
                                                <h4 className={classes.mobileMenu_menuItemLabel}>
                                                    {
                                                        I18N(menuItem.label, 'de', [{
                                                            key: menuItem.label,
                                                            value: I18N(menuItem.label, 'de')
                                                        }])
                                                    }
                                                </h4>
                                            )
                                        }
                                        <ul className={classes.menuList}>
                                            {
                                                (menuItem.menu_list !== undefined && menuItem.menu_list.length > 0) &&
                                                    menuItem.menu_list.map((listElement, listElementKey) => {
                                                        return (
                                                            <li key={listElementKey} className={classes.menuList_listItem}>
                                                                {
                                                                    listElement.clearNotification ? (
                                                                        <Link to={listElement.link}
                                                                              className={classes.mobileMenu_listElement_link}
                                                                              onClick={() => {
                                                                                  clearHeaderNotification(listElement.clearNotification)
                                                                                  setShow(false)
                                                                              }}
                                                                        >
                                                                            {
                                                                                I18N(listElement.label, 'de', [{
                                                                                    key: listElement.label,
                                                                                    value: I18N(listElement.label, 'de')
                                                                                }])
                                                                            }
                                                                        </Link>
                                                                    ) : (
                                                                        <Link to={listElement.link}
                                                                              className={classes.mobileMenu_listElement_link}
                                                                              onClick={() => {
                                                                                  setShow(false)
                                                                              }}
                                                                        >
                                                                            {
                                                                                I18N(listElement.label, 'de', [{
                                                                                    key: listElement.label,
                                                                                    value: I18N(listElement.label, 'de')
                                                                                }])
                                                                            }
                                                                        </Link>
                                                                    )
                                                                }
                                                                {   /** show number of notifications on Menu-item if available */
                                                                    (!!listElement.notification && listElement.notification > 0) &&
                                                                    <span className="notification-menu-item">{listElement.notification}</span>
                                                                }
                                                            </li>
                                                        )
                                                    })
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                        }
                        <hr/>
                        {loggedIn &&
                            <div className={classes.buttonContainer} onClick={()=>{logoutHandler(); setLoginModalOpen(false)}}>
                                <button className={classes.loginButton}>
                                    {I18N('logout')}
                                </button>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
                <Modal show={profileModalOpen} onHide={() => setProfileModalOpen(false)}>
                    <Modal.Header className="d-flex justify-content-end">
                        <div onClick={() => setProfileModalOpen(false)}>
                            <XDeleteIcon />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            profileMenuList.map((navItem, navItemKey)=>{

                                return (
                                    <div key={navItemKey} className={classes.menuItem}>
                                        {
                                            navItem.link ? (
                                                <Link to={navItem.link}
                                                      className={classes.mobileMenu_menuItemLabel}
                                                      onClick={()=>{setProfileModalOpen(false)}}
                                                >
                                                    {
                                                        I18N(navItem.label, 'de', [{
                                                            key: navItem.label,
                                                            value: I18N(navItem.label, 'de')
                                                        }])
                                                    }
                                                </Link>
                                            ) : (
                                                <h4 className={classes.mobileMenu_menuItemLabel}>
                                                    {
                                                        I18N(navItem.label, 'de', [{
                                                            key: navItem.label,
                                                            value: I18N(navItem.label, 'de')
                                                        }])
                                                    }
                                                </h4>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

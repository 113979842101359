import {I18N} from '../../i18n/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from '../../components/Styles/Registration.module.scss';
import {Container} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {token} from "../../Helper/socket.client";

const NotFound = () => {
    const history = useHistory();
    const handlePage = () => {
        if (token?.length > 1) {
            history.push('/profile/0')
        } else {
            history.push('/website')
        }
    }
    return (
        <>
            <Container style={{height: '100vh'}}
                       className="d-flex align-items-center justify-content-center flex-column">
                <h3 className="text-muted my-3 text-center">
                    {I18N('notFoundError', 'de',
                        [{
                            key: 'notFoundError',
                            value: I18N('notFoundError', 'de')
                        }])
                    }
                </h3>
                <img src="/404.svg" className="w-50 my-3" alt="404 Page icon"/>
                <button onClick={handlePage} className={`${classes.btn_primary} text-uppercase`}
                        style={{width: '25%', margin: '0'}}>

                    {I18N('back', 'de',
                        [{
                            key: 'back',
                            value: I18N('back', 'de')
                        }])
                    }
                </button>
            </Container>
        </>

    );
};

export default NotFound;

import React, {useState} from 'react';
import "./VRSection.scss"
import buttonStyles from "../../../components/Buttons/ButtonsStyle.module.scss";
import {Link} from "react-router-dom";
import {LoginModal} from "../../Login/LoginModal";

export const VrSection = () => {

    const [loginOpen, setLoginOpen] = useState<boolean>(false)

    return (
        <div className="VRSection">
            <div className="VRSection__header">
                <h3 className="VRSection__header__headline headline-title">
                    VR Experiences
                </h3>
            </div>
            <div className="VRSection__sectionbox">
                <div className="VRSection__sectionbox__image-wrapper">
                    <img className="VRSection__sectionbox__img" src="/vr-ex.jpg"/>
                </div>
                <div className="VRSection__sectionbox__text-body">
                    <h3 className="VRSection__sectionbox__headline">HIER STEHT NOCH BLINDTEXT</h3>
                    <p className="VRSection__sectionbox__text">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor.
                        Aenean massa. Cum sociis natoque penatibus et
                        magnis dis parturient montes, nascetur ridiculus
                        mus. Donec quam felis,
                    </p>
                    <Link to={'/website#registration'} className={buttonStyles.registerButtonWhite} onClick={()=>setLoginOpen(true)}>MEMBER WERDEN</Link>
                </div>
            </div>
            <LoginModal open={loginOpen} setOpen={setLoginOpen}/>
        </div>
    );
};

export default VrSection;
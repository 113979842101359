import React, {useEffect, useState} from 'react';
import "./ProfileSocialMedia.scss"
import {IUserSocialNetwork} from "../../../Helper/Interfaces/IProfile";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Clear} from "@mui/icons-material";
import {addUserSocialNetwork, deleteUserSocialNetwork} from "../../../Helper/API";
import {I18N} from "../../../i18n/i18n";
import {SaveChangeButton} from "../../../components/Buttons/Buttons";
import {apiSuccessHandler} from "../../../Helper/api.services";
import {ProfileSocialNetwork} from "../ProfileModals/ProfileModals";

interface IProfileSocialMediaProps {
    platforms: Array<IUserSocialNetwork> | [],
    editProfileInfo: boolean,
    isMyProfile: boolean
}

const ProfileSocialMedia = (props: IProfileSocialMediaProps) => {

    const [showSocialModal, setShowSocialModal] = useState(false)
    const [platforms, setPlatforms] = useState<Array<IUserSocialNetwork>>(props.platforms)
    const [newSocialMediaPlatform, setNewSocialMediaPlatform] = useState<{link: string, name: string}>({link: "", name:""})
    const [selectedNetwork, setSelectedNetwork] = useState<string>("")

    const SocialmediaChip = (platformElement: IUserSocialNetwork) => {

        let socialIconSrc;

        switch (platformElement.platformType) {
            case "INSTAGRAM":
                socialIconSrc = "/instagram.png"
                break;
            case "FACEBOOK":
                socialIconSrc = "/facebookLogo.png"
                break;
            case "DEEZER":
                socialIconSrc = "/deezer.png"
                break;
            case "YOUTUBE":
                socialIconSrc = "/youtube.png"
                break;
            case "SPOTIFY":
                socialIconSrc = "/spotify.png"
                break;
        }

        return (
            <div className="ProfileSocialMedia__profiles__chip h-100">
                <img className="ProfileSocialMedia__profiles__icon" src={socialIconSrc}/>
                <a href={platformElement.link} className="ProfileSocialMedia__profiles__link">{platformElement.name}</a>
                {
                    props.editProfileInfo && (
                        <button className="ProfileSocialMedia__profiles__button"
                                id={platformElement.id + ""}
                                onClick={()=>{
                                    deleteChip({...platformElement})
                                }}
                        >
                            <Clear/>
                        </button>
                    )
                }
            </div>
        )
    }

    async function onAddSocialNetwork(): Promise<void> {
        addUserSocialNetwork( newSocialMediaPlatform).then(res => {
            if (res) {
                setPlatforms(res)
                apiSuccessHandler(
                    <>
                        {I18N('add_social_network', 'de', [{
                            key: 'add_social_network',
                            value: I18N('add_social_network', 'de')
                        }])}
                    </>
                )
                setNewSocialMediaPlatform({
                    link: '',
                    name: ''
                })
            }
        }).catch(error => error);
    }


    const deleteChip = (chipElement: IUserSocialNetwork) => {
        const filteredPlatfrom = platforms.filter(platformEl => platformEl.id !== chipElement.id)
        deleteUserSocialNetwork(chipElement.id!).then(res => {
            setPlatforms(res)
        }).catch(error => error)
        setPlatforms(filteredPlatfrom)
    }

    const selectSocialNetwork = (event) => {
        setSelectedNetwork(event.currentTarget.id)
    }

    const handleSocialNetwork = (event) => {
        setNewSocialMediaPlatform(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    return (
        <div className={"ProfileSocialMedia " + (props.editProfileInfo ? "edit" : "")}>
            {
                props.isMyProfile && (
                    <div className="ProfileSocialMedia__head">
                        <p className="ProfileSocialMedia__head__label">SOCIALMEDIA</p>
                        <button className="ProfileInformation__button-group__button"
                                disabled={!props.editProfileInfo}
                                onClick={()=>{setShowSocialModal(true)}}
                        >
                            <img src={"/SVG/icon_link_connect_48px.svg"}/>
                        </button>
                    </div>
                )
            }
            <div className="ProfileSocialMedia__profiles">
                <Row className="h-100 align-items-center" >
                    {
                        platforms.map((chipObj: IUserSocialNetwork, i: number) => {
                            return (
                                <Col xl={4} lg={4} md={6} key={chipObj.id} className="d-flex align-items-center" style={{height: "50px"}}>
                                    <SocialmediaChip link={chipObj.link}
                                                     platformType={chipObj.platformType}
                                                     name={chipObj.name}
                                                     id={chipObj.id}

                                    />
                                </Col>
                            )
                        })

                    }
                </Row>
            </div>
            <Modal show={showSocialModal} onHide={() => {
                setSelectedNetwork("")
                setShowSocialModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Links</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { selectedNetwork.length !== 0 ?
                        <ProfileSocialNetwork changeHandler={handleSocialNetwork}
                                              selectedNetwork={selectedNetwork}
                                              link={newSocialMediaPlatform?.link}/> :
                        <div
                            className="d-flex align-items-center justify-content-center flex-column"
                        >
                            <div
                                className="social-network-switch border-bottom border-top py-2"
                                onClick={selectSocialNetwork}
                                id="instagram">
                                <h4 className="mb-0">Instagram</h4>
                                <img src="/instagram.png" alt="Instagram logo"/>
                            </div>
                            <div
                                className="social-network-switch border-bottom border-top py-2"
                                onClick={selectSocialNetwork}
                                id="deezer">
                                <h4 className="mb-0">Deezer</h4>
                                <img src="/deezer.png" alt="Deezer logo"/>
                            </div>
                            <div
                                className="social-network-switch border-bottom border-top py-2"
                                onClick={selectSocialNetwork}
                                id="youtube">
                                <h4 className="mb-0">Youtube</h4>
                                <img src="/youtube.png" alt="Youtube logo"/>
                            </div>
                            <div
                                className="social-network-switch border-bottom border-top py-2"
                                onClick={selectSocialNetwork}
                                id="spotify">
                                <h4 className="mb-0">Spotify</h4>
                                <img src="/spotify.png" alt="Spotify logo"/>
                            </div>
                            <div
                                className="social-network-switch border-bottom border-top py-2"
                                onClick={selectSocialNetwork}
                                id="facebook">
                                <h4 className="mb-0">Facebook</h4>
                                <img src="/facebookLogo.png" className="w-25"
                                     alt="Facebook logo"/>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setSelectedNetwork("")
                        setNewSocialMediaPlatform({link: "", name: ""})
                        setShowSocialModal(false)
                    }
                    }>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <SaveChangeButton clickHandler={onAddSocialNetwork}/>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProfileSocialMedia;
import { FindOutMoreButton } from "../../../components/Buttons/Buttons"
import classes from "./AboutSection.module.scss"
export function AboutSection() {
    return (
        <section id="about" className={classes.aboutSection}>
            <div className={classes.headline}>
                About
            </div>
            <div className={classes.lilaBox}>
                <div className={classes.imageLeftContainer}>
                    <img alt="Bloghead" src="/aboutImage.jpg" className={classes.imageAbout}>
                    </img>
                </div>
                <div className={classes.imageRightContainer}>
                    <div className={classes.textfield}>
                        <h2 className={classes.subtitle}>
                            OUR GOAL IS<br></br>
                            TO CONNECT YOU <br></br> TO YOUR FUTURE
                        </h2>
                        <p className={`${classes.description_title} fw-normal my-3`}>
                            We are a platform for Artists to connect and also present themselves to the world. Talented people are searching for all over the world.
                        </p>
                        <p className={`${classes.description_title} fw-normal my-3`}>
                            Now we able you to connect you to events or location with only a couple of clicks.
                        </p>
                        <FindOutMoreButton onClick={() => { }} primary={'white'} name={'FIND OUT MORE'} />
                    </div>
                </div>
            </div>
        </section>
    )
}
import {Button, Col, Container, Modal, Row, Table} from "react-bootstrap";
import LoadingPage from "../Information/LoadingPage";
import classes from "../../components/Styles/ProfileSearch.module.scss";
import {I18N} from "../../i18n/i18n";
import React, {useEffect, useState} from "react";
import {SquareFillBlack} from "../../components/Icons/Icons";
import {CreateTicketModal, SupportCategoryEnum, SupportStatusEnum} from "./ProfileModals/ProfileModals";
import {CalendarConfirmButton, SaveChangeButton} from "../../components/Buttons/Buttons";
import {addNewSupportComment, addNewSupportTicket, getSupportTicket, getSupportTicketID} from "../../Helper/API";
import {apiErrorHandler, apiSuccessHandler} from "../../Helper/api.services";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"

export function ProfileSupportTickets() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [show3, setShow3] = useState<boolean>(false);
    const [show4, setShow4] = useState<boolean>(false);
    const [tickets, setTickets] = useState<any>([]);
    const [clickedTicket, setClickedTicket] = useState<any>();
    const [state, setState] = useState({
        createdAt: '',
        title: '',
        description: '',
        status: SupportStatusEnum.REQUESTED,
        category: SupportCategoryEnum.OTHER
    });
    const scrollTo = document.getElementById('displayedMessages')

    const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);
    const [commentState, setCommentState] = useState({
        ticketId: 0,
        message: ''
    });

    const handleChange = (event: any) => {
        const current = new Date();
        current.toISOString();
        setState((prev: any) => ({...prev, [event.target.name]: event.target.value, createdAt: current}));
    }

    const handleMessage = (event: any) => {
        setCommentState(prev => ({...prev, [event.target.name]: event.target.value, ticketId: clickedTicket?.id}))
    }

    async function onCreateNewTicket(): Promise<void> {
        setIsLoading(true)
        addNewSupportTicket(state).then(res => {
            setIsLoading(true);
            if (res) {
                setState(res);
                setState({
                    createdAt: '',
                    title: '',
                    description: '',
                    status: SupportStatusEnum.REQUESTED,
                    category: SupportCategoryEnum.OTHER
                })
                apiSuccessHandler(
                    <>
                        {I18N('success_open_issue', 'de', [{
                            key: 'success_open_issue',
                            value: I18N('success_open_issue', 'de')
                        }])}
                    </>
                )
                setTickets(res)
            }
        }).catch(error => error).finally(() => {
            setIsLoading(false);
        });
    }

    const getTicketID = (id: number) => {
        getSupportTicketID(id).then(data => {
            setClickedTicket(data);
            setShow4(true);
        }).catch(error => error);
    }

    useEffect(() => {
        if (scrollTo) {
            scrollTo.scrollTop = scrollTo.scrollHeight;
        }
    }, [clickedTicket])

    async function addTicketComment(): Promise<void> {
        if (showMessageDialog) {
            addNewSupportComment(commentState).then(res => {
                apiSuccessHandler(
                    <>
                        {I18N('success_add_ticket_comment', 'de', [{
                            key: 'success_add_ticket_comment',
                            value: I18N('success_add_ticket_comment', 'de')
                        }])}
                    </>
                )
                getTicketID(commentState?.ticketId);
                setCommentState({
                    ticketId: 0,
                    message: ''
                });
            }).catch(error => apiErrorHandler(error));
            setShowMessageDialog(false);
        } else {
            setShowMessageDialog(true)
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getSupportTicket().then(data => {
            setIsLoading(true);
            setTickets(data)
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [JSON.stringify(tickets)])

    const wrapperFunction = () => {
        onCreateNewTicket();
        setShow3(false);
    }

    let date = new Date(clickedTicket?.createdAt);
    let dateTicket = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const classPending = `${classes.status_pending}`;
    const classAccepted = `${classes.status_accepted}`;
    const classDeclined = `${classes.status_declined}`;
    const classOpened = `${classes.status_opened}`;

    return (
        <div className={classes.TransactionHistory}>
            {isLoading && <LoadingPage/>}
            <div className={`${classes.heading}`}>
                <Container className={`${isLoading && 'filter-blur'} mx-4`}>
                        <span className="headline-title">
                            Support-Tickets
                        </span>
                </Container>
            </div>

            <Container className={`${isLoading && 'filter-blur'} mt-5 mb-3 responsive-table`}>

                <div className="mb-4 d-flex justify-content-end justify-content-sm-center">

                    <button className={classes.yes_button} onClick={() => setShow3(true)}>

                        {I18N('open_new_ticket', 'de', [{
                            key: 'open_new_ticket',
                            value: I18N('open_new_ticket', 'de')
                        }])}
                    </button>
                </div>
                <hr style={{background: '#fff'}}/>
                <h2 className="mb-4" style={{color: '#fff'}}>
                    {I18N('all_my_tickets', 'de', [{
                        key: 'all_my_tickets',
                        value: I18N('all_my_tickets', 'de')
                    }])}
                </h2>

                <Table striped bordered hover variant="dark">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>
                            {I18N('created_at', 'de', [{
                                key: 'created_at',
                                value: I18N('created_at', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('selected_category', 'de', [{
                                key: 'selected_category',
                                value: I18N('selected_category', 'de')
                            }])}
                        </th>
                        <th>
                            {I18N('title', 'de', [{
                                key: 'title',
                                value: I18N('title', 'de')
                            }])}
                        </th>
                        <th>STATUS</th>
                        <th>
                            {I18N('see_info', 'de', [{
                                key: 'see_info',
                                value: I18N('see_info', 'de')
                            }])}
                        </th>
                    </tr>
                    </thead>

                    <tbody>

                    {tickets?.length > 0 ? tickets?.map?.((key: any) => {
                            let date = new Date(key.createdAt);
                            let dateMDY = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

                            return (
                                <tr key={key?.id}>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            #{key?.id}
                                        </Rosa>

                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {dateMDY}
                                        </Rosa>
                                    </td>
                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.category}
                                        </Rosa>
                                    </td>

                                    <td>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.title}
                                        </Rosa>
                                    </td>
                                    <td className={`${key?.status === 'REQUESTED' ? `${classPending}` :
                                        key?.status === 'IN_PROGRESS' ? `${classOpened}` :
                                            key?.status === 'CANCELED' ? `${classDeclined}` :
                                                key?.status === 'FINISHED' ? `${classAccepted}` : ''
                                    }`}>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            {key?.status === 'REQUESTED' ? <>{I18N('requested', 'de')}</>
                                                : key?.status === 'IN_PROGRESS' ? <>{I18N('in_progress', 'de')}</>
                                                    : key?.status === 'FINISHED' ? <>{I18N('finished', 'de')}</>
                                                        : <>{I18N('canceled', 'de')}</>}
                                        </Rosa>
                                    </td>


                                    <td className={classes.tickets_table}>
                                        <Rosa animation={'fade-right'} offset={500}>
                                            <button className="no-style-button" onClick={() => getTicketID(key?.id)}>
                                                <SquareFillBlack/>
                                            </button>
                                        </Rosa>
                                    </td>
                                </tr>
                            )
                        }) :
                        <tr>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </Container>
            <Modal show={show3} onHide={() => setShow3(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('open_new_ticket', 'de', [{
                            key: 'open_new_ticket',
                            value: I18N('open_new_ticket', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <CreateTicketModal state={state} changeHandler={handleChange}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow3(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <SaveChangeButton clickHandler={wrapperFunction}/>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={() => setShow4(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('ticket_info', 'de', [{
                            key: 'ticket_info',
                            value: I18N('ticket_info', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>
                                <span className="fw-bold">{I18N('created_at', 'de', [{
                                    key: 'created_at',
                                    value: I18N('created_at', 'de')
                                }])}:</span> {dateTicket}
                            </p>
                            <p>  <span className="fw-bold">{I18N('title', 'de', [{
                                key: 'title',
                                value: I18N('title', 'de')
                            }])}:</span> {clickedTicket?.title}
                            </p>

                            <p>
                                <span className="fw-bold">{I18N('description', 'de', [{
                                    key: 'description',
                                    value: I18N('description', 'de')
                                }])} </span>
                                {clickedTicket?.description}
                            </p>
                            <p>
                                <span
                                    className="fw-bold">Status:</span> {clickedTicket?.status === 'IN_PROGRESS'
                                ? 'IN PROGRESS'
                                : clickedTicket?.status}
                            </p>

                            <div>
                                <span className="fw-bold">
                                    {I18N('comments', 'de', [{
                                        key: 'comments',
                                        value: I18N('comments', 'de')
                                    }])}:
                                </span>
                                {clickedTicket?.comments.length > 0 ?
                                    <div id="displayedMessages"
                                         className={`${classes.comment_section} ms-1 border-top`}>
                                        {clickedTicket?.comments?.map?.((comment: any) => {
                                            return (
                                                <div key={comment?.id}>
                                                    <Row className="mt-2 border-bottom pb-2">
                                                        <Col xl={4} style={{width: 'fit-content'}}>
                                                            <img
                                                                src={!(comment?.user?.portfolio?.picture) ? '/user.jpg'
                                                                    : comment?.user?.portfolio?.picture}
                                                                className={classes.eventReviewCardImage}
                                                                onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                                                alt="User profile"/>
                                                        </Col>
                                                        <Col xl={8} className="d-flex flex-column ">
                                                            <div>
                                                                    <span
                                                                        className="mb-0 fw-bold">
                                                                        {comment?.user?.firstName} {comment?.user?.lastName}
                                                                    </span>
                                                            </div>
                                                            <span>{comment?.message}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </div> :
                                    <span className="ms-1">
                                        {I18N('no_comments', 'de', [{
                                            key: 'no_comments',
                                            value: I18N('no_comments', 'de')
                                        }])}
                                    </span>
                                }
                            </div>
                        </Col>
                        {showMessageDialog && <Col xl={12} className="mt-2">

                            <label htmlFor="message" className="mb-2 fw-bold">
                                {I18N('new_comment', 'de', [{
                                    key: 'new_comment',
                                    value: I18N('new_comment', 'de')
                                }])}:
                            </label>
                            <textarea className={classes.input} onChange={handleMessage}
                                      value={commentState.message}
                                      name="message"
                                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
                            <span
                                className="text-muted d-flex justify-content-end mb-2">
                                {I18N('max_characters', 'de', [{
                                    key: 'max_characters',
                                    value: I18N('max_characters', 'de')
                                }])}
                                250 !
                            </span>
                        </Col>}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow4(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>

                    {clickedTicket?.status === 'IN_PROGRESS' &&
                    <CalendarConfirmButton buttonName={I18N('new_comment', 'de', [{
                        key: 'new_comment',
                        value: I18N('new_comment', 'de')
                    }])} clickHandler={addTicketComment}/>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
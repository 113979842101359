import classes from './Advantages.module.scss'
export function Advantages() {
    return (
        <section className={classes.container}>
            <div className={classes.textfield}>
                <div className={classes.headline}>
                    BE A MEMBER. BE A FAN.
                </div>
                <div className={classes.bodyText}>
                    You are an artist searching for a location to perform?<br></br>
                    You are a location searching for an artist?<br></br>
                    You are a Eventmanager searching for an artist to perform at your event?<br></br>
                    Or you just love music as we do?
                </div>
            </div>
            <div className={classes.textfield}>
                <div className={`${classes.headline} ${classes.centerText}`}>
                    VORTEILE MEMBER
                </div>
                <div className={`${classes.bodyText} ${classes.centerText}`}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Do- nec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.<br></br><br></br>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aene- an massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
                </div>

            </div>
        </section>
    )
}
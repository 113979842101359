import classes from "../../components/Styles/BookingOverview.module.scss";
import {Accordion, Button, Col, Container, Image, Modal, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {
    addBookingAnswer,
    addBookingReview,
    addNewPrivateMessage,
    getAllAcceptedBookings,
    getBookingList,
    getInvoiceByID
} from "../../Helper/API";
import {apiSuccessHandler} from "../../Helper/api.services";
import {
    BOOKING_FINISHED,
    BOOKING_STATUS,
    BookingCounterOfferPrice,
    BookingFinishedRating,
    iBookingFinish,
    iBookUserAnswer, iSendMessage, SendMessageFromProfileDialog
} from "../Profile/ProfileModals/ProfileModals";
import {BookingRequestManipulatingButton, CancelButton, SaveChangeButton} from "../../components/Buttons/Buttons";
import {Link} from "react-router-dom";
import LoadingPage from "../Information/LoadingPage";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"

import {I18N} from "../../i18n/i18n";
import moment from "moment";
import Payment from "../../components/Payment/Payment";
import {ArrowDownIcon, ArrowUpIcon, DownloadInvoiceIcon, OpenChatWhiteIcon} from "../../components/Icons/Icons";

export function BookingOverview() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [actionOpen, setActionOpen] = useState<number>();
    const [showSendMessageModal, setShowSendMessageModal] = useState(false);
    const [amountToPay, setAmountToPay] = useState<any>();
    const pdfRef = useRef<any>()
    const [bookingMessageInfo, setBookingMessageInfo] = useState({
        id: 0,
        name: ''
    });
    const [currentID, setCurrentID] = useState<any>();
    const [currentUserType, setCurrentUserType] = useState('')
    const [acceptedBookings, setAcceptedBookings] = useState<any[]>();
    const [defaultUserBooking, setDefaultUserBooking] = useState<any[]>()
    const [allAcceptedBokings, setAllAcceptedBookings] = useState<any[]>()
    const [seeAllAcceptedBookings, setSeeAllAcceptedBookings] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [filterState, setFilterState] = useState<any[]>();
    const [clicked, setClicked] = useState<any>();
    const [sendMessage, setSendMessage] = useState<iSendMessage>({
        to: 0,
        content: ''
    });

    const [acceptBooking, setAcceptBooking] = useState<iBookUserAnswer>(
        {
            status: BOOKING_STATUS.ACCEPTED,
        }
    );

    const [declineBooking, setDeclineBooking] = useState<iBookUserAnswer>(
        {
            status: BOOKING_STATUS.REJECTED,
        }
    );

    const [negotiateBooking, setNegotiateBooking] = useState<iBookUserAnswer>({
            pricePerHour: undefined,
        }
    );

    const [finishedBooking, setFinishedBooking] = useState<iBookingFinish>({
        message: '',
        rating: 0,
        status: BOOKING_FINISHED.FINISHED
    })

    const [reviewBooking, setReviewBooking] = useState<iBookingFinish>({
        message: '',
        rating: undefined,
        status: BOOKING_FINISHED.REVIEWED
    })

    const [bookingCanceled, setBookingCanceled] = useState<iBookingFinish>({
        status: BOOKING_FINISHED.CANCELED
    })

    const updateState = (newBooking: any) => {
        const newState = [...defaultUserBooking!.filter(booking => booking.id != newBooking.id), newBooking]
        setDefaultUserBooking(newState)
    }

    const updateAcceptedBookings = (newBooking: any) => {
        const newState = [...allAcceptedBokings!.filter(booking => booking.id != newBooking.id), newBooking]
        setAllAcceptedBookings(newState)
    }

    useEffect(() => {
        setIsLoading(true);
        if (seeAllAcceptedBookings) {
            getAllAcceptedBookings().then(data => {
                if (data.currentUserType === currentUserType) {
                    setAcceptedBookings(data.data);
                    setAllAcceptedBookings(data.data)
                    setFilterState(data.data)
                }
            }).catch(error => error).finally(() => {
                setIsLoading(false)
            });
        } else {
            getBookingList().then(data => {
                if (data) {
                    setDefaultUserBooking(data.data);
                    setCurrentUserType(data.currentUserType);
                }
            }).catch(error => error).finally(() => {
                setIsLoading(false)
            });
        }

    }, [seeAllAcceptedBookings, JSON.stringify(allAcceptedBokings)]);

    function handlerChange(event: any) {
        setNegotiateBooking(prev => ({...prev, [event.target.name]: event.target.value}));
        setFinishedBooking(prev => ({...prev, [event.target.name]: event.target.value}));
        setReviewBooking(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    function handlerChangeTextMessage(event: any) {
        setSendMessage(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    function handleRating(rate: number) {
        rate = rate / 20;
        setReviewBooking(prev => ({...prev, rating: +rate}))
    }

    async function acceptBookingRequest(e: any): Promise<void> {
        addBookingAnswer(acceptBooking!, currentID!).then(res => {
            if (res) {
                updateState(res)
                setAcceptBooking(res);
                apiSuccessHandler(<>
                    {I18N('accept_booking', 'de', [{
                        key: 'accept_booking',
                        value: I18N('accept_booking', 'de')
                    }])}
                </>);
            }
        }).catch(error => error);
    }

    async function declineBookingRequest(e: any): Promise<void> {
        addBookingAnswer(declineBooking!, currentID).then(res => {
            if (res) {
                updateState(res)
                setDeclineBooking(res);
                apiSuccessHandler(<>
                    {I18N('decline_booking', 'de', [{
                        key: 'decline_booking',
                        value: I18N('decline_booking', 'de')
                    }])}
                </>);
            }
        }).catch(error => error);
    }

    async function negotiateBookingRequest(e: any): Promise<void> {
        const currentBooking = defaultUserBooking?.find(booking => booking?.id == currentID);
        const status = currentBooking?.status === BOOKING_STATUS.OFFERED ? BOOKING_STATUS.COUNTERED : BOOKING_STATUS.OFFERED
        addBookingAnswer({...negotiateBooking!, status}, currentID).then(res => {
            if (res) {
                updateState(res)
                setNegotiateBooking(res);
                apiSuccessHandler(
                    <>
                        {I18N('negotiation_booking', 'de', [{
                            key: 'negotiation_booking',
                            value: I18N('negotiation_booking', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error);
    }

    async function finishBookingByDecline(e: any): Promise<void> {
        addBookingReview(bookingCanceled!, currentID).then(res => {
            if (res) {
                updateAcceptedBookings(res)
                setBookingCanceled(res)
                apiSuccessHandler(
                    <>
                        {I18N('set_booking_canceled', 'de', [{
                            key: 'set_booking_canceled',
                            value: I18N('set_booking_canceled', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error)
    }

    async function finishBookingByAccepting(e: any): Promise<void> {
        addBookingReview(finishedBooking!, currentID).then(res => {
            if (res) {
                updateAcceptedBookings(res)
                setFinishedBooking(res)
                apiSuccessHandler(
                    <>
                        {I18N('set_booking_finished', 'de', [{
                            key: 'set_booking_finished',
                            value: I18N('set_booking_finished', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error);
    }

    async function finishBookingByReview(e: any): Promise<void> {
        addBookingReview(reviewBooking!, currentID).then(res => {
            if (res) {
                updateAcceptedBookings(res)
                setReviewBooking(res)
                apiSuccessHandler(
                    <>
                        {I18N('set_booking_finished', 'de', [{
                            key: 'set_booking_finished',
                            value: I18N('set_booking_finished', 'de')
                        }])}
                    </>
                );
            }
        })
    }

    const wrapperFunctionNegotiationOffer = (e: any) => {
        negotiateBookingRequest(e)
        setShow(false);
    }

    const wrapperFunctionAcceptOffer = (e: any) => {
        acceptBookingRequest(e);
        setShow2(false)
    }

    const wrapperFunctionDeclineOffer = (e: any) => {
        declineBookingRequest(e)
        setShow3(false)
    }

    const wrapperFunctionDeclineBooking = (e: any) => {
        finishBookingByDecline(e);
        setShow4(false);
    }

    const wrapperFunctionFinishBooking = (e: any) => {
        finishBookingByAccepting(e);
        setShow5(false);
    }

    const wrapperFunctionFinishBookingBySendingReview = (e: any) => {
        finishBookingByReview(e);
        setShow6(false);
    }


    function seeAcceptedBookings() {
        setSeeAllAcceptedBookings(true)
        setFilterState(acceptedBookings);
        setClicked(undefined);
    }

    function seeAllBookings() {
        setSeeAllAcceptedBookings(false)
    }

    function getFilterBooking(filter: BOOKING_FINISHED) {
        const filteredArray = acceptedBookings?.filter(openedBookings => openedBookings?.status == filter)
        setFilterState(filteredArray)
        setClicked(filter)
    }

    async function sendUserPrivateMessage(): Promise<void> {
        addNewPrivateMessage({
            ...sendMessage,
            content: `${I18N('order_id', 'de')}: ${bookingMessageInfo?.id} \n ${I18N('booking_name', 'de')}: ${bookingMessageInfo?.name} \n`
                + sendMessage?.content,
            to: sendMessage?.to
        }).then(res => {
            if (res) {
                apiSuccessHandler(
                    <>
                        {I18N('success_send_message', 'de', [{
                            key: 'success_send_message',
                            value: I18N('success_send_message', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error);
        setShowSendMessageModal(false);
    }

    const classPending = `${classes.status_pending}`;
    const classAccepted = `${classes.status_accepted}`;
    const classDeclined = `${classes.status_declined}`;
    const classOffered = `${classes.status_offered}`;
    const classOpened = `${classes.status_opened}`;
    const classFinished = `${classes.status_opened}`;


    return (

        <div className={classes.Booking} style={{minHeight: '100%', width: '100%'}}>
            {isLoading && <LoadingPage/>}
            <div style={{minHeight: '100%', width: '100%'}}>

                <div className={classes.heading}>
                    <Container className="mx-4">
                        <span className="headline-title">{I18N('booking_overview', 'de', [{
                            key: 'booking_overview',
                            value: I18N('booking_overview', 'de')
                        }])}</span>
                    </Container>
                </div>

                <div className={classes.bookingContentContainer}>
                    <div className={classes.tabLeft}>
                        <div className={`${classes.tabEntryTop} ${seeAllAcceptedBookings ? '' : classes.activeTab}`}
                             onClick={() => {
                                 seeAllBookings();
                                 setActionOpen(undefined)
                             }}>
                            {I18N('opened_bookings', 'de', [{
                                key: 'opened_bookings',
                                value: I18N('opened_bookings', 'de')
                            }])}
                        </div>
                        <div
                            className={`${classes.tabEntryTop}  ${seeAllAcceptedBookings && clicked == undefined ? classes.activeTab : ''}`}
                            onClick={() => {
                                seeAcceptedBookings();
                                setActionOpen(undefined)
                            }}>
                            {I18N('all_bookings', 'de', [{
                                key: 'all_bookings',
                                value: I18N('all_bookings', 'de')
                            }])}
                        </div>
                        {seeAllAcceptedBookings &&
                            <>
                                <div
                                    className={`${classes.tabEntryDown}  ${(clicked === BOOKING_FINISHED.OPENED) ? classes.activeTab : ''}`}
                                    onClick={() => {
                                        getFilterBooking(BOOKING_FINISHED.OPENED);
                                        setActionOpen(undefined)
                                    }}>
                                    {I18N('search_all_opened_bookings', 'de', [{
                                        key: 'search_all_opened_bookings',
                                        value: I18N('search_all_opened_bookings', 'de')
                                    }])}
                                </div>
                                <div
                                    className={`${classes.tabEntryDown}  ${(clicked === BOOKING_FINISHED.FINISHED) ? classes.activeTab : ''}`}
                                    onClick={() => {
                                        getFilterBooking(BOOKING_FINISHED.FINISHED);
                                        setActionOpen(undefined)
                                    }}>
                                    {I18N('search_all_finished_bookings', 'de', [{
                                        key: 'search_all_finished_bookings',
                                        value: I18N('search_all_finished_bookings', 'de')
                                    }])}
                                </div>
                                <div
                                    className={`${classes.tabEntryDown}  ${(clicked === BOOKING_FINISHED.CANCELED) ? classes.activeTab : ''}`}
                                    onClick={() => {
                                        getFilterBooking(BOOKING_FINISHED.CANCELED);
                                        setActionOpen(undefined)
                                    }}>
                                    {I18N('search_all_canceled_bookings', 'de', [{
                                        key: 'search_all_canceled_bookings',
                                        value: I18N('search_all_canceled_bookings', 'de')
                                    }])}
                                </div>
                            </>
                        }
                    </div>
                    <div className={classes.bookingContent}>
                        {!seeAllAcceptedBookings ? defaultUserBooking?.length === 0 ?
                            <Container className="mt-5 text-center">
                                <span className="fw-bold c-white">
                                    {I18N('no_bookings_yet', 'de', [{
                                        key: 'no_bookings_yet',
                                        value: I18N('no_bookings_yet', 'de')
                                    }])}
                                </span>
                            </Container> : defaultUserBooking?.map(booking => {
                                return (
                                    <Rosa animation={'fade-right'} key={booking?.id} offset={500} duration={500}>
                                        <div className={`${isLoading ? 'filter-blur' : ''} ${classes.bookingContent_inner}`}>
                                            <Row className={`${classes.bg_black}`}>
                                                <Col xl={12}
                                                     className={`${classes.bg_secondary} justify-content-between`}>
                                                    <span>
                                                        {I18N('order_id', 'de', [{
                                                            key: 'order_id',
                                                            value: I18N('order_id', 'de')
                                                        }])}
                                                        <span className="ms-2">#</span>{booking?.id}</span>
                                                    {currentUserType === "CLUB" ?
                                                        <Link
                                                            className="text_decoration_none text-white d-none d-lg-block"
                                                            to={`/profile/${booking?.user?.id}`}>
                                                            {booking?.user?.firstName} {booking?.user?.lastName}
                                                        </Link> :
                                                        <Link
                                                            className="text_decoration_none text-white d-none d-lg-block"
                                                            to={`/profile/${booking?.requestedInfo?.id}`}>
                                                            {booking?.requestedInfo?.firstName} {booking?.requestedInfo?.lastName}
                                                        </Link>
                                                    }
                                                    <div className={classes.actionContainer}>
                                                        <div
                                                            onClick={() => actionOpen === undefined ? setActionOpen(booking.id) : setActionOpen(undefined)}
                                                            className={classes.ActionRow}>
                                                            {I18N('actions', 'de')}
                                                            {actionOpen === booking.id &&
                                                                <ArrowUpIcon/>
                                                            }
                                                            {actionOpen !== booking.id &&
                                                                <ArrowDownIcon/>
                                                            }
                                                        </div>
                                                        {actionOpen === booking.id &&
                                                            <>
                                                                <div className={`${classes.ActionRow} my-3`}>
                                                                    <button
                                                                        className={`${classes.btn_primary} w-100 mt-2`}
                                                                        onClick={() => {
                                                                            setShowSendMessageModal(true);
                                                                            setSendMessage(prev => ({
                                                                                ...prev,
                                                                                to: currentUserType === 'ARTIST' ? booking?.requestedInfo?.id : booking?.user?.id
                                                                            }))
                                                                            setBookingMessageInfo(prev => ({
                                                                                ...prev,
                                                                                name: booking?.name,
                                                                                id: booking?.id
                                                                            }))
                                                                            setActionOpen(undefined)
                                                                        }}
                                                                    >
                                                                        {I18N('send_message', 'de')}
                                                                        <OpenChatWhiteIcon/>

                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xl={12} className="p-4">
                                                    <Row>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('booking_name', 'de', [{
                                                                    key: 'booking_name',
                                                                    value: I18N('booking_name', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.name}</span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('booking_duration', 'de', [{
                                                                    key: 'booking_duration',
                                                                    value: I18N('booking_duration', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.numberOfHours}
                                                                {I18N('hours', 'de', [{
                                                                    key: 'hours',
                                                                    value: I18N('hours', 'de')
                                                                }])}
                                                            </span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('price', 'de', [{
                                                                    key: 'price',
                                                                    value: I18N('price', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>
                                                                ca. {booking?.numberOfHours * booking?.pricePerHour},-€
                                                            </span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('budget_per_hour', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.pricePerHour}</span>
                                                        </Col>
                                                        <Col xl={4} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('country', 'de', [{
                                                                    key: 'country',
                                                                    value: I18N('country', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.location?.name}</span>
                                                        </Col>

                                                        <Col xl={4} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('technicalStaff', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.technicalStaff ? <>{I18N('yes')}</> : <>{I18N('no')}</>}</span>
                                                        </Col>
                                                        <Col xl={4} className="border-bottom px-0 d-flex flex-column justify-content-between">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('technology', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.technology}</span>
                                                        </Col>

                                                    </Row>
                                                </Col>

                                                {(booking?.status === 'PENDING' || booking?.status === 'OFFERED' || booking?.status === 'COUNTERED') &&
                                                    <Col xl={12}
                                                         className="d-flex justify-content-evenly flex-column-reverse flex-xl-row flex-xl-row
                                      align-items-center pb-5">
                                                        {(currentUserType === 'ARTIST' && booking?.status === 'COUNTERED' || currentUserType === 'ARTIST' && booking?.status === 'PENDING') ?
                                                            <>
                                                                <button className={classes.btn_decline} onClick={() => {
                                                                    setShow3(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('decline_offer', 'de', [{
                                                                        key: 'decline_offer',
                                                                        value: I18N('decline_offer', 'de')
                                                                    }])}
                                                                </button>

                                                                <button className={classes.btn_warning} onClick={() => {
                                                                    setShow(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('negotiate_offer', 'de', [{
                                                                        key: 'negotiate_offer',
                                                                        value: I18N('negotiate_offer', 'de')
                                                                    }])}
                                                                </button>

                                                                <button className={classes.btn_primary} onClick={() => {
                                                                    setShow2(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('accept_offer', 'de', [{
                                                                        key: 'accept_offer',
                                                                        value: I18N('accept_offer', 'de')
                                                                    }])}
                                                                </button>
                                                            </> : (booking?.status === 'OFFERED' && currentUserType === 'CLUB') &&
                                                            <>
                                                                <button className={classes.btn_decline} onClick={() => {
                                                                    setShow3(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('decline_offer', 'de', [{
                                                                        key: 'decline_offer',
                                                                        value: I18N('decline_offer', 'de')
                                                                    }])}
                                                                </button>

                                                                <button className={classes.btn_warning} onClick={() => {
                                                                    setShow(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('negotiate_offer', 'de', [{
                                                                        key: 'negotiate_offer',
                                                                        value: I18N('negotiate_offer', 'de')
                                                                    }])}
                                                                </button>

                                                                <button className={classes.btn_primary} onClick={() => {
                                                                    setShow2(true)
                                                                    setCurrentID(booking?.id)
                                                                }}>
                                                                    {I18N('accept_offer', 'de', [{
                                                                        key: 'accept_offer',
                                                                        value: I18N('accept_offer', 'de')
                                                                    }])}
                                                                </button>
                                                            </>
                                                        }
                                                    </Col>
                                                }

                                                <Col xl={12}
                                                     className="fw-bold pb-4 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                                                    <div>
                                                        <span className="c-white">
                                                            {
                                                                I18N('booking_status', 'de', [{
                                                                key: 'booking_status',
                                                                value: I18N('booking_status', 'de')}])
                                                            }:
                                                        </span>
                                                        <span
                                                        className={`${booking?.status === 'ACCEPTED' ? classAccepted :
                                                            booking?.status === 'REJECTED' ? classDeclined : booking?.status === 'PENDING' ?
                                                                classPending : booking?.status === 'OFFERED' ? classOffered :
                                                                    booking?.status === 'COUNTERED' ? classOffered : ''} ms-1`}>
                                                           {booking?.status === 'ACCEPTED' ? <>{I18N('accepted', 'de')}</> :
                                                               booking?.status === 'REJECTED' ? <>{I18N('rejected', 'de')}</> :
                                                                   booking?.status === 'OFFERED' ? <>{I18N('offered', 'de')}</> :
                                                                       booking?.status === 'COUNTERED' ? <>{I18N('countered', 'de')}</> :
                                                                           <>{I18N('pending', 'de')}</>
                                                           }
                                                        </span>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Rosa>

                                )

                            }) : filterState?.length === 0 ?
                            <Container className={`${isLoading && 'filter-blur'} text-center mt-5`}>
                                <span className="fw-bold">
                                    {I18N('no_bookings_yet', 'de', [{
                                        key: 'no_bookings_yet',
                                        value: I18N('no_bookings_yet', 'de')
                                    }])}
                                </span>
                            </Container> : filterState?.map(booking => {
                                let acceptedDate = new Date(booking?.date);
                                let dateMDY = `${moment(acceptedDate).format('DD.MM.YYYY')} ${I18N('at')} ${moment(acceptedDate).format('HH:mm')}`;
                                return (
                                    <Rosa animation={'fade-right'} offset={500}>
                                        <Container className={`${isLoading && 'filter-blur'} my-5`} key={booking?.id}>
                                            <Row className={`${classes.bg_black}`}>
                                                <Col xl={12} className={classes.bg_secondary}>
                                                    <span>
                                                        {I18N('order_id', 'de', [{
                                                            key: 'order_id',
                                                            value: I18N('order_id', 'de')
                                                        }])}
                                                        <span className="ms-2">#</span>{booking?.id}
                                                    </span>
                                                    <span>

                                                    </span>

                                                    <div className="d-flex align-items-center d-none d-lg-block">
                                                        <span
                                                            className="me-3">
                                                            {booking?.request?.numberOfHours * booking?.request?.pricePerHour},-€
                                                        </span>
                                                    </div>
                                                    <div className={`${classes.actionContainer}`}>
                                                        <div
                                                            onClick={() => actionOpen === undefined ? setActionOpen(booking.id) : setActionOpen(undefined)}
                                                            className={classes.ActionRow}>
                                                            {I18N('actions', 'de')}
                                                            {actionOpen === booking.id &&
                                                                <ArrowUpIcon/>
                                                            }
                                                            {actionOpen !== booking.id &&
                                                                <ArrowDownIcon/>
                                                            }
                                                        </div>
                                                        {actionOpen === booking.id &&
                                                            <>

                                                                <div className={`${classes.ActionRow} my-3`}>
                                                                    <button
                                                                        className={`${classes.btn_primary} w-100 mt-2`}
                                                                        onClick={() => {
                                                                            setShowSendMessageModal(true);
                                                                            setSendMessage(prev => ({
                                                                                ...prev,
                                                                                to: currentUserType === 'ARTIST' ? booking?.request?.requestedBy : booking?.request?.userId
                                                                            }))
                                                                            setBookingMessageInfo(prev => ({
                                                                                ...prev,
                                                                                name: booking?.name,
                                                                                id: booking?.id
                                                                            }))
                                                                            setActionOpen(undefined)
                                                                        }}
                                                                    >
                                                                        {I18N('send_message', 'de')}
                                                                        <OpenChatWhiteIcon/>

                                                                    </button>
                                                                </div>
                                                                <div className={`${classes.ActionRow} my-3`}>
                                                                    {(booking.status === 'FINISHED' || booking.status === 'REVIEWED') &&
                                                                        <button
                                                                            onClick={() => {
                                                                                getInvoiceByID(booking.id).then((res) => {
                                                                                    downloadPDF(res, booking.id);
                                                                                });
                                                                                setIsLoading(true);
                                                                                setTimeout(() => {
                                                                                    setActionOpen(undefined)
                                                                                    setIsLoading(false)
                                                                                }, 2000)
                                                                            }
                                                                            }
                                                                            className={`${classes.btn_primary} w-100 h-100`}>
                                                                            {I18N('download_invoice', 'de')}
                                                                            <DownloadInvoiceIcon/>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>

                                                </Col>
                                                <Col xl={12} className="p-4">
                                                    <Row>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('booked_by', 'de')}
                                                            </span>
                                                            <a href={`/profile/${booking?.request?.user?.id}`}
                                                               className={`${classes.bg_table_content}`}>
                                                                {booking?.request?.user?.firstName} {booking?.request?.user?.lastName}
                                                            </a>
                                                        </Col>
                                                        <Col xl={2} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('booking_name', 'de', [{
                                                                    key: 'booking_name',
                                                                    value: I18N('booking_name', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>
                                                                {(booking?.request?.name?.length > 0) ? booking?.request?.name : I18N('noData', 'de')}
                                                            </span>
                                                        </Col>
                                                        <Col xl={2} className="border-bottom px-0">
                                                            <span
                                                                className={classes.bg_gray}>{I18N('booking_duration')}</span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>
                                                                {booking?.request?.numberOfHours}
                                                                {I18N('hours', 'de', [{
                                                                    key: 'hours',
                                                                    value: I18N('hours', 'de')
                                                                }])}</span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('country', 'de', [{
                                                                    key: 'country',
                                                                    value: I18N('country', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>
                                                                {booking?.request?.location?.name}
                                                            </span>
                                                        </Col>
                                                        <Col xl={2} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('accept_offer', 'de', [{
                                                                    key: 'accept_offer',
                                                                    value: I18N('accept_offer', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{dateMDY}</span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('price', 'de', [{
                                                                    key: 'price',
                                                                    value: I18N('price', 'de')
                                                                }])}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>
                                                                {booking?.request?.numberOfHours * booking?.request?.pricePerHour},-€
                                                            </span>
                                                        </Col>

                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('budget_per_hour', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.request?.pricePerHour}</span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('technicalStaff', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.request?.technicalStaff ? <>{I18N('yes')}</> : <>{I18N('no')}</>}</span>
                                                        </Col>
                                                        <Col xl={3} className="border-bottom px-0">
                                                            <span
                                                                className={`${classes.bg_gray} ${classes.card_radius}`}>
                                                                {I18N('technology', 'de')}
                                                            </span>
                                                            <span
                                                                className={`${classes.bg_table_content}`}>{booking?.request?.technology ? <>{I18N('yes')}</> : <>{I18N('no')}</>}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {(booking?.status === 'OPENED' && currentUserType === 'CLUB') ?
                                                    <Col xl={12}
                                                         className="d-flex justify-content-evenly flex-column-reverse flex-xl-row flex-xl-row
                                      align-items-center pb-5">

                                                        <button className={classes.btn_decline} onClick={() => {
                                                            setShow4(true)
                                                            setCurrentID(booking?.id)
                                                        }}>
                                                            {I18N('cancel_booking', 'de', [{
                                                                key: 'cancel_booking',
                                                                value: I18N('cancel_booking', 'de')
                                                            }])}
                                                        </button>

                                                        <button className={classes.btn_primary} onClick={() => {
                                                            setAmountToPay(booking?.request?.numberOfHours * booking?.request?.pricePerHour)
                                                            setShow5(true)
                                                            setCurrentID(booking?.id)
                                                        }}>
                                                            {I18N('finish_booking', 'de', [{
                                                                key: 'finish_booking',
                                                                value: I18N('finish_booking', 'de')
                                                            }])}
                                                        </button>

                                                    </Col>
                                                    : (booking?.status === 'FINISHED' && currentUserType === 'ARTIST') ?
                                                        <Col xl={12}
                                                             className="d-flex justify-content-evenly flex-column-reverse flex-xl-row flex-xl-row
                                      align-items-center pb-5">

                                                            <button className={classes.btn_warning} onClick={() => {
                                                                setShow6(true)
                                                                setCurrentID(booking?.id)
                                                            }}>
                                                                {I18N('send_rating', 'de', [{
                                                                    key: 'send_rating',
                                                                    value: I18N('send_rating', 'de')
                                                                }])}
                                                            </button>
                                                        </Col> : booking?.status === 'REVIEWED' && ''

                                                }
                                                <Col xl={12}
                                                     className="fw-bold pb-4 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
                                                    <div>
                                                        {I18N('booking_status', 'de', [{
                                                            key: 'booking_status',
                                                            value: I18N('booking_status', 'de')
                                                        }])}:
                                                        <span
                                                            className={`${booking?.status === 'OPENED' ? classOpened :
                                                                booking?.status === 'CANCELED' ? classDeclined :
                                                                    booking?.status === 'FINISHED' ? classFinished :
                                                                        booking?.status === 'REVIEWED' ? classAccepted : ''
                                                            } ms-1`}>
                                                            {booking?.status === 'OPENED' ? <>{I18N('opened', 'de')}</> :
                                                                booking?.status === 'CANCELED' ? <>{I18N('canceled', 'de')}</> :
                                                                    booking?.status === 'FINISHED' ? <>{I18N('finished', 'de')}</> :
                                                                        <>{I18N('reviewed', 'de')}</>
                                                            }
                                                        </span>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Rosa>
                                )
                            })

                        }
                        <Modal show={show} onHide={() => setShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {I18N('negotiate_offer', 'de', [{
                                        key: 'negotiate_offer',
                                        value: I18N('negotiate_offer', 'de')
                                    }])}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex flex-column">
                                    <span className="mb-2">
                                        {I18N('budget_per_hour', 'de',)}
                                    </span>
                                    <BookingCounterOfferPrice state={negotiateBooking} changeHandler={handlerChange}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CancelButton className="me-2" onClick={() => setShow(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </CancelButton>
                                    <BookingRequestManipulatingButton
                                        style={'warning'}
                                        clickHandler={wrapperFunctionNegotiationOffer}
                                        buttonName='Senden Sie ein Angebot '/>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show2} onHide={() => setShow2(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {I18N('accept_offer', 'de', [{
                                        key: 'accept_offer',
                                        value: I18N('accept_offer', 'de')
                                    }])}?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CancelButton className="me-2"
                                            onClick={() => setShow2(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </CancelButton>
                                    <BookingRequestManipulatingButton clickHandler={wrapperFunctionAcceptOffer}
                                                                      buttonName='Akzeptiere Angebot'
                                    />
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show3} onHide={() => setShow3(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{I18N('decline_offer', 'de', [{
                                    key: 'decline_offer',
                                    value: I18N('decline_offer', 'de')
                                }])}?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CancelButton className="me-2"
                                            onClick={() => setShow3(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </CancelButton>
                                    <BookingRequestManipulatingButton clickHandler={wrapperFunctionDeclineOffer}
                                                                      style={'danger'}
                                                                      buttonName='Angebot ablehnen'/>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show4} onHide={() => setShow4(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{I18N('cancel_booking', 'de', [{
                                    key: 'cancel_booking',
                                    value: I18N('cancel_booking', 'de')
                                }])}?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CancelButton className="me-2"
                                            onClick={() => setShow4(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </CancelButton>
                                    <BookingRequestManipulatingButton clickHandler={wrapperFunctionDeclineBooking}
                                                                      buttonName='Buchung stornieren'/>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show5} onHide={() => setShow5(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{I18N('finish_booking', 'de', [{
                                    key: 'finish_booking',
                                    value: I18N('finish_booking', 'de')
                                }])}?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{
                                    minHeight: 300,
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Payment bookingId={currentID} parentAmount={amountToPay}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Button variant="secondary" className="me-2"
                                            onClick={() => setShow5(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </Button>

                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={show6} onHide={() => setShow6(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {I18N('send_rating', 'de', [{
                                        key: 'send_rating',
                                        value: I18N('send_rating', 'de')
                                    }])}?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex flex-column">
                                    <span className="mb-2">
                                        {I18N('send_rating', 'de', [{
                                            key: 'send_rating',
                                            value: I18N('send_rating', 'de')
                                        }])}
                                    </span>
                                    <BookingFinishedRating state={reviewBooking} handleRating={handleRating}
                                                           changeHandler={handlerChange}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Button variant="secondary" className="me-2"
                                            onClick={() => setShow6(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </Button>
                                    <BookingRequestManipulatingButton
                                        clickHandler={wrapperFunctionFinishBookingBySendingReview}
                                        buttonName='Angebot ablehnen'/>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showSendMessageModal} onHide={() => setShowSendMessageModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {I18N('send_message', 'de')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <p>
                                    <span className="fw-bold me-1">
                                        {I18N('order_id', 'de')}:
                                    </span>
                                    #{bookingMessageInfo?.id}
                                </p>
                                <p>
                                    <span
                                        className="fw-bold me-1">
                                        {I18N('booking_name', 'de')}:
                                    </span> {bookingMessageInfo?.name}
                                </p>
                                <SendMessageFromProfileDialog changeHandler={handlerChangeTextMessage}
                                                              state={sendMessage}/>
                            </Modal.Body>
                            <Modal.Footer className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Button variant="secondary" className="me-2"
                                            onClick={() => setShowSendMessageModal(false)}>
                                        {I18N('close', 'de', [{
                                            key: 'close',
                                            value: I18N('close', 'de')
                                        }])}
                                    </Button>
                                    <SaveChangeButton text={I18N('send', 'de')} clickHandler={sendUserPrivateMessage}/>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

function downloadPDF(pdf, bookingID) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `MUVE_INVOICE_FOR_BOOKING_#${bookingID}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
import React, {useState} from 'react';
import "./ProfileCard.scss";
import {Icon, Rating} from "@mui/material";
import {Link} from 'react-router-dom';
import {addUserToFavorites, deleteUserFromFavorites} from "../../Helper/API";
import {apiSuccessHandler} from "../../Helper/api.services";
import {I18N} from "../../i18n/i18n";

interface IProfileCard {
    picturePath: string | null;
    firstName: string | null;
    lastName: string | null;
    jobs?: Array<{ id: number, name: string }> | [];
    categories: Array<{ id: number, name: string }> | [];
    rating: number | null;
    location: string | null;
    userId: number;
    isFavorite?: boolean;
}

const ProfileCard = (props: IProfileCard) => {

    const [markedAsFavorite, setMarkedAsFavorite] = useState(props.isFavorite);

    const markAsFavorite = () => {
        if (markedAsFavorite) {
            deleteUserFromFavorites(props.userId).then(res => {
                apiSuccessHandler(
                    <>
                        {I18N('success_remove_favorite', 'de', [{
                            key: 'success_remove_favorite',
                            value: I18N('success_remove_favorite', 'de')
                        }])}
                    </>
                )
            }).catch(error => error)
            setMarkedAsFavorite(false)
        } else {
            addUserToFavorites(props.userId).then(res => {
                apiSuccessHandler(
                    <>
                        {I18N('success_add_favorite', 'de', [{
                            key: 'success_add_favorite',
                            value: I18N('success_add_favorite', 'de')
                        }])}
                    </>
                )
            }).catch(error => error)
            setMarkedAsFavorite(true);
        }
    }

    return (
        <div className="ProfileCard">
            <button className="ProfileCard__favorite-btn" onClick={markAsFavorite}>
                {
                    markedAsFavorite ? (
                        <img className="ProfileCard__favorite-btn__img" src={"/SVG/icon_heart_full_48px.svg"}/>
                    ) : (
                        <img className="ProfileCard__favorite-btn__img" src={"/SVG/icon_heart_48px.svg"}/>
                    )
                }

            </button>
            <Link to={`/profile/${props.userId}`} className="ProfileCard-link">
                <div className="ProfileCard__image-wrapper">
                    <img className="ProfileCard__image-wrapper__image"
                         src={props.picturePath ? props.picturePath : '/user.jpg'}
                    />
                </div>
            </Link>
            <div className="ProfileCard__info">
            <h3 className="ProfileCard__info__text artistname">
                {props.firstName} {props.lastName}
            </h3>
            {
                (props.jobs && props.jobs?.length > 0) && (
                    <p className="ProfileCard__info__text">
                        {
                            props.jobs?.map(job => job?.name + (props.jobs!.length > 1 ? ', '  : ''))
                        }
                    </p>
                )
            }
            {
                props.location && (
                    <p className="ProfileCard__info__text">{props.location}</p>
                )
            }
            {
                props.categories.length > 0 && (
                    <p className="ProfileCard__info__text">
                        {
                            props.categories.map(category => category?.name + (props.categories.length > 1 ? ', ' : ''))
                        }
                    </p>
                )
            }
            {
                props.rating && (
                    <Rating value={props.rating}
                            precision={0.5}
                            icon={<Icon fontSize={'large'}><img src="./SVG/icon_star-full_48px.svg"/></Icon>}
                            emptyIcon={<Icon fontSize={'large'}><img src="./SVG/icon_star-outline_48px.svg"/></Icon>}
                            readOnly
                    />
                )
            }
            </div>
            <div className="ProfileCard__visit-profile">
                <Link to={`/profile/${props.userId}`} className="ProfileCard-link">
                    <button className="ProfileCard__visit-profile__visit-btn primary-btn">{I18N('visit')}</button>
                </Link>
            </div>
        </div>
    );
};

export default ProfileCard;
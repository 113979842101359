import {
    iCategory,
    iForgotPassword,
    iUserInfo
} from "../Profile/Profile";
import classes from "../../../components/Styles/Profile.module.scss";
import sendMessageClass from "../../../components/Styles/ProfileInbox.module.scss";
import {Col, Image, Row} from "react-bootstrap";
import Select, {GroupBase, OptionsOrGroups} from 'react-select';
import {
    getAllMusicCategory,
    getAllPortfolioJobs,
    getBookingOccasion,
    getUserPortfolio
} from "../../../Helper/API";
import React, {useEffect, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import dayjs from 'dayjs';
import {BoomboxIcon, EyeClose, EyeOpen, RulersIcon} from "../../../components/Icons/Icons";
import {iAdminData} from "../ProfileAdmin";
import "react-on-scroll-animation/build/index.css";
import {LocationSelectField} from "../../../components/LocationSelectField/LocationSelectField";
import {LocalizationProvider, DateTimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {Rating} from "react-simple-star-rating";
import {SxProps, TextField} from "@mui/material";
import "./ProfileModals.scss"
import moment from "moment";

/*
export interface iProfile {
    state: iProfileInfo;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}
*/


interface iBookingCountered {
    state?: iBookUserAnswer,
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/*
export interface iDescription {
    state: iUserDescription,
    changeHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
*/

interface iCalendarBooking {
    state: iCreateCalendarEvent,
    selectHandler?: (e: any) => void
    selectLocation?: (e: any) => void
    changeHandler: any
    timeHandler?: any
    userType?: string
}

/*
export interface iPrice {
    state: iUserPrice,
    changeHandlerNumber?: (e: any) => void,
    changeHandler: any
}
*/

export interface iSocialNetwork {
    link?: string,
    name?: string,
    changeHandler?: any
    selectedNetwork?: string
}

export enum BOOKING_STATUS {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    OFFERED = 'OFFERED',
    COUNTERED = 'COUNTERED',
    REJECTED = 'REJECTED',
}

export enum BOOKING_FINISHED {
    OPENED = 'OPENED',
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
    REVIEWED = 'REVIEWED',
}

export enum SupportCategoryEnum {
    LOGIN = 'LOGIN',
    PROFILE = 'PROFILE',
    BOOKING = 'BOOKING',
    CHAT = 'CHAT',
    PAYMENT = 'PAYMENT',
    OTHER = 'OTHER',
}

export enum SupportStatusEnum {
    REQUESTED = 'REQUESTED',
    CANCELED = 'CANCELED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
}

export enum BookingTechnicalStaff {
    TRUE = "YES",
    FALSE = "NO"
}

export enum BookingTechnology {
    TRUE = "YES",
    FALSE = "NO"
}


export interface iBookUser {
    startDate?: string,
    endDate?: string,
    message?: string,
    locationId?: number,
    technicalStaff: string,
    technology: string,
    numberOfHours?: number,
    occasionId?: number,
    name?: string,
    pricePerHour?: number,
    status?: BOOKING_STATUS,
    userId?: number
}

export interface iCreateCalendarEvent {
    startDate?: any,
    nameOfEvent?: string,
    description?: string,
    locationId?: number,
}

export interface iUserSearchProfile {
    page?: number,
    perPage?: number,
    categoryId?: number,
    location?: number,
    radius?: number,
    firstName?: string
}

export interface iSendMessage {
    content?: string,
    to?: number
}

export interface iBookUserAnswer {
    status?: BOOKING_STATUS,
    pricePerHour?: number
}

export interface iBookingFinish {
    message?: string,
    status?: BOOKING_FINISHED,
    rating?: number
}

export interface iBookingRating {
    state?: iBookingFinish,
    changeHandler?: any
    handleRating?: any
}

export interface iAddPhotoToGallery {
    pictures?: HTMLImageElement[],
    changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
    refHandler?: any
}

export interface iUploadProfile {
    picture?: HTMLImageElement,
    changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
    refHandler?: any
}

export interface iUserPersonalInfo {
    state?: iUserInfo,
    changeHandler?: any,
    changeBirthday?: any,
    selectHandler?: (e: any) => void
    selectLocation?: (e: any) => void
    selectJob?: (e: any) => void
}

export interface iCategoryDTO {
    value: number,
    zipCode: string,
    label: string
}

export interface iJobDTO {
    value: number,
    label: string
}

interface iBooking {
    state?: iBookUser,
    changeHandler?: any
    selectBoolean?: (e: any) => void
    selectLocation?: (e: any) => void
    changeHandlerNumber?: (e: any) => void
    handlerEndDate?: any
    handlerStartDate?: any
}

interface SendMessage {
    state?: iSendMessage
    changeHandler?: any
    keyUp?: any;
    textValue?: string
}

export interface iDialogArtist {
    message: string
}

interface iSendCalendarRequest {
    state?: iDialogArtist,
    changeHandler?: any

}

export interface iFavoriteUser {
    userId: number
}

export interface iSupportTicket {
    createdAt: string,
    title: string,
    description: string,
    status: SupportStatusEnum,
    category: SupportCategoryEnum
}

export interface iSupportComment {
    ticketId: number,
    message: string
}

export interface iSupportStatus {
    status: SupportStatusEnum
}

interface iCreateTicket {
    state?: iSupportTicket,
    changeHandler?: any
}

interface iFilterInboxMessage {
    changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export interface iBiography {
    description: string,
    from: string,
    to: string
}

interface iBiographyState {
    state: iBiography,
    changeHandler?: any,
}

interface iAdminModal {
    state?: iAdminData,
    changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface iForgotPasswordModal {
    state?: iForgotPassword,
    changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CreateAdminModal = ({state, changeHandler}: iAdminModal) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <Col xl={12}>
                <label htmlFor="email">Email</label>
                <input type="email" className={classes.input} value={state?.email} onChange={changeHandler}
                       name="email"/>
            </Col>
            <Col xl={12}>
                <label htmlFor="password" className="mt-3">Password</label>
                <input type={passwordShown ? "text" : "password"} className={classes.input} value={state?.password}
                       onChange={changeHandler}
                       name="password"/>
                <span onClick={togglePassword}>
                    {passwordShown ? <EyeOpen/> : <EyeClose/>}
                </span>
            </Col>

            <Col xl={12}>
                <label htmlFor="firstName" className="mt-3">firstName</label>
                <input type="text" className={classes.input} value={state?.firstName} onChange={changeHandler}
                       name="firstName"/>
            </Col>
            <Col xl={12}>
                <label htmlFor="lastName" className="mt-3">lastName</label>
                <input type="text" className={classes.input} value={state?.lastName} onChange={changeHandler}
                       name="lastName"/>
                <input type="hidden" value={state?.type}/>
                <input type="hidden" value={state?.roles}/>
            </Col>
        </>
    )
}

export const ProfileBiographyModal = ({state, changeHandler}: iBiographyState) => {
    return (
        <>

            <label htmlFor="description" className="mt-3">
                {I18N('description', 'de', [{
                    key: 'description',
                    value: I18N('description', 'de')
                }])}
            </label>
            <textarea name="description" className={classes.input} style={{minHeight: '200px', maxHeight: '200px'}}
                      maxLength={250} value={state?.description} onChange={changeHandler}/>

            <span
                className="d-flex justify-content-end mb-2">
                {I18N('max_characters', 'de', [{
                    key: 'max_characters',
                    value: I18N('max_characters', 'de')
                }])}
                250 !</span>
        </>
    )
}

export const ProfileBookingModal = ({
                                        state,
                                        changeHandler,
                                        changeHandlerNumber,
                                        selectLocation,
                                        selectBoolean,
                                        handlerEndDate,
                                        handlerStartDate
                                    }: iBooking) => {
    const [bookingOccasion, setBookingOccasion] = useState<any[]>([{
        id: 0,
        name: ''
    }]);

    const locationSelectStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            background: 'rgb(16, 15, 15)',
            borderRadius: '10px !important',
            border: '2px solid #fff',
            width: '100%',
            height: '50px',
            paddingLeft: ".5rem"
        }),
        valueContainer: (baseStyles)=> ({
            ...baseStyles,
            padding: '2px 0px'
        }),
        placeholder: (baseStyles) => (
            {
                ...baseStyles,
                color: '#ffffff',
                textTransform: 'uppercase',
                opacity: '0.38',
                textAlign: 'left !important'
            }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'white'
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            color: 'rgb(16, 15, 15)'
        })
    }

    useEffect(() => {
        getBookingOccasion().then(res => {
            setBookingOccasion(res)
        }).catch(error => error);
    }, [])

    const textFieldStyles: React.CSSProperties = {
        border: '2px solid #fff',
        borderRadius: '10px',
        height: '100%',
        marginBottom: '1rem'
    }

    const textFieldSx: SxProps = {
        input: {
            color: '#fff',
            paddingLeft: '0.5rem',
            textTransform: 'uppercase',
            height: '100%'
        },
        svg: {
            color: '#fff'
        }
    }


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Col xl={6} className="d-flex flex-column">

                    <label htmlFor="startDate" className="mb-2">
                        {I18N('enter_start_date', 'de')}
                    </label>
                    <DateTimePicker
                        value={state?.startDate}
                        inputFormat="dd.MM.yyyy hh:mm"
                        ampm={false}
                        className={classes.input}
                        onChange={handlerStartDate}
                        renderInput={(params) => <TextField {...params}
                                                            style={textFieldStyles}
                                                            sx={textFieldSx}/>}
                    />
            </Col>
            <Col xl={6} className="d-flex flex-column">
                    <label htmlFor="endDate" className="mb-2">
                        {I18N('end_date', 'de')}
                    </label>
                    <DateTimePicker
                        value={state?.endDate || ""}
                        inputFormat="dd.MM.yyyy hh:mm"
                        ampm={false}
                        className={classes.inputBlack}
                        onChange={handlerEndDate}
                        renderInput={(params) => <TextField {...params}
                                                            style={textFieldStyles}
                                                            sx={textFieldSx}/>}
                    />

            </Col>
            </LocalizationProvider>
            <Col xl={6}>
                <label htmlFor="message" className="mb-2">
                    {I18N('enter_message', 'de', [{
                        key: 'enter_message',
                        value: I18N('enter_message', 'de')
                    }])}
                </label>
                <textarea name="message" id="message" className={classes.inputBlack} maxLength={250}
                          onChange={changeHandler} value={state?.message}
                          style={{maxHeight: '140px', minHeight: '140px'}}/>
                <span
                    className="d-flex justify-content-end">
                    {I18N('max_characters', 'de', [{
                        key: 'max_characters',
                        value: I18N('max_characters', 'de')
                    }])}
                    250</span>
            </Col>
            <Col xl={6} className="mb-3">
                <Row>
                    <Col xl={12}>
                        <label htmlFor="location" className="mb-2">
                            {I18N('enter_location', 'de', [{
                                key: 'enter_location',
                                value: I18N('enter_location', 'de')
                            }])}
                        </label>
                        <LocationSelectField handleLocation={selectLocation} selectStyles={locationSelectStyles}/>
                    </Col>
                </Row>
            </Col>
            <Col xl={2} className="my-3 d-flex justify-content-between flex-column">
                <label htmlFor="technicalStaff" className="mt-2">
                    {I18N('technicalStaff', 'de', [{
                        key: 'technicalStaff',
                        value: I18N('technicalStaff', 'de')
                    }])}
                </label>
                <select name="technicalStaff" className={classes.inputBlack}
                        onChange={selectBoolean}>
                    <option value="" hidden></option>
                    <option value="NO">
                        {I18N('no', 'de', [{
                            key: 'no',
                            value: I18N('no', 'de')
                        }])}
                    </option>
                    <option value="YES">
                        {I18N('yes', 'de', [{
                            key: 'yes',
                            value: I18N('yes', 'de')
                        }])}
                    </option>
                </select>
            </Col>
            <Col xl={2} className="my-3 d-flex justify-content-between flex-column">
                <label htmlFor="technology" className="mt-2">
                    {I18N('technology', 'de', [{
                        key: 'technology',
                        value: I18N('technology', 'de')
                    }])}
                </label>
                <input type="text" name="technology" id="technology" onChange={changeHandler}
                       value={state?.technology}
                       className={classes.inputBlack}
                />
            </Col>
            <Col xl={2} className="my-3 d-flex justify-content-between flex-column">
                <label htmlFor="occasionId" className="mt-2">
                    {I18N('occasion', 'de', [{
                        key: 'occasion',
                        value: I18N('occasion', 'de')
                    }])}
                </label>
                <select name="occasionId" className={classes.inputBlack} value={state?.occasionId}
                        onChange={changeHandlerNumber}>
                    <option value="" hidden></option>
                    {bookingOccasion?.map?.(key => {
                        return (
                            <option value={key?.id} key={key?.id}>{key?.name}</option>
                        )
                    })}
                </select>
            </Col>
            <Col xl={6} className="my-3 d-flex justify-content-between flex-column">
                <label htmlFor="name" className="mt-2">
                    {I18N('name_of_event', 'de', [{
                        key: 'name_of_event',
                        value: I18N('name_of_event', 'de')
                    }])}
                </label>
                <input type="text" name="name" id="name" onChange={changeHandler} value={state?.name}
                       className={`${classes.inputBlack} mt-2`}/>
            </Col>
            <Col xl={6}>
                <label htmlFor="pricePerHour" className="mt-3 mb-2">
                    Budget pro Stunde (in €)
                </label>
                <input type="number" pattern="^[1-9]+[0-9]*" name="pricePerHour" id="pricePerHour" onChange={changeHandlerNumber}
                       value={Number(state?.pricePerHour).toString()}
                       className={classes.inputBlack}/>
            </Col>
            <Col xl={6}>
                <label htmlFor="numberOfHours" className="mt-3 mb-2">
                    {I18N('enter_number_of_hours', 'de', [{
                        key: 'enter_number_of_hours',
                        value: I18N('enter_number_of_hours', 'de')
                    }])}
                </label>
                <input type="number" pattern="^[1-9]+[0-9]*" name="numberOfHours" id="numberOfHours" onChange={changeHandlerNumber}
                       value={Number(state?.numberOfHours).toString()}
                       className={classes.inputBlack}/>
            </Col>
            <input type="hidden" name="userId" id="userId" value={state?.userId} onChange={changeHandler}/>
            <input type="hidden" name="status" id="status" value={state?.status} onChange={changeHandler}/>
        </>
    )
}

export const SendMessageFromProfileDialog = ({state, changeHandler}: SendMessage) => {
    return (
        <>
            <textarea className={classes.input} onChange={changeHandler} value={state?.content} name="content"
                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
            <span
                className="d-flex justify-content-end mb-2">
                {I18N('max_characters', 'de', [{
                    key: 'max_characters',
                    value: I18N('max_characters', 'de')
                }])}
                250 !
            </span>
        </>
    )
}

export const ForgotPasswordModal = ({state, changeHandler}: iForgotPasswordModal) => {
    return (
        <>
            <Col xl={12}>
                <label htmlFor="email">
                    {I18N('enterAddress', 'de', [{
                        key: 'enterAddress',
                        value: I18N('enterAddress', 'de')
                    }])}:
                </label>
                <input type="email" name="email" className={`${classes.input} mt-2`} onChange={changeHandler}
                       value={state?.email}/>
            </Col>
        </>
    )
}

export const SendMessageInputDialog = ({textValue, changeHandler, keyUp}: SendMessage) => {
    return (
        <input className={sendMessageClass.input_message} value={textValue} name="content"
               onChange={changeHandler}
               onKeyUp={keyUp}
               maxLength={75}
               type="text"/>
    );
}

export const ProfileModalInfo = ({
                                     changeHandler,
                                     state,
                                     selectHandler,
                                     selectLocation,
                                     selectJob,
                                     changeBirthday
                                 }: iUserPersonalInfo) => {

    const [category, setCategory] = useState<OptionsOrGroups<any, GroupBase<any>>>([]);
    const [jobs, setJobs] = useState<OptionsOrGroups<any, GroupBase<any>>>([]);
    const [currentType, setCurrentType] = useState('');
    const [userDisplayInfo, setUserDisplayInfo] = useState<any>({})


    useEffect(() => {
        getUserPortfolio("").then(res => {
            setCurrentType(res?.user?.type)
        })
    }, [])

    useEffect(() => {
        getAllMusicCategory().then(data => {
            setCategory(data.map((d: iCategory) => ({value: d.id, label: d.name})))
        }).catch(error => error);
        getAllPortfolioJobs().then(data => {
            setJobs(data.map((d: iCategory) => ({value: d.id, label: d.name})));
        }).catch(error => error);
        getUserPortfolio("0").then(res => {
            setUserDisplayInfo(res)
        }).catch(error => error);
    }, [])


    return (
        <>
            <Col xl={6} className="p-2 d-flex">
                <Image src="/place-icon.svg" alt="Place icon"/>
                <label htmlFor="location" className="ms-2">
                    {I18N('enter_origin_country', 'de', [{
                        key: 'enter_origin_country',
                        value: I18N('enter_origin_country', 'de')
                    }])}
                </label>
            </Col>
            <Col xl={6} className="p-2">
                {/* general Profile Information */}
                <LocationSelectField presettedValue={state?.location} handleLocation={selectLocation}/>
            </Col>
            {currentType === 'ARTIST' && <>
                <Col xl={6} className="p-2">
                    <Image src="/genre-icon.svg" alt="Place icon"/>
                    <label htmlFor="categories" className="mb-2 ms-2"> Genre:</label>
                </Col>
                <Col xl={6} className="p-2">
                    <Select
                        options={category}
                        isMulti={true}
                        id="categories"
                        onChange={selectHandler}
                        name="categories"
                        defaultValue={
                            state?.categories ?
                                state?.categories?.filter(cat => {
                                    let found = category.find(c => {
                                        if (cat.id) {
                                            return c.value === cat.id
                                        } else {
                                            return c.value === cat.category.id
                                        }
                                    })
                                    return found
                                }).map(cat => {
                                        if (cat.id) {
                                            return {value: cat.id, label: cat.name}
                                        } else {
                                            return {value: cat.category.id, label: cat.category.name}
                                        }
                                    }
                                )
                                : undefined
                        }
                    />
                </Col>
                <Col xl={6} className="p-2">
                    <BoomboxIcon/>
                    <label htmlFor="jobs" className="mb-2 ms-2">
                        {I18N('jobs', 'de', [{
                            key: 'jobs',
                            value: I18N('jobs', 'de')
                        }])}:
                    </label>

                </Col>
                <Col xl={6} className="p-2">
                    <Select options={jobs}
                            isMulti={true}
                            id="jobs"
                            onChange={selectJob}
                            name="jobs"
                            defaultValue={
                                state?.jobs ?
                                    state?.jobs?.filter(cat => {
                                        let found = jobs.find(c => {
                                            if (cat.id) {
                                                return c.value === cat.id
                                            } else {
                                                return c.value === cat.job.id
                                            }
                                        })
                                        return found
                                    }).map(cat => {
                                            if (cat.id) {
                                                return {value: cat.id, label: cat.name}
                                            } else {
                                                return {value: cat.job.id, label: cat.job.name}
                                            }
                                        }
                                    )
                                    : undefined


                            }
                    />
                </Col>

                <Col xl={6} className="p-2">
                    <Image src="/microphone-icon.svg" alt="Place icon"/>
                    <label htmlFor="bandName" className="mb-2 ms-2">
                        Band
                    </label>

                </Col>
                <Col xl={6} className="p-2">
                   {/* <input className={classes.input} name="bandName" onChange={changeHandler}
                           value={state?.bandName}
                           id="latestRelease"
                           maxLength={30}
                           type="text"
                    />*/}
                </Col>
            </>
            }
            {currentType === 'CLUB' &&
                <>
                    <Col xl={6} className="p-2">
                        <RulersIcon/>
                        <label htmlFor="locationSize" className="mb-2 ms-2">
                            {I18N('quadrature', 'de', [{
                                key: 'quadrature',
                                value: I18N('quadrature', 'de')
                            }])}
                        </label>
                    </Col>
                    <Col xl={6} className="p-2">
                        <input className={classes.input} name="locationSize" onChange={changeHandler}
                               value={state?.locationSize}
                               id="latestRelease"
                               maxLength={30}
                               type="number"/>
                    </Col>
                </>}
            <Col xl={6} className="p-2 d-flex">
                <Image src="/calendar-icon.svg" alt="Place icon"/>
                <label htmlFor="birthday" className="ms-2">
                    {I18N('birthday', 'de', [{
                        key: 'birthday',
                        value: I18N('birthday', 'de')
                    }])}
                </label>
            </Col>
            <Col xl={6} className="p-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label={I18N('birthday')}
                        inputFormat="dd.MM.yyyy"
                        value={state?.birthday}
                        onChange={changeBirthday}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Col>
            <Col xl={6} className="p-2 d-flex">
                <Image src="/calendar-icon.svg" alt="Place icon"/>
                <label htmlFor="birthdayDisplay" className="ms-2">
                    {I18N('birthday_display', 'de', [{
                        key: 'birthday_display',
                        value: I18N('birthday_display', 'de')
                    }])}
                </label>
            </Col>
            <Col xl={6} className="p-2">
                <select name="birthdayDisplay" value={state?.birthdayDisplay} onChange={changeHandler}>
                    <option value="" hidden></option>
                    <option value="YEAR">
                        {I18N('year', 'de', [{
                            key: 'year',
                            value: I18N('year', 'de')
                        }])}
                    </option>
                    <option value="DATE">
                        {I18N('date', 'de', [{
                            key: 'date',
                            value: I18N('date', 'de')
                        }])}
                    </option>
                    <option value="NONE">
                        {I18N('none', 'de', [{
                            key: 'none',
                            value: I18N('none', 'de')
                        }])}
                    </option>
                </select>
            </Col>
        </>
    )
}

export const ProfileModalsUserInfo = ({changeHandler, state}: any) => {
    return (
        <>
            <label htmlFor="firstName" className="mb-2">
                {I18N('firstName', 'de', [{
                    key: 'firstName',
                    value: I18N('firstName', 'de')
                }])}
            </label>
            <input className={classes.input} onChange={changeHandler} value={state.firstName} name="firstName"
                   id="firstName" maxLength={30}
                   type="text"/>
            <label htmlFor="lastName" className="mt-4 mb-2">
                {I18N('lastName', 'de', [{
                    key: 'lastName',
                    value: I18N('lastName', 'de')
                }])}
            </label>
            <input className={classes.input} onChange={changeHandler} value={state.lastName} name="lastName"
                   id="lastName" maxLength={30}
                   type="text"/>
        </>
    );
}

export const BookingCounterOfferPrice = ({changeHandler, state}: iBookingCountered) => {
    return (
        <>
            <input className={`${classes.input} w-50`} value={state?.pricePerHour} name="pricePerHour"
                   id="pricePerHour"
                   onChange={changeHandler}
                   type="number"/>
        </>
    )
}

export const BookingFinishedRating = ({changeHandler, state, handleRating}: iBookingRating) => {
    const [ratingValue, setRatingValue] = useState(state?.rating ?? 0)
    const handleRatingLocal = (rate: number) => {
        setRatingValue(rate)
        handleRating(rate)
    }

    return (
        <div className="d-flex flex-column">
            <label htmlFor="rating">{I18N('rating', 'de')}</label>
            <Rating ratingValue={ratingValue} onClick={handleRatingLocal} fillColor="#5B7ADA"/>
            <span className="mb-2">
                {I18N('description', 'de')}
            </span>
            <textarea className={classes.input} onChange={changeHandler} value={state?.message} name="message"
                      id="message"
                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
            <span
                className="d-flex justify-content-end mb-2">
                {I18N('max_characters', 'de')}
                250 !</span>

        </div>
    )
}

export const PorfileModalDescription = ({changeHandler, state}: any) => {
    return (
        <>
            <label htmlFor="description" className="mb-2">
                {I18N('enter_description', 'de', [{
                    key: 'enter_description',
                    value: I18N('enter_description', 'de')
                }])}
            </label>
            <textarea className={classes.input} onChange={changeHandler} value={state.description}
                      name="description"
                      id="description"
                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={200}/>
            <span
                className="d-flex justify-content-end">
                {I18N('max_characters', 'de', [{
                    key: 'max_characters',
                    value: I18N('max_characters', 'de')
                }])}
                200 !</span>
        </>
    )
}

export const ProfileCalendarDescription = ({state, changeHandler}: iSendCalendarRequest) => {
    return (
        <>
            <Col xl={12}>
                <textarea className={classes.input} onChange={changeHandler} value={state?.message} name="message"
                          style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
                <span
                    className="d-flex justify-content-end mb-2">
                    {I18N('max_characters', 'de', [{
                        key: 'max_characters',
                        value: I18N('max_characters', 'de')
                    }])}
                    250 !
                </span>
            </Col>
        </>
    );
}

export const CreateTicketModal = ({state, changeHandler}: iCreateTicket) => {
    return (
        <>
            <Col xl={12}>
                <label htmlFor="title">Title</label>
                <input type="text" name="title" value={state?.title} onChange={changeHandler}
                       className={classes.input}/>
            </Col>
            <Col xl={12} className="mt-2">
                <label htmlFor="description">Description</label>
                <textarea className={classes.input} onChange={changeHandler} value={state?.description}
                          name="description"
                          style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
                <span
                    className="d-flex justify-content-end mb-2">
                    {I18N('max_characters', 'de', [{
                        key: 'max_characters',
                        value: I18N('max_characters', 'de')
                    }])}
                    250 !
                </span>
            </Col>
            <Col xl={12}>
                <label htmlFor="category">Select category</label>
                <select name="category" id="category" onChange={changeHandler} value={state?.category}>
                    <option value="LOGIN">LOGIN</option>
                    <option value="PROFILE">PROFILE</option>
                    <option value="BOOKING">BOOKING</option>
                    <option value="CHAT">CHAT</option>
                    <option value="PAYMENT">PAYMENT</option>
                    <option value="OTHER">OTHER</option>
                </select>
            </Col>
        </>
    )
}

export const ProfileCalendarEvent = ({changeHandler, state, selectLocation, timeHandler, userType}: iCalendarBooking) => {

    const [userCoordinates, setUserCoordinates] = useState<iCategoryDTO[]>();
    const initialDate = state?.startDate ? dayjs(state?.startDate).format("DD.MM.YYYY") : dayjs().format("YYYY-MM-DD")

    const changeTimeHandler = (event: any) => {
        const date = dayjs(initialDate + event.target.value).toISOString();
        timeHandler(date)
    }

    const locationSelectStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            background: '#fff',
            borderRadius: '10px !important',
            border: '2px solid #fff',
            width: '100%',
            height: '50px',
            paddingLeft: ".5rem"
        }),
        valueContainer: (baseStyles)=> ({
            ...baseStyles,
            padding: '2px 0px'
        }),
        placeholder: (baseStyles) => (
            {
                ...baseStyles,
                color: '#6e6e6e',
                textTransform: 'uppercase',
                opacity: '0.38',
                textAlign: 'left !important'
            }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'rgb(16, 15, 15)',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'black'
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            color: 'rgb(16, 15, 15)'
        })
    }

    return (
        <>
            {
                userType === "ARTIST" ? (
                    <>
                        <Col xl={8} className="my-2 d-flex align-items-center justify-content-between">
                            {initialDate}
                        </Col>
                    </>
                ) : (
                    <>
                        <Col xl={4} className="mb-2 d-flex align-items-center">
                            <label htmlFor="startDate">
                                {I18N('this_is_startDate', 'de', [{
                                    key: 'this_is_startDate',
                                    value: I18N('this_is_startDate', 'de')
                                }])}:
                            </label>
                        </Col>
                        <Col xl={8} className="my-2 d-flex align-items-center justify-content-between">
                            {initialDate}
                            <input type="time" className={`${classes.input} w-50`}
                                   onChange={changeTimeHandler} value={moment(state?.startDate).format('HH:mm') || ""}/>
                        </Col>
                        <Col xl={4} className="mt-2 mb-2">
                            <label htmlFor="nameOfEvent">
                                {I18N('name_of_event', 'de', [{
                                    key: 'name_of_event',
                                    value: I18N('name_of_event', 'de')
                                }])}
                            </label>
                        </Col>
                        <Col xl={8} className="mb-2 d-flex align-items-center">
                            <input type="text" className={classes.input} name="nameOfEvent" onChange={changeHandler}
                                   value={state?.nameOfEvent || ""} id="nameOfEvent"/>
                        </Col>

                        <Col xl={4} className="mt-2 mb-2">
                            <label htmlFor="description">
                                {I18N('enter_description', 'de', [{
                                    key: 'enter_description',
                                    value: I18N('enter_description', 'de')
                                }])}
                            </label>
                        </Col>
                        <Col xl={8} className="d-flex flex-column mb-2">
                            <textarea className={classes.input} onChange={changeHandler}
                                      value={state?.description || ""}
                                      name="description"
                                      id="description"
                                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}
                            />
                            <span className="d-flex mt-2 justify-content-end">
                                {I18N('max_characters', 'de', [{
                                    key: 'max_characters',
                                    value: I18N('max_characters', 'de')
                                }])} 250 !
                            </span>
                        </Col>
                        <Col xl={4} className="mt-2 mb-2">
                            <label htmlFor="locationId">
                                {I18N('enter_location_event', 'de', [{
                                    key: 'enter_location_event',
                                    value: I18N('enter_location_event', 'de')
                                }])}
                            </label>
                        </Col>
                        <Col xl={8} className="mb-2">
                            <LocationSelectField
                                presettedValue={(state?.locationId && state?.locationId > 0) ? state?.locationId : undefined}
                                handleLocation={selectLocation} selectStyles={locationSelectStyles}/>
                        </Col>
                    </>

                )
            }

        </>
    )
}

export const ProfileSocialNetwork = ({changeHandler, link, name, selectedNetwork}: iSocialNetwork) => {

    return (
        <>
            <label htmlFor="link" className="mb-1">
                {I18N('enter_social_link', 'de', [{
                    key: 'enter_social_link',
                    value: I18N('enter_social_link', 'de')
                }])}:
            </label>
            <input className={classes.input} onChange={changeHandler} value={link} name="link" id="link"
                   type="text"/>
            <label htmlFor="name" className="mt-2 mb-1">
                Wie soll der Link heißen?
            </label>


            <input type="text" value={name} name="name" id="name" onChange={changeHandler} maxLength={30}
                   className={classes.input}/>
            <span className="d-flex justify-content-end mt-2">
                {I18N('max_characters', 'de', [{
                    key: 'max_characters',
                    value: I18N('max_characters', 'de')
                }])} 30 !
            </span>
        </>
    );
}

export const ProfileGallery = (
    {
        changeHandler, refHandler
    }
        : iAddPhotoToGallery) => {
    return (
        <>
            <label htmlFor="photos" className="mb-3">
                {I18N('upload_photos', 'de', [{
                    key: 'upload_photos',
                    value: I18N('upload_photos', 'de')
                }])}
            </label>
            <input type="file" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG " onChange={changeHandler} name="photos"
                   id="photos" multiple ref={refHandler}/>
        </>
    )
}

export const ProfilePicture = ({changeHandler, refHandler}: iUploadProfile) => {
    return (
        <>
            <label htmlFor="picture" className="mb-3">
                {I18N('change_profile_picture', 'de', [{
                    key: 'change_profile_picture',
                    value: I18N('change_profile_picture', 'de')
                }])}
            </label>
            <input type="file" onChange={changeHandler} ref={refHandler} name="picture" id="picture"/>
        </>
    )
}

export const CompanyLogo = ({changeHandler, refHandler}: iUploadProfile) => {
    return (
        <>
            <label htmlFor="picture" className="mb-3">
                {I18N('change_profile_picture', 'de', [{
                    key: 'change_profile_picture',
                    value: I18N('change_profile_picture', 'de')
                }])}
            </label>
            <input type="file" onChange={changeHandler} ref={refHandler} name="picture"/>
        </>
    )
}

export const SearchBoxInbox = (
    {
        changeHandler
    }
        : iFilterInboxMessage) => {
    return (
        <>
            <input className={`${classes.input} responsive-input`} onChange={changeHandler}
                   placeholder="Suche einen Chat…" maxLength={30}
                   type="text"/>
        </>
    )
}

export const ProfileModalPrice = ({changeHandler, state, changeHandlerNumber}: any) => {


    return (
        <>
            <Col xl={6} className="p-2">
                <label htmlFor="pricePerEvent" className="mb-2 ">
                    {I18N('price_per_event', 'de')}
                </label>
            </Col>
            <Col xl={6} className="p-2">
                <input className={classes.input} onChange={changeHandlerNumber} value={state?.pricePerEvent?.toString()}
                       name="pricePerEvent" id="pricePerEvent"
                       maxLength={30} min={0}
                       type="number"/>
            </Col>
            <Col xl={6} className="p-2">
                <label htmlFor="pricePerHour" className="mb-2 ">
                    {I18N('price_per_hour', 'de')}
                </label>
            </Col>
            <Col xl={6} className="p-2">
                <input className={classes.input} onChange={changeHandlerNumber} value={state?.pricePerHour?.toString()}
                       name="pricePerHour"
                       id="pricePerHour" maxLength={30}
                       type="number"/>
            </Col>
            <Col xl={6} className="p-2">
                <label htmlFor="priceDescription"
                       className="mb-2 ">{I18N('priceDescription', 'de')}:</label>
            </Col>
            <Col xl={6} className="p-2">
                <textarea className={classes.input} name="priceDescription" onChange={changeHandler}
                          value={state?.priceDescription}
                          id="priceDescription"
                          style={{minHeight: '100px'}} maxLength={150}/>
            </Col>
            <span className="d-flex justify-content-end">
                {I18N('max_characters', 'de', [{
                    key: 'max_characters',
                    value: I18N('max_characters', 'de')
                }])} 150 !
            </span>
        </>
    );
}
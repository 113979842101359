import {Container} from "react-bootstrap";

export function Impressum() {
    return (
        <div className="static-page py-5">
            <Container>
                <h3>
                    Impressum
                </h3>
                <p>Angaben gemäß § 5 TMG</p>
                <p>Kemal Fathulla Mustapha</p>
                <p>KFM- Digital Minds</p>
                <p>Anna-Birle-Str.9</p>
                <p>55252 Mainz-Kastel</p>
                <p className="fw-bold mt-5">Kontakt:</p>
                <p>
                    <span className="fw-bold me-1">Telefon:</span>
                    017673550100
                </p>
                <p>
                    <span className="fw-bold me-1">E-Mail:</span>
                    kemal.f@kfm-digitalminds.de
                </p>
                <p className="fw-bold mt-5">Berufsbezeichnung und berufsrechtliche Regelungen</p>
                <p>Berufsbezeichnung: Softwareentwickler</p>
                <p>Zuständige Kammer: Industrie und Handelskammer Wiesbaden</p>
                <p>Verliehen durch: Deutschland</p>
                <p>Es gelten folgende berufsrechtliche Regelungen:</p>
                <p className="fw-bold mt-5">
                    Verbraucherstreitbeilegung/ Universalschlichtungsstelle
                </p>
                <p>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                </p>
                <p>
                    Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <p className="fw-bold mt-5">
                    Haftung für Inhalte
                </p>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                    Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
                    Inhalte umgehend entfernen.
                </p>
                <p className="fw-bold mt-5">
                    Urheberrecht
                </p>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                    Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
                    die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                    umgehend entfernen.
                </p>
            </Container>
        </div>
    )
}
import {Button, Col, Container, Image, Modal, Row} from "react-bootstrap";
import LoadingPage from "../../Information/LoadingPage";
import classes from "../../../components/Styles/ProfileSearch.module.scss";
import styles from "./ProfileAdditionalInformation.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import {SaveChangeButton} from "../../../components/Buttons/Buttons";
import {
    addCompanyLogo,
    getBankInfo,
    getCompanyData,
    updateBankInfo,
    updateBankInfoFirstTime,
    updateCompanyData
} from "../../../Helper/API";
import {CompanyLogo} from "../ProfileModals/ProfileModals";
import {iBankInfo, iUserProfilePicture} from "../Profile/Profile";
import {apiSuccessHandler} from "../../../Helper/api.services";
import {useParams} from "react-router-dom";
import {
    BankFillIcon,
    BankWhiteIcon, CashIcon, InfoFillIcon,
    NumbersIcon,
    Pencil,
    PhotoUploadIcon

} from "../../../components/Icons/Icons";

import cardClass from "../../../components/Styles/ProfileCards.module.scss";

export interface iCompanyInfo {
    street: string,
    taxNumber: string,
    vat: boolean,
    logo: string
}

export enum PaymentTypeEnum {
    MINUS = "MINUS",
    PLUS = "PLUS"
}

export interface iPaymentManipulation {
    amount: number,
    type?: PaymentTypeEnum
}

export interface iStripePayment {
    paymentIntent?: string,
    description?: string
}

export function ProfileAdditionalInformation() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [usePut, setUsePut] = useState<boolean>(false);
    const [openBankInfoModal, setOpenBankInfoModal] = useState<boolean>(false);
    const [showCompanyInformation, setShowCompanyInformation] = useState<boolean>(false);
    const [showBankInfo, setShowBankInfo] = useState<boolean>(false);
    const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
    const [isMyProfile, setIsMyProfile] = useState(false);
    const [companyInfo, setCompanyInfo] = useState<iCompanyInfo>({
        street: '',
        taxNumber: '',
        vat: false,
        logo: ''
    })
    const [bankInfo, setBankInfo] = useState<iBankInfo>({
        IBAN: '',
        BIC: '',
        bankName: ''
    });

    const [newProfilePicture, setNewProfilePicture] = useState<iUserProfilePicture>();
    const inputRef = useRef<HTMLInputElement>()
    const {id} = useParams<{id: string}>() ?? '0';


    function handlerChange(event: any): void {
        setNewProfilePicture(prev => ({...prev, [event.target.name]: event.target.files}));
    }

    function handleInfo(event: any) {
        setCompanyInfo(prevState => ({...prevState, [event.target.name]: event.target.value}))
        if (event.target.value === 'true' && event.target.name === 'vat') {
            setCompanyInfo(prevState => ({...prevState, [event.target.name]: true}))
        } else if (event.target.value === 'false' && event.target.name === 'vat') {
            setCompanyInfo(prevState => ({...prevState, [event.target.name]: false}))
        }
    }

    function handleBankInfo(event: any) {
        setBankInfo(prevState => ({...prevState, [event.target.name]: event.target.value}))
    }

    async function onNewUserPhoto(): Promise<void> {
        if (!inputRef?.current?.files) return
        const data = new FormData();
        for (let file in inputRef.current.files) {
            data.append('picture', inputRef.current.files[file])
        }
        addCompanyLogo(data!).then(res => {
            if (!res.data) {
                setNewProfilePicture(res.picture);
                apiSuccessHandler(
                    <>
                        {I18N('change_picture_success', 'de', [{
                            key: 'change_picture_success',
                            value: I18N('change_picture_success', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error);
    }

    async function updatePaymentInfo(): Promise<void> {
        if (!usePut) {
            updateBankInfo(bankInfo!).then(res => {
                if (res) {
                    apiSuccessHandler('Success')
                }
            }).catch(error => error);
        } else {
            updateBankInfoFirstTime(bankInfo!).then(res => {
                if (res) {
                    apiSuccessHandler('Success')
                }
            }).catch(error => error);
        }
    }

    const wrapperFunction = () => {
        onNewUserPhoto();
        setShow(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getCompanyData(+id).then(res => {
            setCompanyInfo({
                street: res?.street,
                taxNumber: res?.taxNumber,
                vat: res?.vat,
                logo: res?.logo
            })
            setIsMyProfile(res.isMyProfile)
            setIsLoading(true);
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [show])

    useEffect(() => {
        setIsLoading(true);
        getBankInfo().then(res => {
            if (res.length === 0) {
                setUsePut(true)
            } else {
                setUsePut(false)
            }
            setBankInfo(res)
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [showBankInfo])

    const updateData = () => {
        updateCompanyData(companyInfo!).then(res => {
            if (res) {
                apiSuccessHandler(
                    <>
                        {I18N('change_info_success', 'de', [{
                            key: 'change_info_success',
                            value: I18N('change_info_success', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error);
    }

    let logoPicture = '';

    if (!companyInfo?.logo) {
        logoPicture = '/user.jpg';
    } else {
        logoPicture = companyInfo?.logo
    }


    return (
        <div className={classes.additionalInfo}>
            {isLoading && <LoadingPage/>}
            <div className={`${styles.header}`}>
                <Container className={(isLoading ? 'filter-blur' : 'mx-4')}>
                    <h3 className="headline-title">
                        {I18N('company_information', 'de')}
                    </h3>
                </Container>
            </div>

            <Container className={`${isLoading ? 'filter-blur' : ''} py-3`}>
                <div className={`${classes.company_logo}`}>
                    <img src={logoPicture} className={classes.user_profile_picture} alt="Company logo"/>
                    <div className={classes.photoIcon} onClick={() => setShow(true)}>
                        <PhotoUploadIcon/>
                    </div>
                </div>
                <Row>
                    <Col lg={6} md={12} className={styles.info_wrapper + " mt-3 mt-xl-0"}>
                        <Row className={`${cardClass.box_shadow} p-0`}>
                            <Col xl={12}
                                 className={`${cardClass.bg_secondary} d-flex align-items-center justify-content-center`}>
                                <BankWhiteIcon/>
                                <h2 className={`${cardClass.card_title} ms-1`}>
                                    {I18N('general_information', 'de')}
                                </h2>
                                {isMyProfile &&
                                <div onClick={() => setShowCompanyInformation(true)}
                                     className="ms-3 cursor_pointer">
                                    <Pencil/>
                                </div>
                                }
                                <div onClick={() => setOpenInfoModal(true)}>
                                    <InfoFillIcon/>
                                </div>
                            </Col>

                            <Col xl={12} className="my-4 d-flex align-items-center justify-content-center">
                                <Row className="align-items-center w-100">
                                    <Col xl={12} className={`py-3`}>
                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <Image src="/place-icon.svg" alt="Place icon"/>
                                                <span className={cardClass.in_card_title}>
                                                    {I18N('enter_street', 'de')}
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title} user-location-responsive fw-bold`}>
                                                {companyInfo?.street}
                                            </span>
                                        </div>

                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <NumbersIcon/>
                                                <span className={cardClass.in_card_title}>
                                                    {I18N('enter_tax', 'de')}
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title} fw-bold`}>
                                               {companyInfo?.taxNumber}
                                            </span>
                                        </div>

                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <CashIcon/>
                                                <span
                                                    className={`${cardClass.in_card_title} user-location-responsive text-start`}>
                                                    {I18N('are_you_vat', 'de')}
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title} user-location-responsive fw-bold`}>
                                                {companyInfo?.vat ? <>{I18N('yes')}</> : <>{I18N('no')}</>}
                                            </span>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col lg={6} md={12} className={styles.info_wrapper + " mt-3 mt-xl-0"}>
                        <Row className={`${cardClass.box_shadow} p-0`}>
                            <Col xl={12}
                                 className={`${cardClass.bg_secondary} d-flex align-items-center justify-content-center`}>
                                <BankWhiteIcon/>
                                <h2 className={`${cardClass.card_title} ms-1`}>
                                    {I18N('payment_information', 'de')}
                                </h2>
                                {isMyProfile &&
                                <div onClick={() => setShowBankInfo(true)} className="ms-3 cursor_pointer">
                                    <Pencil/>
                                </div>
                                }
                                <div onClick={() => setOpenBankInfoModal(true)}>
                                    <InfoFillIcon/>
                                </div>
                            </Col>

                            <Col xl={12} className="my-4 d-flex align-items-center justify-content-center">
                                <Row className="align-items-center w-100">
                                    <Col xl={12} className={`py-3`}>
                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <BankFillIcon/>
                                                <span className={` ms-1 ${cardClass.in_card_title}`}>
                                                    IBAN
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title} fw-bold`}>
                                                {bankInfo?.IBAN?.length === 0 ? '-' : bankInfo?.IBAN}
                                            </span>
                                        </div>

                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <BankFillIcon/>
                                                <span className={` ms-1 ${cardClass.in_card_title}`}>
                                                    BIC
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title}  fw-bold`}>
                                               {bankInfo?.BIC?.length === 0 ? '-' : bankInfo?.BIC}
                                            </span>
                                        </div>

                                        <div className={styles.info}>
                                            <div className="d-flex align-items-center">
                                                <BankFillIcon/>
                                                <span
                                                    className={`${cardClass.in_card_title} ms-1 text-start`}>
                                                    {I18N('bank_name', 'de')}
                                                </span>
                                            </div>
                                            <span
                                                className={`${cardClass.in_card_title} user-location-responsive fw-bold`}>
                                                {bankInfo?.bankName?.length === 0 ? '-' : bankInfo?.bankName}
                                            </span>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
            <Modal show={show} size="lg" onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('change_profile_picture', 'de', [{
                            key: 'change_profile_picture',
                            value: I18N('change_profile_picture', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <CompanyLogo changeHandler={handlerChange} refHandler={inputRef}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <SaveChangeButton clickHandler={wrapperFunction}/>
                </Modal.Footer>
            </Modal>

            <Modal show={openInfoModal} size="lg" centered onHide={() => setOpenInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('general_information', 'de')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam architecto, atque
                                blanditiis consequatur consequuntur delectus doloribus eaque, facilis mollitia natus
                                neque nostrum pariatur provident quod rem repellat repudiandae, similique voluptas?
                                Alias consequatur dolore earum, eius et nobis tempore velit.
                            </p>
                            <p>
                                A animi autem, debitis
                                delectus dignissimos distinctio earum exercitationem illum ipsam maiores nihil
                                perspiciatis quam qui saepe sunt ut veniam veritatis! Ab ad aliquam aperiam at cum dicta
                                dignissimos eaque harum, in incidunt iste itaque iure libero, mollitia nam natus non
                                officia omnis optio possimus quasi, quisquam quos repudiandae saepe similique?
                            </p>

                            <p>
                                A ab,
                                aliquam amet aperiam consequuntur corporis cumque dicta dolorum eaque earum expedita
                                explicabo, harum in ipsam laboriosam laudantium nam odio officiis optio quo, tempora
                                tenetur totam vel vero vitae! Ad asperiores aspernatur, aut ducimus ea earum error
                                explicabo facilis fugiat ipsum laborum maxime natus nostrum, officiis possimus quas quo
                                sequi sit, temporibus vel. Aspernatur at iusto odit quae qui.
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenInfoModal(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={openBankInfoModal} size="lg" centered onHide={() => setOpenBankInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('payment_information', 'de')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam architecto, atque
                                blanditiis consequatur consequuntur delectus doloribus eaque, facilis mollitia natus
                                neque nostrum pariatur provident quod rem repellat repudiandae, similique voluptas?
                                Alias consequatur dolore earum, eius et nobis tempore velit.
                            </p>
                            <p>
                                A animi autem, debitis
                                delectus dignissimos distinctio earum exercitationem illum ipsam maiores nihil
                                perspiciatis quam qui saepe sunt ut veniam veritatis! Ab ad aliquam aperiam at cum dicta
                                dignissimos eaque harum, in incidunt iste itaque iure libero, mollitia nam natus non
                                officia omnis optio possimus quasi, quisquam quos repudiandae saepe similique?
                            </p>

                            <p>
                                A ab,
                                aliquam amet aperiam consequuntur corporis cumque dicta dolorum eaque earum expedita
                                explicabo, harum in ipsam laboriosam laudantium nam odio officiis optio quo, tempora
                                tenetur totam vel vero vitae! Ad asperiores aspernatur, aut ducimus ea earum error
                                explicabo facilis fugiat ipsum laborum maxime natus nostrum, officiis possimus quas quo
                                sequi sit, temporibus vel. Aspernatur at iusto odit quae qui.
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenBankInfoModal(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showBankInfo} size="lg" onHide={() => setShowBankInfo(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('payment_information', 'de')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <label htmlFor="IBAN" className="pb-2">
                                IBAN
                            </label>
                            <input type="text" name="IBAN" value={bankInfo?.IBAN} onChange={handleBankInfo}
                                   className={classes.modalInput}/>
                        </Col>
                        <Col xl={12} className="mt-3">
                            <label htmlFor="BIC" className="pb-2">
                                BIC
                            </label>
                            <input type="text" name="BIC" value={bankInfo?.BIC}
                                   onChange={handleBankInfo}
                                   className={classes.modalInput}/>
                        </Col>
                        <Col xl={12} className="mt-3">
                            <label htmlFor="bankName" className="pb-2">
                                {I18N('bank_name', 'de')}
                            </label>
                            <input type="text" name="bankName" value={bankInfo?.bankName}
                                   onChange={handleBankInfo}
                                   className={classes.modalInput}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{borderRadius: '10px', height: '45px'}}
                            onClick={() => setShowBankInfo(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <button className={classes.yes_button} onClick={() => {
                        updatePaymentInfo();
                        setShowBankInfo(false)
                    }}>
                        {I18N('save', 'de', [{
                            key: 'save',
                            value: I18N('save', 'de')
                        }])}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCompanyInformation} size="lg" onHide={() => setShowCompanyInformation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('general_information', 'de')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <label htmlFor="street" className="pb-2">
                                {I18N('enter_street', 'de', [{
                                    key: 'enter_street',
                                    value: I18N('enter_street', 'de')
                                }])}
                            </label>
                            <input type="text" name="street" value={companyInfo?.street} onChange={handleInfo}
                                   className={classes.modalInput}/>
                        </Col>
                        <Col xl={12} className="mt-3">
                            <label htmlFor="taxNumber" className="pb-2">
                                {I18N('enter_tax', 'de', [{
                                    key: 'enter_tax',
                                    value: I18N('enter_tax', 'de')
                                }])}
                            </label>
                            <input type="text" name="taxNumber" value={companyInfo?.taxNumber}
                                   onChange={handleInfo}
                                   className={classes.modalInput}/>
                        </Col>
                        <Col xl={12} className="mt-3">
                            <label htmlFor="vat" className="pb-2">
                                {I18N('are_you_vat', 'de', [{
                                    key: 'are_you_vat',
                                    value: I18N('are_you_vat', 'de')
                                }])}
                            </label>
                            <select name="vat" className="mt-0" onChange={handleInfo}
                                    defaultValue=''>
                                <option
                                    value={companyInfo?.vat ? 'true' : 'false'} hidden>
                                    {companyInfo?.vat ? 'Jawohl' : 'Nein'}</option>
                                <option value="false">
                                    {I18N('no', 'de', [{
                                        key: 'no',
                                        value: I18N('no', 'de')
                                    }])}
                                </option>
                                <option value="true">
                                    {I18N('yes', 'de', [{
                                        key: 'yes',
                                        value: I18N('yes', 'de')
                                    }])}
                                </option>
                            </select>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{borderRadius: '10px', height: '45px'}}
                            onClick={() => setShowCompanyInformation(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <button className={classes.yes_button} onClick={() => {
                        updateData();
                        setShowCompanyInformation(false)
                    }}>
                        {I18N('save', 'de', [{
                            key: 'save',
                            value: I18N('save', 'de')
                        }])}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
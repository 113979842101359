import English from './packs/en.json';
import German from './packs/de.json';

export const languages = {
    en: English,
    de: German,
};

interface KeywordType {
    value: string;
    key: string;
}


export function I18N(key: string, language?: 'de' | 'en', keywords?: KeywordType[]): string {
    // @ts-ignore
    let i18nText: string = languages[getLanguage()][key] || key;
    if (!i18nText) {
        console.warn(`${language} : '${key}' is missing`);
    }
    //Converting {keyword vals}
    if (keywords !== undefined) {
        keywords.forEach((keyword) => {
            i18nText = i18nText.replaceAll(`{${keyword.key}}`, keyword.value);
        });
    } else {
        i18nText = i18nText.replaceAll(`\{[.*]}`, '');
    }
    return i18nText;
}

export function getLanguage(){
    const savedLanguage = localStorage.getItem('language')
    return savedLanguage !== null ? savedLanguage : 'de'
}
import {Button, Col, Container, Modal, Row, Table} from "react-bootstrap";
import classes from "../../components/Styles/ProfileAdmin.module.scss"
import {
    BookingIssueIcon,
    ChatIssueIcon, ClipboardDataIcon,
    LoginIssueIcon,
    OtherIssueIcon,
    PaymentIssueIcon,
    UserIssueIcon
} from "../../components/Icons/Icons";
import {I18N} from "../../i18n/i18n";
import React, {useEffect, useMemo, useState} from "react";
import {
    addBookingOccasion,
    addMusicCategory, addNewAdmin,
    addNewSupportComment, addPortfolioJob,
    changeSupportStatus, deleteAdmin, deleteBookingOccasion,
    deleteMusicCategory, deletePortfolioJob, getAdminStatistics, getAllAdmins, getAllJobs,
    getAllMusicCategory, getBookingEvents,
    getSupportTicket,
    getSupportTicketID
} from "../../Helper/API";
import LoadingPage from "../Information/LoadingPage";
import {apiErrorHandler, apiSuccessHandler, apiWarningHandler} from "../../Helper/api.services";
import {CalendarConfirmButton, SaveChangeButton} from "../../components/Buttons/Buttons";
import {CreateAdminModal, iSupportStatus, SupportStatusEnum} from "./ProfileModals/ProfileModals";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"
import CountUp from "react-countup";

interface iFilters {
    category?: string,
    status?: string
    order?: string
}

export interface iAdminData {
    roles?: string,
    createdAt?: string,
    email?: string,
    password?: string,
    firstName?: string,
    id?: number,
    lastName?: string,
    type?: string
}

export function ProfileAdmin() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openedIssues, setOpenedIssues] = useState<any>([]);
    const [filteredIssues, setFilteredIssues] = useState<any>([]);
    const [filteredState, setFilteredState] = useState<iFilters>();
    const [show, setShow] = useState<boolean>(false);
    const [show1, setShow1] = useState<boolean>(false);
    const [show2, setShow2] = useState<boolean>(false);
    const [show3, setShow3] = useState<boolean>(false);
    const [show4, setShow4] = useState<boolean>(false);
    const [show5, setShow5] = useState<boolean>(false);
    const [show6, setShow6] = useState<boolean>(false);
    const [show7, setShow7] = useState<boolean>(false);
    const [clickedTicket, setClickedTicket] = useState<any>();
    const [admins, setAdmins] = useState<iAdminData[]>([{
        createdAt: '',
        email: '',
        firstName: '',
        id: 0,
        lastName: '',
        type: ''
    }]);

    const [newAdmin, setNewAdmin] = useState<iAdminData>({
        password: '',
        type: 'ADMIN',
        roles: 'ADMIN',
        firstName: '',
        lastName: '',
        email: ''
    })

    const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);
    const [commentState, setCommentState] = useState({
        ticketId: 0,
        message: ''
    });
    const [ticketStatus, setTicketStatus] = useState<iSupportStatus>({
        status: SupportStatusEnum.REQUESTED
    });
    const [finishTicket, setFinishTicket] = useState<iSupportStatus>({
        status: SupportStatusEnum.FINISHED
    });

    const [cancelTicket, setCancelTicket] = useState<iSupportStatus>({
        status: SupportStatusEnum.CANCELED
    })

    const [category, setCategory] = useState([{
        id: 0,
        name: ''
    }])

    const [jobs, setJobs] = useState([{
        id: 0,
        name: ''
    }])

    const [bookingEvents, setBookingEvents] = useState([{
        id: 0,
        name: ''
    }])


    const [musicCategory, setMusicCategory] = useState({
        name: ''
    })

    const [jobCategory, setJobCategory] = useState({
        name: ''
    })

    const [bookingCategory, setBookingCategory] = useState({
        name: ''
    })

    const [adminStatistic, setAdminStatistic] = useState<any>();

    const scrollTo = document.getElementById('displayedMessages')
    const isAdmin = localStorage.getItem('type') === 'ADMIN';
    const sortDescending = (prev: any, current: any) => current.id - prev.id
    const sortAscending = (prev: any, current: any) => prev.id - current.id
    const handleMessage = (event: any) => {
        setCommentState(prev => ({...prev, [event.target.name]: event.target.value, ticketId: clickedTicket?.id}))
    }

    const handleCategory = (event: any) => {
        setMusicCategory(prev => ({...prev, [event.target.name]: event.target.value}));
        setNewAdmin(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    const handleJob = (event: any) => {
        setJobCategory(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    const handleEvent = (event: any) => {
        setBookingCategory(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    useEffect(() => {
        setIsLoading(true);
        getAllMusicCategory().then(data => {
            setCategory(data);
            setIsLoading(true);
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [JSON.stringify(category)])

    useEffect(() => {
        setIsLoading(true);
        getAllJobs().then(res => {
            setJobs(res)
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [JSON.stringify(jobs)])

    useEffect(() => {
        setIsLoading(true);
        getBookingEvents().then(res => {
            setBookingEvents(res)
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [JSON.stringify(bookingEvents)])

    useEffect(() => {
        setIsLoading(true);
        getSupportTicket().then(data => {
            setOpenedIssues(data);
            setFilteredIssues(data);
            setIsLoading(true);
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [show, show4])

    useEffect(() => {
        if (scrollTo) {
            scrollTo.scrollTop = scrollTo.scrollHeight;
        }
    }, [clickedTicket])

    const getTicketID = (id: number) => {
        getSupportTicketID(id).then(data => {
            setClickedTicket(data);
            setShow4(true);
        }).catch(error => error);
    }

    const filterIssues = (event: any) => {
        setFilteredState(prevState => ({...prevState, status: event.target.value}))
    }

    const filterType = (event: any) => {
        setFilteredState(prevState => ({...prevState, category: event.target.value}))
    }

    const clearAllFilters = () => {
        setFilteredState(openedIssues);
    }

    const filterStateCallback = () => {
        if (filteredState?.category && filteredState?.status) {
            const filter = openedIssues.filter((issue: any) => issue.category === filteredState.category && issue.status === filteredState.status)
                .sort(filteredState?.order !== 'descending' ? sortAscending : sortDescending);
            setFilteredIssues(filter)
        }
        if (filteredState?.category && !filteredState?.status) {
            const filter = openedIssues.filter((issue: any) => issue.category === filteredState.category).sort(filteredState?.order !== 'descending' ? sortAscending : sortDescending);
            setFilteredIssues(filter)
        }
        if (!filteredState?.category && filteredState?.status) {
            const filter = openedIssues.filter((issue: any) => issue.status === filteredState.status).sort(filteredState?.order !== 'descending' ? sortAscending : sortDescending);
            setFilteredIssues(filter)
        }
        if (!filteredState?.category && !filteredState?.status) {
            setFilteredIssues(openedIssues.sort(filteredState?.order !== 'descending' ? sortAscending : sortDescending));
        }
    }

    useMemo(() => {
        filterStateCallback()
    }, [filteredState])

    const sortOrderIssues = (event: any) => {
        setFilteredState(prevState => ({...prevState, order: event.target.value}))
    }

    async function addTicketComment(): Promise<void> {
        if (showMessageDialog) {
            if (clickedTicket?.status === "REQUESTED" && isAdmin) {
                changeSupportStatus(ticketStatus, clickedTicket?.id).then(res => {
                    if (res) {
                        apiSuccessHandler(
                            <>
                                {I18N('success_change_ticket_inProgress', 'de', [{
                                    key: 'success_change_ticket_inProgress',
                                    value: I18N('success_change_ticket_inProgress', 'de')
                                }])}
                            </>
                        )
                    }
                }).catch(error => error);
            }
            addNewSupportComment(commentState).then(res => {
                if (res) {
                    apiSuccessHandler(
                        <>
                            {I18N('success_add_ticket_comment', 'de', [{
                                key: 'success_add_ticket_comment',
                                value: I18N('success_add_ticket_comment', 'de')
                            }])}
                        </>
                    )
                    getTicketID(commentState?.ticketId);
                    setCommentState({
                        ticketId: 0,
                        message: ''
                    });
                }
            }).catch(error => apiErrorHandler(error));
            setShowMessageDialog(false);
        } else {
            setShowMessageDialog(true)
            if (isAdmin) {
                setTicketStatus(prev => ({...prev, status: SupportStatusEnum.IN_PROGRESS}));
            }
        }
    }

    async function changeStatusFinish(): Promise<void> {
        changeSupportStatus(finishTicket, clickedTicket?.id).then(res => {
            if (res) {
                apiSuccessHandler(
                    <>
                        {I18N('success_change_ticket_finished', 'de', [{
                            key: 'success_change_ticket_finished',
                            value: I18N('success_change_ticket_finished', 'de')
                        }])}
                    </>
                )
                setShow(false);
            }
        })
    }

    async function changeStatusCancel(): Promise<void> {
        changeSupportStatus(cancelTicket, clickedTicket?.id).then(res => {
            if (res) {
                apiSuccessHandler(
                    <>
                        {I18N('success_change_ticket_canceled', 'de', [{
                            key: 'success_change_ticket_canceled',
                            value: I18N('success_change_ticket_canceled', 'de')
                        }])}
                    </>
                )
                setShow(false);
            }
        })
    }

    async function createNewAdmin(): Promise<void> {
        addNewAdmin(newAdmin).then(res => {
                if (res) {
                    apiSuccessHandler(
                        <>
                            {I18N('success_create_admin', 'de', [{
                                key: 'success_create_admin',
                                value: I18N('success_create_admin', 'de')
                            }])}
                        </>
                    )
                    setAdmins(res);
                    setNewAdmin({
                        password: '',
                        type: 'ADMIN',
                        roles: 'ADMIN',
                        firstName: '',
                        lastName: '',
                        email: ''
                    })
                }
            }
        ).catch(error => error)
    }

    const createBookingEvent = () => {
        if (bookingCategory?.name?.trim().length > 1) {
            addBookingOccasion(bookingCategory).then(res => {
                if (res) {
                    setBookingCategory(prev => ({...prev, name: ''}));
                    apiSuccessHandler(
                        <>
                            {I18N('success_create_event', 'de', [{
                                key: 'success_create_event',
                                value: I18N('success_create_event', 'de')
                            }])}
                        </>
                    )
                }
            }).catch(error => error).finally(() => {
                getBookingEvents().then(res => {
                    setBookingEvents(res);
                }).catch(error => error);
            })
        } else {
            apiWarningHandler(
                <>
                    {I18N('at_least_2', 'de', [{
                        key: 'at_least_2',
                        value: I18N('at_least_2', 'de')
                    }])}
                </>
            )
        }
    }

    const createJob = () => {
        if (jobCategory?.name.trim().length > 1) {
            addPortfolioJob(jobCategory).then(res => {
                if (res) {
                    setJobCategory(prev => ({...prev, name: ''}))
                    apiSuccessHandler(
                        <>
                            {I18N('success_create_job', 'de', [{
                                key: 'success_create_job',
                                value: I18N('success_create_job', 'de')
                            }])}
                        </>
                    )
                }
            }).catch(error => error).finally(() => {
                getAllJobs().then(res => {
                    setJobs(res);
                }).catch(error => error);
            })
        } else {
            apiWarningHandler(
                <>
                    {I18N('at_least_2', 'de', [{
                        key: 'at_least_2',
                        value: I18N('at_least_2', 'de')
                    }])}
                </>
            )
        }
    }

    const createMusicCategory = () => {
        if (musicCategory?.name.trim().length > 1) {
            addMusicCategory(musicCategory).then(res => {
                if (res) {
                    setMusicCategory(prev => ({...prev, name: ''}))
                    apiSuccessHandler(
                        <>
                            {I18N('success_create_music_category', 'de', [{
                                key: 'success_create_music_category',
                                value: I18N('success_create_music_category', 'de')
                            }])}
                        </>
                    )
                }
            }).catch(error => error).finally(() => {
                getAllMusicCategory().then(res => {
                    setCategory(res);
                }).catch(error => error);
            })
        } else {
            apiWarningHandler(
                <>
                    {I18N('at_least_2', 'de', [{
                        key: 'at_least_2',
                        value: I18N('at_least_2', 'de')
                    }])}
                </>
            )
        }
    }

    const deleteMusicCategorys = (id: number) => {
        deleteMusicCategory(+id).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_deleted_category', 'de', [{
                        key: 'success_deleted_category',
                        value: I18N('success_deleted_category', 'de')
                    }])}
                </>
            )
        }).catch(error => error).finally(() => {
            getAllMusicCategory().then(res => {
                setCategory(res);
            }).catch(error => error);
        });
    }

    const deleteJob = (id: number) => {
        deletePortfolioJob(+id).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_deleted_category', 'de', [{
                        key: 'success_deleted_category',
                        value: I18N('success_deleted_category', 'de')
                    }])}
                </>
            )
        }).catch(error => error).finally(() => {
            getAllJobs().then(res => {
                setJobs(res);
            }).catch(error => error);
        });
    }

    const deleteBookingEvent = (id: number) => {
        deleteBookingOccasion(+id).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_deleted_event', 'de', [{
                        key: 'success_deleted_event',
                        value: I18N('success_deleted_event', 'de')
                    }])}
                </>
            )
        }).catch(error => error).finally(() => {
            getBookingEvents().then(res => {
                setBookingEvents(res);
            }).catch(error => error);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        getAdminStatistics().then(res => {
            setIsLoading(true);
            setAdminStatistic(res);
        }).catch(error => error).finally(() => setIsLoading(false));
    }, [])


    useEffect(() => {
        setIsLoading(true);
        getAllAdmins().then(res => {
            setAdmins(res);
            setIsLoading(true);
        }).catch(error => error).finally(() => setIsLoading(false))
    }, [JSON.stringify(admins)])

    const wrapperFunction = () => {
        createNewAdmin();
        setShow7(false);
    }

    const pointsBoughtByMonth = adminStatistic?.pointsBoughtByMonth?.slice(-1)[0];
    const bookingsByMonth = adminStatistic?.bookingsByMonth?.slice(-1)[0];
    const bookingsByMonthPercentClass = bookingsByMonth?.percentageOfChange?.includes("-") ?
        classes.percentText_danger : classes.percentText_success
    const messagesByMonth = adminStatistic?.messagesByMonth?.slice(-1)[0];
    const messagesByMonthPercentClass = messagesByMonth?.percentageOfChange?.includes("-") ?
        classes.percentText_danger : classes.percentText_success
    const usersByMonth = adminStatistic?.usersByMonth?.slice(-1)[0];
    const usersByMonthPercentClass = usersByMonth?.percentageOfChange?.includes("-") ?
        classes.percentText_danger : classes.percentText_success
    const pointsSpentByMonth = adminStatistic?.pointsSpentByMonth?.slice(-1)[0];
    const pointsSpentByMonthPercentClass = pointsSpentByMonth?.percentageOfChange?.includes("-") ?
        classes.percentText_danger : classes.percentText_success


    let date = new Date(clickedTicket?.createdAt);
    let dateTicket = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const classFinished = `${classes.status_finished}`;
    const classDeclined = `${classes.status_declined}`;
    const classInProgress = `${classes.status_inProgress}`;
    const classRequested = `${classes.status_requested}`;

    const statisticData = (stats: any) => {
        return (
            <div key={stats?.period}>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <span className="fw-bold me-1">
                            {I18N('period', 'de', [{
                                key: 'period',
                                value: I18N('period', 'de')
                            }])}:
                        </span>
                        {stats?.period}
                    </div>

                    <div>
                        <span className="fw-bold me-1">
                            {I18N('total', 'de', [{
                                key: 'total',
                                value: I18N('total', 'de')
                            }])}:
                        </span>
                        {stats?.total}
                    </div>
                </div>
            </div>
        )
    }

    const deleteOneAdmin = (id: number) => {
        deleteAdmin(+id).then(res => {
            if (res) {
                apiSuccessHandler(
                    <>
                        {I18N('success_delete_admin', 'de', [{
                            key: 'success_delete_admin',
                            value: I18N('success_delete_admin', 'de')
                        }])}
                    </>
                )
                setAdmins(res);
            }
        }).catch(error => error);
    }

    const pointsBoughtByMonthStatistic = adminStatistic?.pointsBoughtByMonth?.map?.((stats: any) => statisticData(stats))

    const bookingsByMonthStatistic = adminStatistic?.bookingsByMonth?.map?.((stats: any) => statisticData(stats))

    const usersByMonthStatistic = adminStatistic?.usersByMonth?.map?.((stats: any) => statisticData(stats));

    const messageByMonthStatistic = adminStatistic?.messagesByMonth?.map?.((stats: any) => statisticData(stats))

    const pointsSpentByMonthStatistic = adminStatistic?.pointsSpentByMonth?.map?.((stats: any) => statisticData(stats))

    return (
        <>
            {isLoading && <LoadingPage/>}

            <Container className={`${classes.pt_6} ${isLoading && 'filter-blur'} `}>
                <Row>
                    <Col lg={6}>
                        <div className={classes.darkCard}>
                            <div>
                                <p className={`${classes.cardTitle} d-flex align-items-center mb-0`}>
                                    {adminStatistic?.pointsSpentByMonth?.length === 0 ? '0' : pointsSpentByMonth?.total}
                                    <span className={`${pointsSpentByMonthPercentClass}`}>
                                            {adminStatistic?.pointsSpentByMonth?.length === 0 ? '0' : pointsSpentByMonth?.percentageOfChange}
                                        </span>
                                    <span
                                        className={classes.percentText_success}>{usersByMonth?.period}
                                        </span>
                                </p>
                                <p className={`${classes.cardSubTitle} ${classes.wrap_text}`}>
                                    {I18N('average_monthly_earnings', 'de', [{
                                        key: 'average_monthly_earnings',
                                        value: I18N('average_monthly_earnings', 'de')
                                    }])}
                                </p>
                            </div>
                            <div onClick={() => setShow6(true)}>
                                <ClipboardDataIcon/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className="mt-3 mt-xl-0">
                        <div className={classes.darkCard}>
                            <div>
                                <p className={`${classes.cardTitle} d-flex align-items-center mb-0`}>
                                    <CountUp
                                        end={adminStatistic?.totalUsers}
                                        duration={2}/>

                                    <span className={`${usersByMonthPercentClass}`}>
                                            {usersByMonth?.percentageOfChange}
                                        </span>
                                    <span
                                        className={classes.percentText_success}>
                                        {usersByMonth?.period}
                                    </span>
                                </p>
                                <p className={`${classes.cardSubTitle} ${classes.wrap_text}`}>
                                    {I18N('users', 'de', [{
                                        key: 'users',
                                        value: I18N('users', 'de')
                                    }])}
                                </p>
                            </div>
                            <div onClick={() => setShow1(true)}>
                                <ClipboardDataIcon/>
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} className="mt-4">
                        <div className={`${classes.darkCard} flex-column`}>
                            <Row className="w-100 border-bottom">
                                <Col xl={12}
                                     className="p-0 p-md-2 d-flex align-items-center justify-content-between">
                                    <p className={classes.cardTitle}>
                                        {I18N('opened_tickets', 'de', [{
                                            key: 'opened_tickets',
                                            value: I18N('opened_tickets', 'de')
                                        }])}
                                    </p>
                                    <button className={classes.infoButton} onClick={clearAllFilters}>
                                        {I18N('clear_filters', 'de', [{
                                            key: 'clear_filters',
                                            value: I18N('clear_filters', 'de')
                                        }])}
                                    </button>
                                </Col>
                                <Col xl={12} className="mb-3">
                                    <Row className="align-items-center justify-content-end">
                                        <Col xl={2}>
                                            {I18N('sort_by_type_of_issue', 'de', [{
                                                key: 'sort_by_type_of_issue',
                                                value: I18N('sort_by_type_of_issue', 'de')
                                            }])}
                                        </Col>
                                        <Col xl={2}>
                                            <select name="issues" className={`${classes.input}`} id="issues"
                                                    onChange={filterType}>
                                                <option value="">ALL</option>
                                                <option value="OTHER">
                                                    OTHER
                                                </option>
                                                <option value="PROFILE">PROFILE</option>
                                                <option value="BOOKING">BOOKING</option>
                                                <option value="CHAT">CHAT</option>
                                                <option value="PAYMENT">PAYMENT</option>
                                            </select>
                                        </Col>
                                        <Col xl={2}>
                                            {I18N('select_status_to_filter', 'de', [{
                                                key: 'select_status_to_filter',
                                                value: I18N('select_status_to_filter', 'de')
                                            }])}
                                        </Col>
                                        <Col xl={2}>
                                            <select name="category" className={`${classes.input}`} id="category"
                                                    onChange={filterIssues}>
                                                <option value="">ALL</option>
                                                <option value="FINISHED">
                                                    FINISHED
                                                </option>
                                                <option value="CANCELED">CANCELED</option>
                                                <option value="REQUESTED">REQUESTED</option>
                                                <option value="IN_PROGRESS">IN PROGRESS</option>
                                            </select>
                                        </Col>
                                        <Col xl={2} className="d-flex justify-content-md-end">
                                            {I18N('filter_by_date', 'de', [{
                                                key: 'filter_by_date',
                                                value: I18N('filter_by_date', 'de')
                                            }])}
                                        </Col>
                                        <Col xl={2} className="d-flex justify-content-end">
                                            <select name="sortOrder" id="sortOrder"
                                                    onChange={sortOrderIssues}
                                            >
                                                <option value="">ALL</option>
                                                <option value="descending">
                                                    {I18N('descending', 'de', [{
                                                        key: 'descending',
                                                        value: I18N('descending', 'de')
                                                    }])}
                                                </option>
                                                <option value="ascending">
                                                    {I18N('ascending', 'de', [{
                                                        key: 'ascending',
                                                        value: I18N('ascending', 'de')
                                                    }])}
                                                </option>
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                className={`${filteredIssues?.length < 20 ? `height-fit-content` : `issue-table-admin`} w-100 `}>
                                {filteredIssues?.length > 0 ? filteredIssues?.map?.((issue: any) => {
                                    return (
                                        <Col xl={12} key={issue?.id}>
                                            <Rosa animation={'fade-right'} offset={700}>
                                                <div className="border-bottom px-0 py-3 p-md-3">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center
                                                     flex-column flex-md-row">
                                                        <div className="d-flex w-md-0 align-items-md-center">
                                                            {issue?.category === 'LOGIN' ? <LoginIssueIcon/>
                                                                : issue?.category === 'PAYMENT' ? <PaymentIssueIcon/> :
                                                                    issue?.category === 'CHAT' ? <ChatIssueIcon/> :
                                                                        issue?.category === 'PROFILE' ?
                                                                            <UserIssueIcon/> :
                                                                            issue?.category === 'BOOKING' ?
                                                                                <BookingIssueIcon/> : <OtherIssueIcon/>}
                                                            <div className="ms-2">
                                                                <p className="mb-0 fw-bold">{issue?.title}</p>
                                                                <p className="mb-0 text-muted">{issue?.category}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-end w-md-0">
                                                            <p className="mb-0">
                                                            <span
                                                                className="fw-bold">Status: </span>
                                                                <span
                                                                    className={`${issue?.status === 'CANCELED' ? `${classDeclined}` :
                                                                        issue?.status === 'IN_PROGRESS' ? `${classInProgress}` :
                                                                            issue?.status === 'FINISHED' ? `${classFinished}` :
                                                                                issue?.status === 'REQUESTED' ? `${classRequested}` : ''
                                                                    }`}>
                                                                           {issue?.status === 'REQUESTED' ? <>{I18N('requested', 'de')}</>
                                                                               : issue?.status === 'IN_PROGRESS' ? <>{I18N('in_progress', 'de')}</>
                                                                                   : issue?.status === 'FINISHED' ? <>{I18N('finished', 'de')}</>
                                                                                       : <>{I18N('canceled', 'de')}</>}
                                                            </span>
                                                            </p>
                                                            <p className="mb-0 text-muted">{issue?.comments?.length}
                                                                <span className="ms-1">
                                                                {I18N('comments', 'de', [{
                                                                    key: 'comments',
                                                                    value: I18N('comments', 'de')
                                                                }])}
                                                            </span>
                                                            </p>
                                                            <button className={classes.infoButton}
                                                                    onClick={() => getTicketID(issue?.id)}>Info
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Rosa>
                                        </Col>
                                    )
                                }) : <span className="text-center fw-bold my-5">
                                {I18N('filter_empty', 'de', [{
                                    key: 'filter_empty',
                                    value: I18N('filter_empty', 'de')
                                }])}</span>
                                }
                            </Row>
                        </div>
                    </Col>
                    <Col xl={12} className="mt-4">
                        <div className={classes.darkCard}>
                            <Row className={`align-items-start w-100`}>
                                <Col xl={12}>
                                    <p className={`${classes.cardTitle}`}>
                                        {I18N('categories', 'de', [{
                                            key: 'categories',
                                            value: I18N('categories', 'de')
                                        }])}
                                    </p>
                                    <p className="fw-bold text-muted">
                                        {I18N('delete_music_category', 'de', [{
                                            key: 'delete_music_category',
                                            value: I18N('delete_music_category', 'de')
                                        }])}
                                    </p>
                                </Col>

                                <Col xl={6} style={{maxHeight: '250px', overflow: 'auto'}}>

                                    {category?.map?.(cat => {
                                        return (
                                            <Rosa animation={'fade-right'} offset={700} key={cat?.id}>
                                                <div
                                                    className="d-flex p-2 align-items-center border-bottom justify-content-between">
                                                    <span>{cat?.name}</span>
                                                    <button className={classes.btn_decline} style={{height: 'auto'}}
                                                            onClick={() => deleteMusicCategorys(cat?.id)}>
                                                        X
                                                    </button>
                                                </div>
                                            </Rosa>
                                        )
                                    })}
                                </Col>
                                <Col xl={6} className="mt-2 mt-xl-0">
                                    <p className="fw-bold text-muted">
                                        {I18N('create_music_category', 'de', [{
                                            key: 'create_music_category',
                                            value: I18N('create_music_category', 'de')
                                        }])}
                                    </p>
                                    <Row className="align-items-center">
                                        <Col xl={10}>
                                            <input type="text" name="name" onChange={handleCategory}
                                                   value={musicCategory?.name}
                                                   className={classes.input}/>
                                        </Col>
                                        <Col xl={2} className="p-0 mt-3 mt-xl-0">
                                            <button className={`${classes.btn_primary} w-100`}
                                                    onClick={createMusicCategory}>
                                                {I18N('create', 'de', [{
                                                    key: 'create',
                                                    value: I18N('create', 'de')
                                                }])}
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={12} className="mt-4">
                        <div className={classes.darkCard}>
                            <Row className={`align-items-start w-100`}>
                                <Col xl={12}>
                                    <p className={`${classes.cardTitle}`}>
                                        {I18N('jobs', 'de', [{
                                            key: 'jobs',
                                            value: I18N('jobs', 'de')
                                        }])}
                                    </p>
                                    <p className="fw-bold text-muted">
                                        {I18N('delete_job', 'de', [{
                                            key: 'delete_job',
                                            value: I18N('delete_job', 'de')
                                        }])}
                                    </p>
                                </Col>

                                <Col xl={6} style={{maxHeight: '250px', overflow: 'auto'}}>

                                    {jobs?.map?.(cat => {
                                        return (
                                            <Rosa animation={'fade-right'} offset={700} key={cat?.id}>
                                                <div
                                                    className="d-flex p-2 align-items-center border-bottom justify-content-between">
                                                    <span>{cat?.name}</span>
                                                    <button className={classes.btn_decline} style={{height: 'auto'}}
                                                            onClick={() => deleteJob(cat?.id)}>
                                                        X
                                                    </button>
                                                </div>
                                            </Rosa>
                                        )
                                    })}
                                </Col>
                                <Col xl={6} className="mt-2 mt-xl-0">
                                    <p className="fw-bold text-muted">
                                        {I18N('create_job', 'de', [{
                                            key: 'create_job',
                                            value: I18N('create_job', 'de')
                                        }])}
                                    </p>
                                    <Row className="align-items-center">
                                        <Col xl={10}>
                                            <input type="text" name="name" onChange={handleJob}
                                                   value={jobCategory?.name}
                                                   className={classes.input}/>
                                        </Col>
                                        <Col xl={2} className="p-0 mt-3 mt-xl-0">
                                            <button className={`${classes.btn_primary} w-100`}
                                                    onClick={createJob}>
                                                {I18N('create', 'de', [{
                                                    key: 'create',
                                                    value: I18N('create', 'de')
                                                }])}
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xl={12} className="mt-4">
                        <div className={classes.darkCard}>
                            <Row className={`align-items-start w-100`}>
                                <Col xl={12}>
                                    <p className={`${classes.cardTitle}`}>
                                        {I18N('events', 'de', [{
                                            key: 'events',
                                            value: I18N('events', 'de')
                                        }])}
                                    </p>
                                    <p className="fw-bold text-muted">

                                        {I18N('delete_events', 'de', [{
                                            key: 'delete_events',
                                            value: I18N('delete_events', 'de')
                                        }])}
                                    </p>
                                </Col>

                                <Col xl={6} style={{maxHeight: '250px', overflow: 'auto'}}>

                                    {bookingEvents?.map?.(cat => {
                                        return (
                                            <Rosa animation={'fade-right'} offset={700}>
                                                <div key={cat?.id}
                                                     className="d-flex p-2 align-items-center border-bottom justify-content-between">
                                                    <span>{cat?.name}</span>
                                                    <button className={classes.btn_decline} style={{height: 'auto'}}
                                                            onClick={() => deleteBookingEvent(cat?.id)}>
                                                        X
                                                    </button>
                                                </div>
                                            </Rosa>
                                        )
                                    })}
                                </Col>
                                <Col xl={6} className="mt-2 mt-xl-0">
                                    <p className="fw-bold text-muted">
                                        {I18N('create_event', 'de', [{
                                            key: 'create_event',
                                            value: I18N('create_event', 'de')
                                        }])}
                                    </p>
                                    <Row className="align-items-center">
                                        <Col xl={10}>
                                            <input type="text" name="name" onChange={handleEvent}
                                                   value={bookingCategory?.name}
                                                   className={classes.input}/>
                                        </Col>
                                        <Col xl={2} className="p-0 mt-3 mt-xl-0">
                                            <button className={`${classes.btn_primary} w-100`}
                                                    onClick={createBookingEvent}>
                                                {I18N('create', 'de', [{
                                                    key: 'create',
                                                    value: I18N('create', 'de')
                                                }])}
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xl={12} className="mt-4">
                        <Row>
                            <Col xl={4} className="mt-3 mt-xl-0">
                                <Rosa animation={'fade-right'} offset={700}>
                                    <div className={classes.darkCard}>
                                        <div>
                                            <p className={`${classes.cardTitle} d-flex align-items-center mb-0`}>

                                                <CountUp end={bookingsByMonth?.total} duration={2}/>

                                                <span
                                                    className={`${bookingsByMonthPercentClass}`}>
                                                {bookingsByMonth?.percentageOfChange}
                                            </span>
                                                <span
                                                    className={classes.percentText_success}>{bookingsByMonth?.period}
                                                </span>

                                            </p>
                                            <p className={`${classes.cardSubTitle} ${classes.wrap_text}`}>
                                                {I18N('number_of_booking', 'de', [{
                                                    key: 'number_of_booking',
                                                    value: I18N('number_of_booking', 'de')
                                                }])}
                                            </p>
                                        </div>
                                        <div onClick={() => setShow2(true)}>
                                            <ClipboardDataIcon/>
                                        </div>
                                    </div>
                                </Rosa>
                            </Col>

                            <Col xl={4} className="mt-3 mt-xl-0">
                                <Rosa animation={'fade-right'} offset={700}>
                                    <div className={classes.darkCard}>
                                        <div>
                                            <p className={`${classes.cardTitle} d-flex align-items-center mb-0`}>
                                                <CountUp end={pointsBoughtByMonth?.total} duration={2}/>
                                                <span
                                                    className={classes.percentText_success}>{pointsBoughtByMonth?.period}</span>
                                            </p>
                                            <p className={`${classes.cardSubTitle} ${classes.wrap_text}`}>
                                                {I18N('number_of_tokens', 'de', [{
                                                    key: 'number_of_tokens',
                                                    value: I18N('number_of_tokens', 'de')
                                                }])}
                                            </p>
                                        </div>
                                        <div onClick={() => setShow3(true)}>
                                            <ClipboardDataIcon/>
                                        </div>
                                    </div>
                                </Rosa>
                            </Col>

                            <Col xl={4} className="mt-3 mt-xl-0">
                                <Rosa animation={'fade-right'} offset={700}>
                                    <div className={classes.darkCard}>
                                        <div>
                                            <p className={`${classes.cardTitle} d-flex align-items-center mb-0`}>
                                                <CountUp end={messagesByMonth?.total} duration={2}/>
                                                <span
                                                    className={`${messagesByMonthPercentClass}`}>
                                                {messagesByMonth?.percentageOfChange}
                                            </span>
                                                <span
                                                    className={classes.percentText_success}>
                                                {messagesByMonth?.period}
                                            </span>

                                            </p>
                                            <p className={`${classes.cardSubTitle} ${classes.wrap_text}`}>
                                                {I18N('number_of_messages', 'de', [{
                                                    key: 'number_of_messages',
                                                    value: I18N('number_of_messages', 'de')
                                                }])}
                                            </p>
                                        </div>
                                        <div onClick={() => setShow5(true)}>
                                            <ClipboardDataIcon/>
                                        </div>
                                    </div>
                                </Rosa>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12} className="mt-4">
                        <Rosa animation={'fade-right'} offset={700}>
                            <div
                                className={`${classes.darkCard} d-flex flex-column align-items-start overflow-md-normal`}>

                                <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                                    <p className={`${classes.cardTitle} mb-0`}>
                                        Admins
                                    </p>
                                    <button className={classes.infoButton} onClick={() => setShow7(true)}>
                                        {I18N('create_admin', 'de', [{
                                            key: 'create_admin',
                                            value: I18N('create_admin', 'de')
                                        }])}
                                    </button>
                                </div>

                                <Table striped bordered hover className="text-center">
                                    <thead>
                                    <tr>
                                        <th>
                                            #ID
                                        </th>
                                        <th>
                                            {I18N('email', 'de', [{
                                                key: 'email',
                                                value: I18N('email', 'de')
                                            }])}
                                        </th>
                                        <th>
                                            {I18N('firstName', 'de', [{
                                                key: 'firstName',
                                                value: I18N('firstName', 'de')
                                            }])}
                                        </th>
                                        <th>
                                            {I18N('lastName', 'de', [{
                                                key: 'lastName',
                                                value: I18N('lastName', 'de')
                                            }])}
                                        </th>
                                        <th>
                                            {I18N('created_at', 'de', [{
                                                key: 'created_at',
                                                value: I18N('created_at', 'de')
                                            }])}
                                        </th>
                                        <th>
                                            {I18N('type', 'de', [{
                                                key: 'type',
                                                value: I18N('type', 'de')
                                            }])}
                                        </th>
                                        <th>
                                            {I18N('delete', 'de', [{
                                                key: 'delete',
                                                value: I18N('delete', 'de')
                                            }])}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {admins?.map?.(admin => {
                                        let date = new Date(admin?.createdAt!);
                                        let createdAt = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

                                        return (
                                            <tr key={admin?.id}>
                                                <td>{admin?.id}</td>
                                                <td>{admin?.email}</td>
                                                <td>{admin?.firstName}</td>
                                                <td>{admin?.lastName}</td>
                                                <td>{createdAt}</td>
                                                <td>{admin?.type}</td>
                                                <td>
                                                    <button className={classes.btn_decline}
                                                            onClick={() => deleteOneAdmin(admin?.id!)}
                                                            style={{height: 'auto'}}>X
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>
                                </Table>
                            </div>
                        </Rosa>
                    </Col>
                </Row>
            </Container>
            <Modal show={show4} onHide={() => setShow4(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('ticket_info', 'de', [{
                            key: 'ticket_info',
                            value: I18N('ticket_info', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>
                                <span className="fw-bold">{I18N('created_at', 'de', [{
                                    key: 'created_at',
                                    value: I18N('created_at', 'de')
                                }])}:</span> {dateTicket}
                            </p>
                            <p>  <span className="fw-bold">{I18N('title', 'de', [{
                                key: 'title',
                                value: I18N('title', 'de')
                            }])}:</span> {clickedTicket?.title}
                            </p>

                            <p>
                                <span className="fw-bold">{I18N('description', 'de', [{
                                    key: 'description',
                                    value: I18N('description', 'de')
                                }])} </span>
                                {clickedTicket?.description}
                            </p>
                            <p>
                                <span
                                    className="fw-bold">Status:</span> {clickedTicket?.status === 'IN_PROGRESS' ? 'IN PROGRESS'
                                : clickedTicket?.status}
                            </p>

                            <div>
                                <span className="fw-bold">
                                    {I18N('comments', 'de', [{
                                        key: 'comments',
                                        value: I18N('comments', 'de')
                                    }])}:
                                </span>
                                {clickedTicket?.comments?.length > 0 ?
                                    <div id="displayedMessages"
                                         className={`${classes.comment_section} ms-1 border-top`}>
                                        {clickedTicket?.comments?.map?.((comment: any) => {
                                            let date = new Date(comment?.createdAt);
                                            let dateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${(date.getHours())}
                                            :${(date.getMinutes() < 10 ? '0' : '') + (date.getMinutes())}`
                                            return (
                                                <div key={comment?.id}>
                                                    <Row className="mt-2 border-bottom pb-2">
                                                        <Col xl={4} style={{width: 'fit-content'}}>
                                                            <img
                                                                src={!(comment?.user?.portfolio?.picture) ? '/user.jpg'
                                                                    : comment?.user?.portfolio?.picture}
                                                                onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                                                className={classes.eventReviewCardImage}
                                                                alt="User profile"/>
                                                        </Col>
                                                        <Col xl={8} className="d-flex flex-column ">
                                                            <div>
                                                                    <span
                                                                        className="mb-0 fw-bold">
                                                                        {comment?.user?.firstName} {comment?.user?.lastName}
                                                                    </span>
                                                            </div>
                                                            <span>{comment?.message}</span>
                                                        </Col>
                                                        <Col xl={12}
                                                             className="d-flex justify-content-end text-muted pt-2">
                                                            {dateTime}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </div> :
                                    <span className="ms-1">
                                        {I18N('no_comments', 'de', [{
                                            key: 'no_comments',
                                            value: I18N('no_comments', 'de')
                                        }])}
                                    </span>
                                }
                            </div>
                        </Col>
                        {showMessageDialog && <Col xl={12} className="mt-2">
                            <label htmlFor="message" className="mb-2 fw-bold">
                                {I18N('new_comment', 'de', [{
                                    key: 'new_comment',
                                    value: I18N('new_comment', 'de')
                                }])}:
                            </label>
                            <textarea className={classes.input} onChange={handleMessage}
                                      value={commentState.message}
                                      name="message"
                                      style={{minHeight: '200px', maxHeight: '200px'}} maxLength={250}/>
                            <span
                                className="text-muted d-flex justify-content-end mb-2">
                                {I18N('max_characters', 'de', [{
                                    key: 'max_characters',
                                    value: I18N('max_characters', 'de')
                                }])}
                                250 !
                            </span>
                        </Col>}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow4(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    {!(clickedTicket?.status === 'CANCELED' || clickedTicket?.status === 'FINISHED') &&
                    <CalendarConfirmButton buttonName={I18N('new_comment', 'de', [{
                        key: 'new_comment',
                        value: I18N('new_comment', 'de')
                    }])} clickHandler={addTicketComment}/>}

                    {!(clickedTicket?.status === 'FINISHED' || clickedTicket?.status === 'CANCELED'
                        || clickedTicket?.status === 'REQUESTED') &&
                    <button className={classes.btn_success} onClick={() => {
                        setShow(true)
                        setShow4(false)
                    }}>
                        {I18N('finish_ticket', 'de', [{
                            key: 'finish_ticket',
                            value: I18N('finish_ticket', 'de')
                        }])}
                    </button>}
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('ticket_info', 'de', [{
                            key: 'ticket_info',
                            value: I18N('ticket_info', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <p>
                                {I18N('ticket_status_change', 'de', [{
                                    key: 'ticket_status_change',
                                    value: I18N('ticket_status_change', 'de')
                                }])}
                            </p>
                        </Col>

                        <Col xl={6} className="d-flex justify-content-center">
                            <button className={classes.btn_decline} onClick={changeStatusCancel}>
                                {I18N('cancel_ticket', 'de', [{
                                    key: 'cancel_ticket',
                                    value: I18N('cancel_ticket', 'de')
                                }])}
                            </button>
                        </Col>
                        <Col xl={6} className="d-flex justify-content-center">
                            <button className={classes.btn_primary} onClick={changeStatusFinish}>
                                {I18N('finish_ticket', 'de', [{
                                    key: 'finish_ticket',
                                    value: I18N('finish_ticket', 'de')
                                }])}
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={() => setShow1(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('users', 'de', [{
                            key: 'users',
                            value: I18N('users', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>

                            {!usersByMonthStatistic?.length ? I18N('noData', 'de') : usersByMonthStatistic}

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow1(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={() => setShow2(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('number_of_booking', 'de', [{
                            key: 'number_of_booking',
                            value: I18N('number_of_booking', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>

                            {!bookingsByMonthStatistic?.length ? I18N('noData', 'de') : bookingsByMonthStatistic}

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow2(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={() => setShow3(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('number_of_tokens', 'de', [{
                            key: 'number_of_tokens',
                            value: I18N('number_of_tokens', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            {!pointsBoughtByMonthStatistic?.length ? I18N('noData', 'de') : pointsBoughtByMonthStatistic}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow3(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show5} onHide={() => setShow5(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('number_of_messages', 'de', [{
                            key: 'number_of_messages',
                            value: I18N('number_of_messages', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>

                            {!messageByMonthStatistic?.length ? I18N('noData', 'de') : messageByMonthStatistic}

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow5(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show6} onHide={() => setShow6(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('average_monthly_earnings', 'de', [{
                            key: 'average_monthly_earnings',
                            value: I18N('average_monthly_earnings', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            {!pointsSpentByMonthStatistic?.length ? I18N('noData', 'de') : pointsSpentByMonthStatistic}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow6(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show7} onHide={() => setShow7(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18N('create_admin', 'de', [{
                            key: 'create_admin',
                            value: I18N('create_admin', 'de')
                        }])}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <CreateAdminModal state={newAdmin} changeHandler={handleCategory}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow7(false)}>
                        {I18N('close', 'de', [{
                            key: 'close',
                            value: I18N('close', 'de')
                        }])}
                    </Button>
                    <SaveChangeButton clickHandler={wrapperFunction}/>

                </Modal.Footer>
            </Modal>

        </>
    )
}
import React, {useState} from 'react';
import classes from "./EStickyNote.module.scss"
import {XDeleteIcon} from "../Icons/Icons";

interface IProps {
    children: string;
    top: number;
    right: number;
    open: boolean;
}

const EStickyNote = (props: IProps) => {

    const [open, setOpen] = useState<boolean>(props.open);

    const closeButton = () => {
        setOpen(false);
    }

    return (
        <div style={{top: props.top + "rem", right: props.right + "rem"}}
             className={classes.eStickyNote + (open ? " " + classes.open : "")}
        >
            <div className={classes.closeBtn}
                 onClick={()=>{closeButton()}}
            >
                <XDeleteIcon/>
            </div>
            {props.children}
        </div>
    );
};

export default EStickyNote;
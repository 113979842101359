import classes from "../../components/Styles/ProfileSearch.module.scss";
import {Col, Row} from "react-bootstrap";
import {
    StarEmpty,
    StarFill,
    StarHalfFill,
} from "../../components/Icons/Icons";
import React, {useEffect, useState} from "react";
import {getAllMusicCategory, getAllUsers, getCurrentUserData, getUserFavorites} from "../../Helper/API";
import LoadingPage from "../Information/LoadingPage";
import {I18N} from "../../i18n/i18n";
import {Pagination} from "../../components/Pagination/Pagination";
import {iCategory} from "./Profile/Profile";
import {apiWarningHandler} from "../../Helper/api.services";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"
import {LocationSelectField} from "../../components/LocationSelectField/LocationSelectField";
import {
    FormControl,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import ProfileCard from "../../components/ProfileCard/ProfileCard";

export const RatingCalculation = (rating: number, maxStarLength: number = 5) => {

    const tempArray = [Math.floor(rating), rating * 10 - Math.floor(rating) * 10 >= 5 ? 5 : 0]
    const stars = new Array(5).fill(0).map((_, i) => {
        if (i + 1 <= tempArray[0]) {
            return 1
        } else if (i == tempArray[0] && tempArray[1]) {
            return 5
        } else {
            return 0
        }
    })

    const MapStart = {
        0: () => <div className={classes.user_profile_rating}>
            <StarEmpty/>
        </div>,
        1: () => <div className={classes.user_profile_rating}>
            <StarFill/>
        </div>,
        5: () => <div className={classes.user_profile_rating}>
            <StarHalfFill/>
        </div>
    }
    return stars.map(star => MapStart[star]())
}

interface iFilterTabs {
    value: string,
    isShown: boolean,
    stateKey: string
}

interface IStateTypes {
    page: number;
    perPage: number;
    categoryId: string;
    location: any;
    radius: number;
    firstName: string
    type: any;
    availability: Array<number>;
}

export function ProfileSearch() {

    /**
     * Component specific states
     */
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [filterArray, setFilterArray] = useState<any[]>()
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [filterUserName, setFilterUserName] = useState<boolean>(false);
    const [filterTab, setFilterTab] = useState<iFilterTabs[]>([]);

    /**
     * states for filterelements Name, Genre, Radius, Availability
     */
    const [radius, setRadius] = useState<number>(0);
    const [category, setCategory] = useState<Array<{value: number, label: string} | null>>([]);
    const [selectedDays, setSelectedDays] = useState<Array<number>>([])
    const [selectedCategory, setSelectedCategory] = useState<number>(0)
    const [selectedUserType, setSelectedUserType] = useState<string>('ENTER')
    const [userData, setUserData] = useState<any>(undefined)
    const [favorites, setFavorites] = useState<Array<Record<string, any>>>([])

    /**
     * state for request after applying filters
     */
    const [state, setState] = useState<IStateTypes>({
        page: 1,
        perPage: 12,
        categoryId: '',
        location: undefined,
        radius: 0 ,
        firstName: '',
        type: undefined,
        availability: [],
    })

    const pageArray = [3, 6, 12, 36];

    useEffect(() => {
        getAllMusicCategory().then(data => {
            setCategory(data.map((d: iCategory) => ({value: d.id, label: d.name})))
        }).catch(error => error);
        getCurrentUserData('0').then(userData => {
            setUserData(userData)
        }).catch(error => error)
        getUserFavorites().then(favorites => setFavorites(favorites)).catch(error => error)
    }, [])

    useEffect(() => {
        getFilteredUsers()
    }, [page, state?.perPage, filterUserName])

    /**
     * Function for requesting profiles after filtering
     *
     */
    const getFilteredUsers = () => {
        if (state?.location != undefined && state?.radius === 0 || state?.radius !== 0 && state?.location == undefined) {
            apiWarningHandler(
                <>
                    {I18N('enter_kilometers', 'de', [{
                        key: 'enter_kilometers',
                        value: I18N('enter_kilometers', 'de')
                    }])}
                </>
            )
            return;
        }
        setIsLoading(true)
        const filteredState = searchDto(state)
        getAllUsers(filteredState).then(data => {
            if (data) {
                setTotalPages(Math.ceil(data?.meta?.totalCount / state?.perPage))
                setFilterArray(data?.portfolioArray)
                setFilterTab(prev => ([...prev?.map?.(value => ({...value, isShown: true}))]))
            }
        }).catch(error => error).finally(() => {
            setIsLoading(false)
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    const filterUserCategories = (event) => {
            setSelectedCategory(event?.target?.value)
            setState(prev => ({...prev, categoryId: event?.target?.value}))
    }

    const filterUsersNames = (event: any) => {
        setState(prev => ({...prev, firstName: event?.target?.value}))
    }

    const changePerPageNumber = (event: any) => {
        setState(prev => ({...prev, perPage: +event.target.value, page: 1}));
    }

    const searchDto = (state: any) => {
        const output: any = {}
        for (const s in state) {
            if (state[s] || state[s]?.length) output[s] = state[s]
        }
        return output
    }

    function handleLocation(current: any) {
        setState(prev => ({...prev, location: current?.value}));
    }

    function handleRadiusChange(event) {
       setRadius(event?.target?.value);
       setState(prev => ({...prev, radius: event?.target?.value}));
    }

    function clearFilterForm(key: string) {
        setState(prev => ({...prev, [key]: ''}))
        setFilterTab(prev => ([...prev.filter(filter => filter.stateKey !== key)]))
        if (key === 'radius') {
            const inputField = document.getElementsByClassName('css-qc6sy-singleValue') as any;
            const radiusField = document.getElementsByName('radius')
            if (inputField) {
                inputField[0].style.color = 'transparent';
                radiusField[0].style.color = 'transparent';
            }
            setState(prev => ({...prev, location: undefined}))
        }
        setFilterUserName(!filterUserName);
    }

    const handleUserTypeSelection = (e) => {
        if(e?.target?.value !== 'ENTER') {
            setSelectedUserType(e?.target?.value)
            setState(prevState => ({...prevState, type: e?.target?.value}))
        }
    }

    const daysOfWeek = [
        {value: 0, label: `${I18N('monday', 'de')}`},
        {value: 1, label: `${I18N('tuesday', 'de')}`},
        {value: 2, label: `${I18N('wednesday', 'de')}`},
        {value: 3, label: `${I18N('thursday', 'de')}`},
        {value: 4, label: `${I18N('friday', 'de')}`},
        {value: 5, label: `${I18N('saturday', 'de')}`},
        {value: 6, label: `${I18N('sunday', 'de')}`}
    ]

    const locationRadiusOptions : Array<{label: number, value: number}> = [
        {label: 0, value: 0},
        {label: 50, value: 50,},
        {label: 100, value: 100,},
        {label: 150, value: 150,},
        {label: 300, value: 300,},
        {label: 400, value: 400,},
        {label: 50, value: 500,},
    ]

    const radiusSelectStyles = {
        color: 'white',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        background: 'rgb(16, 15, 15)',
        border: '2px solid white',
        height: '2.8rem',
    }

    const filterDaysInWeek = (event: any) => {
        const value = event?.target?.value
        setSelectedDays(value)
        setState(prev => ({...prev, availability: value}))
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getFilteredUsers()
        }
    }

    const locationSelectStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            background: 'rgb(16, 15, 15)',
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            border: '2px solid white',
            height: '2.8rem',
            borderRight: 'none'
        }),
        placeholder: (baseStyles) => (
            {
                ...baseStyles,
                color: '#ffffff',
            }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'white'
        })
    }

    return (
        <>
            {isLoading && <LoadingPage/>}
            <div className={classes.header + ' position-relative'}>
                    <h2 className={classes.headline}>
                        Artists
                    </h2>
            </div>
            <div className={classes.searchContainer}>
                <Row>
                    <Col xl={12} className="d-flex">
                        {
                            filterTab?.filter(a => a.isShown)?.map?.(key => {
                                return (
                                    <div className="d-flex align-items-center searchFilterBox  fw-bold">
                                        {
                                            key.value === 'ARTIST' ? (
                                                <span className="fw-bold text-white">
                                                    {
                                                        I18N('musician', 'de', [{
                                                            key: 'musician',
                                                            value: I18N('musician', 'de')
                                                        }])
                                                    }
                                                </span>
                                            ) : (
                                                key.value === 'CLUB' ? (
                                                    <span className="fw-bold text-white">
                                                        {
                                                            I18N('organizer', 'de', [{
                                                                key: 'organizer',
                                                                value: I18N('organizer', 'de')
                                                            }])
                                                        }
                                                    </span>
                                                ) : (
                                                    <span className="fw-bold text-white">{key.value}</span>
                                                )
                                            )
                                        }
                                        <button className={classes.xButton}
                                                onClick={() =>
                                                    clearFilterForm(key?.stateKey)
                                                }>
                                            X
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row className="my-5">
                        <div className="col-md-12 col-xl-2 mt-3 mt-xl-0 align-items-center">
                            <FormControl fullWidth>
                                <OutlinedInput
                                    onKeyPress={(e)=>{
                                        handleKeyPress(e)
                                    }}
                                    placeholder={ I18N('name', 'de').toUpperCase()}
                                    className={classes.input}
                                    style={{color: 'white', textAlign: 'center'}}
                                    onChange={filterUsersNames}
                                    sx={{
                                        input: {
                                            "&::placeholder": {
                                                opacity: 1,
                                                textAlign: 'center'
                                            },
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        {
                            category.length > 0 &&
                            <div className="col-md-12 col-xl-2 mt-3 mt-xl-0 align-items-center">
                                <FormControl fullWidth>
                                    <Select value={selectedCategory}
                                            className={classes.input}
                                            style={{color: 'white'}}
                                            onKeyPress={(e)=>{
                                                handleKeyPress(e)
                                            }}
                                            defaultValue={0}
                                            onChange={filterUserCategories}
                                            input={<OutlinedInput style={{textAlign: 'center'}}/>}
                                            renderValue={(selected) => {
                                                if (selected === 0) return I18N('categories').toUpperCase()
                                                else {
                                                    const categoryObj = category.filter( el => el?.value === selected)[0]
                                                    return categoryObj?.label
                                                }
                                            }}
                                    >
                                        {
                                            category.map((category, categorykey) => {
                                                return (
                                                    <MenuItem key={categorykey}
                                                              value={category?.value}
                                                    >{category?.label}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                        {/** React needs an item where value 0 is provided, see renderValue function*/}
                                        <MenuItem className="d-none" value={0}>GENRE</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }
                        <div className={classes.locationSelect + ' col-md-12 col-xl-4 mt-3 mt-xl-0 align-items-center'}>
                            <LocationSelectField  className={classes.locationSelectField}
                                                  selectStyles={locationSelectStyles}
                                                  handleLocation={handleLocation}
                                                  placeholder={I18N('place', 'de').toUpperCase()}
                            />
                            <FormControl style={{width: '30%'}}>
                                <Select
                                    style={radiusSelectStyles}
                                    value={radius}
                                    onKeyPress={(e)=>{
                                        handleKeyPress(e)
                                    }}
                                    onChange={handleRadiusChange}
                                    input={<OutlinedInput/>}
                                    renderValue={(selected) => {
                                        if (selected === 0) return '0 KM'
                                        return selected + ' KM'
                                    }}
                                >
                                    {
                                        locationRadiusOptions.map((radiusOption, radiusOptionKey) => {
                                            return (
                                                <MenuItem key={radiusOptionKey}
                                                          value={radiusOption.value}
                                                >{radiusOption.label} KM
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-12 col-xl-2 mt-3 mt-xl-0 align-items-center ">
                            <FormControl fullWidth>
                                <Select className={classes.input}
                                        value={selectedUserType}
                                        input={<OutlinedInput/>}
                                        native={false}
                                        style={{color: 'white'}}
                                        onChange={handleUserTypeSelection}
                                >
                                    <MenuItem value={'ENTER'}>{I18N('enter_user_type', 'de')}</MenuItem>
                                    <MenuItem value={'ARTIST'}>{I18N('musician', 'de')}</MenuItem>
                                    <MenuItem value={'CLUB'}>{I18N('organizer', 'de')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-12 col-xl-2 mt-3 mt-xl-0 align-items-center ">
                            <button className="primary-btn"
                                    onClick={getFilteredUsers}>
                                {I18N('click_to_apply_filters', 'de', [{
                                    key: 'click_to_apply_filters',
                                    value: I18N('click_to_apply_filters', 'de')
                                }])}
                            </button>
                        </div>

                </Row>
                <Row className="justify-content-between align-items-end">
                    <Col md={2}>
                        <div className="d-flex flex-column">
                            <label htmlFor="perPage" className={classes.pageSelectLabel}>
                                {I18N('per_page', 'de', [{
                                    key: 'per_page',
                                    value: I18N('per_page', 'de')
                                }])}
                            </label>
                            <select name="perPage"
                                    value={state?.perPage}
                                    className={classes.paginationSelect}
                                    onChange={changePerPageNumber}>
                                {pageArray?.map?.((pages, i) => {
                                    return <option value={pages} key={i}>{pages}</option>
                                })}
                            </select>
                        </div>
                    </Col>
                </Row>
                <div className="mb-3 p-2 p-xl-5">
                    <Row className={"justify-content-center w-100 mx-0 " + (isLoading ? ' filter-blur' : '')}>
                        {
                            (filterArray?.length !== 0 && userData) ? filterArray?.map?.((user: any) => {
                                let isFavorite = false;
                                
                                favorites.forEach((el) => {
                                    if(el.userId === user.userId) {
                                        isFavorite = true
                                    }
                                })

                                return user &&
                                    <Col xl={4}
                                         sm={12}
                                         md={6}
                                         lg={6}
                                         xxl={3}
                                         key={user?.id}
                                         className="mt-5"
                                    >
                                        <Rosa animation={'fade-right'} offset={500} className="h-100">
                                            <ProfileCard firstName={user.firstName}
                                                         lastName={user.lastName}
                                                         jobs={user.jobs}
                                                         rating={user?.reviewStatistic?._avg?.rating}
                                                         location={user.name}
                                                         picturePath={user.picture}
                                                         categories={user?.categories}
                                                         userId={user?.userId}
                                                         isFavorite={isFavorite}
                                            />
                                        </Rosa>
                                    </Col>
                        }) : <span className="text-center fw-bold">
                            {I18N('filter_empty', 'de', [{
                                key: 'filter_empty',
                                value: I18N('filter_empty', 'de')
                            }])}</span>
                        }
                    </Row>
                </div>
                <Row>
                    <Col xl={12} className="d-flex justify-content-between">
                        <div>
                            {totalPages <= 1 ? '' :
                                <Pagination
                                    page={page}
                                    totalPages={totalPages}
                                    handlePagination={handlePages}
                                />
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <label htmlFor="perPage" className={classes.pageSelectLabel}>
                                {I18N('per_page', 'de', [{
                                    key: 'per_page',
                                    value: I18N('per_page', 'de')
                                }])}
                            </label>
                            <select name="perPage"
                                    value={state?.perPage}
                                    className={classes.paginationSelect}
                                    onChange={changePerPageNumber}>
                                {pageArray?.map?.((pages, i) => {
                                    return <option value={pages} key={i}>{pages}</option>
                                })}
                            </select>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}
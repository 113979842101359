import React, {useEffect, useState} from 'react';
import './ProfileInformation..scss';
import {BirthdayDisplayEnum, ICategory, IProfileInfo, IProfileJobs, IUser} from "../../../Helper/Interfaces/IProfile";
import {Col, Container, Row} from "react-bootstrap";
import {Chip, InputAdornment, MenuItem, Select, SxProps, TextField, Typography} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";
import {LocationSelectField} from "../../../components/LocationSelectField/LocationSelectField";
import {IPortfolioPutRequest} from "../../../Helper/IAPI";
import {Clear, Done, Lock} from '@mui/icons-material';
import {updateProfile, updateUserPortfolio} from "../../../Helper/API";
import {apiSuccessHandler} from "../../../Helper/api.services";
import ProfileSocialMedia from "../ProfileSocialMedia/ProfileSocialMedia";

interface IProfileInformationProps {
    profileInformation: IProfileInfo
    setProfileInformation: (newProfileInfo: IProfileInfo | null) => void
    categories: Array<ICategory> | null;
    jobs: Array<IProfileJobs> | null;
}

const ProfileInformation = (props: IProfileInformationProps) => {

    const [profileInfo, setProfileInfo] = useState<IProfileInfo | null>(null)
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [editProfileInfo, setEditProfileInfo] = useState(false)
    const [updatedPortfolioInfo, setUpdatedPortfolioInfo] = useState<IPortfolioPutRequest>({})
    const [updatedUserInfo, setUpdatedUserInfo] = useState<IUser | {}>({})
    const [selectedCategories, setSelectedCategories] = useState<Array<number | null>>([])
    const [selectedJobs, setSelectedJobs] = useState<Array<IProfileJobs | null>>([])
    const [clear, setClear] = useState(false)
    const [heightOfJobInput, setHeightOfJobInput] = useState(0);
    const [heightOfCategoryInput, setHeightOfCategoryInput] = useState(0);

    useEffect(() => {
        if (props.profileInformation) {
            initSelectedCategories()
            initSelectedJobs()
            setProfileInfo(props.profileInformation)
            if (clear) {
                setClear(false)
            }
            calculateHeightOfJobInput("job")
            calculateHeightOfJobInput("category")
        }
    }, [clear, props.profileInformation])

    const initSelectedCategories = () => {
        if (props.profileInformation) {
            setSelectedCategories(props.profileInformation.categories.map(catObject => catObject.id))
        }
    }

    const initSelectedJobs = () => {
        if (props.profileInformation) {
            setSelectedJobs(props.profileInformation.jobs.map(catObject => catObject.id))
        }
    }

    const handleDate = (date) => {
        if (date) {
            setUpdatedPortfolioInfo(prevState => ({...prevState, birthday: moment(date).format()}))
            setProfileInfo((prevState: any) => ({...prevState, birthday: moment(date).format()}))
        }
    }

    const handlelocation = (current: any) => {
        setUpdatedPortfolioInfo((prevState: any) => ({...prevState, location: current?.value}))
    }

    const datePickerKeyDown = (e) => {
        e.preventDefault();
    }

    const updatePortfolioInfo = (e) => {
            let isNumber = e.target.name === "pricePerHour" || e.target.name === "pricePerEvent";
            let value = isNumber ? parseInt(e.target.value) : e.target.value
            setUpdatedPortfolioInfo((prevState: any) => ({...prevState, [e.target.name]: value}))
            setProfileInfo((prevState: any) => ({...prevState, [e.target.name]: value}))
    }

    const updateUserInfo = (e) => {
        setUpdatedUserInfo((prevState: any) => ({...prevState, [e.target.name]: e.target.value}))
        setProfileInfo((prevState: any) => ({
            ...prevState,
            user: {...profileInfo?.user, [e.target.name]: e.target.value}
        }))
    }

    const updateUserCategories = (e) => {
        setSelectedCategories(e?.target?.value)
        let updatedCategories: Array<any> = [];
        e.target.value.forEach(el => {
            const catobj = props.categories?.find(categoryElement => categoryElement?.id === el)
            updatedCategories.push(catobj)
        })
        setUpdatedPortfolioInfo((prevState: any) => ({...prevState, categories: updatedCategories}))
        setProfileInfo((prevState: any) => ({...prevState, categories: updatedCategories}))
    }

    const updateUserJobs = (e) => {
        setSelectedJobs(e?.target?.value)
        let updatedJobs: Array<any> = [];
        e.target.value.forEach(el => {
            const jobObj = props.jobs?.find(jobElement => jobElement?.id === el)
            updatedJobs.push(jobObj)
        })
        setUpdatedPortfolioInfo((prevState: any) => ({...prevState, jobs: updatedJobs}))
        setProfileInfo((prevState: any) => ({...prevState, jobs: updatedJobs}))
    }

    const sendUpdatedPortfolioInfo = () => {
        if (Object.keys(updatedPortfolioInfo).length > 0) {
            updateUserPortfolio(updatedPortfolioInfo).then((res: IProfileInfo) => {
                apiSuccessHandler(<>{I18N('change_info_success')}</>)
                if (res) {
                    Object.keys(res).forEach((el) => {
                        setProfileInfo((prevState: any) => ({...prevState, [el] : res[el]}))
                    })
                }
                clearInfo()
            }).catch(error => error)
        }
    }

    const sendUpdatedUserInfo = () => {
        if (Object.keys(updatedUserInfo).length > 0) {
            updateProfile(updatedUserInfo).then((res) => {
                apiSuccessHandler(<>{I18N('change_info_success')}</>)
                clearInfo()
            }).catch(error => error)
        }
    }

    const clearInfo = () => {
        setUpdatedUserInfo({})
        setUpdatedPortfolioInfo({})
        setSelectedCategories([])
        initSelectedCategories()
        initSelectedJobs()
    }

    const calculateHeightOfJobInput = (attribute: string) => {
        if(props.profileInformation){
            let heightFactor = attribute === "job" ? Math.round(props.profileInformation?.jobs?.length / 2) : Math.round(props.profileInformation?.categories?.length / 2)
            if(window.innerWidth >= 1200) {
                if(heightFactor > 2) {
                  heightFactor = 3
                }
            }
            if(attribute === "job") {
                setHeightOfJobInput(heightFactor * 50)
            } else {
                setHeightOfCategoryInput((heightFactor - 1) * 50)
            }
        }
    }

    const chipStyles: React.CSSProperties = {
        color: '#fff'
    }

    const getChips = (property) => {
        if (profileInfo) {
            return <div className="ProfileInformation__text-field__chip-container">{
                profileInfo[property]?.map((chip, chipKey) => {
                    return (
                        <Chip key={chipKey}
                              sx={chipStyles}
                              label={chip?.name}
                              clickable
                              onMouseDown={(event) => event.stopPropagation()}
                              onDelete={(e) => deleteChip(chip, property, e)}
                              deleteIcon={
                                  <Clear
                                      key={chip?.id}
                                      id={chip?.id}
                                      className={"ProfileInformation__text-field__chip-container__deleteIcon" + (editProfileInfo ? " show" : "")}
                                  />
                              }
                        />
                    )
                })
            }
            </div>
        }
    }

    const deleteChip = (obj, property: string, e) => {
        if(profileInfo){
            if (property === 'jobs') {
                let newSelectedJobs = selectedJobs.filter((selectedJob: any) => {
                    return selectedJob !== parseInt(e.target.id)
                })
                updateUserJobs({target: {value: newSelectedJobs}})
            }
            if (property === 'categories') {
                let newSelectedCategories = selectedCategories.filter((selectedCategory: any) => selectedCategory !== parseInt(e.target.id))
                updateUserCategories({target: { value: newSelectedCategories}})
            }
        }
    }

    const textFieldStyles: React.CSSProperties = {
        borderBottom: '2px solid #fff',
        paddingLeft: '0px',
        color: '#fff',
        width: '100%',
        minHeight: '50px',
        background: (!editProfileInfo ? 'rgb(16, 15, 15)' : 'rgb(30,29,29)')
    }

    const textFieldSx: SxProps = {
        input: {
            color: '#fff',
            paddingLeft: '0px',
            textTransform: 'uppercase',
            height: '100%'
        }
    }

    const selectStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            background: (!editProfileInfo ? 'rgb(16, 15, 15)' : 'rgb(30,29,29)'),
            borderRadius: '0px !important',
            border: 'none',
            width: '100%',
            height: '50px'
        }),
        valueContainer: (baseStyles)=> ({
            ...baseStyles,
            padding: '2px 0px'
        }),
        placeholder: (baseStyles) => (
            {
                ...baseStyles,
                color: '#ffffff',
                textTransform: 'uppercase',
                opacity: '0.38',
                textAlign: 'left !important'
            }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'white'
        })
    }

    return (
        <div className="ProfileInformation">
            {
                profileInfo && (
                    <Container>
                        {
                            profileInfo?.isMyProfile ? (
                                <>
                                    <div className="ProfileInformation__text-field-group">
                                        <div className="ProfileInformation__button-group">
                                            {
                                                editProfileInfo ? (
                                                    <>
                                                        <button className="ProfileInformation__button-group__button"
                                                                onClick={() => {
                                                                    sendUpdatedPortfolioInfo()
                                                                    sendUpdatedUserInfo()
                                                                    setEditProfileInfo(false)
                                                                }}
                                                        >
                                                            <Done/>
                                                        </button>
                                                        <button className="ProfileInformation__button-group__button"
                                                                onClick={() => {
                                                                    clearInfo()
                                                                    setClear(true)
                                                                    setEditProfileInfo(false)
                                                                }}
                                                        >
                                                            <Clear/>
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button className="ProfileInformation__button-group__button"
                                                            onClick={() => {
                                                                setDatePickerOpen(false)
                                                                setEditProfileInfo(true)
                                                            }}>
                                                        <img src="/SVG/icon_edit_48px.svg"/>
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <div className={"ProfileInformation__text-field-group__left" + (profileInfo?.user?.type === 'ARTIST' ? " col-sm-12 col-md-12 col-lg-6" : " col-12")}>
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       style={{...textFieldStyles, borderTop: '2px solid #fff'}}
                                                       sx={textFieldSx}
                                                       placeholder={I18N('firstName')}
                                                       value={profileInfo?.user?.firstName || ""}
                                                       onChange={(e) => {
                                                           updateUserInfo(e)
                                                       }}
                                                       name="firstName"
                                                       InputProps={{
                                                           endAdornment: (profileInfo?.user?.firstName ? <InputAdornment position="end">{I18N('firstName')}</InputAdornment> : <></>),
                                                       }}
                                            />
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       style={textFieldStyles}
                                                       sx={textFieldSx}
                                                       placeholder={I18N('lastName')}
                                                       value={profileInfo?.user?.lastName || ""}
                                                       onChange={(e) => {
                                                           updateUserInfo(e)
                                                       }}
                                                       name="lastName"
                                                       InputProps={{
                                                           endAdornment: (profileInfo?.user?.lastName ? <InputAdornment position="end">{I18N('lastName')}</InputAdornment> : <></>),
                                                       }}
                                            />
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       style={textFieldStyles}
                                                       placeholder={I18N('enter_street')}
                                                       sx={textFieldSx}
                                                       onChange={(e) => {
                                                           updatePortfolioInfo(e)
                                                       }}
                                                       value={profileInfo?.street || ""}
                                                       name="street"
                                                       InputProps={{
                                                           endAdornment: (profileInfo.street ? <InputAdornment position="end">{I18N('enter_street')}</InputAdornment> : <></>),
                                                       }}
                                            />
                                            <div className="ProfileInformation__wrapper" style={{background: (!editProfileInfo ? 'rgb(16, 15, 15)' : 'rgb(30,29,29)')}}>
                                                <LocationSelectField selectStyles={selectStyles}
                                                                     handleLocation={handlelocation}
                                                                     className="ProfileInformation__text-field-group__locationselect"
                                                                     presettedValue={profileInfo?.location}
                                                                     isDisabled={!editProfileInfo}
                                                />
                                                <Typography className="ProfileInformation__wrapper__adornment">PLZ/Ort</Typography>
                                            </div>
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       onChange={(e) => {
                                                           updatePortfolioInfo(e)
                                                       }}
                                                       style={textFieldStyles}
                                                       sx={textFieldSx}
                                                       placeholder={I18N('phone')}
                                                       type="tel"
                                                       name="phoneNumber"
                                                       value={profileInfo?.phoneNumber || ""}
                                                       InputProps={{
                                                           endAdornment: (profileInfo?.phoneNumber ? <InputAdornment position="end">{I18N('phone')}</InputAdornment> : <></>),
                                                       }}
                                            />
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       style={textFieldStyles}
                                                       sx={textFieldSx}
                                                       placeholder={I18N('email')}
                                                       type="email"
                                                       value={profileInfo?.user?.email || ""}
                                                       name="email"
                                                       onChange={(e) => {
                                                           updateUserInfo(e)
                                                       }}
                                                       InputProps={{
                                                           endAdornment: (profileInfo?.user?.email ? <InputAdornment position="end">{I18N('email')}</InputAdornment> : <></>),
                                                       }}

                                            />
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <div className="ProfileInformation__wrapper" style={{background: (!editProfileInfo ? 'rgb(16, 15, 15)' : 'rgb(30,29,29)')}}>
                                                    <DatePicker
                                                        disabled={!editProfileInfo}
                                                        open={datePickerOpen && editProfileInfo}
                                                        onOpen={() => {
                                                            setDatePickerOpen(true)
                                                        }}
                                                        onClose={() => {
                                                            setDatePickerOpen(false)
                                                        }}
                                                        value={profileInfo?.birthday || ""}
                                                        inputFormat="dd.MM.yyyy"
                                                        mask="__.__.____"
                                                        onChange={(newValue) => {
                                                            handleDate(newValue)
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            className="ProfileInformation__text-field"
                                                            style={{...textFieldStyles, borderBottom: 'none'}}
                                                            sx={{...textFieldSx, height: 'unset'}}
                                                            InputProps={{
                                                                sx: {color: '#fff'}
                                                            }}
                                                            onKeyDown={datePickerKeyDown}
                                                            onClick={() => {
                                                                setDatePickerOpen(true)
                                                            }}
                                                            {...params}
                                                        />}
                                                    />
                                                    <Typography className="ProfileInformation__wrapper__adornment">{I18N('birthday')}</Typography>
                                                </div>
                                            </LocalizationProvider>
                                            <Select className="ProfileInformation__text-field"
                                                    disabled={!editProfileInfo}
                                                    value={profileInfo?.birthdayDisplay || ""}
                                                    onChange={(e) => {
                                                        if(e.target.value !== "0") {
                                                            updatePortfolioInfo(e)
                                                        } else {
                                                            setProfileInfo((prevState: any) => ({...prevState, [e.target.name] : e.target.value}))
                                                        }
                                                    }}
                                                    name="birthdayDisplay"
                                                    style={textFieldStyles}
                                                    sx={textFieldSx}
                                                    displayEmpty={true}
                                                    renderValue={(selected) => {
                                                        if (selected as string === "") {
                                                            return <span
                                                                className="ProfileInformation__text-field__placeholder">
                                                            {I18N('birthday_display_option')}
                                                        </span>
                                                        } else {
                                                            if(selected as string === "0") {
                                                                return "-- Wähle, wie dein Geburtstag angezeigt werden soll --"
                                                            } else {
                                                                switch (selected) {
                                                                    case BirthdayDisplayEnum.YEAR:
                                                                        return "Nur das Jahr anzeigen";
                                                                    case BirthdayDisplayEnum.DATE:
                                                                        return "Das genaue Datum anzeigen";
                                                                    case BirthdayDisplayEnum.NONE:
                                                                        return "Ausblenden";
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    endAdornment={(profileInfo?.birthdayDisplay ? <InputAdornment
                                                        className="ProfileInformation__text-field__select-adornment"
                                                        position="end">{I18N('birthday_display')}</InputAdornment> : <></>)
                                                    }
                                            >
                                                <MenuItem value="0">Wähle, wie dein Geburtstag angezeigt werden soll</MenuItem>
                                                <MenuItem value={BirthdayDisplayEnum.YEAR}>Nur das Jahr anzeigen</MenuItem>
                                                <MenuItem value={BirthdayDisplayEnum.DATE}>Das genaue Datum anzeigen</MenuItem>
                                                <MenuItem value={BirthdayDisplayEnum.NONE}>Ausblenden</MenuItem>
                                            </Select>
                                            {
                                                profileInfo.user?.type === "CLUB" && (
                                                    <TextField className="ProfileInformation__text-field"
                                                               disabled={!editProfileInfo}
                                                               style={textFieldStyles}
                                                               sx={textFieldSx}
                                                               value={profileInfo?.locationSize || ""}
                                                               placeholder={I18N('quadrature')}
                                                               onChange={(e) => {
                                                                   updatePortfolioInfo(e)
                                                               }}
                                                               InputProps={{
                                                                   endAdornment: (profileInfo?.locationSize ?  <InputAdornment position="end">{I18N('quadrature') + "  in m\u00B2"}</InputAdornment> : <></>),
                                                               }}
                                                    />
                                                )
                                            }
                                            <TextField className="ProfileInformation__text-field"
                                                       disabled={!editProfileInfo}
                                                       style={textFieldStyles}
                                                       sx={textFieldSx}
                                                       placeholder={I18N('enter_tax') + " (Nicht öffentlich Sichtbar)"}
                                                       name="taxNumber"
                                                       onChange={(e) => {
                                                           updatePortfolioInfo(e)
                                                       }}
                                                       value={profileInfo?.taxNumber || ""}
                                                       InputProps={{
                                                           endAdornment: (profileInfo?.taxNumber ? <InputAdornment position="end">{I18N('enter_tax')} <Lock/></InputAdornment> : <></>),
                                                       }}
                                            />
                                            <Select className="ProfileInformation__text-field"
                                                    disabled={!editProfileInfo}
                                                    value={(profileInfo?.vat !== null) ? profileInfo?.vat : ""}
                                                    onChange={(e) => {
                                                        if(e.target.value !== "0") {
                                                            updatePortfolioInfo(e)
                                                        } else {
                                                            setProfileInfo((prevState: any) => ({...prevState, [e.target.name] : e.target.value}))
                                                        }
                                                    }}
                                                    name="vat"
                                                    style={textFieldStyles}
                                                    sx={textFieldSx}
                                                    displayEmpty={true}
                                                    renderValue={(selected) => {
                                                        if (selected as any === "") {
                                                            return <span
                                                                className="ProfileInformation__text-field__placeholder">
                                                            {I18N('vat')} (Nicht öffentlich Sichtbar)
                                                        </span>
                                                        } else {
                                                            if (selected === "0"){
                                                                return "-- Wähle, ob die Kleinunternehmerregelung angewendet wird --"
                                                            } else {
                                                                return selected ? "JA" : "NEIN"
                                                            }
                                                        }
                                                    }}
                                                    endAdornment={( profileInfo?.vat !== "" ? <InputAdornment
                                                        className="ProfileInformation__text-field__select-adornment"
                                                        position="end">{I18N('vat')} <Lock/></InputAdornment> : <></>)
                                                    }
                                            >
                                                <MenuItem value="0">Wähle, ob die Kleinunternehmerregelung angewendet wird</MenuItem>
                                                <MenuItem value={true as any}>JA</MenuItem>
                                                <MenuItem value={false as any}>NEIN</MenuItem>
                                            </Select>
                                            <div className={"ProfileInformation__text-field-group__line-space mobile-space" + (!editProfileInfo ? "" : " edit")}></div>
                                        </div>
                                        {
                                            profileInfo?.user?.type === 'ARTIST' &&
                                            <div className="ProfileInformation__text-field-group__right col-sm-12 col-md-12 col-lg-6">
                                                {
                                                    profileInfo?.user?.type === 'ARTIST' && (
                                                        <TextField className="ProfileInformation__text-field"
                                                                   disabled={!editProfileInfo}
                                                                   style={{...textFieldStyles, borderTop: '2px solid #fff'}}
                                                                   sx={textFieldSx}
                                                                   placeholder={I18N('band_name')}
                                                                   name="bandName"
                                                                   value={profileInfo?.bandName || ""}
                                                                   onChange={(e) => {
                                                                       updatePortfolioInfo(e)
                                                                   }}
                                                                   InputProps={{
                                                                       endAdornment: (profileInfo?.bandName ? <InputAdornment position="end">{I18N('band_name')}</InputAdornment> : <></>),
                                                                   }}
                                                        />
                                                    )
                                                }
                                                {
                                                    profileInfo.user?.type === "ARTIST" && (
                                                        <Select className="ProfileInformation__text-field"
                                                                disabled={!editProfileInfo}
                                                                name="categories"
                                                                multiple
                                                                onChange={(e) => {
                                                                    updateUserCategories(e)
                                                                }}
                                                                style={{...textFieldStyles, height: (heightOfCategoryInput) + 'px'}}
                                                                sx={textFieldSx}
                                                                value={selectedCategories || ""}
                                                                displayEmpty={true}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span
                                                                            className="ProfileInformation__text-field__placeholder">
                                                                {I18N('categories')}
                                                            </span>
                                                                    } else {
                                                                        return getChips("categories")
                                                                    }
                                                                }}
                                                                endAdornment={(profileInfo?.categories?.length > 0 ? <InputAdornment
                                                                    className="ProfileInformation__text-field__select-adornment"
                                                                    position="end">{I18N('categories')}</InputAdornment> : <></>)
                                                                }
                                                        >
                                                            {
                                                                props?.categories?.map((category, categoryKey) => {
                                                                    return (
                                                                        <MenuItem key={categoryKey} value={category?.id}>
                                                                            {category?.name}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    )
                                                }
                                                {
                                                    (profileInfo?.user?.type === 'ARTIST') && (
                                                        <Select className="ProfileInformation__text-field"
                                                                disabled={!editProfileInfo}
                                                                name="jobs"
                                                                multiple
                                                                onChange={(e) => {
                                                                    updateUserJobs(e)
                                                                }}
                                                                displayEmpty={true}
                                                                style={{...textFieldStyles, height: (heightOfJobInput) + 'px'}}
                                                                sx={textFieldSx}
                                                                endAdornment={(profileInfo?.jobs?.length > 0 ? <InputAdornment
                                                                    className="ProfileInformation__text-field__select-adornment"
                                                                    position="end">{I18N('jobs')}</InputAdornment> : <></>)
                                                                }
                                                                value={selectedJobs || ""}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <span
                                                                            className="ProfileInformation__text-field__placeholder">
                                                            JOBBEZEICHNUNGEN
                                                        </span>
                                                                    } else {
                                                                        return getChips("jobs")
                                                                    }
                                                                }}
                                                        >
                                                            {
                                                                props?.jobs?.map((job, jobKey) => {
                                                                    return (
                                                                        <MenuItem key={jobKey} value={job?.id}>
                                                                            {job?.name}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    )
                                                }
                                                {
                                                    profileInfo?.user?.type === 'ARTIST' && (
                                                        <TextField className="ProfileInformation__text-field"
                                                                   disabled={!editProfileInfo}
                                                                   style={textFieldStyles}
                                                                   sx={textFieldSx}
                                                                   placeholder={"Welche Technik steht mir zur Verfügung?"}
                                                                   name="technology"
                                                                   value={profileInfo?.technology || ""}
                                                                   onChange={(e) => {
                                                                       updatePortfolioInfo(e)
                                                                   }}
                                                                   InputProps={{
                                                                       endAdornment: ( profileInfo?.technology !== "" ? <InputAdornment
                                                                           position="end">Technik</InputAdornment> : <></>),
                                                                   }}
                                                        />
                                                    )
                                                }
                                                {
                                                    profileInfo.user?.type === "ARTIST" && (
                                                        <>
                                                            <TextField className="ProfileInformation__text-field"
                                                                       disabled={!editProfileInfo}
                                                                       style={textFieldStyles}
                                                                       sx={textFieldSx}
                                                                       placeholder={I18N('price_per_hour')}
                                                                       name="pricePerHour"
                                                                       value={profileInfo?.pricePerHour || 0}
                                                                       type="number"
                                                                       onChange={(e) => {
                                                                           updatePortfolioInfo(e)
                                                                       }}
                                                                       InputProps={{
                                                                           endAdornment: profileInfo?.pricePerHour ? (
                                                                               <InputAdornment position="end">
                                                                                   {I18N('price_per_hour')}
                                                                               </InputAdornment>) : <></>
                                                                       }}
                                                            />
                                                            <TextField className="ProfileInformation__text-field"
                                                                       disabled={!editProfileInfo}
                                                                       style={textFieldStyles}
                                                                       sx={textFieldSx}
                                                                       placeholder={I18N('price_per_event')}
                                                                       name="pricePerEvent"
                                                                       value={profileInfo?.pricePerEvent || 0}
                                                                       onChange={(e) => {
                                                                           updatePortfolioInfo(e)
                                                                       }}
                                                                       type="number"
                                                                       InputProps={{
                                                                           endAdornment: (profileInfo?.pricePerEvent ? (<InputAdornment
                                                                               position="end">{I18N('price_per_event')}</InputAdornment>) : <></>),
                                                                       }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {
                                                    profileInfo?.user?.type === 'ARTIST' && (
                                                        <div className="ProfileInformation__text-field-group__social-media">
                                                            <ProfileSocialMedia editProfileInfo={editProfileInfo}
                                                                                platforms={profileInfo?.platforms}
                                                                                isMyProfile={profileInfo?.isMyProfile}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                { /*
                                                    profileInfo?.user?.type === 'ARTIST' && (
                                                        <TextField className="ProfileInformation__text-field"
                                                                   disabled={!editProfileInfo}
                                                                   style={textFieldStyles}
                                                                   sx={textFieldSx}
                                                                   placeholder={I18N('techwriter')}
                                                                   name="techwriter"
                                                                   InputProps={{
                                                                       endAdornment: <InputAdornment position="end">{I18N('techwriter')}</InputAdornment>,
                                                                   }}
                                                        />
                                                    )
                                                 */}
                                                {/*
                                            <div className={"ProfileInformation__text-field-group__line-space" + (!editProfileInfo ? "" : " edit")}></div>
                                        */}
                                            </div>
                                        }
                                    </div>
                                    <div className="ProfileInformation__disclaimer">
                                        <Lock/>: Diese Informationen werden nicht öffentlich angezeigt.
                                    </div>
                                </>
                            ) : (
                                <Row>
                                    <Col lg={6}>
                                        {
                                            profileInfo?.user?.type === 'ARTIST' && (
                                                <div className="ProfileInformation__profile-infos">
                                                    <p className="ProfileInformation__profile-infos__info">
                                                        {profileInfo?.user?.firstName} {profileInfo?.user?.lastName}
                                                    </p>
                                                    {
                                                        profileInfo?.bandName && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                {props.profileInformation?.bandName}
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        (profileInfo?.birthday && profileInfo?.birthdayDisplay !== BirthdayDisplayEnum.NONE) && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                {
                                                                    profileInfo?.birthdayDisplay === BirthdayDisplayEnum.DATE ? moment(profileInfo?.birthday).format('DD.MM.YYYY') : moment(profileInfo?.birthday).format('YYYY')
                                                                }
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        props.profileInformation.categories.length > 0 &&
                                                        <p className="ProfileInformation__profile-infos__info">
                                                            {
                                                                props.profileInformation?.categories.map(category => category?.name + (props.profileInformation?.categories?.length > 1 ? ', ' : ''))
                                                            }
                                                        </p>
                                                    }
                                                    {
                                                        props.profileInformation.jobs.length > 0 &&
                                                        <p className="ProfileInformation__profile-infos__info">
                                                            {
                                                                props.profileInformation?.jobs.map(job => job?.name + (props.profileInformation?.jobs?.length > 1 ? ', ' : ''))
                                                            }
                                                        </p>
                                                    }
                                                    {
                                                        profileInfo?.pricePerHour && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                {profileInfo?.pricePerHour} €/H
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        profileInfo?.pricePerEvent && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                {profileInfo?.pricePerEvent} € pro Veranstaltung
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        profileInfo?.technology && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                {profileInfo?.technology}
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        profileInfo?.platforms.length > 0 && (
                                                            <p className="ProfileInformation__profile-infos__info">
                                                                <ProfileSocialMedia editProfileInfo={false}
                                                                                    isMyProfile={profileInfo?.isMyProfile}
                                                                                    platforms={profileInfo?.platforms}
                                                                />
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </Container>
                )
            }
        </div>
    );
};

export default ProfileInformation;
import {Col, Container, Row} from "react-bootstrap";
import classes from "../../components/Styles/ProfileCards.module.scss";
import {FolderIcon, StarFill} from "../../components/Icons/Icons";
import {I18N} from "../../i18n/i18n";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"
import moment from "moment";

export function ProfileReviews({userReviewStatistic, reviewsData, isLoading}: any) {
    const roundTwoDigit = Math.round((userReviewStatistic?._avg?.rating) * 100) / 100
    return (
        <div className={`${isLoading && 'filter-blur'}`}>
            <Row className={`${classes.bg_white} ${classes.box_shadow} p-0`}>
                <Col xl={12}
                     className="py-4 d-flex justify-content-between flex-column border-bottom flex-xl-row align-items-center">
                    <div className="d-flex align-items-center">
                        <span className={`${classes.in_card_main_title} me-4`}>
                            {I18N('overall_rating', 'de', [{
                                key: 'overall_rating',
                                value: I18N('overall_rating', 'de')
                            }])}
                        </span>
                        <StarFill/>
                        <span
                            className={`${classes.in_card_main_title} ms-2`}>{roundTwoDigit}</span>
                        <span className="text-muted ms-2">({userReviewStatistic?._count?._all})</span>
                    </div>
                </Col>
                {reviewsData?.length != 0 ?
                    <Col xl={12} className={`pb-2 ${classes.review_scroll_box}`}>
                        {
                            reviewsData?.map?.((reviewData: any) => {
                                const acceptedDate = new Date(reviewData?.date)
                                let dateMDY =
                                    moment(acceptedDate).format('DD.MM.YYYY HH:mm');
                                return (
                                    <div className="border-bottom" key={reviewData?.bookingId}>
                                        <Rosa animation={'fade-right'} offset={500} duration={500}>

                                            <div
                                                className="mt-4 d-flex align-items-center flex-column flex-xl-row">
                                                <img
                                                    src={!(reviewData?.user?.portfolio?.picture) ? '/user.jpg' : reviewData?.user?.portfolio?.picture}
                                                    className={classes.user_comment_picture}
                                                    onError={(e: any) => (e.target.onerror = null, e.target.src = '/user.jpg')}
                                                    alt="Profile pic of user"/>
                                                <div
                                                    className="mt-3 mt-xl-0 d-flex align-items-center align-items-md-start flex-column mx-3">
                                                    <div className="d-flex align-items-center">
                                                        <span
                                                            className={`${classes.in_card_title} fw-bold me-3`}>
                                                            {reviewData?.user?.firstName} {reviewData?.user?.lastName}
                                                        </span>
                                                        <StarFill/>
                                                        <span
                                                            className={`fw-bold ms-1`}>
                                                            {reviewData?.rating}
                                                        </span>
                                                    </div>
                                                    <span className="py-3">{(reviewData?.message?.length === 0) ?
                                                        <p className="text-muted mb-0">I18N('noData',
                                                            'de')</p> : reviewData?.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <span
                                                className="d-flex justify-content-md-end justify-content-center fw-bold">
                                                {I18N('reviewed_at', 'de', [{
                                                    key: 'reviewed_at',
                                                    value: I18N('reviewed_at', 'de')
                                                }])}
                                                <span className="fw-normal pb-3 ms-1 text-muted">
                                                    {dateMDY}
                                                </span>
                                            </span>
                                        </Rosa>
                                    </div>
                                )
                        })}
                    </Col> : (
                        <span className="py-4 text-center fw-bold">
                            {I18N('no_review_rating', 'de', [{
                                key: 'no_review_rating',
                                value: I18N('no_review_rating', 'de')
                            }])}
                        </span>
                    )
                }
            </Row>
        </div>
    );
}
import {ProfilePictures} from "../ProfilePictures";
import {ProfileReviews} from "../ProfileReviews";
import React, {useEffect, useState} from "react";
import {
    getAllMusicCategory,
    getAllPortfolioJobs,
    getUserPortfolio,
} from "../../../Helper/API";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {
    BOOKING_STATUS,
    BookingTechnicalStaff,
    iBookUser,
} from "../ProfileModals/ProfileModals";
import LoadingPage from "../../Information/LoadingPage";
import {ProfileCalendar} from "../ProfileCalendar";
import "./Profile.scss"
import ProfileInformation from "../ProfileInformation/ProfileInformation";
import {ICategory, IProfileInfo, IProfileJobs} from "../../../Helper/Interfaces/IProfile";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import ProfileAboutMe from "../ProfileAboutMe/ProfileAboutMe";
import ProfileSoundcloud from "../ProfileSoundcloud/ProfileSoundcloud";
import {ProfileBooking} from "../ProfileBooking";
import ProfileSection from "../ProfileSection/ProfileSection";
import {I18N} from "../../../i18n/i18n";
import classes from "../../../components/Styles/ProfileAdmin.module.scss";
import HeaderImage from "../HeaderImage/HeaderImage";
import InstaGallery from "../../../components/InstaGallery/InstaGallery";

export interface iUserProfilePicture {
    picture?: HTMLImageElement
}

export interface iCategory {
    id?: number,
    name?: string
}

export enum BirthdayDisplayEnum {
    NONE = 'NONE',
    YEAR = 'YEAR',
    DATE = 'DATE',
}

export interface iUserInfo {
    bandName: string | null,
    birthdayDisplay?: BirthdayDisplayEnum,
    birthday?: string,
    concertDuration?: string,
    location?: number,
    categories: any[],
    jobs: any[],
    isMyProfile: boolean,
    locationSize: string
}

export interface iUserMusicCategory {
    name: string;
}

export interface iForgotPassword {
    email: string
}

export interface iNewPassword {
    password?: string,
    rePassword?: string,
    token: string
}

export interface iBankInfo {
    IBAN: string,
    BIC: string,
    bankName: string
}

export interface iWorkingDays {
    from: string,
    to: string,
    day: number
}

export interface iChangeLanguage {
    language: string
}

export default function Profile() {

    const [profileInformation, setProfileInformation] = useState<IProfileInfo | null>(null)
    const [categories, setCategories] = useState<Array<ICategory> | null>(null)
    const [jobs, setJobs] = useState<Array<IProfileJobs> | null>(null);

    const {id} = useParams<{id: string}>();
    const history = useHistory();

    const [editAboutMe, setEditAboutMe] = useState<boolean>(false)
    const [galleryModalOpen, setGalleryModalOpen] = useState<boolean>(false)
    const [editBio, setEditBio] = useState<boolean>(false)
    const [editAvailability, setEditAvailability] = useState<boolean>(false)
    const [editSoundcloud, setEditSoundCloud] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [defaultBooking, setDefaultBooking] = useState<iBookUser>({
        startDate: new Date().toString(),
        endDate: new Date().toString(),
        message: '',
        locationId: 0,
        occasionId: 0,
        technology: "",
        technicalStaff: BookingTechnicalStaff.FALSE,
        numberOfHours: undefined,
        name: '',
        pricePerHour: undefined,
        status: BOOKING_STATUS.PENDING,
        userId: +id
    });

    const updateDescription = (description: string | null) => {
        if(description) {
            setProfileInformation((prevState: any) => ({...prevState, description: description}))
        }
    }

    const changeProfileInfo = (profileInfo: IProfileInfo | null) => {
        if(profileInformation && profileInfo) {
            Object.keys(profileInfo).map((profileInfoKey)=> {
                setProfileInformation((prevState: any) => ({...prevState, [profileInfoKey]: profileInfo[profileInfoKey]}))
            })
        }
    }

    const refreshProfileGallery = (newProfileGalleryItems: Array<any>) => {
        setProfileInformation((prevState: any) => ({...prevState, gallery: newProfileGalleryItems}))
    }

    useEffect(() => {
        setProfileInformation(null);
        setIsLoading(true)
        getUserPortfolio(id).then(data => {
            if (data) {
                setProfileInformation(data)
            }
        }).catch(err => err)
        getAllMusicCategory().then(data => {
            setCategories(data)
        }).catch(error => error);
        getAllPortfolioJobs().then(data => {
            setJobs(data);
        }).catch(error => error);
        setIsLoading(false)
    }, [id])

    return (
        <div className="Profile">
            {(isLoading && !profileInformation) && <LoadingPage/>}
            {
                (profileInformation && categories && jobs) &&
                    <>
                        <HeaderImage headerImg={profileInformation?.headerImg}
                                     isMyProfile={profileInformation.isMyProfile}
                        />
                        <ProfileHeader user={profileInformation.user}
                                       picture={profileInformation?.picture}
                                       jobs={profileInformation?.jobs}
                                       isMyProfile={profileInformation?.isMyProfile}
                                       isFavorite={profileInformation?.isFavorite}
                        />
                        <ProfileInformation profileInformation={profileInformation}
                                            setProfileInformation={changeProfileInfo}
                                            categories={categories}
                                            jobs={jobs}
                        />
                        <ProfileSection iconSrc={'/account.svg'}
                                        isMyProfile={profileInformation?.isMyProfile}
                                        title={'Something About Me'}
                                        setEdit={setEditAboutMe}
                        >
                            <ProfileAboutMe description={profileInformation?.description}
                                            isMyProfile={profileInformation?.isMyProfile}
                                            setDescription={updateDescription}
                                            editAboutMe={editAboutMe}
                                            setEditAboutMe={setEditAboutMe}
                                            firstName={profileInformation.user?.firstName}
                            />
                        </ProfileSection>
                        {
                            (profileInformation?.user?.type === 'ARTIST' && (profileInformation?.soundcloudUrl || profileInformation?.isMyProfile)) && (
                                <ProfileSection iconSrc={"/SVG/icon_audio_48px.svg"}
                                                isMyProfile={profileInformation?.isMyProfile}
                                                title={'Soundcloud'}
                                                setEdit={setEditSoundCloud}
                                >
                                    <ProfileSoundcloud edit={editSoundcloud}
                                                       setEdit={setEditSoundCloud}
                                                       soundcloudUrl={profileInformation?.soundcloudUrl}
                                                       isMyProfile={profileInformation?.isMyProfile}
                                                       setProfileInformation={setProfileInformation}
                                    />
                                </ProfileSection>
                            )
                        }
                        <ProfileSection isMyProfile={profileInformation.isMyProfile} iconSrc={"/SVG/icon_insta_48px.svg"} title={"Instagram"}>
                            <InstaGallery/>
                        </ProfileSection>
                    </>
            }
            {
                !profileInformation?.isMyProfile && profileInformation?.user?.type === 'ARTIST' &&
                    <ProfileSection title={"Buchen"} iconSrc={"/calendar-icon.svg"} isMyProfile={!profileInformation?.isMyProfile}>
                        <ProfileBooking defaultBooking={defaultBooking}
                                        setDefaultBooking={setDefaultBooking}
                        />
                    </ProfileSection>
            }
            {
                    <ProfileCalendar myProfile={profileInformation?.isMyProfile} userType={profileInformation?.user?.type} myID={profileInformation?.userId}/>
            }
            {
                (((profileInformation?.user?.type === 'ARTIST') && (profileInformation?.gallery.length > 0)) || profileInformation?.isMyProfile) &&
                    <>
                        <ProfileSection title={I18N('pictures')}
                                        iconSrc={'/image-card.svg'}
                                        iconSize={2}
                                        isMyProfile={profileInformation!.isMyProfile}
                                        setEdit={setGalleryModalOpen}
                        >
                            <ProfilePictures profileInformation={profileInformation}
                                             setGalleryModalOpen={setGalleryModalOpen}
                                             galleryModalOpen={galleryModalOpen}
                                             refreshProfileGallery={refreshProfileGallery}
                            />
                        </ProfileSection>
                        { /*
                            (profileInformation.isMyProfile || profileInformation?.availability.length !== 0) && (
                                <ProfileSection iconSrc={"/alarm-icon.svg"}
                                                title={I18N('availability', 'de')}
                                                iconSize={2}
                                                isMyProfile={profileInformation!.isMyProfile}
                                                setEdit={setEditAvailability}
                                >
                                    <ProfileAvailability editAvailability={editAvailability}
                                                         setEditAvailability={setEditAvailability}
                                                         availability={profileInformation?.availability}
                                                         userID={profileInformation?.userId}
                                                         setProfileInformation={setProfileInformation}
                                    />
                                </ProfileSection>
                            )
                         */ }
                    </>
            }
            {
                profileInformation &&
                    <ProfileSection iconSrc={"/SVG/icon_star-full_48px.svg"}
                                                           title={I18N("reviews")}
                                                           iconSize={2.5}
                                                           isMyProfile={profileInformation?.isMyProfile}
                    >
                        <ProfileReviews userReviewStatistic={profileInformation?.reviewStatistic}
                                        isLoading={isLoading}
                                        reviewsData={profileInformation?.reviews}
                        />
                    </ProfileSection>
            }
        </div>
    );
}
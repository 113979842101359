import { FindOutMoreButton } from '../../../components/Buttons/Buttons'
import classes from './ArtistsSection.module.scss'
import { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/a11y/a11y.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import { ImageSlider, ImageSliderElement } from './ImageSlider/imageSlide';

export function ArtistsSection() {
    const IMAGESLIDEELEMENTS: ImageSliderElement[] = [
        { imgSrc: '/artistImages/firstImg.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/artistImages/secondImg.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/artistImages/thirdImg.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
        { imgSrc: '/artistImages/lastImg.jpg', headline: 'NAME ARTIST', bodyText: 'dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' },
    ]
    return (
        <section className={classes.section}>
            <div className={classes.sectionHeadline} id="artists">Artists</div>
            <div className={classes.artistsTopConatiner}>
                <div className={classes.textfield}>

                    <div className={classes.headline}>
                        UPGRADE YOUR BUSINESS
                    </div>
                    <div className={classes.bodyText}>
                        One of the most important challenges of each artist is not only to be an entrepreneur, but also be an influencer, content creator and
                        others. We will help you achieve your goals.
                    </div>
                    <div className={classes.button}>
                        <FindOutMoreButton onClick={() => { }} name={'FIND OUT MORE'} primary={'white'} />
                    </div>
                </div>
            </div>
            <ImageSlider elements={IMAGESLIDEELEMENTS} />
        </section>
    )
}
import axios, {AxiosResponse} from 'axios';
import {toast} from 'react-toastify';


const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/html, application/pdf',
    },
    validateStatus: function (status: number) {
        return status >= 200 && status < 300
    }
};

export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token") ?? "{}"
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const rejectResponse = (arg: any) => Promise.reject(arg)

export const requests = {
    get: (url: string, params?: any) => instance.get(url, {params}).then(responseBody).catch(e => {
        if (e?.response?.status === 401 && !window.location.href.includes('website')) {
            localStorage.setItem('token','')
            localStorage.setItem('type','')
            setTimeout(() => {
                window.location.href = '/website'
            }, 500)
        }
        throw e
    }),
    post: (url: string, body?: {}) => instance.post(url, body).then(responseBody, rejectResponse).catch(e => {
        throw e
    }),
    put: (url: string, body?: {}) => instance.put(url, body).then(responseBody).catch(e => {
        throw e
    }),
    patch: (url: string, body: {}) => instance.patch(url, body).then(responseBody).catch(e => {
        throw e
    }),
    delete: (url: string) => instance.delete(url).then(responseBody).catch(e => {
        throw e
    }),
};


export function apiErrorHandler(e: any, callback?: ((args?: any) => void), handler?: any) {
    if (callback) {
        callback(handler)
    }

    let message = e.response?.data?.message ? e.response.data.message : 'Etwas ist schief gelaufen';

    if (Array.isArray(message)) {
        message = e.response?.data?.message[0]
    }

    toast.error(message, {
        progress: undefined,
    });
    toast.clearWaitingQueue();
}

export function apiErrorHandlerBadRequest(props: any) {
    toast.error(props, {
        progress: undefined,
    });
}

export function apiSuccessHandler(props: any) {
    toast.success(props, {
        progress: undefined,
    });
}

export function apiWarningHandler(props: any) {
    toast.warning(props, {
        progress: undefined,
    });
}


import React, {useEffect, useRef, useState} from 'react';
import "../Profile/Profile.scss"
import {Button, Modal} from "react-bootstrap";
import {I18N} from "../../../i18n/i18n";
import {ProfilePicture} from "../ProfileModals/ProfileModals";
import {SaveChangeButton} from "../../../components/Buttons/Buttons";
import {uploadHeaderImage, uploadProfilePicture} from "../../../Helper/API";
import {apiSuccessHandler} from "../../../Helper/api.services";
import {CircularProgress} from "@mui/material";

interface IProps {
    headerImg: string | null | undefined
    isMyProfile: boolean
}

export const HeaderImage = (props: IProps) => {

    const uploadInputRef = useRef<HTMLInputElement>()
    const [showPhotoUploadModal, setShowPhotoUploadModal] = useState<boolean>(false)
    const [headerImg, setHeaderImg] = useState("")
    const [headerImageLoading, setHeaderImageLoading] = useState<boolean>(false)

    useEffect(()=>{
        if(props.headerImg){
            setHeaderImg(props.headerImg)
        }
    },[])

    const saveHeaderImg = () => {
        setShowPhotoUploadModal(false)
        setHeaderImageLoading(true)
        if (!uploadInputRef?.current?.files) return
        const data = new FormData();
        for (let file in uploadInputRef.current.files) {
            data.append('headerImg', uploadInputRef.current.files[file])
        }
        uploadHeaderImage(data!).then(res => {
            if (!res.data) {
                setHeaderImg(res.headerImg)
                apiSuccessHandler(
                    <>
                        {I18N('change_picture_success', 'de', [{
                            key: 'change_picture_success',
                            value: I18N('change_picture_success', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error).finally(() => {
            setHeaderImageLoading(false)
        });

    }

    return (
        <div className="Profile__header-image"
             style={{background: headerImg !== "" ? `no-repeat url('${headerImg}') center` : 'no-repeat url("../default_header.jpg") center'}}
        >
            {
                props.isMyProfile &&
                    <div className="Profile__header-image__button-wrapper">
                        <button className="ProfileInformation__button-group__button"
                                onClick={() => {
                                    setShowPhotoUploadModal(true)
                                }}>
                            <img src="/SVG/icon_edit_48px.svg"/>
                        </button>
                    </div>
            }
            {
                headerImageLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                )
            }
            <>
                <Modal show={showPhotoUploadModal} onHide={() => setShowPhotoUploadModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {I18N('change_profile_picture', 'de', [{
                                key: 'change_profile_picture',
                                value: I18N('change_profile_picture', 'de')
                            }])}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProfilePicture refHandler={uploadInputRef}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoUploadModal(false)}>
                            {I18N('close', 'de', [{
                                key: 'close',
                                value: I18N('close', 'de')
                            }])}
                        </Button>
                        <SaveChangeButton clickHandler={saveHeaderImg}/>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
};

export default HeaderImage;
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Image, Row } from "react-bootstrap";
import classes from "../../components/Styles/Registration.module.scss";
import { RegisterButton } from "../../components/Buttons/Buttons";
import {
    RegistrationInput,
    RegistrationInputPassword
} from "../../components/Input/LoginInput";
import { useEffect, useState } from "react";
import { ConfirmSocialAPI, RegistrationAPI as register } from '../../Helper/API';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
    ArrowBack, ArrowBackRegistrationIcon,
    Briefcase,
    FacebookIcon,
    GmailIcon,
    Microphone,
    ReturnIcon,
    ReturnIconGray
} from "../../components/Icons/Icons";
import { I18N } from "../../i18n/i18n";
import { apiWarningHandler } from "../../Helper/api.services";


export interface ILogin {
    email: string;
    password: string;
    rePassword?: string;
}

export enum BRAND {
    ARTIST = "ARTIST",
    CLUB = "CLUB"
}

export enum ROLES {
    ARTIST = "ARTIST",
    CLUB = "CLUB"
}

export interface IRegister extends ILogin {
    firstName: string;
    lastName: string;
    type: string;
    roles: string;
}


export function Registration() {
    const token = useQuery("token");
    const refreshToken = useQuery("refreshToken");

    const history = useHistory();

    const PASSWORD_LENGHT: number = 8;
    const EMAIL_VALID: RegExp = /^[\w-/.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const [formData, setFormData] = useState<IRegister>({
        email: '',
        password: '',
        rePassword: '',
        firstName: '',
        lastName: '',
        type: '',
        roles: ''
    });

    const [error, setError] = useState<string>("");
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [step, setStep] = useState<'first' | 'second'>('first');
    const [clickedForm, setClickedForm] = useState<'artist' | 'musicer'>();

    useEffect(() => {
        if (!EMAIL_VALID.test(formData.email)) {
            setValidEmail(false)
        } else {
            setValidEmail(true)
        }
    }, [formData.email])


    function isValid(): void {
        if (formData.password.length < PASSWORD_LENGHT || !EMAIL_VALID.test(formData.email) || formData.password !== formData.rePassword) {
            setFormIsValid(false);
        } else {
            setFormIsValid(true);
        }
    };

    function handleFurther(): void {
        if (validEmail) {
            setStep('second')
        } else {
            apiWarningHandler(
                <>
                    {I18N('email_not_valid', 'de', [{
                        key: 'email_not_valid',
                        value: I18N('email_not_valid', 'de')
                    }])}
                </>
            )
            return
        }
    };

    function stepBack(): void {
        setStep('first');
    }

    async function _login(e: any): Promise<void> {
        e.preventDefault();

        await register(formData).then(res => {
            setError("")
            history.push('/website');
        }).catch(error => {
            setError("Something went wrong")
        })
    }

    function handlerChange(event: any, type: string): void {
        if (type === 'musicer') {
            setFormData(prev => ({ ...prev, type: BRAND.ARTIST, roles: ROLES.ARTIST }));
        }

        if (type === 'artist') {
            setFormData(prev => ({ ...prev, type: BRAND.CLUB, roles: ROLES.CLUB }));
        }

        setFormData(prev => ({ ...prev, [event.target.name]: event.target.value }));
    }

    useEffect(() => {
        isValid()
        if (formData.firstName || formData.email || formData.lastName) {
            if (formData.type === BRAND.ARTIST) {
                setClickedForm('musicer');

            } else {
                setClickedForm('artist');

            }
        } else {
            setClickedForm(undefined);
        }
    }, [formData])

    const goToWebsite = () => {
        history.push('/website');
    }

    const backToLogin = () => {
        history.push('/website');
    }

    return (
        <div className={classes.Registration}>
            <div className={classes.header}>
                <Container>
                    <p className={classes.main_title}>
                        {I18N('registerYourself', 'de', [{
                            key: 'registerYourself',
                            value: I18N('registerYourself', 'de')
                        }])}
                    </p>
                </Container>
            </div>
            <Container className="mt-4">

                {clickedForm === undefined &&
                <div
                    className={`${classes.backbutton} d-flex align-items-center justify-content-center cursor_pointer`}
                    onClick={() => backToLogin()}>

                    <ArrowBackRegistrationIcon />
                    <span className="ms-2">
                        {I18N('backToLogin', 'de')}
                    </span>
                </div>

                }
                <Container className="my-5">

                    {clickedForm === undefined && <h3 className={(classes.subHeadline) + "text-center text-md-start"}>
                        {I18N('what_are_you', 'de', [{
                            key: 'what_are_you',
                            value: I18N('what_are_you', 'de')
                        }])}
                    </h3>}
                    <Row className={`${clickedForm === 'artist' && `flex-row-reverse`}`}>

                        {clickedForm === undefined &&
                        <Col md={12} className="d-flex justify-content-center mt-5">
                            <div
                                className={`${classes.whatareyoubuttons} d-flex align-items-center justify-content-center cursor_pointer`}
                                onClick={() => setClickedForm('musicer')}>
                                <Microphone />
                                <span className="ms-2">
                                    {I18N('musician', 'de', [{
                                        key: 'musician',
                                        value: I18N('musician', 'de')
                                    }])}
                                </span>
                            </div>
                        </Col>}
                        {clickedForm === undefined &&
                        <Col md={12} className="d-flex justify-content-center mt-5">
                            <div
                                className={`${classes.whatareyoubuttons} d-flex align-items-center justify-content-center cursor_pointer`}
                                onClick={() => setClickedForm('artist')}>

                                <Briefcase />
                                <span className="ms-2">
                                    {I18N('organizer', 'de', [{
                                        key: 'organizer',
                                        value: I18N('organizer', 'de')
                                    }])}
                                </span>
                            </div>
                        </Col>
                        }
                        {clickedForm === undefined &&
                        <hr className="w-50 mt-5 mx-auto" />
                        }

                        {clickedForm !== undefined && <Col className="mt-5 mt-xl-0 d-flex justify-content-center" xl={5}>
                            {(clickedForm === 'artist') ?
                                <>
                                    {step === 'first' &&
                                    <>
                                        <div className={`${classes.card}`}>
                                            <div className={classes.head}>
                                                <Briefcase />
                                                <p>
                                                    {I18N('organizer', 'de', [{
                                                        key: 'organizer',
                                                        value: I18N('organizer', 'de')
                                                    }])}
                                                </p>
                                            </div>

                                            <div className={classes.body}>
                                                <RegistrationInput state={formData}
                                                                   changeHandler={(e) => handlerChange(e, 'artist')} />
                                                <p className="mt-3">*
                                                    {I18N('requredFields', 'de', [{
                                                        key: 'requredFields',
                                                        value: I18N('requredFields', 'de')
                                                    }])}
                                                </p>
                                                <div onClick={() => handleFurther()}>
                                                    <button
                                                        disabled={!formData.firstName && !formData.email}
                                                        className={`${classes.btn_primary} mt-3`}>
                                                        {I18N('further', 'de', [{
                                                            key: 'further',
                                                            value: I18N('further', 'de')
                                                        }])}
                                                    </button>
                                                </div>
                                                <div className="d-flex justify-content-center my-3">
                                                    <span className="or text-center">
                                                        {I18N('or', 'de', [{
                                                            key: 'or',
                                                            value: I18N('or', 'de')
                                                        }])}
                                                    </span>
                                                </div>
                                                <div onClick={() => setClickedForm('musicer')}>
                                                    <button className={classes.btn_primary}>
                                                        {I18N('are_you_artist', 'de')}
                                                    </button>
                                                </div>

                                                <Link to="/website"
                                                      className={(classes.link) + " text_decoration_none cursor_pointer d-flex justify-content-center mt-4 align-items-center"}>
                                                    <ReturnIconGray />
                                                    <span>
                                                       {I18N('alreadyRegister', 'de', [{
                                                           key: 'alreadyRegister',
                                                           value: I18N('alreadyRegister', 'de')
                                                       }])}
                                                    </span>
                                                </Link>
                                                <hr className="bg-secondary w-75 mx-auto" />

                                                <div

                                                    className="d-flex align-items-center justify-content-between flex-column flex-md-row mt-3">
                                                    <div
                                                        className="d-flex justify-content-center d-md-block mb-3 mb-md-0 hover-opacity cursor_pointer"
                                                        onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'facebook')}>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"
                                                                  id="basic-addon1">
                                                                <FacebookIcon />
                                                            </span>
                                                            <div className="form-control social-text" aria-label="Username"
                                                                 aria-describedby="basic-addon1">
                                                                {I18N('sign_in_with', 'de', [{
                                                                    key: 'network',
                                                                    value: 'Facebook'
                                                                }])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'google')}
                                                         className="d-flex justify-content-center d-md-block hover-opacity cursor_pointer">

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"
                                                                  id="basic-addon1">
                                                                <GmailIcon />
                                                            </span>
                                                            <div className="form-control social-text" aria-label="Username"
                                                                 aria-describedby="basic-addon1">
                                                                {I18N('sign_in_with', 'de', [{
                                                                    key: 'network',
                                                                    value: 'Google'
                                                                }])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {step === 'second' &&
                                    <>
                                        <div className={`${classes.card}`}>
                                            <div className={classes.head2}>
                                                <div onClick={stepBack} className="d-flex align-items-center">
                                                    <ArrowBack />
                                                    <span className="ms-2">
                                                        {I18N('back', 'de', [{
                                                            key: 'back',
                                                            value: I18N('back', 'de')
                                                        }])}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classes.body}>
                                                <Image src="/progressBar.png" className="w-100 mb-4" alt="Progress Bar" />
                                                <span className={classes.title_text}>
                                                    {I18N('enterPassword', 'de', [{
                                                        key: 'enterPassword',
                                                        value: I18N('enterPassword', 'de')
                                                    }])}
                                                </span>
                                                <div className="mt-5">
                                                    <RegistrationInputPassword state={formData}
                                                                               changeHandler={(e) => handlerChange(e, 'artist')} />
                                                </div>

                                                <RegisterButton clickHandler={_login} formIsValid={formIsValid} />
                                                <p className="mt-3">*
                                                    {I18N('requredFields', 'de', [{
                                                        key: 'requredFields',
                                                        value: I18N('requredFields', 'de')
                                                    }])}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </> :
                                <div className={classes.login_section_image}>

                                    <Image style={{ height: '100%', width: '100%', zIndex: -200, objectFit: 'cover' }}
                                           src="/graySinger.png"
                                           alt="Login Singer picture" />
                                    <div style={{
                                        height: '30%',
                                        width: '100%',
                                        position: 'relative',
                                        bottom: '30%',
                                        backdropFilter: 'blur(30px)',
                                        filter: 'brightness(0.8)',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>

                                    </div>
                                    <div style={{
                                        height: '30%',
                                        width: '100%',
                                        position: 'relative',
                                        bottom: '60%',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>

                                        <div style={{
                                            inlineSize: '80%',
                                            overflowWrap: 'anywhere',
                                            position: 'relative',
                                            zIndex: 100
                                        }}>
                                            <div style={{ fontSize: 25, color: '#fff', fontWeight: 'bold' }}>
                                                {I18N('welcome_to_muve')}
                                            </div>
                                            <div style={{ fontSize: 16, color: '#fff', }}>
                                                {I18N('login_text')}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            }
                        </Col>}

                        {clickedForm !== undefined && <Col xl={2} className="d-flex justify-content-center">
                            <div className={classes.black_line}></div>
                        </Col>}

                        {clickedForm !== undefined && <Col xl={5} className="mt-5 mt-xl-0 d-flex justify-content-center">
                            {(clickedForm === 'musicer') ?
                                <>
                                    {step === 'first' &&
                                    <>
                                        <div className={`${classes.card}`}>
                                            <div className={classes.head}>
                                                <Microphone />
                                                <p>
                                                    {I18N('musician', 'de', [{
                                                        key: 'musician',
                                                        value: I18N('musician', 'de')
                                                    }])}
                                                </p>
                                            </div>
                                            <div className={classes.body}>
                                                <RegistrationInput state={formData}
                                                                   changeHandler={(e) => handlerChange(e, 'musicer')} />
                                                <p className="mt-3">*
                                                    {I18N('requredFields', 'de', [{
                                                        key: 'requredFields',
                                                        value: I18N('requredFields', 'de')
                                                    }])}
                                                </p>
                                                <div onClick={() => handleFurther()}>
                                                    <button disabled={!formData.firstName && !formData.email}
                                                            className={`${classes.btn_primary} mt-3`}>
                                                        {I18N('further', 'de', [{
                                                            key: 'further',
                                                            value: I18N('further', 'de')
                                                        }])}
                                                    </button>
                                                </div>

                                                <div className="d-flex justify-content-center my-3">
                                                    <span className="or text-center">
                                                        {I18N('or', 'de', [{
                                                            key: 'or',
                                                            value: I18N('or', 'de')
                                                        }])}
                                                    </span>
                                                </div>
                                                <div onClick={() => setClickedForm('artist')}>
                                                    <button className={classes.btn_primary}>
                                                        {I18N('are_you_club', 'de')}
                                                    </button>
                                                </div>
                                                <Link to="/website"
                                                      className={(classes.link) + " text_decoration_none cursor_pointer d-flex justify-content-center mt-4 align-items-center"}>
                                                    <ReturnIconGray />
                                                    <span>
                                                      {I18N('alreadyRegister', 'de', [{
                                                          key: 'alreadyRegister',
                                                          value: I18N('alreadyRegister', 'de')
                                                      }])}
                                                    </span>
                                                </Link>
                                                <hr className="bg-secondary w-75 mx-auto" />
                                                <div

                                                    className="d-flex align-items-center justify-content-between flex-column flex-md-row mt-3">
                                                    <div
                                                        className="d-flex justify-content-center d-md-block mb-3 mb-md-0 hover-opacity cursor_pointer"
                                                        onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'facebook')}>

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"
                                                                  id="basic-addon1">
                                                                <FacebookIcon />
                                                            </span>
                                                            <div className="form-control social-text" aria-label="Username"
                                                                 aria-describedby="basic-addon1">
                                                                {I18N('sign_in_with', 'de', [{
                                                                    key: 'network',
                                                                    value: 'Facebook'
                                                                }])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => ConfirmSocialAPI(token!, refreshToken!, 'google')}
                                                         className="d-flex justify-content-center d-md-block hover-opacity cursor_pointer">

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text"
                                                                  id="basic-addon1">
                                                                <GmailIcon />
                                                            </span>
                                                            <div className="form-control social-text" aria-label="Username"
                                                                 aria-describedby="basic-addon1">
                                                                {I18N('sign_in_with', 'de', [{
                                                                    key: 'network',
                                                                    value: 'Google'
                                                                }])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {step === 'second' &&
                                    <>
                                        <div className={`${classes.card} `}>
                                            <div className={classes.head2}>
                                                <div onClick={stepBack} className="d-flex align-items-center">
                                                    <ArrowBack />
                                                    <span className="ms-2">
                                                        {I18N('back', 'de', [{
                                                            key: 'back',
                                                            value: I18N('back', 'de')
                                                        }])}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classes.body}>
                                                <p className={classes.title_text}>
                                                    {I18N('enterPassword', 'de', [{
                                                        key: 'enterPassword',
                                                        value: I18N('enterPassword', 'de')
                                                    }])}
                                                </p>
                                                <div className="mt-3">
                                                    <RegistrationInputPassword state={formData}
                                                                               changeHandler={(e) => handlerChange(e, 'musicer')} />
                                                </div>

                                                <RegisterButton clickHandler={_login} formIsValid={formIsValid} />

                                                <p className="mt-3">*
                                                    {I18N('requredFields', 'de', [{
                                                        key: 'requredFields',
                                                        value: I18N('requredFields', 'de')
                                                    }])}
                                                </p>
                                            </div>
                                        </div>
                                    </>}
                                </> : <div className={classes.login_section_image}>
                                    <Image style={{ height: '100%', width: '100%', zIndex: -200, objectFit: 'cover' }}
                                           src="/graySinger.png"
                                           alt="Login Singer picture" />
                                    <div style={{
                                        height: '30%',
                                        width: '100%',
                                        position: 'relative',
                                        bottom: '30%',
                                        backdropFilter: 'blur(30px)',
                                        filter: 'brightness(0.8)',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                    </div>
                                    <div style={{
                                        height: '30%',
                                        width: '100%',
                                        position: 'relative',
                                        bottom: '60%',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{
                                            inlineSize: '80%',
                                            overflowWrap: 'anywhere',
                                            position: 'relative',
                                            zIndex: 100
                                        }}>
                                            <div style={{ fontSize: 25, color: '#fff', fontWeight: 'bold' }}>
                                                {I18N('welcome_to_muve')}
                                            </div>
                                            <div style={{ fontSize: 16, color: '#fff' }}>
                                                {I18N('login_text')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Col>}
                    </Row>
                </Container>
            </Container>
        </div>
    );
}


// export default withRouteGuard({guard: () => !isGuestUser, fallbackPath: '/profile'})(Registration)

function useQuery(key: string) {
    return new URLSearchParams(useLocation().search).get(key);
}

export interface IProfileName {
    firstName: string,
    lastName: string
}

export interface IProfileInfo {
    availability: Array<IAvailabilty> | [],
    bandName: string | null,
    biography: Array<IBiography> | [],
    birthday: string | null,
    birthdayDisplay: BirthdayDisplayEnum | null | string,
    categories: Array<ICategory> | [],
    coordinates: IUserCoordinates | null,
    createdAt: string,
    description: string | null,
    gallery: Array<IUserGallery> | [],
    headerImg: string | null,
    id: number,
    isFavorite: boolean,
    isMyProfile: boolean,
    jobs: Array<IProfileJobs> | [],
    lat: string | null,
    location: number | null,
    locationSize: string | null,
    logo: string | null,
    lon: string | null,
    picture: string | null,
    phoneNumber: string | null,
    platforms: Array<IUserSocialNetwork> | [],
    priceDescription: string | null,
    pricePerEvent: number | null,
    pricePerHour: number | null,
    reviewStatistic: IUserReviewStatistic,
    reviews: Array<IReviewData> | [],
    soundcloudUrl: string | null,
    street: string | null,
    taxNumber: string | null,
    technology: string | null,
    user: IUser,
    userId: number,
    vat: boolean | string
}

export interface IAvailabilty {
    day: number,
    start: string,
    end: string,
    userID: number,
    ID?: number,

}

export interface IUser {
    createdAt: string,
    firstName: string,
    id: number,
    lastName: string,
    type: string
    email?: string
}

export interface IBiography {
    id: number,
    createdAt: string,
    description: string,
    from: string,
    to: string,
    portfolioId: number
}

export interface IUserCoordinates {
    id: number
    lat: string
    lon: string
    name: string
    zipCode: string
}


export interface IUserSocialNetwork {
    link: string;
    id?: number;
    name?: string;
    platformType?: string;
}

export interface IProfileJobs {
    id: number,
    name: string
}

export interface IUserGallery {
    picture?: string,
    id?: number,
    portfolioId?: number,
    thumbnail?: string
}

export interface IUserReviewStatistic {
    _avg: {
        rating: number | null
    },
    _count: {
        _all: number
    }
}

export interface IReviewData {
    bookingId?: number,
    date?: string,
    from?: number,
    id?: number,
    message?: string,
    rating?: number,
    title?: string,
    to?: number
    user?: {
        firstName?: string,
        lastName?: string,
        id?: number,
        portfolio?: {
            picture?: string
        }
    }
}

export interface ICategory {
    id?: number,
    value?: number,
    name?: string,
    label?: string
}

export enum BirthdayDisplayEnum {
    NONE = 'NONE',
    YEAR = 'YEAR',
    DATE = 'DATE',
}

export interface IForgotPassword {
    email: string
}

export interface INewPassword {
    password?: string,
    rePassword?: string,
    token: string
}

export interface IBankInfo {
    IBAN: string,
    BIC: string,
    bankName: string
}

export interface IWorkingDays {
    from: string,
    to: string,
    day: number
}

export interface IChangeLanguage {
    language: string
}
import React, {useEffect, useState} from 'react';
import "./ProfileAboutMe.scss"
import {Clear, Done} from "@mui/icons-material";
import {SxProps, TextField} from "@mui/material";
import {updateUserPortfolio} from "../../../Helper/API";
import {apiSuccessHandler} from "../../../Helper/api.services";
import {I18N} from "../../../i18n/i18n";
import {IProfileInfo} from "../../../Helper/Interfaces/IProfile";

interface IProfileAboutMeProps {
    description: string | null,
    isMyProfile: boolean | null,
    setDescription: (description: string | null) => void,
    editAboutMe: boolean,
    setEditAboutMe: React.Dispatch<React.SetStateAction<boolean>>,
    firstName: string | null,
}

const ProfileAboutMe = (props: IProfileAboutMeProps) => {

    const [editDescription, setEditDescription] = useState(false)
    const [description, setDescription] = useState("")
    const [updatedDescription, setUpdatedDescription] = useState<{description: string}>({description: ""})

    useEffect(()=>{
        if(props.description) {
            setDescription(props.description)
        }
    },[props.description])

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
        setUpdatedDescription({description: e.target.value})
    }

    const sendUpdatedDescription = () => {
        updateUserPortfolio(updatedDescription).then((res: IProfileInfo) => {
            apiSuccessHandler(<>{I18N('change_info_success')}</>)
            if (res) {
                props.setDescription(res.description)
            }
        }).catch(error => error)
    }

    const clearInfo = () => {
        if (props.description) {
            setDescription(props.description)
        }
        setUpdatedDescription({description: ""})
    }

    const textFieldStyles : React.CSSProperties = {
        color: "#fff !important",
        background: "#1f1f1f",
        borderRadius: "10px",
        marginBottom: ".5rem"
    }

    const textFieldSX : SxProps = {
        textArea: {
            "&::-webkit-input-placeholder": {
                color : '#cecece'
            },
            color: "#fff",
        }
    }

    return (
        <div className="ProfileAboutMe">
            {
                props.editAboutMe ? (
                    <>
                        <TextField multiline
                                   fullWidth
                                   placeholder={"Schreib etwas über dich..."}
                                   sx={textFieldSX}
                                   style={textFieldStyles}
                                   value={description || ""}
                                   onChange={(e)=> {
                                       handleDescriptionChange(e)
                                   }}
                        />
                        <button className="ProfileInformation__button-group__button"
                                onClick={() => {
                                    sendUpdatedDescription()
                                    props.setEditAboutMe(false)
                                }}
                        >
                            <Done/>
                        </button>
                        <button className="ProfileInformation__button-group__button"
                                onClick={() => {
                                    clearInfo()
                                    props.setEditAboutMe(false)
                                }}
                        >
                            <Clear/>
                        </button>
                    </>
                ) : (
                    description !== "" ? description : (props.isMyProfile ? "Schreib etwas über dich..." : props.firstName + " hat noch nichts über sich geschrieben..." )
                )
            }
        </div>
    );
};

export default ProfileAboutMe;
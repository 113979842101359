import React, {useEffect, useState} from 'react';
import './InstaGallery.scss'
import {BASE_PATH, CLIENT_ID_FOR_INSTAGRAM, FRONTEND_PATH} from "../../Helper/api.route";
import {useLocation} from "react-router-dom";
import {getInstaAccessToken} from "../../Helper/API";
import {InstagramGallery} from "instagram-gallery";

interface IProps {
    instagramData: any;
}

const InstaGallery = () => {

    const instaCode = useQuery('code')

    const [instaData, setInstaData] = useState<{access_token: string, user_id: string} | null>(null)

    function useQuery(key: string) {
        return new URLSearchParams(useLocation().search).get(key);
    }

    useEffect(()=>{
        if(instaCode){
            getInstagramAccessToken()
        }
    },[instaCode])

    const getInstagramAccessToken = () => {
        if(instaCode){
            getInstaAccessToken(instaCode!).then((res)=>{
                if(res){
                    setInstaData(res)
                }
            })
        }

    }

    return (
        <div className="InstagramGallery">
            <a href={`${BASE_PATH}/auth/instagram/redirect?client_id=${CLIENT_ID_FOR_INSTAGRAM}&redirect_uri=${BASE_PATH}/auth/instagram/redirect&scope=user_profile,user_media&response_type=code`}>
                Mit Instagram Verknüpfen
            </a>
            {
                instaData && <InstagramGallery accessToken={"IGQVJWakFWMmJBaTQ2X3VXMEV3aDlPaDBlejZAzNk85RjM0bWJuVTNtc1JBYXNYNG9mQ2dxV3VESkFWc0JRUENDeUR6d2xsT3JlOXg1cGs3SXpGVENocS1yUFNFZA3ZATLXB2aUJMQk94NkI2dS1NR01waUZA4RVBQcC1oSTcw"} count={8} />
            }
        </div>
    );
};

export default InstaGallery;
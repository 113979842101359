import { useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight } from '../../../components/Icons/Icons'
import { ImageSlider, ImageSliderElement } from '../ArtistsSection/ImageSlider/imageSlide'
import classes from './BigSlider.module.scss'

export function BigSlider(props: { imagess: ImageSliderElement[] }) {

    const [bigImage, setbigImage] = useState(0)

    useEffect(() => {
        let timer = setInterval(() => {
            changeActiveImage(1)
        }, 4000)
        return () => {
            clearInterval(timer)
        };
    }, [bigImage])

    function getImageByIndex(index: number): string {
        let searchedIndex = bigImage - index
        if (searchedIndex < 0) {
            searchedIndex = props.imagess.length + searchedIndex
        }

        return props.imagess[searchedIndex].imgSrc
    }

    function changeActiveImage(index) {
        let maybeeNewIndex = bigImage + index
        let newIndex = 0
        if (maybeeNewIndex < 0) {
            newIndex = props.imagess.length - 1
        } else if (maybeeNewIndex >= props.imagess.length) {
            newIndex = 0
        } else {
            newIndex = maybeeNewIndex
        }
        setbigImage(newIndex)
    }

    return (
        <section id="events" className={classes.section}>
            <div className={classes.sectionHeadline}><div>Events</div></div>
            <div className={classes.container}>
                <div className={classes.row1}>
                    <div className={classes.headline}>
                        READY TO INSPIRE YOU.
                    </div>
                    <div className={classes.bodyText}>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget do- lor. Aenean massa. Cum
                    </div>
                </div>
                <div className={classes.row2}>
                    <div className={classes.left}>
                        <div onClick={() => changeActiveImage(-1)} className={classes.switchButton}>
                            <ArrowLeft height={50} />
                        </div>
                        <img alt="artist" src={getImageByIndex(1)} className={classes.image}></img>
                        <img alt="artist" src={getImageByIndex(2)} className={classes.image}></img>
                    </div>
                    <div className={classes.center}>
                        <img alt="artist" src={getImageByIndex(4)} className={classes.image}></img>
                        <div className={classes.purpleBox}></div>
                    </div>
                    <div className={classes.left}>
                        <div onClick={() => changeActiveImage(1)} className={`${classes.switchButton} ${classes.moveToRight}`}>
                            <ArrowRight height={50} />
                        </div>
                        <img alt="artist" src={getImageByIndex(3)} className={classes.image}></img>
                    </div>
                </div>
            </div>
            <div className={classes.mobileContainer}>
                <ImageSlider elements={props.imagess} />
            </div>
        </section>
    )
}
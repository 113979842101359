import React, {useEffect, useRef, useState} from 'react';
import './ProfileHeader.scss'
import {IProfileJobs, IUser} from "../../../Helper/Interfaces/IProfile";
import {Button, Col, Container, Modal} from "react-bootstrap";
import {ProfilePicture, SendMessageFromProfileDialog} from "../ProfileModals/ProfileModals";
import {I18N} from "../../../i18n/i18n";
import {SaveChangeButton} from "../../../components/Buttons/Buttons";
import {
    addNewPrivateMessage,
    addUserToFavorites,
    deleteUserFromFavorites,
    uploadProfilePicture
} from "../../../Helper/API";
import {apiSuccessHandler} from "../../../Helper/api.services";
import {OpenChatIcon, StarFillIcon, StartEmptyIcon} from "../../../components/Icons/Icons";
import {CircularProgress} from "@mui/material";

interface IProfileHeaderProps {
    user: IUser,
    picture: string | null,
    jobs: Array<IProfileJobs> | [],
    isMyProfile: boolean | null,
    isFavorite: boolean | null,
}

const ProfileHeader = (props: IProfileHeaderProps) => {

    const [showPhotoUploadModal, setShowPhotoUploadModal] = useState<boolean>(false)
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false)
    const [profilePicture, setProfilePicture] = useState("")
    const [message, setMessage] = useState<{to: number, content: string}>({to: 0, content: ""})
    const [isFavorite, setIsFavorite] = useState<boolean | null>(false)
    const [profileImageLoading, setProfileImageLoading] = useState<boolean>(false)
    const uploadInputRef = useRef<HTMLInputElement>()

    const saveProfilePicture = (e) => {
        if (!uploadInputRef?.current?.files) return
        const data = new FormData();
        for (let file in uploadInputRef.current.files) {
            data.append('picture', uploadInputRef.current.files[file])
        }
        setShowPhotoUploadModal(false)
        setProfileImageLoading(true)
        uploadProfilePicture(data!).then(res => {
            if (!res.data) {
                setProfilePicture(res.picture)
                apiSuccessHandler(
                    <>
                        {I18N('change_picture_success', 'de', [{
                            key: 'change_picture_success',
                            value: I18N('change_picture_success', 'de')
                        }])}
                    </>
                );
            }
        }).catch(error => error).finally(()=>{
            setProfileImageLoading(false);
        });
    }

    const messageChangeHandler = (e) => {
        setMessage({to: props.user?.id, content: e.target.value})
    }

    const addUserFavorite = () => {
        addUserToFavorites(props.user?.id).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_add_favorite', 'de', [{
                        key: 'success_add_favorite',
                        value: I18N('success_add_favorite', 'de')
                    }])}
                </>
            )
            setIsFavorite(true)
        }).catch(error => error)
    }

    const deleteUserFavorite = () => {
        deleteUserFromFavorites(props.user?.id).then(res => {
            apiSuccessHandler(
                <>
                    {I18N('success_remove_favorite', 'de', [{
                        key: 'success_remove_favorite',
                        value: I18N('success_remove_favorite', 'de')
                    }])}
                </>
            )
            setIsFavorite(false)
        }).catch(error => error)
    }

    async function sendPrivateMessage(): Promise<void> {
        addNewPrivateMessage(message)
            .then(res => {
                if (res) {
                    apiSuccessHandler(
                        <>
                            {I18N('success_send_message', 'de', [{
                                key: 'success_send_message',
                                value: I18N('success_send_message', 'de')
                            }])}
                        </>
                    );
                    setMessage(prevState => ({...prevState, content: ""}))
                }
            }).catch(error => error);
    }

    useEffect(()=>{
        if (props.picture) {
            setProfilePicture(props.picture)
        } else {
            setProfilePicture("/user.jpg")
        }
        if (props.isFavorite !== undefined) {
            setIsFavorite(props.isFavorite)
        }
    },[])

    return (
        <div className="ProfileHeader">
            <Container className="ProfileHeader__wrapper d-flex">
                <div className="ProfileHeader__profile-image">
                    {
                        props.isMyProfile &&
                            <div className="ProfileInformation__button-group" style={{top: "-1rem", right: "-1rem"}}>
                                <button className="ProfileInformation__button-group__button"
                                        onClick={() => {
                                            setShowPhotoUploadModal(true)
                                        }}>
                                    <img src="/SVG/icon_edit_48px.svg"/>
                                </button>
                            </div>
                    }
                    {
                        profileImageLoading && (
                            <div style={{height: '200px', width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <CircularProgress/>
                            </div>
                        )
                    }
                    {
                        (profilePicture && !profileImageLoading) && <img src={profilePicture}
                                              className="ProfileHeader__profile-image__image"/>
                    }
                </div>
                <div className="ProfileHeader__profile">
                    <div className="ProfileHeader__profile-info">
                        <h3 className="ProfileHeader__profile-info__name">{props.user?.firstName} {props.user?.lastName} <span className="ProfileHeader__profile-info__name__type">({props.user?.type})</span>
                        </h3>
                        <p className="ProfileHeader__profile-info__jobs">
                            {
                                props.jobs?.length > 0 &&
                                props.jobs?.map((job, jobkey) => {
                                    return job.name + ((props.jobs?.length > 1) && (jobkey !== props.jobs?.length - 1) ? ', ' : '')
                                })
                            }
                        </p>
                    </div>
                    <div className="ProfileHeader__profile-action">
                        {(props.user?.type !== 'ADMIN' && props.isMyProfile === false) && <div className="d-flex">
                            <div onClick={()=>{setShowMessageModal(true)}} className="cursor_pointer ms-3">
                                <OpenChatIcon/>
                            </div>
                            <div className="ms-3">
                                {
                                    !isFavorite &&
                                        <div className="cursor_pointer" onClick={() => addUserFavorite()}>
                                            <StartEmptyIcon/>
                                        </div>
                                }
                                {
                                    isFavorite &&
                                        <div className="cursor_pointer" onClick={() => deleteUserFavorite()}>
                                            <StarFillIcon/>
                                        </div>
                                }
                            </div>
                        </div>}
                    </div>
                </div>
            </Container>
            <>
                <Modal show={showPhotoUploadModal} onHide={() => setShowPhotoUploadModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {I18N('change_profile_picture', 'de', [{
                                key: 'change_profile_picture',
                                value: I18N('change_profile_picture', 'de')
                            }])}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProfilePicture refHandler={uploadInputRef}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoUploadModal(false)}>
                            {I18N('close', 'de', [{
                                key: 'close',
                                value: I18N('close', 'de')
                            }])}
                        </Button>
                        <SaveChangeButton clickHandler={saveProfilePicture}/>
                    </Modal.Footer>
                </Modal>
            </>
            <>
                <Modal show={showMessageModal} onHide={()=>{setShowMessageModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {I18N('send_message', 'de', [{
                                key: 'send_message',
                                value: I18N('send_message', 'de')
                            }])}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendMessageFromProfileDialog changeHandler={messageChangeHandler}
                                                      state={message}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setShowMessageModal(false)}}>
                            {I18N('close', 'de', [{
                                key: 'close',
                                value: I18N('close', 'de')
                            }])}
                        </Button>
                        <SaveChangeButton text={I18N('send', 'de')} clickHandler={()=>{
                            setShowMessageModal(false)
                            sendPrivateMessage()
                        }}/>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    );
};

export default ProfileHeader;
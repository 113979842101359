import classes from "../../components/Styles/ProfileCards.module.scss";
import {Col, Row} from "react-bootstrap";
import {useState} from "react";
import {
    BOOKING_STATUS,
    BookingTechnicalStaff,
    BookingTechnology,
    iBookUser,
    ProfileBookingModal,
} from "./ProfileModals/ProfileModals";
import {
    addNewBooking,
} from "../../Helper/API";
import {apiSuccessHandler} from "../../Helper/api.services";
import {SendBookingRequestButton} from "../../components/Buttons/Buttons";
import {I18N} from "../../i18n/i18n";
import "react-on-scroll-animation/build/index.css";
import Rosa from "react-on-scroll-animation"

export function ProfileBooking({
                                 defaultBooking,
                                 setDefaultBooking,
                             }: any) {

    const [newBooking, setNewBooking] = useState<iBookUser>({
        startDate: defaultBooking?.startDate,
        endDate: defaultBooking?.endDate,
        message: defaultBooking?.message,
        locationId: defaultBooking?.locationId,
        occasionId: defaultBooking?.occasionId,
        technology: defaultBooking?.technology,
        technicalStaff: defaultBooking?.technicalStaff,
        numberOfHours: defaultBooking?.numberOfHours,
        name: defaultBooking?.name,
        pricePerHour: defaultBooking?.pricePerHour,
        status: defaultBooking?.status,
        userId: defaultBooking?.userId
    });

    const clearState = () => {
        setNewBooking({
            startDate: new Date().setDate(0).toString(),
            endDate: new Date().setDate(0).toString(),
            message: '',
            locationId: 0,
            occasionId: 0,
            technicalStaff: BookingTechnicalStaff.FALSE,
            technology: '',
            numberOfHours: 0,
            name: '',
            pricePerHour: 0,
            status: BOOKING_STATUS.PENDING,
            userId: defaultBooking.userId
        })
    }

    const [seeMoreBooking, setSeeMoreBooking] = useState(false);

    function handlerChange(event: any) {
        setNewBooking(prev => ({...prev, [event.target.name]: event.target.value}));
    }

    function handlerEndDate(newValue: Date | any) {
        setNewBooking(prev => ({...prev, endDate: newValue}));
    }

    function handlerStartDate(newValue: Date | any) {
        setNewBooking(prev => ({...prev, startDate: newValue}));
    }

    function selectBoolean(event: any) {
        if (event?.target?.value === 'YES') {
            setNewBooking(prev => ({...prev, [event.target.name]: true}));
        } else if (event?.target?.value === 'NO') {
            setNewBooking(prev => ({...prev, [event.target.name]: false}));
        }
    }

    function handleNumber(event: any) {
        setNewBooking(prev => ({...prev, [event.target.name]: +event.target.value}));
    }

    function handleBookingLocation(current: any) {
        setNewBooking(prev => ({...prev, locationId: current?.value}))
    }

    async function onCreateNewBooking(e: any): Promise<void> {
        addNewBooking({
            ...newBooking,
            startDate: new Date(newBooking.startDate!).toISOString(),
            endDate: new Date(newBooking.endDate!).toISOString()
        }).then(res => {
            if (!res.data) {
                setDefaultBooking(res)
                apiSuccessHandler(
                    <>
                        {I18N('send_booking_request', 'de', [{
                            key: 'send_booking_request',
                            value: I18N('send_booking_request', 'de')
                        }])}
                    </>
                );
                clearState();
                setSeeMoreBooking(false)
            }
        }).catch(error => error);
    }

    const showBookingCard = () => {
        setSeeMoreBooking(!seeMoreBooking);
    }

    return (
        <Row className="m-0 p-0">
            <Col xl={12} className="w-100">
                {seeMoreBooking ?
                    <Rosa animation={'fade-right'} offset={500} duration={500}>
                        <Row>
                            <ProfileBookingModal changeHandler={handlerChange} state={newBooking}
                                                 selectBoolean={selectBoolean}
                                                 handlerStartDate={handlerStartDate}
                                                 handlerEndDate={handlerEndDate}
                                                 selectLocation={handleBookingLocation}
                                                 changeHandlerNumber={handleNumber}/>
                            <Col xl={12} className="my-3 d-flex align-items-center justify-content-end">
                                <button className={`${classes.btn_primary_modal} me-3`}
                                        onClick={showBookingCard}>
                                    {I18N('see_less', 'de')}
                                </button>
                                <SendBookingRequestButton clickHandler={onCreateNewBooking}/>
                            </Col>
                        </Row>
                    </Rosa>
                    :
                    <Rosa animation={'fade-right'} offset={700} duration={500}>
                        <div className="d-flex justify-content-center">
                            <h4>
                                {I18N('see_more_about_booking', 'de')}
                            </h4>
                            <button className={classes.btn_primary_modal} onClick={showBookingCard}>
                                {I18N('see_more', 'de')}
                            </button>
                        </div>
                    </Rosa>
                }
            </Col>
        </Row>
    );
}